<template>
  <div class="navLink" @click="nav">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'navLink',
    props:{
      path:{
        type:String,
        default:""
      }
    },
    data(){
      return {
        
      }
    },
    created() {
      
    },
    mounted() {
      
    },
    computed:{
      
    },
    methods:{
     nav(){
       if(this.path){ 
        this.$router.push(this.path)
       }else{
         console.log("path is null");
       }
     }
    }
  }
</script>

<style lang="scss" scoped>
  .navLink{
    cursor: pointer;
  }
</style>

