import { render, staticRenderFns } from "./info_register.vue?vue&type=template&id=8da7acce&"
import script from "./info_register.vue?vue&type=script&lang=js&"
export * from "./info_register.vue?vue&type=script&lang=js&"
import style0 from "./info_register.vue?vue&type=style&index=0&id=8da7acce&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports