<template>
  <div class="userSafe">
    <card class="Card">
      <div class="card-title">账号安全</div>
      <div class="item" v-for="(item,index) of list" :key="index">
        <div class="ai-top row">
          <div class="row Regular item-name" v-html="item.name"></div>
          <div class="row flex-1 item-info">
            <div class="info flex-1">{{item.info}}</div>
            <div class="link pointer" v-if="item.link" @click="item.link(index)">{{item.linkText}}</div>
          </div>
        </div>
        <div class="sub" v-if="item.sub">{{item.sub}}</div>
      </div>
    </card>
  </div>
</template>

<script>
  export default {
    name: 'userSafe',
    data(){
      return {
        list:[
          {
            name:'账号密码：',
            info:'',
            link:this.navLink,
            linkText:'修改密码',
            sub:'修改密码需要扫码验证'
          },
          {
            name:'违规记录：',
            info:'',
            link:this.navLink,
            linkText:'详情',
          },
        ]
      }
    },
    created() {
      
    },
    mounted() {
      
    },
    computed:{
      
    },
    methods:{
      navLink(i){
        if(i == 0){
          this.$router.push('/page/password')
        }else{
          this.$router.push('/page/Violation')
        }
      }
    }
  }
</script>

<style lang="scss">
  .userSafe{
    .Card{
      padding: 11px 0 11px;
      .card-title{
        margin-bottom: 17px;
        margin-left: 17px;
      }
      
      .item{
        border-bottom: 1px solid rgba(151,151,151,0.13);
        padding-left: 18px;
        padding-top: 26px;
        padding-bottom: 10px;
        &:last-child{
          border: none;
        }
        .sub{
          margin-top: 21px;
          font-weight: 700;
        }
        .item-name{
          color: #666A82;
          width: 6em;
        }
        .item-info{
          margin-left: 18px;
          .link{
            color: #1564B9;
            margin-right: 24px;
            font-weight: 700;
          }
          .info{
            color: #3B3F56;
            font-size: 15px;
            line-height: 21px;
            &.images{
              .image{
                width: 161px;
                height: 114px;
              }
            }
          }
        }
      }
      
    }
  }
</style>
