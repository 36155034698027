import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import i18n from './i18n'
import * as echarts from 'echarts';
import config from './config/index.js'
import VueCropper from 'vue-cropper'
import Element from 'element-ui'
import emoji from './config/emoji.js'
import IM from './config/im.js'
Vue.prototype.IM = new IM()

Vue.prototype.$emoji = emoji




import waterfall from "vue-waterfall2";
Vue.use(waterfall);


import Button from '@/components/Button.vue'
import navBar from '@/components/navBar.vue'
import About from '@/components/About.vue'
import navLink from '@/components/navLink.vue'
import card from '@/components/card.vue'
import loadMore from '@/components/loadMore.vue'
import Switch from '@/components/m-Switch.vue'
import Popup from '@/components/Popup.vue'
import updataFile from '@/components/updataFile.vue'
import insert from '@/components/insert.vue'
import backtop from '@/components/backtop.vue'
import check from '@/components/check.vue'
import {formatDate} from '@/config/date.js';



Vue.use(VueCropper)
Vue.use(config)
Vue.component('Button', Button)
Vue.component('navBar', navBar)
Vue.component('About', About)
Vue.component('navLink', navLink)
Vue.component('loadMore', loadMore)
Vue.component('card', card)
Vue.component('m-switch', Switch)
Vue.component('Popup', Popup)
Vue.component('updataFile', updataFile)
Vue.component('insert', insert)
Vue.component('backtop', backtop)
Vue.component('check', check)
Vue.component('VueCropper', VueCropper)
Vue.filter('getDate',(val)=>{
  return formatDate(new Date(val),'yyyy-MM-dd')
})
Vue.filter('getTime',(val)=>{
  console.log(val);
  return formatDate(new Date(val),'yyyy-MM-dd hh:mm')
})
Vue.filter('getDuration',(val)=>{
  if(!val){
    val = 0
  }
  return formatSeconds(val)
})

Vue.prototype.$getDate = function (val){
  return formatDate(new Date(val),'yyyy-MM-dd')
}



Number.prototype.getDuration = function(){
  return formatSeconds(this)
}

Vue.prototype.loading = (val)=>{
  return Element.Message({
    dangerouslyUseHTMLString: true,
    iconClass:"el-icon-loading",
    message: val,
    center:true,
    duration:0
  });
}


Vue.config.productionTip = false
Vue.prototype.$store = store
Vue.prototype.echarts = echarts


Vue.prototype.getBase64Img = function(params,fun) {
  return new Promise((rel,rej)=>{
    if (params && params.url) {
      var that = this;
      var image = new Image();    
      image.src = params.url + "?" + Math.random();
      image.crossOrigin = 'anonymous';
      image.onload = function() {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, image.width, image.height);
        var ext = image.src.substring(image.src.lastIndexOf(".") + 1).toLowerCase();
        var dataURL = canvas.toDataURL("image/" + ext);
        if (params.callback) {
            if (params.data) {
                that[params.callback](dataURL, params.data);
            } else {
                that[params.callback](dataURL);
            }
        };
        fun && fun(dataURL)
        rel(dataURL)
        return dataURL;
      }
    }
  })
};



let $Vue = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

store.commit('VueSet',$Vue)

//去除空格
String.prototype.Trim = function() { 
  return this.replace(/\s+/g, ""); 
} 
//去除空格和换行 
String.prototype.ClearBr = function() { 
  return this.replace(/[\r\n]/g, "");; 
} 


// 时长格式化
function formatSeconds(value) {
    var theTime = parseInt(value);// 秒
    var theTime1 = 0;// 分
    var theTime2 = 0;// 小时
    if (theTime > 60) {
        theTime1 = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);
        if (theTime1 > 60) {
            theTime2 = parseInt(theTime1 / 60);
            theTime1 = parseInt(theTime1 % 60);
        }
    }
 
    var result = "" + parseInt(theTime);//秒
    if (10 > theTime > 0) {
        result = "0" + parseInt(theTime);//秒
    } else {
        result = "" + parseInt(theTime);//秒
    }
 
    if (10 > theTime1 > 0) {
        result = "0" + parseInt(theTime1) + ":" + result;//分，不足两位数，首位补充0，
    } else {
        result = "" + parseInt(theTime1) + ":" + result;//分
    }
    if (theTime2 > 0) {
        result = "" + parseInt(theTime2) + ":" + result;//时
    }
    return result;
}