<template>
  <div class="draftBox">
    <card class="Card row">
      <div class="name">{{$t('草稿箱')}}：</div>
      <div class="flex-1 number">{{count}}</div>
      <Button class="Button" @click="release">{{$t('发布')}}</Button>
    </card>
    <div class="cell row" ref="cell">
      <waterfall :col="col" :data="list" :style="{width:width+'px'}">
        <template>
          <div class="cell-item pointer" v-for="(item, index) in list" :key="index" @click="preview(item,index)">
            <img :src="item.coverImg" v-if="item.coverImg" />
            <div class="item-body">
              <div class="item-title">{{ item.title }}</div>
              <div class="item-desc Regular">{{ item.introduce }}</div>
              <div class="item-footer row pointer pos-rel">
                <div class="footer-time Regular flex-1">{{$t('更新于')}} {{item.updatedAt | getDate }}</div>
                <div class="more" @click.stop="item.menu = !item.menu"><img src="assets/image/more@2x.png" ></div>
                <!-- 菜单 -->
                <div class="menu" v-if="item.menu" @click.stop>
                  <div class="menu-item row js-center" @click.stop="deleteCr(item,index)">
                    <div class="menu-item-icon row"><img src="assets/image/delete@2x.png" ></div>
                    <div class="menu-item-text Regular">{{$t('删除')}}</div>
                  </div>
                  <div class="menu-item row js-center" @click.stop="editCr(item,index)">
                    <div class="menu-item-icon row"><img src="assets/image/edit@2x.png" ></div>
                    <div class="menu-item-text Regular">{{$t('编辑')}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </waterfall>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'draftBox',
    data(){
      return {
        col:3,
        width:795,
        list:[],
        page:1,
        count:0,
        total:0,
        search:''
      }
    },
    created() {
      this.$store.commit('searchSet',this.Search)
      this.init()
      
      // 监听全局click，关闭所有已开启的more
      window.onclick = (ev)=>{
        if(ev.target.id != 'menu' && this.$route.path == '/draftBox'){
          this.list.map((item,index)=>{
            this.list[index].menu = false
          })
        }
      }
    },
    mounted() {
      onresize = this.widthResize
    },
    computed:{
      
    },
    methods:{
      widthResize(){
        let width = this.$refs.cell.clientWidth
        this.col = parseInt(width / 256)
        // if(this.col>2){
        this.width = 265 * this.col
        // }
      },
      init(){
        let data = {
          "pageNum": this.page,
          "pageSize": 40,
          title:this.search
        }
        this.$apis.wz.getArticleDraft(data).then(res=>{
          this.count = res.data.total
          res.data.articles  = res.data.articles?res.data.articles:[]
          res.data.articles = res.data.articles.map(item=>{
            item.menu = false
            return item
          })
          if(this.page == 1){
            this.list = res.data.articles
          }else{
            this.list = [...this.list,...res.data.articles]
          }
          this.widthResize()
        })
      },
      Search(val){
        this.search = val
        this.page = 1
        this.init()
      },
      deleteCr(item,index){
        this.$confirm(this.$t('确定删除该草稿？'), this.$t('提示'), {
          confirmButtonText: this.$t('确定'),
          cancelButtonText: this.$t('取消'),
          type: 'warning'
        }).then(res=>{
          item.menu = false
          let loading = this.loading(this.$t("删除中……"))
          let data = {
            id:item.id
          }
          this.$apis.wz.deleteArticle(data).then(res=>{
            setTimeout(()=>{
              this.$message.success(this.$t("删除成功"))
              loading.close()
              this.page = 1
              this.init()
            },500)
          }).catch(err=>{
            loading.close()
          })
        })
        
      },
      editCr(item,index){
        item.menu = false
        this.$router.push('/page/release?id='+item.id+'&type=preview')
      },
      preview(item){
        this.$router.push('/preview?type=cgx&id='+item.id)
      },
      release(){
        this.$router.push('/page/release?type=release')
      },
    }
  }
</script>

<style lang="scss">
  .draftBox{
    .Card{
      padding: 0 26px;
      height: 51px;
      text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
      color: #696B76;
      line-height: 17px;
      
      .number{
        font-size: 26px;
        line-height: 31px;
        font-weight: bold;
      }
      
      .Button{
        width: 81px;
        height: 32px;
        box-shadow: 0px 2px 3px 0px rgba(220,231,239,0.5);
        border-radius: 5px;
      }
    }
    
    .cell{
      margin-top: 5px;
      .cell-item{
        width: 256px;
        background-color: #FFFFFF;
        box-shadow: 0px 2px 3px 0px rgba(220,231,239,0.5);
        border-radius: 5px;
        margin-top: 13px;
        overflow: hidden;
        img{
          opacity: 1;
        }
        .item-body{
          padding: 9px 15px 13px 9px;
          .item-title{
            
          }
          .item-desc{
            font-size: 11px;
            color: #696B76;
            line-height: 15px;
            text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
            margin-top: 4px;
          }
          .item-footer{
            margin-top: 14px;
            .footer-time{
              font-size: 10px;
              color: #696B76;
              line-height: 15px;
              text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
            }
            .more{
              width: 20px;
              height: 15px;
            }
            .menu{
              position: absolute;
              right: 0;
              bottom: 12px;
              width: 75px;
              background: rgba(0, 0, 0, 0.6);
              padding: 5px;
              border-radius: 5px;
              .menu-item{
                padding: 5px;
                .menu-item-icon{
                  width: 12px;
                  margin-right: 12px;
                }
                .menu-item-text{
                  font-size: 10px;
                  color: #FFFFFF;
                  line-height: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
