import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin:false,
    Vue:{},
    userInfo:{},
    scrollBottom:null,
    search:null,
    rongYunId:'',
    headerMsg:null,
    radeMsgNumber:0
  },
  getters:{
    isLogin(state){
      return state.isLogin
    },
    userInfo(state){
      return state.userInfo
    }
  },
  mutations: {
    VueSet(state,data){
      state.Vue = data
    },
    isLoginSet(state,data){
      state.isLogin = data
    },
    userInfoSet(state,data){
      // 登录融云
      Vue.prototype.$apis.login.getRongToken().then(res=>{
        state.rongYunId = res.data.rongYunId
        Vue.prototype.IM.connect(res.data.rongYunToken).then(ress=>{
          state.userInfo.userId = ress.data.userId
        }).catch(err=>{
          console.log(err);
        })
      })
      
      state.userInfo = data
    },
    scrollBottomSet(state,data){
      state.scrollBottom = data
    },
    searchSet(state,data){
      state.search = data
    },
    headerMsgSet(state,data){
      state.headerMsg = data
    },
    radeMsgNumberSet(state,data){
      state.radeMsgNumber = data
    }
  },
  actions: {
  },
  modules: {
  }
})
