<template>
  <div class="voteDetail">
    <card class="Card_">
      <div class="vote-info">
        <div class="vote-title">{{info.title}}</div>
        <div class="row vote-number">
          <div class="row"><img class="icon" src="assets/image/menu/eye@2x.png" ><span class="Regular">{{info.visitNumber || 0}}</span></div>
          <div class="row"><img class="icon" src="assets/image/menu/vote@2x.png" ><span class="Regular">{{info.voteNumber || 0}}</span></div>
          <div class="row"><img class="icon" src="assets/image/menu/people@2x.png" ><span class="Regular">{{info.partakeNumber || 0}}</span></div>
        </div>
        <div class="vote-desc Regular">{{'有效时间'.$t()}}：{{info.startTime | getTime}} - {{info.endTime | getTime}}</div>
        <div class="vote-desc Regular">{{'投票方式'.$t()}}：{{info.partakeMode == 1 ?'单选'.$t():'多选'.$t()}}</div>
        <div class="vote-desc Regular line-bottom">{{'投票机制'.$t()}}：{{info.voteSet == 1 ? '一人一票'.$t():'每天一票'.$t()}}</div>
        <div class="vote-items row">
          <div class="vote-item row col ai-top js-center" v-for="(item,index) of info.voteOptionInfoItemList" :key="index">
            <div class="vote-item-title line-1">{{'选项'+(index+1).toStringNumber()}}:{{item.name}}</div>
            <div class="vote-item-info Regular">{{item.partakeNumber}}{{'人参与'.$t()}}/{{item.voteNumber }}{{'票'.$t()}}</div>
          </div>
        </div>
      </div>
      <navBar :list="navBar.list" :current.sync="navBar.current" @change="getChart" class="navBar"></navBar>
      <div class="pos-rel">
        <div class="echarts-items">
          <div class="echarts-item row" v-for="(item,index) of series" :style="{'--echart-color':item.color}" :key="index">{{item.name}}</div>
        </div>
        <div class="echarts" ref="echarts"></div>
      </div>
    </card>
  </div>
</template>

<script>
  import * as echarts from 'echarts';
  export default {
    name: 'voteDetail',
    data(){
      return {
        id:0,
        Chart:null,
        series:[],
        navBar:{
          list:[
            {
              name:"访问量(次)".$t(),
              key:1,
              kLine:{}
            },
            {
              name:'参与人数(人)'.$t(),
              key:2,
              kLine:{}
            },
            {
              name:'票数(票)'.$t(),
              key:3,
              kLine:{}
            }
          ],
          current:0
        },
        info:{}
      }
    },
    created() {
      let o = this.$route.query
      if(o.id){
        this.id = Number(o.id)
        this.init()
      }
      Number.prototype.toStringNumber = function(){
        // 暂时只到99
        let numS1 = ['零','一','二','三','四','五','六','七','八','九','十','']
        let numS3 = ['十','百']
        if(this<=10){
          return numS1[this]
        }
        if(this>10 && this<20){
          return '十' + numS1[this % 10]
        }
        if(this>=20 && this < 100){
          let ge = (this % 10)
          if(ge == 0) ge = 11
          let shi = parseInt(this / 10)
          return numS1[shi] + '十' + numS1[ge]
        }
      }
    },
    mounted() {
      this.Chart = echarts.init(this.$refs.echarts)
      window.addEventListener('resize', ()=> {
        // 对每个拖拽圆点重新计算位置，并用 setOption 更新。
        this.Chart.resize()
        // this.getChart()
      });
    },
    computed:{
      
    },
    methods:{
      init(){
        this.$apis.menu.getVoteInfo({id:this.id}).then(res=>{
          this.info = res.data.voteInfoItem
        })
        this.$apis.menu.getVoteTotal({id:this.id}).then(res=>{
          this.navBar.list[0].kLine = res.data.voteTotalItemList[0]
          this.navBar.list[1].kLine = res.data.voteTotalItemList[1]
          this.navBar.list[2].kLine = res.data.voteTotalItemList[2]
          this.getChart()
        })
      },
      getChart(){
        let colors = ['#5F76F8','#F26B7A','#F26B7A','#25A943']
        let series = []
        this.navBar.list[this.navBar.current].kLine.dataItemList.map((item,index)=>{
          let seriesItem = {
              data: item.total,
              name:item.name,
              type: 'line',
              smooth: true,
              color:colors[index],
            }
            series.push(seriesItem)
        })
        this.series = series
        
        
        let option = {
          xAxis: {
            type: 'category',
            data: this.navBar.list[this.navBar.current].kLine.strDayList
          },
          yAxis: {
            type: 'value'
          },
          series: series,
          tooltip: {
            show:true,
            borderWidth:0,
            textStyle:{
              color:'#ffffff',
              fontSize:10,
              textShadow:'0px 2px 3px rgba(220, 231, 239, 0.5)'
            },
            backgroundColor:"rgba(0,0,0,0.6)",
            formatter: arg=>{
              if(this.navBar.current == 1){
                return arg.seriesName + '：' + arg.data + '人'.$t()
              }
              if(this.navBar.current == 2){
                return arg.seriesName + '：' + arg.data + '票'.$t()
              }else{
                return arg.seriesName + '：' + arg.data + '次'.$t()
              }
            }
          }
        };
        this.Chart.setOption(option,true);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .voteDetail{
    .Card_{
      .vote-info{
        padding: 20px 22px;
        .vote-title{
          font-size: 15px;
          color: #3B3F56;
          line-height: 21px;
          text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
        }
        
        .vote-number{
          margin-top: 15px;
          padding-bottom: 10px;
          .row{
            margin-right: 15px;
            .icon{
              width: 15px;
              margin-right: 4px;
            }
            span{
              font-size: 10px;
              color: #989BB0;
              line-height: 15px;
              text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
            }
          }
        }
        
        .vote-desc{
          margin-top: 9px;
          font-size: 12px;
          color: #696B76;
          line-height: 17px;
          text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
          &.line-bottom::after{
            content: '';
            display: block;
            height: 1px;
            background: #DBDBE7;
            box-shadow: 0px 2px 3px 0px rgba(220,231,239,0.5);
            opacity: 0.26;
            margin-top: 25px;
          }
        }
        
        .vote-items{
          margin-top: 17px;
          .vote-item{
            width: 183px;
            height: 84px;
            box-shadow: 0px 2px 3px 0px rgba(220,231,239,0.5);
            border: 1px dashed #E3E3E3;
            margin-right: 14px;
            padding: 10px;
            .vote-item-title{
              font-size: 12px;
              width: 163px;
              color: #3B3F56;
              line-height: 17px;
              text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
            }
            .vote-item-info{
              font-size: 12px;
              color: #696B76;
              line-height: 17px;
              text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
              margin-top: 12px;
            }
          }
        }
        
      }
      
    }
    
    
    .navBar{
      /deep/.nav-bar{
        box-shadow:none;
      }
    }
    .echarts-items{
      position: absolute;
      bottom: 95%;
      right: 24px;
      .echarts-item{
        &::before{
          content: '';
          display: inline-block;
          width: 24px;
          height: 2px;
          background: var(--echart-color);
          box-shadow: 0px 2px 3px 0px rgba(220,231,239,0.5);
          margin-right: 7px;
        }
      }
    }
    .echarts{
      width: 100%;
      height: 254px;
    }
  }
</style>
