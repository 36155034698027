<template>
  <div class="home" :class="{login:isLogin}">
    <Header></Header>
    <div class="content-box">
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import TabBar from '@/components/TabBar.vue'
let timer
export default {
  name: 'Home',
  components: {
    Header,
    TabBar
  },
  data(){
    return{
      top:0
    }
  },
  computed:{
    isLogin(){
      return this.$store.getters.isLogin
    }
  },
  created() {
    
  },
  methods:{
    // 内容区域滚动到底部的方法
    handleScroll(ev){
      if(ev.target.scrollHeight - ev.target.scrollTop <= this.$refs.content.clientHeight){
        timer = setTimeout(()=>{
          clearTimeout(timer)
          // 调用全局的方法
          this.$store.state.scrollBottom && this.$store.state.scrollBottom()
        },200)
      }
    }
  }
}
</script>

<style lang="scss">
  .home{
    height: 100vh;
    min-width: 1024px;
    margin: 0 auto;
    background-color: #F9FBFD;
    &.login{
      display: flex;
      flex-direction: column;
    }
    .content-box{
      display: flex;
      flex: 1;
      .content{
        flex: 1;
        padding: 0 27px 40px 14px;
        height: calc(100vh - 77px);
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar{
          width:5px !important;
          height:5px !important;
          display: block;
        }
        &::-webkit-scrollbar-track{
          background-color: rgb(239, 239, 239);
          border-radius:5px;
        }
        &::-webkit-scrollbar-thumb{
          background-color: #bfbfbf;
          border-radius:5px;
        }
        &::-webkit-scrollbar-thumb:hover{
          background: #333;
        }
        /**
         * // ::-webkit-scrollbar 滚动条整体部分
        // ::-webkit-scrollbar-thumb  滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
        // ::-webkit-scrollbar-track  滚动条的轨道（里面装有Thumb）
        // ::-webkit-scrollbar-button 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
        // ::-webkit-scrollbar-track-piece 内层轨道，滚动条中间部分（除去）
        // ::-webkit-scrollbar-corner 边角，即两个滚动条的交汇处
        // ::-webkit-resizer 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
         */
      }
    }
    
    .login-page{
      flex: 1;
    }
    
  }
</style>
