// import store from './store'
import host from './host.js'
/**
 * 阿里OSS 上传参数
 */
const OSS = require('ali-oss');
const client = new OSS({
    region: 'oss-cn-beijing',
    accessKeyId: 'LTAI4GEeqNjgrtwjBiaAfjgu',
    accessKeySecret: 'W2VJK6fhjs75NnzYIAVhrwKwTtweQ4',
    refreshSTSTokenInterval: 300000,
    bucket: 'zksymmcyy'
});

/**
 * 所有接口地址
 */
class apis {
  constructor(axios) {
    this.axios = axios
  }
  login = {
    scanLogon:()=>{
      return this.axios.post('/loginService/scanLogon',{
        userId:0
      })
    },
    login:(data)=>{
      return this.axios.post('/loginService/logon',data)
    },
    // 发送验证码
    sendSms:(data)=>{
      return this.axios.post('/loginService/sendSms',data)
    },
    // 验证验证码
    validateSms:(data)=>{
      return this.axios.post('loginService/validateSms',data)
    },
    // 注册
    register:(data)=>{
      return this.axios.post('/loginService/register',data)
    },
    // 获取二维码
    getQrCode:(data)=>{
      return this.axios.post('/loginService/getQrCode',data)
    },
    // 验证二维码
    getScanResult:(data)=>{
      return this.axios.post('/loginService/getScanResult',data)
    },
    // 登录后二维码
    getConfirmScan:(data)=>{
      return this.axios.post('/loginService/getConfirmScan',data)
    },
    
    // 信息登记
    accountInfoRegister:(data)=>{
      return this.axios.post('/loginService/accountInfoRegister',data)
    },
    // 修改手机
    replacePhone:(data)=>{
      return this.axios.post('/loginService/replacePhone',data)
    },
    // 重置密码
    forgotPassword:(data)=>{
      return this.axios.post('/loginService/forgotPassword',data)
    },
    // 融云登录
    getRongToken:(data)=>{
      return this.axios.post('/toolService/getRongToken',data)
    },
    
  }
  
  home = {
    // 是否有未读消息
    getUnreadMessage:(data)=>{
      return this.axios.post('/homeServer/getUnreadMessage',data)
    },
    // 获取首页统计数据
    getHomeTotal:(data)=>{
      return this.axios.post('/homeServer/getHomeTotal',data)
    },
    // 获取首页图形数据
    getHomeFigureData:(data)=>{
      return this.axios.post('/homeServer/getHomeFigureData',data)
    },
  }
  
  wz = {
    // 文章列表
    getArticlePageList:(data)=>{
      return this.axios.post('/articleService/getArticlePageList',data)
    },
    // 下架 / 发布
    articlesLowerFrame:(data)=>{
      return this.axios.post('/articleService/articlesLowerFrame',data)
    },
    // 删除
    deleteArticle:(data)=>{
      return this.axios.post('/articleService/deleteArticle',data)
    },
    // 文章详情
    queryArticleInfo:(data)=>{
      return this.axios.post('/articleService/queryArticleInfo',data)
    },
    // 添加文章
    addArticleDraft:(data)=>{
      return this.axios.post('/articleService/addArticleDraft',data)
    },
    // 编辑
    renewArticleDraft:(data)=>{
      return this.axios.post('/articleService/renewArticleDraft',data)
    },
    // 草稿列表
    getArticleDraft:(data)=>{
      return this.axios.post('/articleService/getArticleDraft',data)
    },
    // 添加投票
    addVoteTopic:(data)=>{
      if(data.id){
        return this.axios.post('/voteTopicService/updateVoteTopic',data)
      }else{
        return this.axios.post('/voteTopicService/addVoteTopic',data)
      }
    },
    // 添加投票选项
    addVoteOption:(data)=>{
      if(data.id){
        return this.axios.post('/voteOptionService/updateVoteOption',data)
      }else{
        return this.axios.post('/voteOptionService/addVoteOption',data)
      }
    },
    // 删除投票
    deleteVoteTopic:(data)=>{
      return this.axios.post('/voteTopicService/deleteVoteTopic',data)
    },
    
  }
  
  sc = {
    // 获取素材分组
    getGroupingList:(data)=>{
      return this.axios.post('/materialGroupingService/getGroupingList',data)
    },
    // 获取素材分组-上传时
    queryGroupingList:(data)=>{
      return this.axios.post('/materialGroupingService/queryGroupingList',data)
    },
    // 添加素材分组
    addGrouping:(data)=>{
      return this.axios.post('/materialGroupingService/addGrouping',data)
    },
    // 删除素材分组
    removeGrouping:(data)=>{
      return this.axios.post('/materialGroupingService/removeGrouping',data)
    },
    // 修改素材分组
    updateMaterialGrouping:(data)=>{
      return this.axios.post('/materialGroupingService/updateMaterialGrouping',data)
    },
    // 获取素材列表
    getGroupingMaterial:(data)=>{
      return this.axios.post('/materialService/getGroupingMaterial',data)
    },
    // 添加素材
    addMaterial:(data)=>{
      return this.axios.post('/materialService/addMaterial',data)
    },
    // 添加素材
    upload:(data,Progress)=>{
      return new Promise((resolve,reject)=>{
        let name = data.name
        let suffix = name.substr(name.indexOf("."));
        let FileName = new Date().getTime();
        let storeAs = FileName + suffix;
        client.multipartUpload('files/'+storeAs, data, {
           progress: Progress,
           parallel: 5,
           // 设置分片大小。默认值为1 MB，最小值为100 KB。
           partSize: 1024*1024,
        }).then(result=>{
           let imgUrl = 'https://zksymmcyy.oss-cn-beijing.aliyuncs.com/' + result.name;
           resolve(imgUrl)
        }).catch(err=>{
          reject(err)
        })
      })
    },
    // 批量删除
    batchRemove:(data)=>{
      return this.axios.post('/materialService/batchRemove',data)
    },
    
    // 修改信息
    updateMaterial:(data)=>{
      return this.axios.post('/materialService/updateMaterial',data)
    },
    
    // 删除素材
    deleteMaterial:(data)=>{
      return this.axios.post('/materialService/deleteMaterial',data)
    },
    
    // 移动素材分组
    moveGrouping:(data)=>{
      return this.axios.post('/materialService/moveGrouping',data)
    },
    
    // 添加水印
    uploadArticlePhoto:(data)=>{
      return this.axios.post('/toolService/uploadArticlePhoto',data)
    },
    
    
  }
  
  fs = {
    // 获取粉丝列表
    getFansList:(data)=>{
      return this.axios.post('/userFollowService/getFansList',data)
    },
    // 拉黑粉丝
    setFansState:(data)=>{
      return this.axios.post('/userFollowService/setFansState',data)
    },
    // 修改粉丝备注名
    setRemarkName:(data)=>{
      return this.axios.post('/userFollowService/setRemarkName',data)
    },
  }
  
  user = {
    // 获取公众号基本信息
    getOfficialAccountInfo:(data)=>{
      return this.axios.post('/officialAccountService/getOfficialAccountInfo',data)
    },
    // 编辑功能设置
    renewSetUp:(data)=>{
      return this.axios.post('/officialAccountService/renewSetUp',data)
    },
    // 违规记录
    getPunishRecord:(data)=>{
      return this.axios.post('/accountComplaintService/getPunishRecord',data)
    },
    // 编辑资料
    renewAccountInfo:(data)=>{
      return this.axios.post('/officialAccountService/renewAccountInfo',data)
    },
    // 根据字段条件查询公众号运营人员管理信息列表
    queryOperateMgt:(data)=>{
      return this.axios.post('/operateMgtService/queryOperateMgt',data)
    },
    // [商家] 绑定管理人员
    addManagement:(data)=>{
      return this.axios.post('/operateMgtService/addManagement',data)
    },
    // [商家] 添加运营人员
    addOperators:(data)=>{
      return this.axios.post('/operateMgtService/addOperators',data)
    },
    
    // [商家] 解除运营人员
    deleteOperateMgt:(data)=>{
      return this.axios.post('/operateMgtService/deleteOperateMgt',data)
    },
    // [商家] 验证密码
    validatePassword:(data)=>{
      return this.axios.post('/loginService/validatePassword',data)
    },
    // [商家]修改密码
    renewPassword:(data)=>{
      return this.axios.post('/loginService/renewPassword',data)
    },
    
    
    
    // [OO] 搜索oo账号
    getFriendsListSearch:(data)=>{
      return this.axios.post(host.ooHost + '/system/appuser/getFriendsListSearch',data)
    },
  }
  
  msg = {
    // 获取系统消息
    getMessageList:(data)=>{
      return this.axios.post('/systemMessageService/getMessageList',data)
    },
    // 系统消息已读
    readMessage:(data)=>{
      return this.axios.post('/systemMessageService/readMessage',data)
    },
  }
  
  menu = {
    // 投票数据列表
    getVotePage:(data)=>{
      return this.axios.post('/voteTopicService/getVotePage',data)
    },
    // 投票数据详情
    getVoteInfo:(data)=>{
      return this.axios.post('/voteTopicService/getVoteInfo',data)
    },
    // 投票统计数据详情
    getVoteTotal:(data)=>{
      return this.axios.post('/voteTopicService/getVoteTotal',data)
    },
    // 删除投票数据列表
    deleteVoteTopic:(data)=>{
      return this.axios.post('/voteTopicService/deleteVoteTopic',data)
    },
    // 获取菜单
    getMenu:(data)=>{
      return this.axios.post('/customizeMenuService/getMenu',data)
    },
    // 添加菜单
    addMenu:(data)=>{
      return this.axios.post('/customizeMenuService/addMenu',data)
    },
    // 编辑菜单
    renewMenu:(data)=>{
      return this.axios.post('/customizeMenuService/renewMenu',data)
    },
    // 删除菜单
    deleteCustomizeMenu:(data)=>{
      return this.axios.post('/customizeMenuService/deleteCustomizeMenu',data)
    },
    // 添加子菜单
    addSubmenu:(data)=>{
      return this.axios.post('/customizeSubmenuService/addSubmenu',data)
    },
    // 编辑子菜单
    renewSubmenu:(data)=>{
      return this.axios.post('/customizeSubmenuService/renewSubmenu',data)
    },
    // 删除子菜单
    deleteCustomizeSubmenu:(data)=>{
      return this.axios.post('/customizeSubmenuService/deleteCustomizeSubmenu',data)
    },
    // 编辑自动回复
    renewAutoReply:(data)=>{
      return this.axios.post('/autoReplyService/renewAutoReply',data)
    },
    // 查询自动回复
    queryAutoReply:(data)=>{
      return this.axios.post('/autoReplyService/queryAutoReply',data)
    },
    // 添加关键字自动回复/编辑关键字自动回复
    addReplyKeyword:(data)=>{
      if(data.id){
        return this.axios.post('/autoReplyKeywordService/renewReplyKeyword',data)
      }else{
        return this.axios.post('/autoReplyKeywordService/addReplyKeyword',data)
      }
    },
    // 查询关键字自动回复
    queryAutoReplyKeyword:(data)=>{
      return this.axios.post('/autoReplyKeywordService/getAutoReplyKeywordList',data)
    },
    // 开关关键字自动回复
    renewKeywordSwitch:(data)=>{
      return this.axios.post('/autoReplyKeywordService/renewKeywordSwitch',data)
    },
    // 删除关键字自动回复
    deleteAutoReplyKeyword:(data)=>{
      return this.axios.post('/autoReplyKeywordService/deleteAutoReplyKeyword',data)
    },
    // 获取融云用户信息
    getRongInfo:(data)=>{
      return this.axios.post(host.ooHost + '/system/appuser/getManyUser',{
        forceIdOrder:0,
        id:data
      })
    },
  }
}


export default {
  install(Vue,axios = {}){
    Vue.prototype.$apis = new apis(axios)
  }
}