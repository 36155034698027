<template>
  <div class="preview row ai-top">
    <div class="btns row col">
      <Button class="button" @click="articlesLowerFrame" v-if="type == 'cgx'">{{$t('发布')}}</Button>
      <Button class="button button-ho" @click="deleteCr" v-if="type == 'cgx'">{{$t("删除")}}</Button>
      <Button class="button button-ho" @click="edit" v-if="type == 'cgx'">{{$t("编辑")}}</Button>
      <Button class="button button-ho" @click="back">{{$t("关闭")}}</Button>
    </div>
    <div class="content-box flex-1">
      <div class="wz-title">{{title}}</div>
      <div class="wz-user-name-time" :style="{opacity: publishTime?1:0}">{{nickname}} {{publishTime | getDate}}</div>
      <div v-html="content" style="margin-top: 24px;word-break: break-all;line-height: normal;"></div>
      <!-- 投票数据 -->
      <div class="votes">
        <div class="vote-item" v-for="(item,index) of votes" :key="index">
          <div class="vote-title row">
            <span>{{item.title}}</span>
          </div>
          <div class="vote-time Regular">有效时间：{{item.stareDate | getTime}} 至 {{item.endDate | getTime}}</div>
          <div class="options">
            <div class="options-item" v-for="(option,i) of item.options" :key="'o'+i">
              <check :label="option.name"></check>
            </div>
          </div>
        </div>
      </div>
    </div>
   <!-- <div class="other-info row col">
      <div class="qrcode"><img v-if="qRCode" :src="qRCode" ></div>
      <div class="other-info-title">使用“妈妈创业营生活”App扫码<br>预览手机效果</div>
    </div> -->
  </div>
</template>

<script>
  export default {
    name: 'preview',
    data(){
      return {
        id:0,
        content:'',
        title:"",
        coverImg:"",
        summary:"",
        comment:"",
        great:"",
        publishTime:0,//发布时间
        nickname:"",
        type:'cgx',
        qRCode:'',
        votes:[]
      }
    },
    created() {
      let o = this.$route.query
      if(o.id){
        this.id = o.id
        this.type = o.type
        this.init()
      }
    },
    mounted() {
      
    },
    computed:{
      
    },
    watch:{
    },
    methods:{
     init(){
       this.$apis.wz.queryArticleInfo({id:this.id}).then(res=>{
         // 自定义化投票数据
         res.data.voteTopicResponseList = res.data.voteTopicResponseList.map(item=>{
           item.options = item.voteOptionResponseList
           item.stareDate = item.startTime
           item.endDate = item.endTime
           return item
         })
         this.votes = res.data.voteTopicResponseList
         let data = res.data.articleItem
         this.title = data.title
         this.content = data.content
         this.coverImg = data.coverImg
         this.summary = data.introduce
         this.publishTime  = data.publishTime
         this.nickname  = data.nickname
         this.qRCode  = data.qRCode
         this.comment = data.comment == 1
         this.great == data.fabulous == 1
       })
     },
     edit(){
       this.$router.push('/page/release?id='+this.id+'&type=preview')
     },
     back(){
       this.$router.back()
     },
     deleteCr(){
       this.$confirm(this.$t('确定删除该文章吗?'), this.$t('提示'), {
          confirmButtonText: this.$t('确定'),
          cancelButtonText: this.$t('取消'),
          type: 'warning'
       }).then(() => {
          let loading = this.loading(this.$t("删除中……"))
          let data = {
            id:this.id
          }
          this.$apis.wz.deleteArticle(data).then(res=>{
            setTimeout(()=>{
              this.$message.success(this.$t("删除成功"))
              loading.close()
              this.$router.back()
            },500)
          }).catch(err=>{
            loading.close()
          })
       })
       
     },
     articlesLowerFrame(){
       this.$confirm(this.$t('确定发布该文章吗?'), this.$t('提示'), {
          confirmButtonText: this.$t('确定'),
          cancelButtonText: this.$t('取消'),
          type: 'warning'
       }).then(() => {
          let loading = this.loading(this.$t("发布中"))
          let data = {
            articleId:this.id,
            type:2
          }
          this.$apis.wz.articlesLowerFrame(data).then(res=>{
            loading.close()
            this.$message.success(this.$t('发布成功,请等待管理员审核'))
            this.$router.push('/myCreation')
          }).catch(err=>{
            loading.close()
          })
       })
       
     }
    }
  }
</script>

<style lang="scss">
  .preview{
    padding-top: 39px;
    min-width: 1024px;
    .btns{
      min-width: 150px;
      flex: 105;
      .button{
        width: 81px;
        height: 32px;
        border-radius: 5px;
        margin-bottom: 18px;
      }
    }
    
    .content-box{
      flex: 706;
    }
    
    .wz-title{
      font-size: 18px;
      color: #3B3F56;
      line-height: 25px;
      text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
      margin-top: 12px;
      font-weight: 700;
    }
    .wz-user-name-time{
      margin-top: 10px;
    }
    
    .other-info{
      width: 168px;
      flex: 168;
      border-left: 1px solid #DBDBE7;
      height: calc(100vh - 39px);
      .qrcode{
        width: 87px;
        height: 87px;
        margin-top: 50px;
      }
      .other-info-title{
        font-size: 9px;
        color: #3B3F56;
        line-height: 18x;
        text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
        text-align: center;
        margin-top: 9px;
      }
    }
    
    .votes{
      // padding-left: 13px;
      .vote-item{
        margin-top: 19px;
        .vote-title{
          font-size: 14px;
          color: #3B3F56;
          line-height: 19px;
          .vote-icon{
            width: 12px;
            height: 13px;
            margin-left: 4px;
          }
          .vote-edit{
            color: #2C7BE5;
            margin-left: 4px;
          }
        }
        .vote-time{
          font-size: 14px;
          color: #696B76;
          line-height: 19px;
          margin-top: 9px;
        }
        .options{
          padding-top: 2px;
          padding-bottom: 27px;
          .options-item{
            margin-top: 16px;
          }
        }
      }
    }
    
  }
</style>
