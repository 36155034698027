<template>
  <div class="customizeMenu">
    <card class="Card">
      <div class="card-title_">自定义菜单</div>
      <div class="row ai-top">
        <div class="menu-image row col">
          <div class="menu-image_ pos-rel">
            <img class="w-100 h-100" src="assets/image/pic@2x.png" >
            <div class="menu row">
              <div class="menu-item row js-center pos-rel pointer" v-for="(item,index) of menu" :key="index" @click="selectMenu(index,-1)">
                <span :class="{activeMenu:currentType == 1 && current[0] == index}">{{item.name}}</span>
                <div class="menu-sub" v-if="current[0] == index">
                  <div class="menu-sub-item row js-center" @click.stop="addSubMenu" v-if="item.submenuItemList.length < 5">添加子菜单</div>
                  <div class="menu-sub-item row js-center"
                    :class="{activeMenu:currentType == 2 && current[1] == idx}"
                    v-for="(it,idx) of item.submenuItemList"
                    :key="'sub' + idx"
                     @click.stop="selectMenu(index,idx)"
                   >{{it.name}}</div>
                </div>
              </div>
              <div class="menu-item row js-center pointer" v-if="menu.length < 3" @click="addMenu">添加菜单</div>
            </div>
          </div>
          <div class="menu-image-tip Regular">选择或添加菜单进行内容编辑</div>
        </div>
        <div class="menu-edit flex-1">
          <div class="menu-edit-title row">
            <div class="flex-1" v-if="currentType == 1">菜单设置</div>
            <div class="flex-1" v-else>子菜单设置</div>
            <div class="delete-menu pointer" @click="deleteMenu">删除菜单</div>
          </div>
          <div class="menu-edit-name row m-t-17" v-if="menu.length">
            <div class="name">
              菜单名称
            </div>
            <input v-if="currentType == 1" class="input flex-1" v-model="menu[current[0]].name" type="text" @input="onInput($event,menu[current[0]])" />
            <input v-else class="input flex-1" v-model="menu[current[0]].submenuItemList[current[1]].name" type="text" @input="onInput($event,menu[current[0]].submenuItemList[current[1]])" />
          </div>
          <div class="menu-edit-name row">
            <div class="name"></div>
            <div class="tips row">
              <img class="tips-img" src="assets/image/notice@2x.png" >
              <span class="tips-text">{{$t('仅支持中英文和数字，菜单名称不超过4个汉字或8个字母')}}</span>
            </div>
          </div>
          <div class="menu-edit-name row m-t-17" v-if="currentType == 2">
            <div class="name">菜单排序</div>
            <div class="btns row">
              <Button class="button" @click="move('up')"><img src="assets/image/up@2x.png" >上移</Button>
              <Button class="button" @click="move('down')"><img src="assets/image/down@2x.png" >下移</Button>
            </div>
          </div>
          
          <div class="menu-edit-name row m-t-17" v-if="currentType == 1">
            <div class="name">菜单排序</div>
            <div class="btns row">
              <Button class="button" @click="move('left')"><img src="assets/image/up@2x.png" style="transform: rotate(270deg);">左移</Button>
              <Button class="button" @click="move('right')">右移<img src="assets/image/down@2x.png" style="margin-right: 0;transform: rotate(270deg);margin-left:11px;"></Button>
            </div>
          </div>
          <template v-if="currentType == 2">
            <div class="menu-edit-name row m-t-17">
              <div class="name">子菜单内容</div>
              <div class="checkes row">
                <check class="checked_" :label="'发消息'.$t()" :checked="contentType == 1" @change="contentType = 1"></check>
                <check class="checked_" :label="'跳转外部链接'.$t()" :checked="contentType == 2" @change="contentType = 2"></check>
                <check class="checked_" :label="'发送文章'.$t()" :checked="contentType == 3" @change="contentType = 3"></check>
              </div>
            </div>
            <template v-if="contentType == 1">
              <div class="res-content row col">
                <div class="send-type-head row">
                  <div class="send-type pointer row Regular" :class="{active:sendType == 1}" @click="sendType = 1">
                    <img src="assets/image/menu/word_selected@2x.png" v-if="sendType == 1">
                    <img src="assets/image/menu/word@2x.png" v-else>
                    <span>文字</span>
                  </div>
                  <div class="send-type pointer row Regular" :class="{active:sendType == 2}" @click="sendType = 2">
                    <img src="assets/image/menu/pic_selected@2x.png" v-if="sendType == 2">
                    <img src="assets/image/menu/pic_2@2x.png" v-else>
                    <span>图片</span>
                  </div>
                  <div class="send-type pointer row Regular" :class="{active:sendType == 3}" @click="sendType = 3">
                    <img src="assets/image/menu/video_selected@2x.png" v-if="sendType == 3">
                    <img src="assets/image/menu/video_2@2x.png" v-else>
                    <span>视频</span>
                  </div>
                  <div class="send-type pointer row Regular" :class="{active:sendType == 4}" @click="sendType = 4">
                    <img src="assets/image/menu/music_selected@2x.png" v-if="sendType == 4">
                    <img src="assets/image/menu/music_2@2x.png" v-else>
                    <span>音乐</span>
                  </div>
                </div>
                <div class="send-type-content" v-if="sendType == 1">
                  <textarea class="textarea" v-model="messageContent"></textarea>
                  <div class="emoji row">
                    <div class="emoji-icon pointer" @click="insert('emoji')"><img src="assets/image/menu/smile_2@2x.png" ></div>
                  </div>
                </div>
                <div class="send-type-content row js-center col pointer" v-if="sendType == 2"  @click="insert('image')">
                  <template v-if="!file.image.path">
                    <div class="sc-icon"><img src="assets/image/menu/inventory@2x.png" ></div>
                    <div class="sc-text Regular">从素材库选择</div>
                  </template>
                  <template v-else>
                    <div class="file row ai-top w-100">
                      <img :src="file.image.path" class="image" >
                      <div class="image-name line-1 Regular">{{file.image.name}}</div>
                      <div class="link pointer" @click.stop="deleteInfo('image')">删除</div>
                    </div>
                  </template>
                </div>
                <div class="send-type-content row js-center col" v-if="sendType == 3" @click="insert('video')">
                  <template v-if="!file.video.path">
                    <div class="sc-icon"><img src="assets/image/menu/inventory@2x.png" ></div>
                    <div class="sc-text Regular" >从素材库选择</div>
                  </template>
                  <template v-else>
                    <div class="file row ai-top w-100">
                      <video class="image" autoplay controls>
                        <source :src="file.video.path" type="video/mp4"></source>
                      </video>
                      <div class="image-name line-1 Regular">{{file.video.name}}</div>
                      <div class="link pointer" @click.stop="deleteInfo('video')">删除</div>
                    </div>
                  </template>
                </div>
                <div class="send-type-content row js-center col" v-if="sendType == 4" @click="insert('audio')">
                  <template v-if="!file.audio.path">
                    <div class="sc-icon"><img src="assets/image/menu/inventory@2x.png" ></div>
                    <div class="sc-text Regular">从素材库选择</div>
                  </template>
                  <template v-else>
                    <div class="file row ai-top w-100">
                      <div class="audio-item pointer">
                        <div class="title-audio line-1">{{file.audio.name}}</div>
                        <div class="length Regular">{{file.audio.duration | getDuration}}</div>
                        <div class="play-icon"><img src="assets/image/sc/play_an@2x.png" ></div>
                      </div>
                      <div class="link pointer" @click.stop="deleteInfo('audio')">删除</div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
            <template v-if="contentType == 2">
              <div class="res-content padding_">
                <div class="send-title">设置点击页面需要跳转的网页地址</div>
                <div class="input__ row">
                  <div class="input-name row">网页地址</div>
                  <input type="text" class="input-in" v-model="messageContent" />
                </div>
              </div>
            </template>
            <template v-if="contentType == 3">
              <div class="res-content padding_">
                <div class="send-title">选择需要跳转的公众号文章</div>
                <div class="row" style="margin-top: 9px;">
                  <div class="row pointer" v-if="!file.article.name" @click="selectWz" style="color: #1564B9;">选择文章</div>
                  <div class="row w-100" v-else>
                    <div v-html="file.article.html" class="flex-1"></div>
                    <div class="link pointer" @click.stop="file.article.name = ''">删除</div>
                    <div class="link" style="padding: 0 4px;">|</div>
                    <div class="link pointer" @click.stop="selectWz">重新选择</div>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
      <div class="btns-save row js-center">
        <Button class="button"  @click="save" :loading="loading.save">保存</Button>
      </div>
    </card>
    
    
    <!-- 插入内容组件 -->
    <insert ref="insert" @change="insertChange"></insert>
  </div>
</template>

<script>
  let inputData = ''
  export default {
    name: 'customizeMenu',
    data(){
      return {
        loading:{
          save:false
        },
        menu:[],
        current:{
          0:0,
          1:-1
        },// 索引1 选中菜单 索引2 选中子菜单
        currentType:1,// 1 主菜单 2 子菜单
        contentType:1,//
        sendType:1,//
        messageContent:'',
        file:{
          image:{
            path:"",
            name:"",
            info:{}
          },
          video:{
            path:"",
            name:"",
            info:{}
          },
          audio:{
            path:"",
            name:"",
            duration:0,
            info:{}
          },
          article:{
            name:"",
            info:{},
            html:""
          }
        },
        filePath:'',
        fileName:'',
        fileDuration:0,
        
      }
    },
    created() {
      this.init()
    },
    mounted() {
      
    },
    computed:{
      
    },
    watch:{
      messageContent(){
        this.menu[this.current[0]].submenuItemList[this.current[1]].messageContent = this.messageContent
      },
      // menuId (integer, optional): 自定义菜单Id ,
      // menuType (integer, optional): 菜单类型 1发消息 2跳转外部链接 3发送文章 ,
      // messageContent (string, optional): 发送消息内容 ,
      // messageType (integer, optional): 发送消息类型 1文字 2图片 3视频 4音乐 ,
      // name (string, optional): 子菜单名称 ,
      // referenceId (integer, optional): 文章/图片/视频/音乐 Id ,
      // sort (integer, optional): 排序 最小在最下面
      // resource (string, optional): 资源地址 ,
      // type (integer, optional): 素材类型 ,
      // duration (integer, optional): 时长（s） ,
      // materialName (string, optional): 素材名称 ,
      'file.image':{
        deep:true,
        handler(ev){
          this.menu[this.current[0]].submenuItemList[this.current[1]].referenceId = ev.info.id
          this.menu[this.current[0]].submenuItemList[this.current[1]].resource = ev.path
          this.menu[this.current[0]].submenuItemList[this.current[1]].materialName = ev.name
        }
      },
      'file.audio':{
        deep:true,
        handler(ev){
          this.menu[this.current[0]].submenuItemList[this.current[1]].referenceId = ev.info.id
          this.menu[this.current[0]].submenuItemList[this.current[1]].materialName = ev.name
          this.menu[this.current[0]].submenuItemList[this.current[1]].resource = ev.path
          this.menu[this.current[0]].submenuItemList[this.current[1]].duration = ev.info.duration
        }
      },
      'file.video':{
        deep:true,
        handler(ev){
          this.menu[this.current[0]].submenuItemList[this.current[1]].referenceId = ev.info.id
          this.menu[this.current[0]].submenuItemList[this.current[1]].resource = ev.path
          this.menu[this.current[0]].submenuItemList[this.current[1]].materialName = ev.name
        }
      },
      'file.article':{
        deep:true,
        handler(ev){
          console.log(ev.info);
          this.menu[this.current[0]].submenuItemList[this.current[1]].referenceId = ev.info.id
          this.menu[this.current[0]].submenuItemList[this.current[1]].messageContent = ev.info.link
        }
      },
      contentType(){
        this.messageContent = ''
        this.menu[this.current[0]].submenuItemList[this.current[1]].menuType = this.contentType
      },
      sendType(){
        this.messageContent = ''
        this.fileName =  ''
        this.filePath = ''
        this.menu[this.current[0]].submenuItemList[this.current[1]].messageType = this.sendType
      },
    },
    methods:{
     init(){
       this.$apis.menu.getMenu().then(res=>{
         this.menu = res.data.getMenuModelList
       })
     },
     addMenu(){
       if(this.menu.length < 3){
         this.currentType = 1
         this.current[0] = this.menu.length
         this.menu.push({
           id:0,
           name: "菜单"+(this.current[0]+1),
           sort: 0,
           submenuItemList: [],
         })
       }
     },
     // 删除菜单
     deleteMenu(){
       this.$confirm(this.$t('确定删除菜单吗?'), this.$t('提示'), {
         confirmButtonText: this.$t('确定'),
         cancelButtonText: this.$t('取消'),
         type: 'warning'
       }).then(res=>{
         if(this.currentType == 1){
           let data= this.menu[this.current[0]]
           if(data.id){
             this.$apis.menu.deleteCustomizeMenu({id:data.id}).then(res=>{
               this.menu.splice(this.current[0],1)
               if(this.current[0] != 0){
                this.current[0] --
               }
             })
           }else{
             this.menu.splice(this.current[0],1)
             if(this.current[0] != 0){
              this.current[0] --
             }
           }
         }else{
           let data= this.menu[this.current[0]].submenuItemList[this.current[1]]
           if(data.id){
             this.$apis.menu.deleteCustomizeSubmenu({id:data.id}).then(res=>{
               this.menu[this.current[0]].submenuItemList.splice(this.current[1],1)
               if(this.current[1]>0){
                this.current[1] --
               }
               if(this.menu[this.current[0]].submenuItemList.length == 0){
                 this.currentType = 1
               }
             })
           }else{
             this.menu[this.current[0]].submenuItemList.splice(this.current[1],1)
             if(this.current[1]>0){
              this.current[1] --
             }
             if(this.menu[this.current[0]].submenuItemList.length == 0){
               this.currentType = 1
             }
           }
           
         }
       })
       
     },
     // 选择资源
     insert(type){
       let messageType = {
         2:'image',
         3:'video',
         4:'audio'
       }
       if(!this.file[messageType[this.sendType]].name || type == 'emoji'){
        this.$refs.insert.open(type)
       }
     },
     // 选择菜单
     selectMenu(menu,subMenu){
       if(subMenu == -1){
         this.currentType = 1
       }else{
         this.currentType = 2
       }
       this.current[0] = menu
       this.current[1] = subMenu
       // menuId (integer, optional): 自定义菜单Id ,
       // menuType (integer, optional): 菜单类型 1发消息 2跳转外部链接 3发送文章 ,
       // messageContent (string, optional): 发送消息内容 ,
       // messageType (integer, optional): 发送消息类型 1文字 2图片 3视频 4音乐 ,
       // name (string, optional): 子菜单名称 ,
       // referenceId (integer, optional): 文章/图片/视频/音乐 Id ,
       // sort (integer, optional): 排序 最小在最下面
       // resource (string, optional): 资源地址 ,
       // type (integer, optional): 素材类型 ,
       // duration (integer, optional): 时长（s） ,
       // materialName (string, optional): 素材名称 ,
       if(this.currentType == 2){
         let messageContent = this.menu[this.current[0]].submenuItemList[this.current[1]].messageContent
         this.contentType = this.menu[this.current[0]].submenuItemList[this.current[1]].menuType
         this.sendType = this.menu[this.current[0]].submenuItemList[this.current[1]].messageType
         
         // 文章
         if(this.contentType == 3){
           this.file.article.name = this.menu[this.current[0]].submenuItemList[this.current[1]].materialName
           this.file.article.info.link = this.menu[this.current[0]].submenuItemList[this.current[1]].messageContent
           this.file.article.html = `<a href="${this.file.article.info.link}">${this.file.article.name}</a>`
         }else{
           if(this.sendType == 1){
             setTimeout(()=>{
               this.messageContent = messageContent
             },60)
           }else{
             console.log(this.file);
             let messageType = {
               2:'image',
               3:'video',
               4:'audio'
             }
             this.file[messageType[this.sendType]].name = this.menu[this.current[0]].submenuItemList[this.current[1]].materialName
             this.file[messageType[this.sendType]].path = this.menu[this.current[0]].submenuItemList[this.current[1]].resource
             this.file[messageType[this.sendType]].duration  = this.menu[this.current[0]].submenuItemList[this.current[1]].duration 
             console.log(this.file);
           }
         }
         
          
       }
       
       
     },
     // 移动菜单
     move(type){
       if(type == 'up'){
         // let data = this.menu[this.current[0]].submenuItemList[this.current[1]]
         // this.menu[this.current[0]].submenuItemList.splice()
         if(this.current[1] != 0){
          upGo(this.menu[this.current[0]].submenuItemList,this.current[1])
          this.current[1] --
         }
       }
       if(type == 'down'){
         if(this.current[1] < this.menu[this.current[0]].submenuItemList.length - 1){
          downGo(this.menu[this.current[0]].submenuItemList,this.current[1])
          this.current[1] ++
         }
       }
       if(type == 'left'){
         if(this.current[0] != 0){
          upGo(this.menu,this.current[0])
          this.current[0] --
         }
       }
       if(type == 'right'){
         if(this.current[0]  < this.menu.length - 1){
          downGo(this.menu,this.current[0])
          this.current[0] ++
         }
       }
       
       function upGo(fieldData,index){
         if(index!=0){
             fieldData[index] = fieldData.splice(index-1, 1, fieldData[index])[0];
         }else{
             fieldData.push(fieldData.shift());
         }
       }
       function downGo(fieldData,index) {
           if(index!=fieldData.length-1){
               fieldData[index] = fieldData.splice(index+1, 1, fieldData[index])[0];
           }else{
               fieldData.unshift( fieldData.splice(index,1)[0]);
           }
       }
     },
     selectWz(){
       this.$refs.insert.open('article')
     },
     deleteInfo(type){
       this.file[type].path = ''
       this.file[type].name = ''
     },
     async save(){
       this.loading.save = true
       let menuPro = this.menu.map(async (item,index)=>{
         if(item.id){// 保存
           let data = {
              name:item.name,
              sort:index,
              id:item.id
            }
            return await this.$apis.menu.renewMenu(data)
         }else{//添加
           let data = {
             name:item.name,
             sort:index
           }
           return await this.$apis.menu.addMenu(data).then(res=>{
             this.menu[index].id = res.data.customizeMenu.id
           })
         }
       })
       await Promise.all(menuPro)
       let allPromise =this.menu.map(async (item,index)=>{
         let pros = item.submenuItemList.map(async (it,i)=>{
           if(it.id){// 保存
             // menuId (integer, optional): 自定义菜单Id ,
             // menuType (integer, optional): 菜单类型 1发消息 2跳转外部链接 3发送文章 ,
             // messageContent (string, optional): 发送消息内容 ,
             // messageType (integer, optional): 发送消息类型 1文字 2图片 3视频 4音乐 ,
             // name (string, optional): 子菜单名称 ,
             // referenceId (integer, optional): 文章/图片/视频/音乐 Id ,
             // sort (integer, optional): 排序 最小在最下面
             let data = {
                "id": it.id,
                "link": it.messageContent,
                "menuId": item.id,
                "menuType": it.menuType,
                "messageContent": it.messageContent,
                "messageType": it.messageType,
                "name": it.name,
                "referenceId":it.referenceId,
                "sort": i
              }
              return await this.$apis.menu.renewSubmenu(data)
           }else{//添加
             let data = {
               "link": it.messageContent,
               "menuId": item.id,
               "menuType": it.menuType,
               "messageContent": it.messageContent,
               "messageType": it.messageType,
               "name": it.name,
               "referenceId":it.referenceId,
               "sort": i
             }
             return await this.$apis.menu.addSubmenu(data)
           }
         })
         return Promise.all(pros)
       })
       await Promise.all(allPromise)
       setTimeout(()=>{
         this.$message.success('保存成功'.$t())
         this.init()
         this.loading.save = false 
       },500)
     },
     // 插入结果
      insertChange(ev){
       console.log(ev);
       if(ev.type == 'emoji'){
         this.messageContent = this.messageContent + ev.info
       }else if(ev.type == 'article'){
         this.file[ev.type].name = ev.name
         this.file[ev.type].info = ev.info
         this.file[ev.type].html = `<a href="${ev.info.link}">${ev.name}</a>`
       }else{
         if(ev.type != 'audio'){
           this.file[ev.type].name = ev.name
           this.file[ev.type].path = ev.url
         }else{
           this.file[ev.type].name = ev.item.name
           this.file[ev.type].path = ev.item.resource
           this.file[ev.type].duration = ev.item.duration
         }
         if(ev.type == 'image') this.sendType = 2
         if(ev.type == 'video') this.sendType = 3
         if(ev.type == 'audio') this.sendType = 4
         this.file[ev.type].info = ev.info
       }
     },
      onInput(ev,item){
        let reg = new RegExp("^[A-Za-z0-9\u4e00-\u9fa5]+$")
        // 获取输入内容字节长度
        if(this.getLength(item.name)>8){
          let strLeng = 0
          // 此处处理文长度问题,汉字占2字节,英文及数子占1字节 所有字节不能超过8位
          let strArr = item.name.split('')
          let str = strArr.map((item)=>{
            let obj = {}
            if(new RegExp("^[\u4e00-\u9fa5]+$").test(item)){
              obj.data = item
              obj.num = 2
            }else{
              obj.data = item
              obj.num = 1
            }
            return obj
          }).filter(item=>{
            strLeng+= item.num
            return strLeng <= 8
          }).map(item=>{
            return item.data
          }).join('')
          item.name = str
          return 
        }
        if(!item.name){
          inputData = item.name
          return
        }
        
        if(!reg.test(item.name)){
          item.name = inputData
        }else{
          inputData = item.name
        }
      },
      // 获取文本的字节长度,汉字占2字节,英文占1字节
      getLength(val) {  
         var str = new String(val);  
         var bytesCount = 0;  
          for (var i = 0 ,n = str.length; i < n; i++) {  
            var c = str.charCodeAt(i);  
            if ((c >= 0x0001 && c <= 0x007e) || (0xff60<=c && c<=0xff9f)) {  
              bytesCount += 1;  
            } else {  
            bytesCount += 2;  
          }  
        }  
        return bytesCount;  
     },
      addSubMenu(){
        if(this.menu[this.current[0]].submenuItemList.length >= 5) return
        // menuId (integer, optional): 自定义菜单Id ,
        // menuType (integer, optional): 菜单类型 1发消息 2跳转外部链接 3发送文章 ,
        // messageContent (string, optional): 发送消息内容 ,
        // messageType (integer, optional): 发送消息类型 1文字 2图片 3视频 4音乐 ,
        // name (string, optional): 子菜单名称 ,
        // referenceId (integer, optional): 文章/图片/视频/音乐 Id ,
        // sort (integer, optional): 排序 最小在最下面
        // resource (string, optional): 资源地址 ,
        // type (integer, optional): 素材类型 ,
        // duration (integer, optional): 时长（s） ,
        // materialName (string, optional): 素材名称 ,  
        let data = {
          "link": "",
          "menuId": 0,
          "menuType": 1,
          "messageContent": "",
          "messageType": 1,
          "name": "子菜单"+(this.menu[this.current[0]].submenuItemList.length + 1) ,
          "referenceId":0,
          "sort": 0,
          resource:"",
          type:"",
          duration:0,
          materialName:""
        }
        
        this.messageContent = ''
        this.file.image.path = ''
        this.file.image.name = ''
        this.file.image.info = {}
        this.file.audio.path = ''
        this.file.audio.name = ''
        this.file.audio.info = {}
        this.file.video.path = ''
        this.file.video.name = ''
        this.file.video.info = {}
        this.file.article.html = ''
        this.file.article.name = ''
        this.file.article.info = {}
        
        this.sendType = 1
        this.contentType  = 1
        
        this.current[1] = 0
        this.menu[this.current[0]].submenuItemList.unshift(data)
        this.currentType = 2
     }
    }
  }
</script>

<style lang="scss">
  .customizeMenu{
    .Card{
      padding: 16px 20px;
      .card-title_{
        font-size: 18px;
        color: #3B3F56;
        line-height: 25px;
      }
      .activeMenu{
        color: #FFAE2E;
      }
      .menu-image{
        margin-top: 14px;
        .menu-image_{
          width: 218px;
          height: 473px;
          .menu{
            width: 175px;
            min-height: 27px;
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: #FFFFFF;
            .menu-item{
              width: 58px;
              font-size: 12px;
              line-height: 17px;
              border-right: 1px solid rgba(0,0,0,0.04);
              height: 27px;
              .menu-sub{
                width: 58px;
                position: absolute;
                bottom: 100%;
                left: 0;
                .menu-sub-item{
                  font-size: 10px;
                  line-height: 17px;
                  height: 27px;
                  border-top: 1px solid rgba(0,0,0,0.04);
                  border-left: 1px solid rgba(0,0,0,0.04);
                  border-right: 1px solid rgba(0,0,0,0.04);
                }
              }
            }
          }
        }
        .menu-image-tip{
          font-size: 12px;
          color: #696B76;
          line-height: 17px;
          margin-top: 14px;
        }
      }
      .menu-edit{
        background-color: #F4F5F9;
        min-height: 486px;
        margin-left: 19px;
        padding: 0 20px;
        padding-bottom: 20px;
        .menu-edit-title{
          height: 49px;
          position: relative;
          &::after{
            content: '';
            width: 100%;
            background-color: #DBDBE7;
            height: 1px;
            opacity: 0.26;
            position: absolute;
            bottom: 0;
            left: 0;
          }
          div{
            font-size: 15px;
            color: #3B3F56;
            line-height: 21px;
          }
          .delete-menu{
            font-size: 14px;
            color: #1564B9;
            line-height: 19px;
          }
        }
        
        .menu-edit-name{
          height: 34px;
          .name{
            width: 6em;
          }
          .checkes{
            .checked_{
              margin-right: 28px;
            }
          }
          .input{
            height: 34px;
            background-color: #FFFFFF;
            border-radius: 3px;
            border: 1px solid #E3E3E3;
            padding-left: 12px;
          }
          .btns{
            .button{
              width: 77px;
              height: 32px;
              border-radius: 5px;
              margin-right: 18px;
              font-size: 12px;
              color: #FFFFFF;
              line-height: 17px;
              img{
                width: 10px;
                height: 19px;
                margin-right: 11px;
              }
            }
          }
          &.m-t-17{
            margin-top: 17px;
          }
        }
        
        .res-content{
          min-height: 216px;
          background-color: #FFFFFF;
          &.padding_{
            padding: 15px;
          }
          .send-title{
            height: 17px;
            font-size: 12px;
            color: #696B76;
            line-height: 17px;
          }
          .link{
            color: #2C7BE5;
          }
          .input__{
            height: 34px;
            margin-top: 15px;
            .input-name{
              width: 4.5em;
              height: 34px;
            }
            .input-in{
              flex: 1;
              height: 34px;
              background: #FFFFFF;
              border-radius: 3px;
              border: 1px solid #E3E3E3;
              padding-left: 10px;
              box-sizing: border-box;
            }
          }
          .send-type-head{
            height: 39px;
            background: #FBFBFB;
            border-radius: 2px;
            // width: 497px;
            width: 100%;
            padding: 0 17px;
            .send-type{
              margin-right: 32px;
              font-size: 12px;
              color: #696B76;
              line-height: 17px;
              &.active{
                color: #FFAE2E;
              }
              img{
                width: 15px;
                height: auto;
                margin-right: 7px;
              }
            }
          }
          
          .send-type-content{
            background-color: #FFFFFF;
            padding: 0 14px;
            // width: 497px;
            width: 100%;
            min-height: 177px;
            .sc-icon{
              width: 18px;
              height: 19px;
            }
            .sc-text{
              font-size: 12px;
              color: #696B76;
              line-height: 17px;
              margin-top: 4px;
            }
            .file{
              min-height: 157px;
              .audio-item{
                width: 137px;
                height: 79px;
                box-shadow: 0px 2px 3px 0px rgba(220,231,239,0.5);
                border-radius: 5px;
                border: 1px solid #E8E3E3;
                margin-right: 13px;
                padding: 10px 0 0 7px;
                .title-audio{
                  font-size: 12px;
                  color: #696B76;
                  line-height: 17px;
                  text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5), 0px 2px 12px rgba(248, 221, 177, 0.61);
                }
                .length{
                  font-size: 12px;
                  color: #696B76;
                  line-height: 17px;
                  text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5), 0px 2px 12px rgba(248, 221, 177, 0.61);
                  margin-top: 3px;
                }
                .play-icon{
                  width: 15px;
                  height: 15px;
                  position: absolute;
                  bottom: 9px;
                  right: 9px;
                  &.playing_{
                    padding: 2px;
                    border-radius: 100%;
                    background-color: rgba(0,0,0,0.5);
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                  }
                  .playing{
                    animation: playing 1.3s ease infinite alternate;
                    width: 2px;
                    background-color: #FFFFFF;
                    &.bar2{
                      animation-delay: -2.4s;
                    }
                    &.bar3{
                      animation-delay: -3.7s;
                    }
                  }
                }
              }
            }
            .image{
              width: 137px;
              height: 102px;
            }
            .image-name{
              margin-left: 18px;
              font-size: 14px;
              color: #3B3F56;
              line-height: 19px;
              margin-right: 18px;
              width: 8em;
            }
            .link{
              font-size: 14px;
              color: #1564B9;
              line-height: 19px;
            }
            .textarea{
              height: 128px;
              width: 100%;
              resize: none;
              border-bottom: 1px solid rgba(219, 219, 231, 0.26);
              padding-top: 17px;
            }
            .emoji{
              height: 38px;
              .emoji-icon{
                width: 17px;
                height: 17px;
              }
            }
          }
          
        }
        
      }
    }
    .btns-save{
      padding-left: 256px;
      .button{
        width: 81px;
        height: 32px;
        border-radius: 5px;
        margin: 0 17px;
      }
    }
  }
</style>
