<template>
  <div class="myCreation">
    <card class="Card row">
      <div class="name">我的发布：</div>
      <div class="flex-1 number">{{count}}</div>
      <Button class="Button" @click="release">发布</Button>
    </card>
    <card class="Card-box">
      <div class="card-title_ row js-between pos-rel">
        <div>文章</div>
        <div>操作</div>
      </div>
      <div class="article">
        <div class="article-item row pos-rel" v-for="(item,index)  of list" :key="index">
          <div class="article-image" v-if="item.coverImg"><img :src="item.coverImg" ></div>
          <div class="article-info flex-1">
            <navLink class="title line-1" :path="'/preview?type=wz&id='+item.id">{{item.title}}</navLink>
            <div class="time">{{item.publishTime | getDate}}</div>
            <div class="tags row" v-if="item.auditState == 2">
              <div class="tag-item">
                <img class="icon" src="assets/image/release/eye@2x.png" >
                <span class="number Regular">{{item.visitNumber}}</span>
              </div>
              <div class="tag-item">
                <img class="icon" src="assets/image/release/like@2x.png" >
                <span class="number Regular">{{item.fabulousNumber}}</span>
              </div>
              <div class="tag-item">
                <img class="icon" src="assets/image/release/share@2x.png" >
                <span class="number Regular">{{item.shareNumber}}</span>
              </div>
              <div class="tag-item">
                <img class="icon" src="assets/image/release/comment@2x.png" >
                <span class="number Regular">{{item.evaluateNumber}}</span>
              </div>
            </div>
            <div class="auditState row" v-if="item.auditState == 1">
              <img src="assets/image/notice@2x.png" >
              <span>审核中</span>
            </div>
          </div>
          <div class="operate row">
            <div class="operate-button" @click="offShelf(item)">下架</div>
            <div>｜</div>
            <div class="operate-button" @click="deleteCr(item)">删除</div>
            <div  v-if="item.auditState == 1">｜</div>
            <div class="operate-button" @click="editCr(item)" v-if="item.auditState == 1">编辑</div>
          </div>
        </div>
        
        <loadMore :status="status" @more="scrollBottom"></loadMore>
        
      </div>
    </card>
  </div>
</template>

<script>
  export default {
    name: 'myCreation',
    data(){
      return {
        page:1,
        count:0,
        total:0,
        list:[],
        status:'more',
        search:''
      }
    },
    created() {
      // 监听滚到底部
      this.$store.commit('scrollBottomSet',this.scrollBottom)
      this.$store.commit('searchSet',this.Search)
      this.init()
    },
    mounted() {
      
    },
    computed:{
      
    },
    methods:{
      init(){
        let data = {
          "pageNum": this.page,
          "pageSize": 20,
          title:this.search
        }
        this.status = 'loading'
        this.$apis.wz.getArticlePageList(data).then(res=>{
          this.count = res.data.total
          this.total = parseInt(res.data.total / 20) + 1
          if(this.page == 1){
            this.list = res.data.listModelItemList
          }else{
            this.list = [...this.list,...res.data.listModelItemList]
          }
          if(this.page >= this.total){
            this.status = 'no-more'
          }else{
            this.status = 'more'
          }
        })
      },
      Search(val){
        this.search = val
        this.page = 1
        this.init()
      },
      offShelf(item){
        this.$confirm(this.$t('确定下架该文章吗?'), this.$t('提示'), {
          confirmButtonText: this.$t('确定'),
          cancelButtonText: this.$t('取消'),
          type: 'warning'
        }).then(() => {
          let data = {
            type:1,
            articleId:item.id
          }
          this.$apis.wz.articlesLowerFrame(data).then(res=>{
            this.$message.success(this.$t("下架成功"))
            this.page = 1
            this.status =  'more'
            this.init()
          })
        })
      },
      deleteCr(item){
        this.$confirm(this.$t('确定删除该文章吗?'), this.$t('提示'), {
          confirmButtonText: this.$t('确定'),
          cancelButtonText: this.$t('取消'),
          type: 'warning'
        }).then(() => {
          let data = {
            id:item.id
          }
          this.$apis.wz.deleteArticle(data).then(res=>{
            this.$message.success(this.$t("删除成功"))
            this.page = 1
            this.status =  'more'
            this.init()
          })
        })
      },
      editCr(item){
        this.$router.push('/page/release?type=release&id='+item.id)
      },
      release(){
        this.$router.push('/page/release?type=release')
      },
      scrollBottom(){
        if(this.page < this.total){
          this.page ++ 
          this.init()
        }
      }
    }
  }
</script>

<style lang="scss">
  .myCreation{
    .Card{
      padding: 0 26px;
      height: 51px;
      text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
      color: #696B76;
      line-height: 17px;
      
      .number{
        font-size: 26px;
        line-height: 31px;
        font-weight: bold;
      }
      
      .Button{
        width: 81px;
        height: 32px;
        box-shadow: 0px 2px 3px 0px rgba(220,231,239,0.5);
        border-radius: 5px;
      }
    }
    
    .Card-box{
      // padding: 0 85px;
      .card-title_{
        // padding: 0 21px 0 51px;
        height: 44px;
        color: #989BB0;
        div:first-child{
          margin-left: 51px;
        }
        div:last-child{
          margin-right: 21px;
        }
        &::after{
          content: '';
          background: #DBDBE7;
          box-shadow: 0px 2px 3px 0px rgba(220,231,239,0.5);
          opacity: 0.26;
          height: 1px;
          width: 100%;
          position: absolute;
          bottom: 0;
        }
      }
      .article{
        padding-left: 21px;
        .article-item{
          padding: 15px 0 19px 0;
          .article-image{
            width: 137px;
            height: 102px;
            box-shadow: 0px 2px 3px 0px rgba(220,231,239,0.5);
          }
          .article-info{
            padding-left: 12px;
            padding-top: 6px;
            height: 102px;
            .title{
              text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
              color: #696B76;
              width: 32em;
            }
            .time{
              font-size: 12px;
              color: #696B76;
              line-height: 17px;
              text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
              margin-top: 9px;
              font-family: 'PingFang Regular';
            }
            .tags{
              margin-top: 13px;
              .tag-item{
                margin-right: 21px;
                .icon{
                  width: 10px;
                }
                .number{
                  font-size: 10px;
                  color: #989BB0;
                  line-height: 15px;
                  text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
                  margin-left: 5px;
                }
              }
            }
            .auditState{
              margin-top: 22px;
              img{
                margin-right: 3px;
                width: 12px;
                height: 12px;
              }
              span{
                font-size: 12px;
                color: #696B76;
                line-height: 17px;
                text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
              }
            }
          }
          
          .operate{
            color: #2C7BE5;
            text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
            margin-right: 27px;
            .operate-button{
              cursor: pointer;
            }
          }
          
          
          &::after{
            content: '';
            background: #DBDBE7;
            box-shadow: 0px 2px 3px 0px rgba(220,231,239,0.5);
            opacity: 0.26;
            height: 1px;
            width: 100%;
            position: absolute;
            bottom: 0;
          }
          
        }
      }
      
    }
    
  }
</style>
