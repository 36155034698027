<template>
  <div class="about">
    <div>
      <span>{{$t('关于妈妈创业营')}}</span>
      <span> l </span>
      <span>{{$t('服务协议')}}</span>
    </div>
    <div class="tagging">Copyright © 2012-2021 zhonghuanyunqi. All Rights Reserved.</div>
  </div>
</template>

<script>
  export default {
    name: 'about',
    data(){
      return {
        
      }
    },
    created() {
      
    },
    mounted() {
      
    },
    computed:{
      
    },
    methods:{
     
    }
  }
</script>

<style lang="scss">
  .about{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    padding-top: 24px;
    padding-bottom: 13px;
    div{
      color: #434343;
    }
    .tagging{
      margin-top: 6px;
    }
  }
</style>

