<template>
  <div class="reg_form">
    <div class="card row col">
      <Steps :list="stepsList" current="1"></Steps>
      <!--类别选择 -->
      <div class="card-list">
        <navLink class="card_ row col" path="info?type=1">
          <div class="card-title_">个人</div>
          <img class="card-img" src="assets/image/login/pic@2x.png" >
        </navLink>
        <navLink class="card_  row col" path="info?type=2">
          <div class="card-title_">企业/机构/组织</div>
          <img class="card-img" src="assets/image/login/public@2x.png" >
        </navLink>
      </div>
      
    </div>
    
    <About></About>
    
  </div>
</template>

<script>
  import About from '@/components/About.vue'
  import Steps from '@/components/Steps.vue'
  import navLink from '@/components/navLink.vue'
  export default {
    name: 'reg_form',
    components:{
      Steps,
      About,
      navLink
    },
    data(){
      return {
        stepsList:[this.$t('基本信息'),this.$t('选择类型'),this.$t('信息登记'),this.$t('公众号信息填写')],
      }
    },
    created() {
      this.$apis.user.getOfficialAccountInfo().then(res=>{
        let info = res.data.accountInfoItem
        if(info.attestation == 1){
          this.$router.push('/reg/infoRegister')
        }
      })
    },
    mounted() {
    },
    computed:{
      
    },
    methods:{
      
    }
  }
</script>

<style lang="scss">
  .reg_form{
    padding: 15px 27px 0 44px;
    display: flex;
    flex-direction: column;
    height: 100%;
    .card{
      background-color: #FFFFFF;
      border-radius: 7px;
      padding-bottom: 172px;
      
      .card-list{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 60px;
        .card_{
          width: 390px;
          height: 285px;
          background-color: #FFFFFF;
          box-shadow: 0px 2px 3px 0px rgba(209,209,209,0.5);
          border-radius: 7px;
          margin: 0 17.5px;
          cursor: pointer;
          .card-title_{
            font-size: 15px;
            color: #3B3F56;
            line-height: 21px;
            font-weight: 700;
            text-align: center;
            margin-top: 15px;
            text-shadow: 0px 2px 3px rgba(209, 209, 209, 0.5);
          }
          .card-img{
            width: 200px;
            height: 200px;
            margin-top: 30px;
          }
        }
      }
    }
  }
</style>

