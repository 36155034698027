<template>
  <div class="reg_form">
    <div class="card row col">
      <Steps :list="stepsList" current="0"></Steps>
      <!-- 表单 -->
      <div class="form row col">
        <div class="item" v-for="(item,key) in forms" :key="key">
          <div class="form-item row">
            <div class="input-name">{{ item.name }}:</div>
            <div class="input row hidden">
              <input :type="item.type" @blur="inspectKey(key)" class="flex-1" :placeholder="item.placeholder" v-model="item.value">
              <Button class="button h-100" v-if="item.isButton" :loading="item.loading" :class="{disabled:item.disabled}" @click="item.buttonFun">{{item.loading?'':item.buttonText}}</Button>
            </div>
          </div>
          <div class="row">
            <div class="input-name"></div>
            <div class="tips row" :style="{opacity:item.isTips?1:0}">
              <img src class="tips-img" src="assets/image/notice@2x.png" >
              <span class="tips-text">{{item.tips}}</span>
            </div>
          </div>
        </div>
      </div>
      
      <!-- 提交 -->
      <Button class="button submit" :loading="loading.submit" @click="submit">{{$t('注册')}}</Button>
      
    </div>
    
    <About></About>
    
  </div>
</template>

<script>
  import Steps from '@/components/Steps.vue'
  export default {
    name: 'reg_form',
    components:{
      Steps,
    },
    data(){
      return {
        loading:{
          submit:false
        },
        stepsList:[this.$t('基本信息'),this.$t('选择类型'),this.$t('信息登记'),this.$t('公众号信息填写')],
        forms:{
          email:{
            type:'text',
            name:this.$t('邮箱'),
            placeholder:this.$t("邮箱"),
            value:"",
            isTips:false,
            tips:this.$t('输入邮箱'),
            isButton:true,
            disabled:false,
            buttonText:this.$t("发送验证码"),
            buttonFun:this.sendCode,
            loading:false
          },
          code:{
            type:'text',
            name:this.$t('验证码'),
            placeholder:this.$t('输入验证码'),
            value:"",
            isTips:false,
            tips:this.$t('输入验证码'),
            isButton:false,
          },
          password:{
            type:'password',
            name:this.$t('密码'),
            placeholder:this.$t('请输入密码'),
            value:"",
            isTips:true,
            tips:this.$t('密码不低于6位字符'),
            isButton:false,
          },
          cfPassword:{
            type:'password',
            name:this.$t('确认密码'),
            placeholder:this.$t('请输入密码'),
            value:"",
            isTips:true,
            tips:this.$t('确保两次密码一致'),
            isButton:false,
          }
        }
      }
    },
    created() {
      
    },
    mounted() {
      
    },
    computed:{
      
    },
    methods:{
      sendCode(){
        if(!this.forms.email.value.trim()){
          this.forms.email.isTips = true
          return
        }
        let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
        if(!reg.test(this.forms.email.value)){
          this.$message.error(this.$t('请输入正确的邮箱'));
          return
        }
        if(!this.forms.email.disabled){
          this.forms.email.loading = true
          this.$apis.login.sendSms({account:this.forms.email.value}).then(res=>{
            setTimeout(()=>{
              this.forms.email.loading = false
              this.$message.success(this.$t('验证码已发送，请注意查收'));
              this.forms.email.disabled = true
              let timeNum = 60
              this.forms.email.buttonText = timeNum + this.$t("秒后重试")
              let timer = setInterval(()=>{
                this.forms.email.buttonText = timeNum + this.$t("秒后重试")
                timeNum -- 
                if(timeNum <= 0){
                  clearInterval(timer)
                  this.forms.email.buttonText = this.$t("发送验证码")
                  this.forms.email.disabled = false
                }
              },1000)
            },500)
            
          })
        }
        
      },
      inspectKey(key){
        if(key == 'password' || key == 'cfPassword'){
          if(!this.forms.password.value.trim() && !this.forms.cfPassword.value.trim()){
            // this.forms.cfPassword.tips = this.$t("请输入确认密码")
          }
          if(this.forms.password.value.trim() && this.forms.cfPassword.value.trim()){
            if(this.forms.password.value != this.forms.cfPassword.value){
              // this.forms.cfPassword.tips = this.$t("两次密码不一致")
              this.forms[key].isTips = true
              return true
            }
          }
        }
        if(!this.forms[key].value.trim()){
          this.forms[key].isTips = true
          return true
        }else{
          this.forms[key].isTips = false
          return false
        }
      },
      submit(){
        this.isInspect = false
        for (let key in this.forms) {
          if(this.inspectKey(key)){
            this.isInspect = true
          }
        }
        if(!this.isInspect){
          let data = {
              "email": this.forms.email.value,
              "password": this.forms.password.value,
              "smsCode": this.forms.code.value
          }
          this.$apis.login.register(data).then(res=>{
            this.$setData('userInfo',res.data.officialAccount)
            this.$message.success(this.$t('注册成功'));
            this.$router.push('/reg/setType')
          }).catch(err=>{
            this.$message.error(err.data.returnMsg);
          })
        }

        // this.$router.push('/reg/setType')
      }
    }
  }
</script>

<style lang="scss">
  .reg_form{
    padding: 15px 27px 0 44px;
    display: flex;
    flex-direction: column;
    height: 100%;
    .card{
      background-color: #FFFFFF;
      border-radius: 7px;
      .form{
        .item{
          margin-top: 26px;
        }
        .input-name{
          width: 5em;
        }
        .form-item{
          .input{
            width: 433px;
            height: 34px;
            border-radius: 3px;
            border: 1px solid #DBDBE7;
            padding-left: 11px;
            .button{
              width: 84px;
			  background-color: #427CDF;
              &.disabled{
                background-color: #DCDCDC;
              }
            }
          }
          
        }
      }
      
      .submit{
        width: 400px;
        height: 35px;
        border-radius: 30px;
        margin-top: 54px;
		font-size: 16px;
		background-color: #427CDF;
      }
      
    }
  }
</style>

