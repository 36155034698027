export default {
  // host:'http://api.o1o.link/officialaccount',// 商家端接口地址
  // ooHost:'http://api.o1o.link/oouser', // 用户端地址 （在搜索用户和融云获取头像等信息的时候用到）
  // picHost:'http://api.o1o.link/officialaccount/toolService/upload',// 上传图片地址  没有用到
  //host:'https://mama.jojobot.cn/sinata/officialaccount',// 商家端接口地址
  //ooHost:'https://mama.jojobot.cn/sinata/user', // 用户端地址 （在搜索用户和融云获取头像等信息的时候用到）
  host:'http://officialaccount.tecood.com/officialaccount',// 商家端接口地址
  ooHost:'http://officialaccount.tecood.com/admin', // 用户端地址企业助理
  
 /* host:'http://192.168.20.92:8003/officialaccount',// 商家端接口地址
  ooHost:'http://192.168.20.92:8777/admin', // 用户端地址企业助理 */

}