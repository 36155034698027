<template>
  <div class="info_register">
    <div class="card row col">
      <Steps :list="stepsList" current="3"></Steps>
      <div class="form-box row ai-top">
        <div class="image-g pos-rel"><img src="assets/image/pic@2x.png" ></div>
        <div class="form">
          <div class="item" v-for="(item,key) in forms" :key="key">
            <div class="form-item row ai-top">
              <div class="input-name row">{{ item.name }}:</div>
              <!-- 文字-->
              <div class="input row hidden" v-if="item.type == 'input'">
                <input type="text" @blur="inspectKey(key)" class="flex-1" :placeholder="item.placeholder" v-model="item.value">
              </div>
              <!-- textarea -->
              <div class="textarea row hidden" v-if="item.type == 'textarea'">
                <textarea @blur="inspectKey(key)" class="flex-1" :placeholder="item.placeholder" v-model="item.value"></textarea>
              </div>
              <!-- 图片 -->
              <div class="images row" v-if="item.type == 'updata-image'">
                <el-upload
                  class="image-uploader"
                  name="picture"
                  v-if="!item.pic"
                  :action="$picHost"
                  :show-file-list="false"
                  :on-success="item.Success"
                  :on-change="upload"
                  :auto-upload="false"
                  :before-upload="beforeAvatarUpload">
                  <Button class="button">添加</Button>
                </el-upload>
                <el-avatar size="large" :src="item.pic" v-else></el-avatar>
              </div>
            </div>
            <div class="row">
              <div class="input-name"></div>
              <div class="tips row" :style="{opacity:item.isTips?1:0}">
                <img src class="tips-img" src="assets/image/notice@2x.png" >
                <span class="tips-text">{{item.tips}}</span>
              </div>
            </div>
          </div>
          <!-- 提交 -->
          <Button class="button submit" :loading="$loading.submit" v-if="info.auditState == 1 && this.info.type">{{ $t('审核中')}}</Button>
          <Button class="button submit" :loading="$loading.submit" @click="submit" v-else>{{ $t('完成')}}</Button>
        </div>
      </div>
      
    </div>
    
    <About></About>
    
  </div>
</template>

<script>
  import About from '@/components/About.vue'
  import Steps from '@/components/Steps.vue'
  import navLink from '@/components/navLink.vue'
  export default {
    name: 'info_register',
    components:{
      Steps,
      About,
      navLink
    },
    data(){
      return {
        $loading:{
          submit:false
        },
        stepsList:[this.$t('基本信息'),this.$t('选择类型'),this.$t('信息登记'),this.$t('公众号信息填写')],
        forms:{
          nickname:{
            type:'input',
            name:this.$t('公众号名字'),
            placeholder:this.$t("公众号名字"),
            value:"",
            isTips:true,
            tips:this.$t('仅限中英文字符，不超过32个字符'),
          },
          avatar:{
            type:'updata-image',
            name:this.$t('头像'),
            placeholder:'',
            value:"",
            isTips:false,
            tips:this.$t('上传头像'),
            isButton:false,
            pic:'',
            maxLength:1,
            Success:(ev)=>{
              this.forms.avatar.pic = ev
            }
          },
          introduce:{
            type:'textarea',
            name:this.$t('简介'),
            placeholder:this.$t('输入简介'),
            value:"",
            isTips:true,
            tips:this.$t('不超过200个字符'),
            isButton:false,
          }
          
        },
        userData:{},
        info:{}
      }
    },
    created() {
      this.init()
      this.userData = this.$getData("accountInfo")
    },
    mounted() {
    },
    computed:{
      userInfo(){
        return this.$store.getters.userInfo
      }
    },
    methods:{
      init(){
        this.$apis.user.getOfficialAccountInfo().then(res=>{
          this.info = res.data.accountInfoItem
          if(this.info.auditState == 1 && this.info.attestation == 1){
            this.forms.nickname.value = this.info.nickname
            this.forms.avatar.pic = JSON.parse(this.info.headImg)[0]
            this.forms.introduce.value = this.info.introduce
          }
          if(this.info.auditState == 2 && this.info.type == 1){
            // headImg (string, optional): 公众号头像url ,
            // introduce (string, optional): 公众号简介 ,
            // nickname (string, optional): 公众号昵称 ,
            
            
            this.$setData('userInfo',res.data.officialAccount)
            this.$store.commit('isLoginSet',true)
            this.$store.commit('userInfoSet',res.data.officialAccount)
            this.$router.replace('/index')
          }
        })
      },
      // 验证所有字段是否有输入
      inspectKey(key){
        if( this.forms[key].type == 'updata-image'){
          if(!this.forms[key].pic){
            this.forms[key].isTips = true
            return true
          }else{
            this.forms[key].isTips = false
            return false
          }
        }else{
          if(!this.forms[key].value.trim()){
            this.forms[key].isTips = true
            return true
          }else{
            this.forms[key].isTips = false
            return false
          }
        }
        
      },
      upload(ev){
        if(this.beforeAvatarUpload(ev.raw)){
          let loading = this.loading(this.$t("上传中"))
          this.$apis.sc.upload(ev.raw,(progress,p)=>{
            // this.image.progress = progress*100
          }).then(res=>{
            loading.close()
            this.forms.avatar.Success(res)
          }).catch(err=>{
            loading.close()
          })
        }
      },
      submit(){
        let type = this.userType
        this.isInspect = false
        for (let key in this.forms) {
          if(this.inspectKey(key)){
            this.isInspect = true
          }
        }
        if(!this.isInspect){
          
          /*
            certificateImg (string, optional): 证件url集合（JSON字符串数组） ,
            certificateNum (string, optional): 证件号码/企业营业编号 ,
            certificateType (integer, optional): 证件类型 1医保卡 2社保卡 （个人） ,
            headImg (string, optional): 公众号头像url ,
            holdCertificateImg (string, optional): 手持证件url集合（JSON字符串数组） ,
            id (integer, optional): 公众号id ,
            introduce (string, optional): 公众号简介 ,
            manageName (string, optional): 管理员姓名（企业） ,
            name (string, optional): 姓名（个人）/企业/机构/组织名称 ,
            nickname (string, optional): 公众号昵称 ,
            phone (string, optional): 手机号 ,
            type (integer, optional): 认证类型——1个人 2企业
          */
          this.$loading.submit = true
          this.userData.nickname = this.forms.nickname.value
          this.userData.headImg = this.forms.avatar.pic
          this.userData.introduce = this.forms.introduce.value
          this.userData.id = this.$getData('userInfo').id
          
          this.$apis.login.accountInfoRegister(this.userData).then(res=>{
            this.$loading.submit = false
            try{
              // this.$setData('userInfo',res.data.officialAccount)
              // this.$store.commit('isLoginSet',true)
              // this.$store.commit('userInfoSet',res.data.officialAccount)
              // 保留着本页面，等待审核
              this.$message.success('信息已提交，请等待审核'.$t())
              this.init()
              // this.$router.replace('/index')
            }catch(e){
              //TODO handle the exception
            }
            
          }).catch(err=>{
            this.$loading.submit = false
          })
          
        }
      },
      beforeAvatarUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 5MB!');
        }
        if(isLt2M){
          this.$message.info("上传中")
        }
        return isLt2M;
      },
    }
  }
</script>

<style lang="scss">
  .info_register{
    padding: 15px 27px 0 44px;
    display: flex;
    flex-direction: column;
    height: 100%;
    .card{
      background-color: #FFFFFF;
      border-radius: 7px;
      padding-bottom: 172px;
      .form-box{
        .image-g{
          width: 218px;
          height: 473px;
        }
        .form{
          margin-left: 36px;
          .item{
            margin-top: 26px;
          }
          .input-name{
            width: 6em;
            height: 34px;
          }
          .form-item{
            .images{
              width: 374px;
              /deep/.el-avatar{
                width: 80px;
                height: 80px;
              }
              .image-uploader{
                width: 111px;
                height: 32px;
                margin-right: 18px;
                .button{
                  width: 111px;
                  height: 32px;
                  border-radius: 30px;
				  background-color: #427CDF;
                }
              }
            }
            .input{
              width: 374px;
              height: 34px;
              border-radius: 3px;
              border: 1px solid #DBDBE7;
              padding-left: 11px;
            }
            .textarea{
              width: 374px;
              height: 135px;
              border-radius: 3px;
              border: 1px solid #DBDBE7;
              padding: 11px;
              textarea{
                width: 100%;
                height: 100%;
                resize:none;
              }
            }
            
          }
          
          .submit{
            width: 350px;
            height: 36px;
            border-radius: 30px;
            margin: 0 auto;
            margin-top: 29px;
			background-color: #427CDF;
          }
          
        }
      }
      
      
    }
  }
</style>

