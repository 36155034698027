<template>
  <div class="Html" :class="[type]">
    <template v-if="type == 'audio'">
      <div class="name">{{name}}</div>
      <div class="duration" style=''>{{duration | getDuration}}</div>
      <img  @click="plays" class="plays pointer" :src="playIcon" v-if="!playStatue" >
      <div class="play-icon playing_ pointer" v-if="playStatue" @click.stop="plays">
        <div class="playing bar1"></div>
        <div class="playing bar2"></div>
        <div class="playing bar3"></div>
      </div>
    </template>
    
    <template v-if="type == 'video'">
      <video width="137px" height="102px" style="max-width: 100%;" controls="controls" autoplay="autoplay">
        <source :src="url" type="video/mp4"></source>
        <source :src="url" type="video/ogg"></source>
        <source :src="url" type="video/webm"></source>
      </video>
    </template>
    
  </div>
</template>

<script>
  export default {
    name: 'Html',
    data(){
      return {
        name:'',
        duration:0,
        playStatue:false,
        player:null,
        url:'',
        type:"video",
      }
    },
    created() {
      let o = this.$route.query
      console.log(o)
      if(o.url){
        this.type = o.type
        this.url = o.url
      }
      if(o.name){
        this.name = o.name
        this.duration = o.duration
      }
    },
    mounted() {
      this.player = new Audio(this.url);
    },
    computed:{
      playIcon(){
        return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAMAAADW3miqAAAAAXNSR0IArs4c6QAAAJlQTFRFAAAAgICAVVVVVVWqbW1tcXFxZmZmYmJiW1ttXV1oYGBqYmJsW1ttYGBoXV1kXV1oW1tmX19pXV1nW1tpWlpmXl5mXl5pXV1oXl5nXFxmXFxnXFxmXFxmXV1nXFxmXFxoW1tnXV1mXFxnXV1oXFxnW1tmXFxmW1tmXFxnW1tmXFxmW1tmXFxoW1tnXFxnW1tmXFxmXFxnW1tmriBlqQAAADN0Uk5TAAIDAwcJCg0OFhgaHCAhLC0zNDhBQURHT1BZZmdoaWxtbm9xcnN1dnd4fYSFhoiJioqMbfaW1gAAAOlJREFUOMuNlOcSgjAQhDcq2EvsgCB2VCzc+z+c4igGCHD7LzvfJJdrgCJT2l4QhoFnSxN6jdyIEkXuUIMMfMrIH2QQYZFGS6EyhkNaOYbCnKhAx4QSKyqU83vRohItv/+iUvU/kPr3Sx7yPzlUjAl6hxwVZ9VTzi2gvnhmIPddL6UW1Iyv7u7TUGRiTFkItfkjRUnYeQjobFXXToWUQBCz+9/1cNZCQHuTuAFuBRAa158b8iDWcwWBT1OBs1IgOcnUlGWXKwurwLxWqWq6Nb99WYMA4TBGCsaxejh5Y85bGLzVw1xiZevwBarUqt/eFsorAAAAAElFTkSuQmCC'
      }
    },
    methods:{
      plays(){
        if(!this.playStatue){
          this.player.play();
          this.playStatue=true
        }else{
          this.player.pause();
          this.player.load();
          this.playStatue=false
        }
      }
    }
  }
</script>

<style lang="scss">
  .Html{
    &.audio{
      width:137px;
      height:67px;
      border-radius: 5px;
      border: 1px solid #E8E3E3;
      background-color: #FFFFFF;
      padding:5px 7px;
      display: inline-block;
      position: relative;
    }
    &.video{
      width:137px;
      height: 102px;
    }
    .name{
      font-size:12px;
      color:#696B76;
      line-height:17px;
      width:102px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      word-wrap: break-word;
    }
    .duration{
      margin-top:5px;
      font-size:12px;
      color:#696B76;
      line-height:17px;
    }
    .plays{
      position: absolute;
      width: 15px;
      height: 15px;
      right: 7px;
      bottom: 14px;
    }
    
    .play-icon{
      width: 15px;
      height: 15px;
      position: absolute;
      bottom: 14px;
      right: 7px;
      &.playing_{
        padding: 2px;
        border-radius: 100%;
        background-color: rgba(0,0,0,0.5);
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
      .playing{
        animation: playing 1.3s ease infinite alternate;
        width: 2px;
        background-color: #FFFFFF;
        &.bar2{
          animation-delay: -2.4s;
        }
        &.bar3{
          animation-delay: -3.7s;
        }
      }
    }
  }
  body{
    margin:0
  }
  *{
    box-sizing:border-box;
  }
</style>
