<template>
  <div class="setPhone">
    <div class="title">{{ $t('修改手机') }}</div>
    <card class="Card">
      <!-- 表单 -->
      <div class="form">
        <div class="row user-phone">
          <input type="text" @blur="inspectKey('phone')" class="input-in" :placeholder="$t('输入新的手机号')" v-model="phone">
          <Button class="button h-100" :loading="loading.sendMsg" :class="{disabled:disabled.sendMsg}" @click="sendCode">{{loading.sendMsg?'':buttonText}}</Button>
        </div>
        <div class="input row hidden">
          <input type="text" @blur="inspectKey('code')" class="input-in flex-1" :placeholder="$t('请输入验证码')" v-model="code">
        </div>
        <div class="row">
          <div class="input-name"></div>
          <div class="tips row" :style="{opacity:isTips?1:0}">
            <img src class="tips-img" src="assets/image/notice@2x.png" >
            <span class="tips-text">{{tips}}</span>
          </div>
        </div>
      </div>
      <!-- next -->
      <Button class="button button-submit" :loading="loading.submit" @click="submit">{{$t('确定')}}</Button>
    </card>
  </div>
</template>

<script>
  export default {
    name: 'setPhone',
    data(){
      return {
        loading:{
          submit:false,
          sendMsg:false
        },
        disabled:{
          sendMsg:false
        },
        buttonText:this.$t("发送验证码"),
        code:'',
        isTips:false,
        tips:this.$t("输入验证码"),
        phone:''
      }
    },
    created() {
      
    },
    mounted() {
      
    },
    computed:{
      
    },
    methods:{
      sendCode(){
        if(!this.phone.trim()){
          this.$message.error(this.$t("请输入手机号"))
          return 
        }
        let reg = /^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/;
        if(!reg.test(this.phone)){
          this.$message.error(this.$t("请输入正确的手机号"))
          return
        }
        this.loading.sendMsg = true
        this.$apis.login.sendSms({account:this.phone}).then(res=>{
          setTimeout(()=>{
            this.loading.sendMsg  = false
            this.$message.success(this.$t('验证码已发送，请注意查收'));
            this.disabled.sendMsg = true
            let timeNum = 60
            this.buttonText = timeNum + this.$t("秒后重试")
            let timer = setInterval(()=>{
              this.buttonText = timeNum + this.$t("秒后重试")
              timeNum -- 
              if(timeNum <= 0){
                clearInterval(timer)
                this.buttonText = this.$t("发送验证码")
                this.disabled.sendMsg = false
              }
            },1000)
          },500)
          
        }).catch(err=>{
          this.loading.sendMsg = false
        })
      },
      inspectKey(type){
        if(type == 'code'){
          if(this.code.trim()){
            this.isTips = false
            return true
          }
          this.isTips = true
          return false
        }else{
          if(this.phone.trim()){
            return true
          }
          return false
        }
      },
      submit(){
        if(!this.phone.trim()){
          this.$message.error(this.$t("请输入手机号"))
          return 
        }
        let reg = /^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/;
        if(!reg.test(this.phone)){
          this.$message.error(this.$t("请输入正确的手机号"))
          return
        }
        if(this.inspectKey()){
          this.loading.submit = true
          let data = {
            account:this.phone,
            smsCode:this.code
          }
          this.$apis.login.validateSms(data).then(res=>{
            setTimeout(()=>{
              let params = {
                phone:this.phone
              }
              this.$apis.login.replacePhone(params).then(res=>{
                this.$router.push(`/page/success?btnType=back`)
                this.loading.submit = false
              }).catch(err=>{
                this.loading.submit = false
              })
            },500)
          }).catch(err=>{
            this.loading.submit = false
          })
        }
      }
    }
  }
</script>

<style lang="scss">
  .setPhone{
    padding: 19px 40px;
    display: flex;
    flex-direction: column;
    height: 100%;
    .title{
      color: #3B3F56;
      font-size: 18px;
      line-height: 25px;
      font-weight: 700;
    }
    .Card{
      padding: 0 0 34px 19px;
      .form{
        .user-phone{
          padding: 16px 0 20px;
          border-bottom: 1px solid rgba(151, 151, 151, 0.13);
          .input-in{
            width: 175px;
            height: 32px;
            border: 1px solid #DBDBE7;
            border-radius: 3px;
            padding-left: 12px;
            box-sizing: border-box;
            border-right: none;
          }
          .button{
            width: 70px;
            height: 32px;
            border-radius: 5px;
            transform: translateX(-5px);
            font-size: 12px;
            line-height: 17px;
            
            &.disabled{
              background-color: #DCDCDC;
            }
          }
        }
        
        .input{
          width: 238px;
          height: 34px;
          border-radius: 3px;
          border: 1px solid #DBDBE7;
          margin-top: 19px;
          padding-left: 12px;
        }
      }
      .button-submit{
        width: 125px;
        height: 32px;
        border-radius: 5px;
        margin-top: 10px;
      }
    }
  }
</style>
