<template>
  <div class="reg">
    <div class="title">{{ $t('找回密码') }}</div>
    <div class="card">
      <div v-if="current == 0">
        <!-- 表单 -->
        <div class="form row col ai-top">
          <div class="item" v-for="(item,key) in forms[0]" :key="key">
            <div class="form-item row">
              <div class="input-name">{{ item.name }}:</div>
              <div class="input row hidden">
                <input :type="item.type" @blur="inspectKey(key,0)" @input="$input(item,key)" class="input-in flex-1" :placeholder="item.placeholder" v-model="item.value">
                <Button class="button h-100" v-if="item.isButton" :loading="item.loading" :class="{disabled:item.disabled}" @click="item.buttonFun">{{item.loading?'':item.buttonText}}</Button>
              </div>
            </div>
            <div class="row">
              <div class="input-name"></div>
              <div class="tips row" :style="{opacity:item.isTips?1:0}">
                <img src class="tips-img" src="assets/image/notice@2x.png" >
                <span class="tips-text">{{item.tips}}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- next -->
        <Button class="button button-submit" :loading="loading.next" @click="next">{{$t('下一步')}}</Button>
      </div>
      <div v-if="current == 1">
        <!-- 表单 -->
        <div class="form row col ai-top">
          <div class="item">
            <div class="form-item row">
              <div class="input-name">账号:</div>
              <div class="input row hidden" style="border-color: rgba(0,0,0,0);">{{forms[0].email.value | VEmail}}</div>
            </div>
            <div class="row">
              <div class="input-name"></div>
              <div class="tips row" :style="{opacity:0}">
                <img src class="tips-img" src="assets/image/notice@2x.png" >
                <span class="tips-text"></span>
              </div>
            </div>
          </div>
          <div class="item" v-for="(item,key) in forms[1]" :key="key">
            <div class="form-item row">
              <div class="input-name">{{ item.name }}:</div>
              <div class="input row hidden">
                <input :type="item.type" @blur="inspectKey(key,1)" class="input-in flex-1" :placeholder="item.placeholder" v-model="item.value">
                <Button class="button h-100" v-if="item.isButton" :loading="item.loading" :class="{disabled:item.disabled}" @click="item.buttonFun">{{item.loading?'':item.buttonText}}</Button>
              </div>
            </div>
            <div class="row">
              <div class="input-name"></div>
              <div class="tips row" :style="{opacity:item.isTips?1:0}">
                <img src class="tips-img" src="assets/image/notice@2x.png" >
                <span class="tips-text">{{item.tips}}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 完成 -->
        <Button class="button button-submit" :loading="loading.submit" @click="submit">{{$t('完成')}}</Button>
      </div>
    </div>
    <About></About>
  </div>
</template>

<script>
  export default {
    name: 'reg',
    data(){
      return {
        loading:{
          next:false,
          submit:false
        },
        current:0,
        forms:{
          0:{
            email:{
              type:'text',
              name:this.$t('找回账号'),
              placeholder:this.$t("邮箱"),
              value:"",
              isTips:true,
              tips:this.$t('输入以前已经注册的账号'),
              isButton:true,
              disabled:false,
              buttonText:this.$t("发送验证码"),
              buttonFun:this.sendCode,
              loading:false
            },
            code:{
              type:'tel',
              name:this.$t('验证码'),
              placeholder:this.$t('输入验证码'),
              value:"",
              isTips:true,
              tips:this.$t('6位验证码'),
              isButton:false,
              maxLength:6
            },
          },
          1:{
            password:{
              type:'password',
              name:this.$t('密码'),
              placeholder:this.$t('请输入密码'),
              value:"",
              isTips:true,
              tips:this.$t('密码不低于6位字符'),
              isButton:false,
            },
            cfPassword:{
              type:'password',
              name:this.$t('确认密码'),
              placeholder:this.$t('请输入密码'),
              value:"",
              isTips:true,
              tips:this.$t('确保两次密码一致'),
              isButton:false,
            }
          }
        }
      }
    },
    created() {
      
    },
    mounted() {
      
    },
    computed:{
      
    },
    methods:{
      sendCode(){
        if(!this.forms[0].email.value.trim()){
          this.forms[0].email.isTips = true
          return
        }
        let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
        if(!reg.test(this.forms[0].email.value)){
          this.$message.error(this.$t('请输入正确的邮箱'));
          return
        }
        if(!this.forms[0].email.disabled){
          this.forms[0].email.loading = true
          this.$apis.login.sendSms({account:this.forms[0].email.value}).then(res=>{
            setTimeout(()=>{
              this.forms[0].email.loading = false
              this.$message.success(this.$t('验证码已发送，请注意查收'));
              this.forms[0].email.disabled = true
              let timeNum = 60
              this.forms[0].email.buttonText = timeNum + this.$t("秒后重试")
              let timer = setInterval(()=>{
                this.forms[0].email.buttonText = timeNum + this.$t("秒后重试")
                timeNum -- 
                if(timeNum <= 0){
                  clearInterval(timer)
                  this.forms[0].email.buttonText = this.$t("发送验证码")
                  this.forms[0].email.disabled = false
                }
              },1000)
            },500)
            
          }).catch(err=>{
            this.forms[0].email.loading = false
          })
        }
        
      },
      $input(item,key){
        if(item.maxLength){
          if(item.value.length>item.maxLength) {
            item.value=item.value.slice(0,item.maxLength)
          }
        }
        
      },
       inspectKey(key,i){
         if(key == 'password' || key == 'cfPassword'){
           if(!this.forms[i].password.value.trim() && !this.forms[i].cfPassword.value.trim()){
             // this.forms[i].cfPassword.tips = this.$t("请输入确认密码")
           }
           if(this.forms[i].password.value.trim() && this.forms[i].cfPassword.value.trim()){
             if(this.forms[i].password.value != this.forms[i].cfPassword.value){
               // this.forms[i].cfPassword.tips = this.$t("两次密码不一致")
               this.forms[i][key].isTips = true
               return true
             }
           }
         }
         if(!this.forms[i][key].value.trim()){
           this.forms[i][key].isTips = true
           return true
         }else{
           this.forms[i][key].isTips = false
           return false
         }
       },
      next(){
        this.isInspect = false
        for (let key in this.forms[0]) {
          if(this.inspectKey(key,0)){
            this.isInspect = true
          }
        }
        if(!this.isInspect){
          this.loading.next = true
          let data = {
            account:this.forms[0].email.value,
            smsCode:this.forms[0].code.value
          }
          this.$apis.login.validateSms(data).then(res=>{
            setTimeout(()=>{
              this.loading.next = false
              this.current = 1
            },500)
          }).catch(err=>{
            this.loading.next = false
          })
        }
      },
      submit(){
        this.isInspect = false
        for (let key in this.forms[1]) {
          if(this.inspectKey(key,1)){
            this.isInspect = true
          }
        }
        if(this.forms[1].cfPassword.value.length < 6){
          this.$message.error('密码不低于6位'.$t())
          return
        }
        if(!this.isInspect){
          let data = {
            account:this.forms[0].email.value,
            password:this.forms[1].cfPassword.value
          }
          this.$apis.login.forgotPassword(data).then(res=>{
            this.$message.success(this.$t('重置成功'))
            this.$router.replace('/login')
          })
        }
      }
    },
    filters:{
      VEmail(val){
        let strArr = val.split('')
        strArr[3] = '*'
        strArr[4] = '*'
        strArr[5] = '*'
        strArr[6] = '*'
        
        return strArr.join('')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .reg{
    // padding: 19px 61px;
	height: 85vh;
	width: 100vw;
    display: flex;
    flex-direction: column;
	align-items: center;
	// justify-content: center;
    .title{
      color: #3B3F56;
      font-size: 18px;
      line-height: 25px;
      font-weight: 700;
    }
    .card{
      margin-top: 15px;
      padding: 0 13px;
      padding-bottom: 24px;
      .form{
        .item{
          margin-top: 26px;
        }
        .input-name{
          width: 5em;
        }
        .form-item{
          .input{
            width: 320px;
            height: 34px;
            border-radius: 3px;
            border: 1px solid #DBDBE7;
            padding-left: 11px;
            .input-in{
              width: 159px;
            }
            .button{
              width: 70px;
              font-size: 12px;
              line-height: 17px;
			  background-color: #427CDF;
              &.disabled{
                background-color: #DCDCDC;
              }
            }
          }
          
        }
      }
      
      .button-submit{
        width: 300px;
        height: 35px;
        border-radius: 30px;
        margin-left: 70px;
        margin-top: 38px;
		
		background-color: #427CDF;
      }
      
    }
    
    
  }
</style>

