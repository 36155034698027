<template>
  <div class="setUp">
    <card class="Card">
      <div class="card-title">功能设置</div>
      <div class="item" v-for="(item,index) of list" :key="index">
        <div class="name Regular">{{item.name}}</div>
        <div class="infos row">
          <div class="info flex-1">{{item.info}}</div>
          <m-switch :status.sync="item.Switch" :id="index" :loading="item.loading"></m-switch>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
  export default {
    name: 'setUp',
    data(){
      return {
        list:[
          {
            name:"水印设置：",
            info:"开启后，文章发布的图片将自动打上公众号昵称的水印",
            Switch:false,
            loading:(fun)=>{
              this.SwitchChange(0,fun)
            }
          },
          {
            name:"昵称搜索：",
            info:"开启后，允许用户通过众号昵称搜索到",
            Switch:false,
            loading:(fun)=>{
              this.SwitchChange(1,fun)
            }
          }
        ]
      }
    },
    created() {
      this.init()
    },
    mounted() {
      
    },
    computed:{
      
    },
    methods:{
      init(){
        this.$apis.user.getOfficialAccountInfo().then(res=>{
          this.list[0].Switch = res.data.accountInfoItem.watermark==1?true:false
          this.list[1].Switch = res.data.accountInfoItem.searched==1?true:false
        })
      },
      SwitchChange(index,fun){
        console.log(index);
        let data = {
          searched:this.list[1].Switch ? 1:2,
          watermark:this.list[0].Switch ? 1:2,
        }
        if(index == 0){
          data.watermark = this.list[0].Switch ? 2:1
        }
        if(index == 1){
          data.searched = this.list[1].Switch ? 2:1
        }
        
        this.$apis.user.renewSetUp(data).then(res=>{
          setTimeout(()=>{
            fun(true)
          },500)
        }).catch(err=>{
          fun(false)
        })
      }
    }
  }
</script>

<style lang="scss">
  .setUp{
    .Card{
      padding: 11px 17px;
      .card-title{
        margin-bottom: 7px;
      }
      .item{
        padding: 26px 0 10px 0;
        .name{
          color: #666A82;
          text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
        }
        .infos{
          margin-top: 16px;
          .info{
            font-size: 15px;
            color: #3B3F56;
            line-height: 21px;
            text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
          }
        }
      }
    }
  }
</style>
