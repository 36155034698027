<template>
  <div class="release row ai-top">
    <div class="tab-bar">
      <div class="item row js-center pointer" @click="inData('image')">
        <div class="image"><img src="assets/image/release/pic@2x.png" ></div>
        <div class="text Regular">{{$t('插入图片')}}</div>
      </div>
      <div class="item row js-center pointer" @click="inData('video')">
        <div class="image"><img src="assets/image/release/video@2x.png" ></div>
        <div class="text Regular">{{$t('插入视频')}}</div>
      </div>
      <div class="item row js-center pointer" @click="inData('audio')">
        <div class="image"><img src="assets/image/release/music@2x.png" ></div>
        <div class="text Regular">{{$t('插入音频')}}</div>
      </div>
      <div class="item row js-center pointer" @click="inData('emoji')">
        <div class="image"><img src="assets/image/release/smile@2x.png" ></div>
        <div class="text Regular">{{$t('插入表情')}}</div>
      </div>
      <div class="item row js-center pointer" @click="inData('vote')">
        <div class="image"><img src="assets/image/release/vote@2x.png" ></div>
        <div class="text Regular">{{$t('插入投票')}}</div>
      </div>
      <div class="item row js-center pointer" @click="inData('link')">
        <div class="image"><img src="assets/image/release/link@2x.png" ></div>
        <div class="text Regular">{{$t('插入链接')}}</div>
      </div>
    </div>
    
    
    <div class="flex-1">
      <card class="Card tools row">
        <div id="toolbar" ref="toolbar" class="toolbar row"></div>
      </card>
      <card class="Card editor-box">
        <div class="input-title row"><input type="text" v-model="title" maxlength="64" :placeholder="$t('请在这里输入标题')" /><span class="Regular">{{title.length}}/64</span></div>
        <!-- 富文本 -->
        <div id="wangE" style="padding: 0 5px;min-height: 189px; height: 450px; overflow-y: scroll;" ref="wangE">
        </div>
        <!-- 投票数据 -->
        <div class="votes">
          <div class="vote-item" v-for="(item,index) of votes" :key="index">
            <div class="vote-title row">
              <span>{{item.title}}</span>
              <img class="vote-icon" src="assets/image/menu/edit@2x.png" >
              <span class="vote-edit pointer" @click="editVote(item,index)">{{'编辑投票'.$t()}}</span>
              <img class="vote-icon" src="assets/image/menu/delete@2x.png" >
              <span class="vote-edit pointer" @click="deleteVote(index)">{{'删除投票'.$t()}}</span>
            </div>
            <div class="vote-time Regular">有效时间：{{item.stareDate | getTime}} 至 {{item.endDate | getTime}}</div>
            <div class="options">
              <div class="options-item" v-for="(option,i) of item.options" :key="'o'+i">
                <check :label="option.name"></check>
              </div>
            </div>
          </div>
        </div>
        
        <div class="border-bottom"></div>
        
        <div class="other-info">
          <div class="info-title">{{$t("封面和简介")}}</div>
          <div class="info row">
              <div class="cover"  @click="coverImgIN">
                <img src="assets/image/update-image.png" v-if="!coverImg">
                <img :src="coverImg" v-else>
              </div>
            
            <div class="summary pos-rel flex-1 pointer">
              <textarea :placeholder="$t('简介')" class="Regular" maxlength="200" v-model="summary"></textarea>
              <div class="simmary-number">{{summary.length}}/200</div>
            </div>
          </div>
        </div>
        
        <div class="radio-box row" style="margin-top: 28px;">
          <div>{{$t("点赞权限")}}：</div>
          <div class="radio-group row">
            <div class="radio row pointer" :class="{active:great}" @click="great = true">
              <div class="radio-icon row js-center"></div>
              <div class="radio-text">{{$t("允许")}}</div>
            </div>
            <div class="radio row pointer" :class="{active:!great}" @click="great = false">
              <div class="radio-icon row js-center"></div>
              <div class="radio-text">{{$t("禁止")}}</div>
            </div>
          </div>
        </div>
        
        <div class="radio-box row">
          <div>{{$t("评论权限")}}：</div>
          <div class="radio-group row">
            <div class="radio row pointer" :class="{active:comment}" @click="comment = true">
              <div class="radio-icon row js-center"></div>
              <div class="radio-text">{{$t("允许")}}</div>
            </div>
            <div class="radio row pointer" :class="{active:!comment}" @click="comment = false">
              <div class="radio-icon row js-center"></div>
              <div class="radio-text">{{$t("禁止")}}</div>
            </div>
          </div>
        </div>
        
        <div class="border-bottom"></div>
        
        <div class="btns row">
          <div class="text flex-1 Regular">{{$t('正文字数：'+this.contentTxt.length+'字')}}</div>
          <Button class="button" :loading="loading.send" @click="send">{{$t("发布")}}</Button>
          <Button class="button button-ho" @click="preview">{{$t("预览")}}</Button>
          <Button class="button button-ho" :loading="loading.save" @click="save">{{$t("保存")}}</Button>
        </div>
        
      </card>
      
    </div>
    <!-- 插入内容组件 -->
    <insert ref="insert" @change="insertChange" :inType="inType"></insert>
    <!-- 预览组件 -->
    <popup style="z-index: 9998;" ref="preview">
      <card class="Preview">
        <div class="Preview-title">{{$t("预览")}}</div>
        <div class="Preview-content row ai-top">
          <div class="Preview-detal" >
            <div class="Preview-html-title">{{title}}</div>
            <div class="Preview-html" v-html="content"></div>
            <!-- 投票数据 -->
            <div class="votes_Preview">
              <div class="vote-item" v-for="(item,index) of votes" :key="index">
                <div class="vote-title row">
                  <span>{{item.title}}</span>
                </div>
                <div class="vote-time Regular">有效时间：{{item.stareDate | getTime}} 至 {{item.endDate | getTime}}</div>
                <div class="options">
                  <div class="options-item" v-for="(option,i) of item.options" :key="'o'+i">
                    <check :label="option.name"></check>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="Preview-qrcode">
          </div>
        </div>
        <div class="btns row js-center">
          <Button class="button button-ho" @click="closePreview">{{$t('取消')}}</Button>
          <Button class="button" @click="closePreview">{{$t('确定')}}</Button>
        </div>
      </card>
    </popup>
    
    <!-- 裁剪 -->
    <popup style="z-index: 9998;" ref="cropperPopup">
      <card class="Card-cropper">
        <div class="cropper-title">封面上传</div>
        <div class="cropper-disc Regular">上下拖动选择框，选中封面展示区域</div>
        <div class="cropper-box_">
          <vueCropper
            ref="cropper"
            :img="option.img"
            :outputSize="option.size"
            :outputType="option.outputType"
            :info="true"
            :full="option.full"
            :canMove="option.canMove"
            :canMoveBox="option.canMoveBox"
            :original="option.original"
            :autoCrop="option.autoCrop"
            :fixed="option.fixed"
            :fixedNumber="option.fixedNumber"
            :centerBox="option.centerBox"
            :infoTrue="option.infoTrue"
            :fixedBox="option.fixedBox"
            :checkCrossOrigin="false"
          ></vueCropper>
        </div>
        <div class="btns row js-center">
          <Button class="button button-ho" @click="cropperPopupClose">上一步</Button>
          <Button class="button" :loading="option.loading" @click="cropperPopupSuccess">完成</Button>
        </div>
      </card>
    </popup>
    
    <!-- 返回顶部 -->
    <backtop target=".content" right="40" bottom="110" ></backtop>
    
    <!-- <el-backtop target=".content" :bottom="100">
      up
    </el-backtop> -->
  </div>
</template>

<script>
  import E from 'wangeditor'
  export default {
    name: 'release',
    components:{
    },
    data(){
      return {
        loading:{
          save:false,
          send:false
        },
        option:{
          img: '', // 裁剪图片的地址
          info: true, // 裁剪框的大小信息
          outputSize: 0.8, // 裁剪生成图片的质量
          outputType: 'jpeg', // 裁剪生成图片的格式
          canScale: false, // 图片是否允许滚轮缩放
          autoCrop: true, // 是否默认生成截图框
          autoCropWidth: 230, // 默认生成截图框宽度
          autoCropHeight: 230, // 默认生成截图框高度
          fixed: true, // 是否开启截图框宽高固定比例
          fixedNumber: [1, 1], // 截图框的宽高比例
          fixedBox: false, // 固定截图框大小 不允许改变
          full: true, // 是否输出原图比例的截图
          canMoveBox: true, // 截图框能否拖动
          original: false, // 上传图片按照原始比例渲染
          centerBox: false, // 截图框是否被限制在图片里面
          infoTrue: false ,// true 为展示真实输出图片宽高 false 展示看到的截图框宽高
          maxImagSize:500,
          name:"",
          loading:false
        },
        title:"",// 标题
        content:``,// 内容
        contentTxt:'',// 文本内容
        savedRange:0,// 光标的位置
        summary:"",// 简介
        great:false,// 点赞权限
        comment:false,// 评论权限
        coverImg:"",// 封面图
        editor:null,
        type:'',
        id:'',
        inType:'content',// 插入类型
        votes:[],//投票数据
        deleteVoteIds:[],// 待删除投票id数据
      }
    },
    created() {
      let o = this.$route.query
      if(o.id){
        this.id = o.id
        this.type = o.type
        this.init()
      }
    },
    mounted() {
      // 创建富文本
      // 使用wangeditor
      // 文档地址： https://www.wangeditor.com/
      let editor = new E(this.$refs.toolbar,this.$refs.wangE)
      this.editor =editor
      // editor.config.menus = [
      //   "fontSize",
      //   "foreColor",
      //   "backColor",
      //   "justify",
      //   'bold',
      //   'italic',
      //   'strikeThrough',
      //   'undo',
      //   'redo',
      // ]
	  editor.config.menus = [
	      'head',
	      'bold',
	      'fontSize',
	      'fontName',
	      'italic',
	      'underline',
	      'strikeThrough',
	      'indent',
	      'lineHeight',
	      'foreColor',
	      'backColor',
	      'link',
	      'list',
	      'todo',
	      'justify',
	      'quote',
	      'emoticon',
	      'image',
	      'video',
	      'table',
	      'code',
	      'splitLine',
	      'undo',
	      'redo',
	  ] 
      // 富文本菜单提示设置为底部弹出
      editor.config.menuTooltipPosition = 'down'
      
      editor.config.showFullScreen = false
      // 设置富文本层级
      editor.config.zIndex = 100
      // 设置placeholder
      editor.config.placeholder = '正文'
      // 监听内容改变
      editor.config.onchange = (html)=>{
        this.content = html
        this.contentTxt = editor.txt.text().Trim()
      }
      
      // 忽略复制的图片
      editor.config.pasteIgnoreImg = true
      
      // 配置粘贴文本的内容处理
      editor.config.pasteTextHandle = function (pasteStr) {
          // 对粘贴的文本进行处理，然后返回处理后的结果
          return pasteStr
      }
      
      
      // 创建
      editor.create()
      
      // 此处是富文本菜单中添加清空的按钮及图片，以及监听事件，样式
      setTimeout(()=>{
        let target  = document.querySelector('.w-e-menu[data-title="撤销"]')
        let deleteButton = document.createElement('img')
        deleteButton.src = "assets/image/release/clear@2x.png"
        deleteButton.style = 'width:27px;height:27px;cursor: pointer;'
        deleteButton.onclick = ()=>{
          editor.txt.clear()
        }
        let div = document.createElement('div')
        div.style = 'flex:1;'
        target.parentNode.insertBefore(div,target);
        target.parentNode.insertBefore(deleteButton,target);
      },100)
      // 修改编辑器宽度
      this.getWangEWidth()
      // 监听网页尺寸改变进行修改编辑器宽度
      onresize = this.getWangEWidth
    },
    methods:{
      // 修改编辑器宽度
      getWangEWidth(){
        if(window.innerWidth>=1024){
          wangE.style.width = window.innerWidth - 137 - 14 - 20 - 40 + 'px'
        }
      },
      // 编辑时获取数据
      init(){
        this.$apis.wz.queryArticleInfo({id:this.id}).then(res=>{
          // 自定义化投票数据
          res.data.voteTopicResponseList = res.data.voteTopicResponseList.map(item=>{
            item.options = item.voteOptionResponseList
            item.stareDate = item.startTime
            item.endDate = item.endTime
            return item
          })
          this.votes = res.data.voteTopicResponseList
          let data = res.data.articleItem
          this.title = data.title
          this.coverImg = data.coverImg
          this.summary = data.introduce
          this.comment = data.comment == 1
          this.great = data.fabulous == 1
          this.content = data.content
          this.editor.txt.html(this.content)
        })
      },
      // 点击插内容
      // 右边的插入按钮
      inData(type){
        this.inType = 'content'
        this.$refs.insert.open(type,0,0,true)
      },
      // 编辑器失去焦点
      onEditorBlur(ev){
        // 编辑器失去焦点时获取光标所在的位置
        this.savedRange = ev.selection.savedRange.index
      },
      // 打开预览插件
      preview(){
        this.$refs.preview.open()
      },
      // 预览插件关闭
      closePreview(){
        this.$refs.preview.close()
      },
      // 插入内容插件完成
      insertChange(ev){
        console.log(ev,'----返回数据---'+this.inType)
        // 判断是不是选择封面，选择封面的化打开剪切页面
        if(this.inType == 'coverImg'){
          this.getBase64Img({url:ev.url},(evs)=>{
            this.option.img = evs
            this.$refs.cropperPopup.open()
            this.option.name = ev.name
          })
          return
        }
        // 插入投票内容
        if(ev.type == 'vote'){
          // 判断是添加新投票还是编辑投票
          if(ev.voteType == 'add'){
            this.votes.push(ev.vote)
          }else{
            // 根据i编辑投票
            this.votes[ev.i] = ev.vote
            console.log(this.votes);
          }
          return
        }
        else{
          // 调用全局的插入富文本内容
          // 在 /config/index.js 文件中封装
          this.$insert(ev,this.editor)
        }
      },
      // 编辑投票
      editVote(info,index){
        this.$refs.insert.open('vote',info,index)
      },
      // 投票数据提交
      voteSubmit(){
        
      },
      // 封面点击，打开插入内容组件
      coverImgIN(){
        this.inType = 'coverImg'
        this.$refs.insert.open('image')
      },
      // 选择图片（暂时没用，之前是本地图片作为封面）
      onImageFileChange(ev){
        if(this.beforeUpload(ev.raw)){
          this.option.img = window.URL.createObjectURL(new Blob([ev.raw]))
          this.$refs.cropperPopup.open()
          this.option.name = ev.name
        }
        
      },
      // 关闭截切弹窗
      cropperPopupClose(){
        this.$refs.cropperPopup.close()
      },
      // 截切弹窗完成
      cropperPopupSuccess(){
        this.option.loading = true
        this.$refs.cropper.getCropBlob((data) => {
          let fileData = new window.File([data],this.option.name)
          this.$apis.sc.upload(fileData,(e)=>{
            console.log(e);
          }).then(res=>{
            this.option.loading = false
            this.coverImg = res
            this.$refs.cropperPopup.close()
          }).catch(err=>{
            this.option.loading = false
          })
          
        })
      },
      // 保存按钮点击
      save(){
        if(!this.contentV()) return
        let topicModelList = []
        let data = {
          "comment": this.comment ?1:2,
          "content": this.content,
          "coverImg": this.coverImg,
          "fabulous": this.great?1:2,
          "introduce": this.summary,
          "title": this.title,
          "topicModelList": topicModelList,
          "type": 0 , // 类型—1保存 2发布
        }
        data.type = 1
        this.loading.save = true
        if(this.id){
          data.id = this.id
          this.$apis.wz.renewArticleDraft(data).then(res=>{
            this.loading.save = false
            this.$message.success(this.$t("保存成功"))
            this.$router.replace('/draftBox')
            // if(this.type == 'release'){
            //   this.$router.replace('/myCreation')
            // }else{
            //   this.$router.replace('/draftBox')
            // }
            this.voteAdd(this.id)
          }).catch(err=>{
            this.loading.save = false
          })
        }else{
          this.$apis.wz.addArticleDraft(data).then(res=>{
            this.loading.save = false
            this.id = res.data.articles.id
            this.$message.success(this.$t("保存成功"))
            this.$router.replace('/draftBox')
            this.voteAdd(this.id)
            // if(this.type == 'release'){
            //   this.$router.replace('/myCreation')
            // }else{
            //   this.$router.replace('/draftBox')
            // }
          }).catch(err=>{
            this.loading.save = false
          })
        }
        
        
      },
      // 发布按钮点击
      send(){
        if(!this.contentV()) return
        let topicModelList = []
        let data = {
          "comment": this.comment ?1:2,
          "content": this.content,
          "coverImg": this.coverImg,
          "fabulous": this.great?1:2,
          "introduce": this.summary,
          "title": this.title,
          "topicModelList": topicModelList,
          "type": 0 , // 类型—1保存 2发布
        }
        if(this.id){
          data.id = this.id
          this.$apis.wz.renewArticleDraft(data).then(res=>{
            this.$apis.wz.articlesLowerFrame({articleId:this.id,type:2}).then(res=>{
              this.$message.success(this.$t("发布成功,请等待管理员审核"))
              this.$router.replace('/myCreation')
              this.id = res.data.articles.id
              // if(this.type == 'release'){
              //   this.$router.replace('/myCreation')
              // }else{
              //   this.$router.replace('/draftBox')
              // }
              this.voteAdd(this.id)
            })
          })
        }else{
          data.type = 2
          this.$apis.wz.addArticleDraft(data).then(res=>{
            this.$message.success(this.$t("发布成功,请等待管理员审核"))
            this.$router.replace('/myCreation')
            this.id = res.data.articles.id
            this.voteAdd(this.id)
            // if(this.type == 'release'){
            //   this.$router.replace('/myCreation')
            // }else{
            //   this.$router.replace('/draftBox')
            // }
          })
        }
        
      },
      
      // 投票创建
      // 此处投票是在文章保存和发布后进行创建的
      // 由于接口需要将投票绑定在文章上面，若首次发布时没有文章id，所以在发布后得到文章id在创建投票（同理投票选项也是在投票创建后进行添加的）
      async voteAdd(id){
        // 保存投票数据到该文章
        if(this.votes.length){
          let allT = await this.votes.map(async (item,index)=>{
            let data = {
              articleId: id,
              endTime: new Date(item.endDate).getTime(),
              partakeMode: item.partakeMode,
              startTime: new Date(item.stareDate).getTime(),
              title: item.title,
              voteSet: item.voteSet,
              id:item.id
            }
            let voteData = await this.$apis.wz.addVoteTopic(data)
            let voteTopicId
            if(item.id){
              voteTopicId  = item.id
            }else{
              voteTopicId  = voteData.data.voteTopic.id
            }
            let arrOption = await item.options.map(async (it,i)=>{
              let params = {
                voteTopicId:voteTopicId,
                name:it.name,
                id:it.id
              }
              return await this.$apis.wz.addVoteOption(params)
            })
            return await Promise.all(arrOption)
          })
          Promise.all(allT).then(res=>{
            console.log("投票插入成功",res);
          })
        }
        // 删除投票
        if(this.deleteVoteIds.length){
          let deleteVoteArr = this.deleteVoteIds.map(item=>{
            return this.$apis.wz.deleteVoteTopic({id:item})
          })
          Promise.all(deleteVoteArr).then((res)=>{
            console.log("已删除投票数据",res);
          })
        }
      },
      // 删除投票，暂时保存删除的投票id，等用户点击发布或者保存时进行统一删除
      deleteVote(index){
        this.$confirm(this.$t('确定删除该投票吗？') , this.$t('提示'), {
          confirmButtonText: this.$t('确定'),
          cancelButtonText: this.$t('取消'),
          type: 'warning'
        }).then(res=>{
          let item = this.votes[index]
          if(item.id){
            this.deleteVoteIds.push(item.id)
            this.votes.splice(index,1)
          }else{
            this.votes.splice(index,1)
          }
          
        })
      },
      
      // 判断图片大小
      beforeUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 5;
      
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 5MB!');
        }
        return isLt2M;
      },
      // 内容验证 
      contentV(){
        if(!this.title){
          this.$message.error(this.$t('请输入标题'))
          return false
        }
        if(!this.content){
          this.$message.error(this.$t('请输入正文'))
          return false
        }
        if(!this.summary){
          this.$message.error(this.$t('请输入简介'))
          return false
        }
        return true
      }
    }
  }
</script>

<style lang="scss">
  .release{
    // height: 100%;
    .tab-bar{
      width: 137px;
      background-color: #ffffff;
      height: calc(100% + 40px);
      transform: translateX(-14px);
      padding-top: 13.5px;
      position: fixed;
      .item{
        height: 44px;
        width: 137px;
        .image{
          width: 12px;
          margin-right: 9px;
        }
        .text{
          font-size: 12px;
          color: #696B76;
          line-height: 17px;
        }
      }
    }
    .Card{
      margin-left: 137px;
      &.tools{
        height: 51px;
        .toolbar{
          width: 100%;
          color: #696B76;
          border: none;
          .toolbar-line{
            width: 1px;
            height: 22px;
            background-color: #979797;
          }
        }
      }
      &.editor-box{
        padding: 15px 5px;
        // padding-bottom: 120px;
        .input-title{
          height: 45px;
          padding-left: 15px;
          padding-right: 15px;
          span{
            font-size: 12px;
            color: #696B76;
            line-height: 17px;
          }
          input{
            width: 100%;
            font-size: 18px;
            color: #3B3F56;
            line-height: 25px;
          }
        }
        
        .votes{
          padding-left: 13px;
          .vote-item{
            margin-top: 19px;
            .vote-title{
              font-size: 14px;
              color: #3B3F56;
              line-height: 19px;
              .vote-icon{
                width: 12px;
                height: 13px;
                margin-left: 4px;
              }
              .vote-edit{
                color: #2C7BE5;
                margin-left: 4px;
              }
            }
            .vote-time{
              font-size: 14px;
              color: #696B76;
              line-height: 19px;
              margin-top: 9px;
            }
            .options{
              padding-top: 2px;
              padding-bottom: 27px;
              .options-item{
                margin-top: 16px;
              }
            }
          }
        }
        
        .border-bottom{
          border-bottom: 1px solid rgba(219, 219, 231, 0.26);
          margin: 0 15px;
        }
        
        .other-info{
          padding-left: 15px;
          .info-title{
            padding: 13px 0;
            font-size: 14px;
            color: #696B76;
            line-height: 19px;
            text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
          }
          .info{
            .cover{
              width: 121px;
              height: 121px;
              box-shadow: 0px 2px 3px 0px rgba(220,231,239,0.5);
              border: 1px solid #E3E3E3;
            }
            .summary{
              margin-left: 15px;
              height: 121px;
              border: 1px solid #E3E3E3;
              box-shadow: 0px 2px 3px 0px rgba(220,231,239,0.5);
              textarea{
                width: 100%;
                height: 100%;
                padding: 10px 15px;
                font-size: 12px;
                color: #696B76;
                line-height: 17px;
                text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
                box-sizing: border-box;
                resize:none;
              }
              .simmary-number{
                position: absolute;
                bottom: 14px;
                right: 14px;
                font-size: 12px;
                color: #696B76;
                line-height: 17px;
                text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
              }
            }
          }
        }
        
        .radio-box{
          padding-left: 15px;
          margin-bottom: 19px;
          font-size: 14px;
          color: #696B76;
          line-height: 19px;
          .radio-group{
            .radio{
              margin: 0 15px;
              &.active{
                .radio-icon{
                  border-color: #FFAE2E;
                  &::after{
                    content: '';
                    display: inline-block;
                    background-color: #FFAE2E;
                    width: 12px;
                    height: 12px;
                    border-radius: 100%;
                  }
                }
              }
              .radio-icon{
                width: 18px;
                height: 18px;
                border-radius: 100%;
                border: 1px solid #D2D2D2;
                margin-right: 9px;
              }
              .radio-text{
                font-size: 14px;
                color: #696B76;
                line-height: 19px;
              }
            }
          }
        }
        
        .btns{
          padding: 18px 0;
          padding-left: 15px;
          padding-right: 20px;
          .text{
            font-size: 14px;
            color: #696B76;
            line-height: 19px;
          }
          .button{
            width: 81px;
            height: 32px;
            border-radius: 5px;
            margin-left: 15px;
          }
        }
        
      }
    }
    
    .Card-cropper{
      width: 588px;
      height: 512px;
      padding: 28px;
      .cropper-title{
        font-size: 15px;
        color: #3B3F56;
        line-height: 21px;
      }
      .cropper-disc{
        font-size: 14px;
        color: #3B3F56;
        line-height: 19px;
        margin-top: 4px;
      }
      .cropper-box_{
        width: 446px;
        height: 321px;
        margin-top: 36.7px;
        margin-left: 29px;
      }
      
      .btns{
        margin-top: 31px;
        .button{
          width: 125px;
          height: 32px;
          border-radius: 5px;
          margin: 0 15px;
          &.button-ho{
            border-color: #FFAE2E;
            color: #FFAE2E;
          }
        }
      }
    }
    
  }
  
  .editor {
    line-height: normal !important;
    min-height: 189px;
    width: calc(100vw - 137px - 41px - 10px);
  }
  
  .Preview{
    width: 589px;
    height: 549px;
    padding: 28px 28px 19px;
    .Preview-title{
      font-size: 15px;
      color: #3B3F56;
      line-height: 21px;
    }
    .Preview-content{
      border-radius: 1px;
      border: 1px solid #E5E4E4;
      height: 417px;
      width: 536px;
      overflow-y: auto;
      margin-top: 11px;
      &::-webkit-scrollbar{
        width:5px !important;
        height:5px !important;
        display: block;
      }
      &::-webkit-scrollbar-track{
        background-color: rgb(239, 239, 239);
        border-radius:5px;
      }
      &::-webkit-scrollbar-thumb{
        background-color: #bfbfbf;
        border-radius:5px;
      }
      &::-webkit-scrollbar-thumb:hover{
        background: #333;
      }
      .Preview-detal{
        // margin-top: 15px;
        width: 398px;
        padding: 11px 15px;
        border-right: 1px solid rgba(219, 219, 231, 0.26);
        .Preview-html-title{
          font-size: 18px;
          color: #3B3F56;
          line-height: 25px;
        }
        .Preview-html{
          margin-top: 19px;
          word-break: break-all;
          line-height: normal;
        }
      }
    }
    .btns{
      margin-top: 20px;
      .button{
        width: 125px;
        height: 32px;
        border-radius: 5px;
        margin: 0 15px;
      }
    }
    
    .votes_Preview{
      // padding-left: 13px;
      .vote-item{
        margin-top: 19px;
        .vote-title{
          font-size: 14px;
          color: #3B3F56;
          line-height: 19px;
          .vote-icon{
            width: 12px;
            height: 13px;
            margin-left: 4px;
          }
          .vote-edit{
            color: #2C7BE5;
            margin-left: 4px;
          }
        }
        .vote-time{
          font-size: 14px;
          color: #696B76;
          line-height: 19px;
          margin-top: 9px;
        }
        .options{
          padding-top: 2px;
          padding-bottom: 27px;
          .options-item{
            margin-top: 16px;
          }
        }
      }
    }
    
  }
  
</style>
