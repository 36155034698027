import Vue from 'vue'
import Element from 'element-ui'
import '../element-variables.scss'
import i18n from '../i18n.js'
Vue.prototype.mLoading = (val)=>{
  return Element.Message({
    dangerouslyUseHTMLString: true,
    iconClass:"el-icon-loading",
    message: val,
    center:true,
    duration:0
  });
}
Vue.use(Element,{i18n: (key, value) => i18n.t(key, value)})

