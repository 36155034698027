<template>
  <div class="password">
    <div class="title row">
      <div class="flex-1 title-text">{{ $t('修改密码') }}</div>
      <div class="link pointer" @click="sm">{{ $t('管理员扫码确认')}}</div>
    </div>
    <card class="Card">
      <!-- 表单 -->
      <div class="form">
        <div class="input row hidden">
          <input type="password" class="input-in flex-1" :placeholder="$t('')" v-model="password">
        </div>
        <div class="tip">{{$t('请输入现在的登录密码')}}</div>
      </div>
      <!-- next -->
      <Button class="button button-submit" :loading="loading.submit" @click="submit">{{$t('确定')}}</Button>
    </card>
  </div>
</template>

<script>
  export default {
    name: 'password',
    data(){
      return {
        loading:{
          submit:false,
        },
        password:'',
      }
    },
    created() {
      
    },
    mounted() {
      
    },
    computed:{
      
    },
    methods:{
      sm(){
        this.$router.push('pwdScanCodeConfirm')
        console.log(this.$router);
      },
      submit(){
        if(!this.password){
          this.$message.error(this.$t("请输入现在的密码"))
          return
        }
        this.loading.submit = true
        let data = {
          password:this.password,
        }
        // this.$router.push(`/page/scanCodeConfirm`)
        // return
        this.$apis.user.validatePassword(data).then(res=>{
          setTimeout(()=>{
            this.loading.submit = false
            
            this.$router.push(`/page/setPassword`)
          },500)
        }).catch(err=>{
          this.loading.submit = false
        })
      }
    }
  }
</script>

<style lang="scss">
  .password{
    padding: 19px 40px;
    display: flex;
    flex-direction: column;
    height: 100%;
    .title{
      .title-text{
        color: #3B3F56;
        font-size: 18px;
        line-height: 25px;
        font-weight: 700;
      }
      .link{
        font-size: 14px;
        color: #1564B9;
        line-height: 19px;
      }
    }
    .Card{
      padding: 0 0 34px 19px;
      .form{
        
        .input{
          width: 251px;
          height: 34px;
          border-radius: 3px;
          border: 1px solid #DBDBE7;
          margin-top: 19px;
          padding-left: 12px;
        }
        
        .tip{
          margin-top: 15px;
          color: #666A82;
        }
      }
      .button-submit{
        width: 125px;
        height: 32px;
        border-radius: 5px;
        margin-top: 44px;
      }
    }
  }
</style>
