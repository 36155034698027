<template>
  <div class="voteData">
    <card class="Card height row">
      <div class="name">{{$t('投票：')}}</div>
      <div class="flex-1 number">{{ count }}</div>
    </card>
    <card class="Card">
      <div class="table-header row">
        <div class="tale-col Regular col-1 flex-1">投票标题</div>
        <div class="tale-col Regular col-2">参与人数</div>
        <div class="tale-col Regular col-3">有效时间</div>
        <div class="tale-col Regular col-4">操作</div>
      </div>
      <div class="table">
        <div class="table-item row" v-for="(item,index) of list" :key="index">
          <div class="tale-col col-1 flex-1">{{ item.title }}</div>
          <div class="tale-col col-2">{{ item.partakeNumber }}</div>
          <div class="tale-col col-3">{{item.startTime | getTime}} - {{item.endTime | getTime}}</div>
          <div class="tale-col col-4 row js-center">
            <img class="icon pointer" @click="voteDelete(item)" src="assets/image/menu/delete@2x.png" >
            <img class="icon pointer" @click="vote(item)" src="assets/image/menu/vote@2x.png" >
          </div>
        </div>
        <loadMore :status="status" @more="scrollBottom"></loadMore>
      </div>
      
    </card>
  </div>
</template>

<script>
  export default {
    name: 'voteData',
    data(){
      return {
        list:[],
        page:1,
        status:"more",
        total:0,
        count:0
      }
    },
    created() {
      
    },
    mounted() {
      this.init()
    },
    computed:{
      
    },
    methods:{
      init(){
        let data = {
          pageNum:this.page,
          pageSize:40
        }
        this.status = 'loading'
        this.$apis.menu.getVotePage(data).then(res=>{
          this.count = res.data.total
          this.total = parseInt(res.data.total / 40) + 1
          if(this.page == 1){
            this.list = res.data.voteItemList
          }else{
            this.list = [...this.list,...res.data.voteItemList]
          }
          if(this.total = this.page){
            this.status = 'no-more'
          }else{
            this.status = 'more'
          }
        })
      },
      voteDelete(item){
        this.$confirm(this.$t('确定删除该投票吗?'), this.$t('提示'), {
          confirmButtonText: this.$t('确定'),
          cancelButtonText: this.$t('取消'),
          type: 'warning'
        }).then(res=>{
          let loading = this.mLoading('删除中……'.$t())
          this.$apis.menu.deleteVoteTopic({id:item.id}).then(res=>{
            loading.close()
            this.$message.success('删除成功'.$t())
            this.page == 1
            this.init()
          }).catch(err=>{
            loading.close()
          })
        })
      },
      vote(item){
        this.$router.push('/voteDetail?id='+item.id)
      },
      scrollBottom(){
        if(this.page < this.total){
          this.page ++ 
          this.init()
        }
      }
    }
  }
</script>

<style lang="scss">
  .voteData{
    .Card{
      padding: 0 26px;
      text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
      color: #696B76;
      line-height: 17px;
      &.height{
        height: 51px;
      }
      
      .number{
        font-size: 26px;
        line-height: 31px;
        font-weight: bold;
      }
      
      .table-header{
        height: 44px;
        padding-left: 51px;
        border-bottom: 1px solid rgba(219, 219, 231, 0.26);
        .tale-col{
          text-align: center;
          font-size: 12px;
          color: #989BB0;
          line-height: 17px;
          text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5), 0px 2px 3px rgba(220, 231, 239, 0.5);
        }
        .col-1{
          text-align: left;
          flex: 3;
        }
        .col-2{
          min-width: 147px;
          flex: 2;
        }
        .col-3{
          min-width: 320px;
          flex: 2;
        }
        .col-4{
          min-width: 115px;
          flex: 1;
        }
      }
      .table{
        padding-left: 51px;
        .table-item{
          border-bottom: 1px solid rgba(219, 219, 231, 0.26);
        }
        .tale-col{
          padding: 22px 0;
          text-align: center;
          color: #696B76;
          text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5), 0px 2px 3px rgba(220, 231, 239, 0.5);
        }
        .col-1{
          text-align: left;
          flex: 3;
        }
        .col-2{
          min-width: 147px;
          flex: 2;
        }
        .col-3{
          min-width: 320px;
          flex: 2;
        }
        .col-4{
          flex: 1;
          min-width: 115px;
          .icon{
            height: 14px;
            width: 13px;
            margin: 0 8px;
          }
        }
      }
      
    }
  }
</style>
