<template>
  <div class="insert">
    <popup ref="popup" zIndex="998">
      <!-- 图片 -->
      <card class="insert-Card" :class="[type]" v-if="type == 'image'">
        <div class="info row  ai-top">
          <div class="types row col">
            <div class="title" v-if="inType == 'coverImg'">{{'封面上传'}}</div>
            <div class="title" v-else>插入图片</div>
            <div class="btn row button" @click="setType('image')">
              <div class="icon">
                <img src="assets/image/release/pic_selected@2x.png">
              </div>
              <div>我的图库</div>
            </div>
            <div class="btn pointer" @click="setType('video')" v-if="inType == 'content'">
              <div class="icon">
                <img src="assets/image/release/video@2x.png">
              </div>
              <div>我的视频</div>
            </div>
            <div class="btn pointer" @click="setType('audio')" v-if="inType == 'content'">
              <div class="icon">
                <img src="assets/image/release/music@2x.png">
              </div>
              <div>我的音乐</div>
            </div>
          </div>
          <div class="lists flex-1">
            <div class="row js-right">
              <!-- 注释代码为:点击按钮直接上传,插入文章 下面视频,音频 同样 -->
              <!-- <el-upload
                ref="uploadImage"
                class="image-uploader"
                name="upload"
                accept="image/*"
                :show-file-list="false"
                :on-change="onImageFileChange"
                :auto-upload="false"
                action=""
              >
                <Button class="button button-ho">上传图片</Button>
              </el-upload> -->
              <!-- 此处为打开上传组见上传 下面视频,音频 同样 -->
              <Button class="button button-ho" @click="openUpload(1)">上传图片</Button>
            </div>
            <div class="list row w-100 ">
              <div class="row w-100 js-center" v-if="image.loading">
                <i class="el-icon-loading"></i>
              </div>
              <div class="item pointer" v-else v-for="(item,index) of image.list" :key="index" :class="{active:image.current == index}" @click="image.current == index?image.current = -1:image.current = index">
                <div class="image-b"><img :src="item.resource" ></div>
                <div class="title Regular line-1">{{item.name}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="btns">
          <div class="pages w-100 row js-right">
            <div class="text-page Regular">共{{image.count}}条记录</div>
            <div class="previous-page row js-center pointer" :class="{disabled:image.page == 1}" @click="previous"></div>
            <div class="page-number Regular"><span>{{image.page}}</span>/{{image.total}}</div>
            <div class="next-page row js-center pointer" :class="{disabled:image.page == image.total}" @click="next"></div>
            <div class="input-page pointer"><input type="number" v-model="image.linkPage" /></div>
            <div class="link-page pointer" @click="toPage">跳转</div>
          </div>
          <div class="btn row js-center">
            <Button class="button button-ho" @click="close">取消</Button>
            <Button class="button" @click="submit" :loading="image.btnLoading">确定</Button>
          </div>
        </div>
      </card>
      <!-- 视频 -->
      <card class="insert-Card" :class="[type]" v-if="type == 'video'">
        <div class="info row  ai-top">
          <div class="types row col">
            <div class="title">插入视频</div>
            <div class="btn pointer" @click="setType('image')">
              <div class="icon">
                <img src="assets/image/release/pic@2x.png">
              </div>
              <div>我的图库</div>
            </div>
            <div class="btn row button" @click="setType('video')">
              <div class="icon">
                <img src="assets/image/release/video_selected@2x.png">
              </div>
              <div>我的视频</div>
            </div>
            <div class="btn pointer" @click="setType('audio')">
              <div class="icon">
                <img src="assets/image/release/music@2x.png">
              </div>
              <div>我的音乐</div>
            </div>
          </div>
          <div class="lists flex-1">
            <div class="row js-right">
              <!-- <el-upload
                ref="upload-video"
                class="audio-uploader"
                name="upload"
                accept="video/*"
                :show-file-list="false"
                :on-change="onVideoFileChange"
                :auto-upload="false"
                action=""
                >
                <Button class="button button-ho">上传视频</Button>
              </el-upload> -->
              <Button class="button button-ho" @click="openUpload(3)">上传视频</Button>
            </div>
            <div class="list row w-100 ">
              <div class="row w-100 js-center" v-if="video.loading">
                <i class="el-icon-loading"></i>
              </div>
              <div class="item video-item pos-rel pointer" v-for="(item,index) of video.list"  :key="item.id" @click="video.current == index?video.current = -1:video.current = index"  :class="{active:video.current == index}">
                <div class="image-video"><img :src="item.coverImg" ></div>
                <div class="title-video">
                  <div class="text line-1">{{item.name}}</div>
                  <div class="time Regular">{{item.createdAt | getDate}}</div>
                </div>
                <div class="length">
                  <div class="text Regular">{{item.duration | getDuration}}</div>
                </div>
                <div class="play-icon"><img src="assets/image/sc/play@2x.png" ></div>
                <img class="more" src="assets/image/more_w@2x.png" >
              </div>
              
            </div>
          </div>
        </div>
        <div class="btns">
          <div class="pages w-100 row js-right">
            <div class="text-page Regular">共{{video.count}}条记录</div>
            <div class="previous-page row js-center pointer" :class="{disabled:video.page == 1}" @click="previous"></div>
            <div class="page-number Regular"><span>{{video.page}}</span>/{{video.total}}</div>
            <div class="next-page row js-center pointer" :class="{disabled:video.page == video.total}" @click="next"></div>
            <div class="input-page pointer"><input type="number" v-model="video.linkPage" /></div>
            <div class="link-page pointer" @click="toPage">跳转</div>
          </div>
          <div class="btn row js-center">
            <Button class="button button-ho" @click="close">取消</Button>
            <Button class="button" @click="submit">确定</Button>
          </div>
        </div>
      </card>
      <!-- 音频 -->
      <card class="insert-Card" :class="[type]" v-if="type == 'audio'">
        <div class="info row  ai-top">
          <div class="types row col">
            <div class="title">插入音频</div>
            <div class="btn pointer" @click="setType('image')">
              <div class="icon">
                <img src="assets/image/release/pic@2x.png">
              </div>
              <div>我的图库</div>
            </div>
            <div class="btn pointer" @click="setType('video')">
              <div class="icon">
                <img src="assets/image/release/video@2x.png">
              </div>
              <div>我的视频</div>
            </div>
            <div class="btn row button" @click="setType('audio')">
              <div class="icon">
                <img src="assets/image/release/music_selected@2x.png">
              </div>
              <div>我的音乐</div>
            </div>
          </div>
          <div class="lists flex-1">
            <div class="row js-right">
              <div class="row js-right">
                <!-- <el-upload
                  ref="uploadAudio"
                  class="audio-uploader"
                  name="upload"
                  :show-file-list="false"
                  accept="audio/*"
                  action=""
                  :on-change="onAudioFileChange"
                  :auto-upload="false"
                  >
                  <Button class="button button-ho">上传音频</Button>
                </el-upload> -->
                <Button class="button button-ho" @click="openUpload(2)">上传音频</Button>
              </div>
            </div>
            <div class="list row w-100 ">
              <div class="row w-100 js-center" v-if="audio.loading">
                <i class="el-icon-loading"></i>
              </div>
              <div class="item audio-item pointer" v-else v-for="(item,index) of audio.list" :key="index" :class="{active:audio.current == index}" @click="audio.current == index?audio.current = -1:audio.current = index">
                <div class="title-audio line-1">{{item.name}}</div>
                <div class="length Regular">{{item.duration | getDuration}}</div>
                <div class="time Regular">{{item.createdAt | getDate}}</div>
                <div class="play-icon" v-if="item.paused"><img src="assets/image/sc/play_an@2x.png" ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="btns">
          <div class="pages w-100 row js-right">
            <div class="text-page Regular">共{{audio.count}}条记录</div>
            <div class="previous-page row js-center pointer" :class="{disabled:audio.page == 1}" @click="previous"></div>
            <div class="page-number Regular"><span>{{audio.page}}</span>/{{audio.total}}</div>
            <div class="next-page row js-center pointer" :class="{disabled:audio.page == audio.total}" @click="next"></div>
            <div class="input-page pointer"><input type="number" v-model="audio.linkPage" /></div>
            <div class="link-page pointer" @click="toPage">跳转</div>
          </div>
          <div class="btn row js-center">
            <Button class="button button-ho" @click="close">取消</Button>
            <Button class="button" @click="submit">确定</Button>
          </div>
        </div>
      </card>
      <!-- 链接 -->
      <card class="insert-Card" :class="[type]" v-if="type == 'link'">
        <div class="info">
          <div class="insert-Card-title row col">
            插入链接
          </div>
          <div class="lists">
            <div class="item row">
              <div class="item-name row">链接标题</div>
              <div class="item-input"><input type="text" v-model="link.name"/></div>
            </div>
            <div class="item row">
              <div class="item-name row">链接类型</div>
              <div class="item-input row" style="border: none;">
                <check :label="'输入链接'.$t()" :checked="link.type == 1" @change="link.type = 1" style="margin-right: 20px;"></check>
                <check :label="'我的文章'.$t()" :checked="link.type == 2" @change="link.type = 2"></check>
              </div>
            </div>
            <div class="item row" v-if="link.type == 1">
              <div class="item-name row">{{'链接'.$t()}}</div>
              <div class="item-input"><input type="text" v-model="link.url"/></div>
            </div>
            <div class="item row" v-if="link.type == 2" style="margin-bottom: 0;">
              <div class="item-name row">选择文章</div>
              <div class="item-input row" style="padding-right: 0;"><input placeholder="搜索已发布的文章" type="text" v-model="link.wzKeyword"/><Button class="search-button" @click="searchWz">搜索</Button></div>
            </div>
            <div class="item row" v-if="link.type == 2">
              <div class="item-name row"></div>
              <div class="creation-list scroll-view">
                <div class="creation row ai-top pointer" v-for="(item,index) of link.wzList" :key="index" @click="link.wzIndex = index">
                  <check :size="10" :checked="index == link.wzIndex" style="margin-right: 2px;margin-top: 3px;"></check>
                  <div class="creation-info">
                    <div class="creation-title Regular">{{item.title}}</div>
                    <div class="creation-time Regular">{{item.publishTime | getDate}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btns">
            <div class="btn row js-center">
              <Button class="button button-ho" @click="close">取消</Button>
              <Button class="button" @click="submit">确定</Button>
            </div>
          </div>
        </div>
      </card>
      <!-- 文章 -->
      <card class="insert-Card link" :class="[type]" v-if="type == 'article'">
        <div class="info">
          <div class="lists">
            <div class="item row"  style="margin-bottom: 0;">
              <div class="item-name row">选择文章</div>
              <div class="item-input row" style="padding-right: 0;"><input placeholder="搜索已发布的文章" type="text" v-model="link.wzKeyword"/><Button class="search-button" @click="searchWz">搜索</Button></div>
            </div>
            <div class="item row">
              <div class="item-name row"></div>
              <div class="creation-list scroll-view">
                <div class="creation row ai-top pointer" v-for="(item,index) of link.wzList" :key="index" @click="article.wzIndex = index">
                  <check :size="10" :checked="index == article.wzIndex" style="margin-right: 2px;margin-top: 3px;"></check>
                  <div class="creation-info">
                    <div class="creation-title Regular">{{item.title}}</div>
                    <div class="creation-time Regular">{{item.publishTime | getDate}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btns">
            <!-- <div class="pages w-100 row js-right">
              <div class="text-page Regular">共{{article.count}}条记录</div>
              <div class="previous-page row js-center pointer" :class="{disabled:article.page == 1}" @click="previous"></div>
              <div class="page-number Regular"><span>{{article.page}}</span>/{{article.total}}</div>
              <div class="next-page row js-center pointer" :class="{disabled:article.page == article.total}" @click="next"></div>
              <div class="input-page pointer"><input type="number" v-model="article.linkPage" /></div>
              <div class="link-page pointer" @click="toPage">跳转</div>
            </div> -->
            <div class="btn row js-center">
              <Button class="button button-ho" @click="close">取消</Button>
              <Button class="button" @click="submit">确定</Button>
            </div>
          </div>
        </div>
      </card>
      <!-- 表情 -->
      <card class="insert-Card" :class="[type]" v-if="type == 'emoji'">
        <div class="info">
          <div class="insert-Card-title row col">
            插入表情
          </div>
          <div class="emoji-list scroll-view row">
            <div class="row js-center pointer" v-for="(item,index) of $emoji" :key="index" :class="{active:emoji.info == item}" @click="emoji.info = item">{{item}}</div>
          </div>
        </div>
        <div class="btns">
          <div class="btn row js-center">
            <Button class="button button-ho" @click="close">取消</Button>
            <Button class="button" @click="submit">确定</Button>
          </div>
        </div>
      </card>
      <!-- 投票 -->
      <card class="insert-Card" :class="[type]" v-if="type == 'vote'">
        <div class="info">
          <div class="insert-Card-title row col">
            插入投票
          </div>
          <div class="vote-info">
            <div class="vote-row row ai-top">
              <div class="vote-row-name row">投票标题</div>
              <div class="vote-row-info pos-rel">
                <input class="input" type="text" maxlength="32" v-model="vote.title" />
                <div class="text-length Regular">{{vote.title.length}}/32</div>
              </div>
            </div>
            <div class="vote-row row ai-top">
              <div class="vote-row-name row" style="height: 19px;"></div>
              <div class="vote-row-info pos-rel">
                <div class="link pointer" style="color: #2C7BE5;" @click="addOptions">添加选项</div>
              </div>
            </div>
            <div class="optionss scroll-view">
              <div class="vote-row row ai-top" v-for="(item,index) in vote.options" :key="index">
                <div class="vote-row-name row "></div>
                <div>
                  <div class="vote-row-info pos-rel row">
                    <div class="check_"><check ></check></div>
                    <input class="input sx-input" type="text" maxlength="32" v-model="item.name" />
                    <div class="text-length Regular">{{item.name.length}}/32</div>
                    <div class="delete-options pointer" @click="deleteOptions(index)"><img src="assets/image/release/reduce@2x.png" ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="vote-row row ai-top">
              <div class="vote-row-name row">投票设置</div>
              <div class="vote-row-info height pos-rel row">
                <check :label="$t('一人一票')" class="check_" :checked="vote.voteSet == 1" @change="vote.voteSet = 1"></check>
                <check :label="$t('每天一票')" class="check_" :checked="vote.voteSet == 2" @change="vote.voteSet = 2"></check>
              </div>
            </div>
            <div class="vote-row row ai-top">
              <div class="vote-row-name height row">参与方式</div>
              <div class="vote-row-info pos-rel row height">
                <check :label="$t('单选')" class="check_" :checked="vote.partakeMode == 1" @change="vote.partakeMode = 1"></check>
                <check :label="$t('多选')" class="check_" :checked="vote.partakeMode == 2" @change="vote.partakeMode = 2"></check>
              </div>
            </div>
            <div class="vote-row row ai-top">
              <div class="vote-row-name height row">截止时间</div>
              <div class="vote-row-info pos-rel row height">
                <!-- <el-date-picker
                  v-model="vote.stareDate"
                  type="date"
                  size="mini"
                  placeholder="选择日期">
                </el-date-picker> -->
                <el-date-picker
                  v-model="vote.stareDate"
                  type="datetime"
                  placeholder="选择日期时间">
                </el-date-picker>
                <div class="dete-on">至</div>
                <!-- <el-date-picker
                  v-model="vote.endDate"
                  type="date"
                  size="mini"
                  placeholder="选择日期">
                </el-date-picker> -->
               <el-date-picker
                  v-model="vote.endDate"
                  type="datetime"
                  placeholder="选择日期时间">
                </el-date-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="btns">
          <div class="btn row js-center">
            <Button class="button button-ho" @click="close">取消</Button>
            <Button class="button" @click="submit">确定</Button>
          </div>
        </div>
      </card>
      
      
      <video ref="Video" width="270" height="135" v-if="videoUrl" @canplay="VideoCanplay"  style="position: fixed; opacity: 0;">
        <source :src="videoUrl" type="video/mp4"></source>
        <source :src="videoUrl" type="video/ogg"></source>
        <source :src="videoUrl" type="video/webm"></source>
      </video>
      <canvas  v-if="videoUrl" ref="Canvas" width="270" height="135" style="border:1px solid #d3d3d3;position: fixed;opacity: 0;"></canvas>
    </popup>
    
    <!-- 上传组件 -->
    <updataFile :type="uploadType" ref="updataFile" @success="upLoadSuccess"></updataFile>
  </div>
</template>

<script>
  export default {
    name: 'insert',
    props:{
      inType:{
        type:String,
        default:'content'
      }
    },
    data(){
      return {
        type:"image",
        typeKey:1,
        image:{
          list:[],
          current:-1,
          loading:true,
          page:1,
          count:1,
          total:1,
          linkPage:'',
          btnLoading:false
        },
        audio:{
          list:[],
          current:-1,
          loading:true,
          page:1,
          count:1,
          total:1,
          linkPage:''
        },
        video:{
          list:[],
          current:-1,
          loading:true,
          page:1,
          count:1,
          total:1,
          linkPage:''
        },
        link:{
          url:"",
          name:"",
          type:1,// 1 链接 2 文章
          wzKeyword:'',
          wzList:[],
          wzIndex:-1
        },
        article:{
          page:1,
          count:1,
          total:1,
          loading:true,
          wzIndex:-1,
          url:"",
          name:"",
          linkPage:'',
        },
        emoji:{
          info:""
        },
        vote:{
          stareDate:'',
          endDate:'',
          partakeMode:1,
          title:'',
          voteSet:1,
          options:[
            {
              name:""
            }
          ]
        },
        voteType:'add',
        voteIndex:0,
        types:{
          image:1,
          audio:2,
          video:3
        },
        videoUrl:'',
        videoFun:null,
        uploadType:1,
      }
    },
    created() {
      
    },
    mounted() {
      
    },
    computed:{
      
    },
    methods:{
      // 打开插入弹窗
      open(type,vote,index,isShui = false){
        this.type = type
        this.$refs.popup.open()
        this.link = {
          url:"",
          name:"",
          type:1,// 1 链接 2 文章
          wzKeyword:'',
          wzList:[],
          wzIndex:-1
        }
        this.image.current = -1
        this.video.current = -1
        this.audio.current = -1
        this.emoji.info = ''
        
        if(vote){
          vote.stareDate = new Date(vote.stareDate)
          vote.endDate = new Date(vote.endDate)
          this.vote = vote
          this.voteType = 'edit'
          this.voteIndex = index
        }else{
          this.voteType = 'add'
          this.vote = {
            stareDate:'',
            endDate:'',
            partakeMode:1,
            title:'',
            voteSet:1,
            options:[
              {
                name:""
              }
            ]
          }
        }
        
        if(type == 'link'){
          this.link.wzIndex = -1
          this.link.wzKeyword = ''
          this.searchWz()
        }
        if(type == 'article'){
          this.article.wzIndex = -1
          this.link.wzKeyword = ''
          this.searchWz()
        }
        // 类型为以下三种获取资源列表
        if(['image','audio','video'].indexOf(type) != -1){
          this.image.isShui = isShui
          this.getGroupingMaterial()
        }
      },
      // 搜索文章
      searchWz(){
        let data = {
          title:this.link.wzKeyword
        }
        this.link.wzList = []
        this.$apis.wz.getArticlePageList(data).then(res=>{
          this.link.wzList = res.data.listModelItemList
        })
      },
      // 投票添加选项
      addOptions(){
        this.vote.options.push({name:''})
      },
      deleteOptions(index){
        this.vote.options.splice(index,1)
      },
      setType(type){
        this.type = type
        this[this.type].page = 1
        this[this.type].list = []
        this.getGroupingMaterial()
      },
      // 获取资源列表
      getGroupingMaterial(){
        let data = {
          pageNum:this[this.type].page,
          pageSize:9,
          // groupingId:0,
          type:this.types[this.type]
        }
        this[this.type].loading = true
        this.$apis.sc.getGroupingMaterial(data).then(res=>{
          setTimeout(()=>{
            this[this.type].count = res.data.total
            this[this.type].total = Math.ceil(res.data.total / 9)
            this[this.type].list = res.data.materials
            this[this.type].loading = false
          },300)
        })
      },
      // 上一夜
      previous(){
        if(this[this.type].page <= 0){
          return
        }
        this[this.type].page --
        this[this.type].loading = true
        this[this.type].list = []
        this.getGroupingMaterial()
      },
      // 下一页
      next(){
        if(this[this.type].page >= this[this.type].total){
          return
        }
        this[this.type].page ++
        this[this.type].loading = true
        this[this.type].list = []
        this.getGroupingMaterial()
      },
      // 页面跳转
      toPage(){
        if(this[this.type].linkPage < 1 || this[this.type].linkPage > this[this.type].total){
          this.$message.error(this.$t('跳转页面不存在'))
          return
        }
        this[this.type].page = this[this.type].linkPage
        this[this.type].loading = true
        this[this.type].list = []
        this.getGroupingMaterial()
      },
      // 打开上传组件
      openUpload(ev){
        this.uploadType = ev
        this.$refs.updataFile.open()
      },
      // 上传成功
      upLoadSuccess(ev){
        if(ev.type == 1){// 图片
          this.$emit('change',{
            url:ev.resource,
            type:this.type,
            inType:this.inType,
            name:ev.name
          })
        }
        if(ev.type == 2){// 音频
          this.$emit('change',{
            url:ev.resource,
            item:ev,
            type:this.type
          })
        }
        if(ev.type == 3){// 视频
          this.$emit('change',{
            url:ev.resource,
            type:this.type,
            inType:this.inType,
            name:ev.name
          })
        }
        this.$refs.popup.close()
      },
      // 上传图片
      onImageFileChange(ev){
        if(!this.beforeUpload(ev.raw,1)){
          return
        }
        let loading = this.mLoading('上传中……'.$t())
        this.$apis.sc.upload(ev.raw,(progress,p)=>{
        }).then(res=>{
          let data = {
            coverImg:'',
            duration:'',
            groupingId:0,
            name:ev.raw.name,
            resource:res,
            type:1
          }
          // 上传图片并插入
          this.$apis.sc.addMaterial(data).then(ress=>{
            loading.close()
            this.$message.success(this.$t("上传成功"))
            this.$emit('change',{
              url:res,
              type:this.type,
              inType:this.inType,
              name:ev.raw.name,
            })
            this.$refs.popup.close()
          })
          
        })
      },
      // 上传音频
      onAudioFileChange(ev){
        let audio = {}
        if(this.beforeUpload(ev.raw)){
          audio.unAudioUrl = window.URL.createObjectURL(new Blob([ev.raw]))
          // 获取时长
          let audioElement = new Audio(audio.unAudioUrl);
          audioElement.addEventListener("loadedmetadata", (_event)=>{
              let duration = audioElement.duration;
              audio.name = ev.name
              audio.duration = duration
              let loading = this.mLoading('上传中……'.$t())
              this.$apis.sc.upload(ev.raw,(progress,p)=>{
              }).then(res=>{
                
                let data = {
                  coverImg:'',
                  duration:audio.duration,
                  groupingId:0,
                  name:audio.name,
                  resource:res,
                  type:2
                }
                this.$apis.sc.addMaterial(data).then(ress=>{
                  loading.close()
                  this.$message.success(this.$t("上传成功"))
                  this.$emit('change',{
                    url:res,
                    item:ress.data.material,
                    type:this.type
                  })
                  this.$refs.popup.close()
                })
              })
          });
        }
      },
      // 上传视频
      onVideoFileChange(ev){
        let video = {}
        if(this.beforeUpload(ev.raw,3)){
          video.unVideoUrl = window.URL.createObjectURL(new Blob([ev.raw]))
          video.name = ev.name
          // 获取时长
          let audioElement = new Audio(video.unVideoUrl);
          audioElement.addEventListener("loadedmetadata", (_event)=>{
            let duration = audioElement.duration;
            video.duration = duration
            let loading = this.mLoading('上传中……'.$t())
            // 视频地址
            this.videoUrl = video.unVideoUrl
            // 赋值上传函数，等待视频后获得封面后上传
            this.videoFun = (img)=>{
              this.videoUrl = ''
              // 获得截图 得到封面 base64 图片文件
              this.$apis.sc.upload(ev.raw,(progress,p)=>{
              }).then(res=>{
                video.unVideoUrl = res
                let data = {
                  coverImg:img,
                  duration:video.duration,
                  groupingId:0,
                  name:video.name,
                  resource:video.unVideoUrl,
                  type:3
                }
                this.$apis.sc.addMaterial(data).then(ress=>{
                  loading.close()
                  this.$message.success(this.$t("上传成功"))
                  this.$emit('change',{
                    url:video.unVideoUrl,
                    type:this.type,
                    inType:this.inType,
                    name:video.name,
                  })
                  this.$refs.popup.close()
                })
                
              })
            }
          });
        }
      },
      // 视频可播放，截取视频图片
      VideoCanplay(){
        setTimeout(()=>{
          let v = this.$refs.Video
          let c = this.$refs.Canvas
          let ctx=c.getContext('2d');
          var w = 270
          var h = 135
          var space = 0 // canvas的间距，可取去掉
          
          // 绘制视频到canvas上
          ctx.drawImage(v, space, space, w+space, h+space)
          
          // 生成图片
          let img = c.toDataURL('image/png')
          //将base64转换为文件
          function dataURLtoFile(dataurl, filename) { 
            var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
          }
          //调用
          var file = dataURLtoFile(img, 'coverImg_'+new Date().getTime() + '.png');
          // 上传封面图
          this.$apis.sc.upload(file,(progress,p)=>{
          }).then(res=>{
            // 继续调用视频上传方法
            this.videoFun && this.videoFun(res)
          })
        },250)
      },
      close(){
        this.$refs.popup.close()
      },
      // 确定按钮
      
      submit(){
        if(this[this.type].current == -1) {this.$refs.popup.close();return}
        if(['image','video'].indexOf(this.type) != -1){
          if(this[this.type].current == -1){
            return
          }
          
          if(this.type == 'image'){
            if(this.image.isShui){
              let data = {
                url:this[this.type].list[this[this.type].current].resource
              }
              this.image.btnLoading = true
              this.$apis.sc.uploadArticlePhoto(data).then(res=>{
                this.image.btnLoading = false
                this.$emit('change',{
                  url:res.data.url,
                  type:this.type,
                  inType:this.inType,
                  name:this[this.type].list[this[this.type].current].name,
                  info:this[this.type].list[this[this.type].current]
                })
                this.$refs.popup.close();
              })
            }else{
              this.$emit('change',{
                url:this[this.type].list[this[this.type].current].resource,
                type:this.type,
                inType:this.inType,
                name:this[this.type].list[this[this.type].current].name,
                info:this[this.type].list[this[this.type].current]
              })
              this.$refs.popup.close();
            }
            
            return
          }
          
          this.$emit('change',{
            url:this[this.type].list[this[this.type].current].resource,
            type:this.type,
            inType:this.inType,
            name:this[this.type].list[this[this.type].current].name,
            info:this[this.type].list[this[this.type].current]
          })
        }else if(this.type == 'link'){
          if(!this.link.name){
            this.$message.error('请输入标题'.$t())
            return
          }
          if(this.link.type == 1){
            if(!this.link.url){
              this.$message.error('请输入地址'.$t())
              return
            }
            this.$emit('change',{
              url:this.link.url,
              name:this.link.name,
              type:this.type
            })
          }else{
            if(this.link.wzIndex == -1){
              this.$message.error('请选择文章'.$t())
              return
            }
            this.$emit('change',{
              url:this.link.wzList[this.link.wzIndex].link || '',
              name:this.link.name,
              type:this.type,
              info:this.link.wzList[this.link.wzIndex]
            })
          }
          
        }else if(this.type == 'article'){
          if(this.article.wzIndex == -1){
            this.$message.error('请选择文章'.$t())
            return
          }
          this.$emit('change',{
            url:this.link.wzList[this.article.wzIndex].url || '',
            name:this.link.wzList[this.article.wzIndex].title,
            type:this.type,
            info:this.link.wzList[this.article.wzIndex]
          })
        }else if(this.type == 'audio'){
          this.$emit('change',{
            url:this[this.type].list[this[this.type].current].resource,
            item:this[this.type].list[this[this.type].current],
            type:this.type,
            info:this[this.type].list[this[this.type].current]
          })
        }else if(this.type == 'emoji'){
          this.$emit('change',{
            info:this.emoji.info,
            type:this.type
          })
        }else if(this.type == 'vote'){
          if(!this.vote.title){
            this.$message.error('请输入标题')
            return
          }
          for (let item of this.vote.options) {
            if(!item.name){
              this.$message.error('请输入选项内容')
              return
            }
          }
          if(!this.vote.stareDate){
            this.$message.error('请选择开始时间')
            return
          }
          if(!this.vote.endDate){
            this.$message.error('请选择结束时间')
            return
          }
          if(this.vote.stareDate.getTime() <= new Date().getTime()){
            this.$message.error('开始时间必须大于当前时间')
            return
          }
          if(this.vote.stareDate.getTime() >= this.vote.endDate.getTime()){
            this.$message.error('开始时间必须小于结束时间')
            return
          }
          this.$emit('change',{
            i:this.voteIndex,
            voteType:this.voteType,
            vote:this.vote,
            type:this.type
          })
        }
        
        this.$refs.popup.close()
      },
      // 上传前钩子
      beforeUpload(file,type) {
        let isLt2M = ''
        if(type == 1){
          isLt2M = file.size / 1024 / 1024 < 5;
          if (!isLt2M) {
            this.$message.error('上传图片大小不能超过 5MB!'.$t());
          }
        }else{
          isLt2M = file.size / 1024 / 1024 < 50;
          if (!isLt2M) {
            this.$message.error('上传文件大小不能超过 50MB!'.$t());
          }
        }
        return isLt2M;
      },
    }
  }
</script>

<style lang="scss" scoped>
  .insert{
    .insert-Card{
      width: 589px;
      &.image,&.video,&.audio{
        height: 597px;
        .info{
          .types{
            height: 513px;
            .title{
              margin: 28px 0;
            }
          }
          .lists{
            padding-left: 16px !important;
            .item{
              margin-bottom: 15px;
              .image-b{
                width: 137px;
                height: 102px;
                border: 1px solid #EBEBEB;
                margin-right: 11px;
              }
              .title{
                width: 137px;
                color: #3B3F56;
                margin-top: 9px;
                text-align: center;
              }
            }
          }
        }
      }
      &.video{
        height: 513px;
        .info{
          .types{
            height: calc(513px - 112px);
          }
        }
      }
      
      .video-item{
        width: 137px;
        height: 102px;
        border: 1px solid #EBEBEB;
        margin-right: 10px;
        .image-video{
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
        .title-video{
          width: 137px;
          height: 62px;
          padding: 6px 9px 0;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.55) 0%, rgba(255, 255, 255, 0) 100%);
          // box-shadow: 0px 2px 3px 0px rgba(220,231,239,0.5);
          position: absolute;
          left: 0;
          top: 0;
          .text{
            text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
            color: #FFFFFF;
          }
          .time{
            font-size: 9px;
            color: #FFFFFF;
            line-height: 14px;
            text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
          }
        }
        .length{
          background: linear-gradient(0, rgba(0, 0, 0, 0.55) 0%, rgba(255, 255, 255, 0) 100%);
          box-shadow: 0px 2px 3px 0px rgba(220,231,239,0.5);
          position: absolute;
          bottom: 0;
          left: 0;
          width: 137px;
          height: 40px;
          padding: 15px 9px 0;
          .text{
            font-size: 12px;
            color: #FFFFFF;
            line-height: 17px;
            text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
          }
        }
        .play-icon{
          width: 37px;
          height: 37px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          img{
            border: none !important;
          }
        }
      }
      
      &.audio{
        height: 468px;
        .info{
          .types{
            height: calc(468px - 112px);
          }
        }
      }
      
      .audio-item{
        width: 137px;
        height: 79px;
        box-shadow: 0px 2px 3px 0px rgba(220,231,239,0.5);
        border-radius: 5px;
        border: 1px solid #E8E3E3;
        margin-right: 13px;
        padding: 10px 0 0 7px;
        &.active{
          border: 1px solid #FFAE2E;
        }
        &:hover{
          border: 1px solid #FFAE2E;
          .more{
            display: inline-block;
          }
        }
        .title-audio{
          font-size: 12px;
          color: #696B76;
          line-height: 17px;
          text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5), 0px 2px 12px rgba(248, 221, 177, 0.61);
        }
        .length{
          font-size: 12px;
          color: #696B76;
          line-height: 17px;
          text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5), 0px 2px 12px rgba(248, 221, 177, 0.61);
          margin-top: 3px;
        }
        .time{
          margin-top: 11px;
          font-size: 9px;
          color: #696B76;
          line-height: 14px;
          text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5), 0px 2px 12px rgba(248, 221, 177, 0.61);
        }
        .play-icon{
          width: 15px;
          height: 15px;
          position: absolute;
          bottom: 9px;
          right: 9px;
          &.playing_{
            padding: 2px;
            border-radius: 100%;
            background-color: rgba(0,0,0,0.5);
            display: flex;
            align-items: center;
            justify-content: space-around;
          }
          .playing{
            animation: playing 1.3s ease infinite alternate;
            width: 2px;
            background-color: #FFFFFF;
            &.bar2{
              animation-delay: -2.4s;
            }
            &.bar3{
              animation-delay: -3.7s;
            }
          }
        }
      }
      
      &.link{
        // height: 324px;
        padding-bottom: 15px;
        .info{
          .lists{
            .item{
              margin-bottom: 28px;
            }
          }
        }
        
        .creation-list{
          max-height: 180px;
          width: 433px;
          overflow-y: auto;
          .creation{
            padding: 15px 0 12px;
          }
          .creation-info{
            .creation-title{
              font-size: 12px;
              color: #696B76;
              line-height: 17px;
            }
            .creation-time{
              font-size: 10px;
              color: #696B76;
              line-height: 15px;
            }
          }
        }
      }
      
      &.emoji{
        height: 460px;
        .emoji-list{
          width: 533px;
          height: 324px;
          margin: 0 auto;
          overflow-y: auto;
          flex-wrap: wrap;
          
          
          div{
            border-radius: 50%;
            font-size: 24px;
            width: 66px;
            height: 66px;
            &.active{
              border: 1px solid #FFAE2E;
            }
            &:hover{
              background-color: rgba(0,0,0,0.04);
              border-radius: 50%;
            }
          }
        }
      }
      
      &.vote{
        padding-bottom: 20px;
        .vote-info{
          padding: 0 17px 0 28px;
          .optionss{
            max-height: 200px;
            overflow-y: auto;
          }
          .vote-row{
            margin-top: 14px;
            .vote-row-name{
              width: 5.5em;
              height: 34px;
            }
            .vote-row-info{
              &.height{
                height: 34px;
              }
              &.m-t-12{
                margin-top: 12px;
              }
              /deep/.el-date-editor.el-input, .el-date-editor.el-input__inner{
                .el-input__inner,.el-input__icon{
                  height: 34px;
                  line-height: 34px;
                }
                width: 194px;
                height: 34px;
              }
              .dete-on{
                padding: 0 11px;
              }
              .check_{
                margin-right: 16px;
              }
              .input{
                width: 433px;
                height: 34px;
                border-radius: 3px;
                border: 1px solid #E3E3E3;
                padding-left: 12px;
                box-sizing: border-box;
                padding-right: 58px;
              }
              .sx-input{
                width: 399px;
              }
              .text-length{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 12px;
                font-size: 14px;
                color: #696B76;
                line-height: 19px;
              }
              .delete-options{
                width: 20px;
                height: 20px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: -30px;
              }
            }
          }
        }
      }
      
      &.keyword{
        .keyword-content{
          width: 433px;
        }
      }
      
      &.article{
        padding: 26px 0 15px;
        .creation-list{
          max-height: 319px;
          height: 319px;
        }
      }
      
      .info{
        .insert-Card-title{
          padding: 28px ;
          display: inline-block;
          font-size: 15px;
          color: #3B3F56;
          line-height: 21px;
        }
        .types{
          width: 122px;
          border-right: 1px solid rgba(219, 219, 231, 0.26);
          .title{
            font-size: 15px;
            color: #3B3F56;
            line-height: 21px;
          }
          .btn{
            width: 102px;
            height: 38px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            .icon{
              width: 12px;
              margin-right: 9px;
            }
            div{
              font-size: 12px !important;
              line-height: 17px !important;
            }
            &:hover{
              opacity: 0.8;
            }
          }
        }
        .lists{
          padding-left: 16px;
          .item{
            margin-bottom: 15px;
            .item-name{
              width: 5.5em;
              height: 34px;
            }
            .item-input{
              width: 433px;
              height: 34px;
              border-radius: 3px;
              border: 1px solid #E3E3E3;
              padding: 0 11px;
              input{
                width: 100%;
              }
            }
            .search-button{
              margin: 0;
              color: #FFFFFF;
            }
          }
          .button{
            margin: 23px 0 20px;
            width: 77px;
            height: 32px;
            margin-right: 15px;
            color: #FFAE2E;
            border-color: #FFAE2E;
          }
          .list{
            flex-wrap: wrap;
            .item{
              img{
                border: 2px solid rgba(0,0,0,0);
              }
            }
            .active{
              img{
                border: 2px solid #FFAE2E;
              }
            }
          }
        }
      }
      
      .btns{
        .pages{
          .text-page{
            font-size: 10px;
            color: #989BB0;
            line-height: 15px;
          }
          .previous-page,.next-page{
            width: 26px;
            height: 26px;
            margin: 0 9px;
            box-shadow: 0 2px 3px 0 #DCE7EF;
            border-radius: 5px;
          }
          .previous-page{
            &::after{
              content: '';
              display: inline-block;
              border: 5px solid rgba(0,0,0,0);
              border-right-color: #989BB0;
              transform: translateX(-2.5px);
            }
            &.disabled{
              background-color: #F6F6F7;
              cursor: not-allowed;
              &::after{
                border-right-color: #DBDBE7;
              }
            }
          }
          .next-page{
            &::after{
              content: '';
              display: inline-block;
              border: 5px solid rgba(0,0,0,0);
              border-left-color: #989BB0;
              transform: translateX(2.5px);
            }
            &.disabled{
              background-color: #F6F6F7;
              cursor: not-allowed;
              &::after{
                border-left-color: #DBDBE7;
              }
            }
          }
          .page-number{
            font-size: 14px;
            line-height: 19px;
            span{
              color: #FFAE2E;
            }
          }
          .input-page{
            width: 40px;
            height: 26px;
            border-radius: 3px;
            border: 1px solid #F6F6F6;
            input{
              width: 100%;
              height: 100%;
              text-align: center;
            }
          }
          .link-page{
            font-size: 10px;
            color: #FFAE2E;
            line-height: 15px;
            margin: 0 15px 0 10px;
          }
        }
        .btn{
          margin-top: 8px;
          .button{
            width: 125px;
            height: 32px;
            border-radius: 5px;
            margin: 0 14px;
          }
        }
      }
      
    }
  }
</style>
