<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {
    
  },
  created() {
    this.init()
    
    
    
  },
  methods:{
    init(){
      let thit = this
      String.prototype.$t = function(){
        return thit.$t(this)
      }
    }
  }
}
</script>

<style lang="scss">
@import "assets/css/index.scss";
</style>
