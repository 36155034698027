<template>
  <div class="myMaterialLibrary">
    <navBar :list="navBar.list" :current.sync="navBar.current" @change="navChange">
      <div class="btns row flex-1 js-right">
        <div class="image-tip Regular" v-if="navBar.current == 0">{{$t('图片上传')}}<5M</div>
        <Button class="button button-ho" v-if="!deleteStatus" @click="deleteStatus = true">{{$t('批量删除')}}</Button>
        <Button class="button button-ho" :loading="deleteLoading" v-if="deleteStatus" @click="deleteAll">{{$t('删除')}}{{selectAll}}</Button>
        <Button class="button button-ho" v-if="deleteStatus" @click="deleteStatus = false">{{$t('取消')}}</Button>
        <Button class="button" @click="updata">{{$t('上传')}}</Button>
      </div>
    </navBar>
    <card class="Card">
      <div class="times row">
        <div class="time-name Regular"></div>
        <div class="time-item Regular row" v-for="(item,index) of fz.list" :key="index" :class="{'button-ho':index == fz.current}" @click="timeChange(item,index)">{{item.name}}({{item.total}})</div>
        <div class="btns row flex-1 js-right">
          <div class="row btn-item Regular pointer" @click="addGrouping"><img src="assets/image/sc/add@2x.png" >{{$t('新建分组')}}</div>
          <div class="row btn-item Regular pointer" @click="editGrouping"><img src="assets/image/sc/manage@2x.png" >{{$t('管理分组')}}</div>
        </div>
      </div>
      <div class="list row">
        <!-- 图片 -->
        <div class="item image pointer" v-for="(item,index) of sc.list" :key="index" v-if="navBar.current == 0">
          <div class="image-b pos-rel">
            <img :src="item.resource" @click="openPic(item.resource,'image')">
            <img class="more" src="assets/image/more_w@2x.png" id='menu' @click="item.menu = !item.menu">
            <div class="radio" :class="{active:item.radioStatus}"></div>
            <div class="select row js-center" v-if="deleteStatus" @click="item.check = true" :class="{active:item.check}"></div>
            <div class="menu" v-if="item.menu">
              <div class="menu-item Regular"  @click="menuHandle('setGroup',item)">移动分组</div>
              <div class="menu-item Regular" @click="menuHandle('editname',item)">重命名</div>
              <div class="menu-item Regular" @click="menuHandle('delete',item)">删除</div>
            </div>
          </div>
          <div class="title Regular line-2">{{item.name}}</div>
        </div>
        <!-- 音频 -->
        <div class="item audio pos-rel pointer" v-for="(item,index) of sc.list" :key="item.id" v-if="navBar.current == 1" @click="openPic(item.resource,'audio',index)">
          <div class="title line-2">{{item.name}}</div>
          <div class="length Regular">{{item.duration | getDuration}}</div>
          <div class="time Regular">{{item.createdAt | getDate}}</div>
          <div class="play-icon" v-if="item.paused"><img src="assets/image/sc/play_an@2x.png" ></div>
          <div class="play-icon playing_" v-if="!item.paused" @click.stop="stopPlay(item)">
            <div class="playing bar1"></div>
            <div class="playing bar2"></div>
            <div class="playing bar3"></div>
          </div>
          <img class="more" src="assets/image/more_w@2x.png" id='menu' @click.stop="item.menu = !item.menu">
          <div class="select row js-center" v-if="deleteStatus" @click.stop="item.check = true" :class="{active:item.check}"></div>
          <div class="menu" v-if="item.menu">
            <div class="menu-item Regular"  @click.stop="menuHandle('setGroup',item)">移动分组</div>
            <div class="menu-item Regular" @click.stop="menuHandle('editname',item)">重命名</div>
            <div class="menu-item Regular" @click.stop="menuHandle('delete',item)">删除</div>
          </div>
        </div>
        <!-- 视频 -->
        <div class="item video pos-rel pointer" v-for="(item,index) of sc.list"  :key="item.id" v-if="navBar.current == 2" @click="openPic(item.resource,'video')">
          <div class="image"><img :src="item.coverImg" ></div>
          <div class="title">
            <div class="text line-2">{{item.name}}</div>
            <div class="time Regular">{{item.createdAt | getDate}}</div>
          </div>
          <div class="length">
            <div class="text Regular">{{item.duration | getDuration}}</div>
          </div>
          <div class="play-icon"><img src="assets/image/sc/play@2x.png" ></div>
          <img class="more" src="assets/image/more_w@2x.png" id='menu' @click.stop="item.menu = !item.menu">
          <div class="select row js-center" v-if="deleteStatus" @click.stop="item.check = true" :class="{active:item.check}"></div>
          <div class="menu" v-if="item.menu">
            <div class="menu-item Regular"  @click.stop="menuHandle('setGroup',item)">移动分组</div>
            <div class="menu-item Regular" @click.stop="menuHandle('editname',item)">重命名</div>
            <div class="menu-item Regular" @click.stop="menuHandle('delete',item)">删除</div>
          </div>
        </div>
      </div>
      <loadMore :status="status" @more="scrollBottom"></loadMore>
    </card>
    <!-- 添加分组 -->
    <Popup ref="PopupCard">
      <card class="PopupCard row col">
        <div class="title">{{$t('分组名称')}}</div>
        <div class="searc-input row">
          <input type="text" maxlength="18" class="input-in flex-1" v-model="popup.addGroup.groupName" >
          <span class="string-length Regular">{{popup.addGroup.groupName.length}}/18</span>
        </div>
        <div class="popup-btns row js-between">
          <Button class="button-ho" @click="handleClose">{{$t('取消')}}</Button>
          <Button class="button" :loading="popup.addGroup.loading" @click="handleAdd">{{$t('确定')}}</Button>
        </div>
      </card>
    </Popup>
    <!-- 编辑分组 -->
    <Popup ref="editGroup">
      <!-- 分组管理 -->
      <card class="PopupCard row col ai-top" style="width: 589px;" v-if="popup.editGroup.tp == 0">
        <div class="title-left">{{$t('分组管理')}}</div>
        <div class="close-icon pointer"  @click="handleClose"><img src="assets/image/icon_close@2x.png" ></div>
        <div class="group-list">
          <div class="group-item row" v-for="(item,index) of fz.list" :key="index" v-if="item.id">
            <div class="group-name Regular line-1 flex-1">{{item.name}}</div>
            <div class="delete pointer" @click="handleEdit(item)" v-if="item.id"><img src="assets/image/menu/delete@2x.png" ></div>
            <div class="edit pointer" @click="handleEditName(item)" v-if="item.id"><img src="assets/image/menu/edit@2x.png" ></div>
          </div>
        </div>
        <div class="popup-btns btns-all row js-center">
          <Button class="button" @click="handleClose">{{$t('确定')}}</Button>
        </div>
      </card>
      <!-- 确认删除方式 -->
      <card class="PopupCard row col ai-top" style="width: 369px;" v-if="popup.editGroup.tp == 1">
        <div class="close-icon pointer"  @click="popup.editGroup.tp = 0"><img src="assets/image/icon_close@2x.png" ></div>
        <div class="radio-group">
          <div class="radio row pointer" :class="{active:index == popup.editGroup.qCurrent}" @click="popup.editGroup.qCurrent = index" v-for="(item,index) of qList" :key="'q'+index">
            <div class="radio-icon row js-center"></div>
            <div class="radio-text">{{item}}</div>
          </div>
        </div>
        <div class="popup-btns btns-all row js-center">
          <Button class="button" :loading="popup.editGroup.loading" @click="deleteGroup">{{$t('确定')}}</Button>
        </div>
      </card>
      <!-- 确认删除 -->
      <card class="PopupCard row col" style="width: 287px;" v-if="popup.editGroup.tp == 2">
        <div class="title">{{$t('确定删除选择的素材吗')}}</div>
        <div class="close-icon pointer"  @click="popup.editGroup.tp = 1"><img src="assets/image/icon_close@2x.png" ></div>
        <div class="popup-btns btns-all row js-center">
          <Button class="button" :loading="popup.editGroup.loading" @click="handleDelete">{{$t('确定')}}</Button>
        </div>
      </card>
      <!-- 修分组名 -->
      <card class="PopupCard row col" v-if="popup.editGroup.tp == 3">
        <div class="title">{{$t('分组名称')}}</div>
        <div class="searc-input row">
          <input type="text" maxlength="18" class="input-in flex-1" v-model="popup.editGroup.editItem.name" >
          <span class="string-length Regular">{{popup.editGroup.editItem.name.length}}/18</span>
        </div>
        <div class="popup-btns row js-between">
          <Button class="button-ho" @click="popup.editGroup.tp = 0">{{$t('取消')}}</Button>
          <Button class="button" :loading="popup.editGroup.loading" @click="editGroupName">{{$t('确定')}}</Button>
        </div>
      </card>
      <!-- 修改分组 -->
      <card class="PopupCard row col" v-if="popup.editGroup.tp == 4">
        <div class="edit-group ">
          <div class="edit-group-item row js-center line-1 pointer" v-for="(item,index) of fz.list" :key="index" v-if="item.id" :class="{active:scGroupId == item.id}" @click="scGroupId=item.id">{{item.name}}</div>
        </div>
        <div class="popup-btns row js-between">
          <Button class="button-ho" @click="editGroupClose">{{$t('取消')}}</Button>
          <Button class="button" :loading="popup.editGroup.loading" @click="csGroupChange">{{$t('确定')}}</Button>
        </div>
      </card>
    </Popup>
    
    <!-- 查看大图 -->
    <popup ref="openPic">
      <div class="pic-close pointer" @click="openPicClose"><img src="assets/image/icon_close@2x%20(1).png" ></div>
      <div class="Card-pic">
        <img :src="pic" v-if="picType == 'image'">
        <video width="800" height=""  v-if="picType == 'video'" controls="controls" autoplay="autoplay">
          <source :src="pic" type="video/mp4"></source>
          <source :src="pic" type="video/ogg"></source>
          <source :src="pic" type="video/webm"></source>
          <object width="" height="" type="application/x-shockwave-flash" :data="pic">
            <param name="movie" :value="pic" />
            <param name="flashvars" :value="'autostart=true&amp;file='+pic" />
          </object>
          当前浏览器不支持 video直接播放，点击这里下载视频: <a :href="pic">下载视频</a>
        </video>
      </div>
    </popup>
   
    <updataFile :type="navBar.list[navBar.current].key" ref="updataFile" @addGroup="getGroupingList" @success="upLoadSuccess"></updataFile>
  </div>
</template>

<script>
  export default {
    name: 'myMaterialLibrary',
    data(){
      return {
        navBar:{
          list:[
            {
              name:this.$t("图片"),
              key:1
            },
            {
              name:this.$t("音频"),
              key:2
            },
            {
              name:this.$t("视频"),
              key:3
            }
          ],
          current:0
        },
        fz:{
          list:[],
          current:0
        },
        sc:{
          list:[],
          page:1,
          total:1,
          count:1
        },
        popup:{
          addGroup:{
            groupName:"",
            loading:false
          },
          editGroup:{
            tp:0,
            loading:false,
            qList:[],
            qCurrent:0,
            editItem:{}
          }
        },
        deleteStatus:false,// 批量删除的状态
        deleteLoading:false,// 删除loading
        pic:"",// 查看大图-图片地址,==> 后加 视频大图播放
        picType:'',// 类型 视频还是图片
        audio:'',
        status:"more",
        scGroupId:0,
        scGroupItem:{},
        search:''
      }
    },
    created() {
      // 监听滚到底部
      this.$store.commit('scrollBottomSet',this.scrollBottom)
      this.$store.commit('searchSet',this.Search)
      this.getGroupingList()
      
      
      // 监听全局click，关闭所有已开启的more
      window.onclick = (ev)=>{
        if(ev.target.id != 'menu' && this.$route.path == '/myMaterialLibrary'){
          this.sc.list.map((item,index)=>{
            this.sc.list[index].menu = false
          })
        }
      }
    },
    mounted() {
      
    },
    computed:{
      selectAll(){
        let selectAll = 0
        this.sc.list.map(item=>{
          if(item.check){
            selectAll ++
          }
        })
        if(selectAll){
          return `(${selectAll})`
        }else{
          return ''
        }
      },
      qList(){
        this.popup.editGroup.qList = [this.$t('仅删除分组，') + this.navBar.list[this.navBar.current].name + this.$t('归类到未分组里面'),this.$t('删除组和里面的') + this.navBar.list[this.navBar.current].name]
        return this.popup.editGroup.qList
      }
    },
    methods:{
      async getGroupingList(){
        let data = {
          type:this.navBar.list[this.navBar.current].key
        }
        await this.$apis.sc.getGroupingList(data).then(res=>{
          this.fz.list = res.data.groupingItemList
        })
        this.getGroupingMaterial()
      },
      // 获取分组
      getGroupingMaterial(){
        let data = {
          pageNum:this.sc.page,
          pageSize:20,
          groupingId:this.fz.list[this.fz.current].id,
          type:this.navBar.list[this.navBar.current].key,
          name:this.search
        }
        this.status = 'loading'
        this.$apis.sc.getGroupingMaterial(data).then(res=>{
          this.sc.count = res.data.total
          this.sc.total = parseInt(res.data.total / 20) + 1
          if(!res.data.materials) res.data.materials = []
          res.data.materials = res.data.materials.map(item=>{
            item.check = false
            item.menu = false
            item.audioObj = null
            item.paused = true
            return item
          })
          if(this.sc.page == 1){
            this.sc.list = res.data.materials
          }else{
            this.sc.list = [...this.sc.list,...res.data.materials]
          }
          if(this.sc.page >= this.sc.total){
            this.status = 'no-more'
          }else{
            this.status = 'more'
          }
          if(this.sc.list.length == 0){
            this.status = 'no-data'
          }
        })
      },
      // 搜索方法
      Search(val){
        this.search = val
        this.sc.page = 1
        this.getGroupingMaterial()
      },
      // 分类改变
      navChange(){
        this.stopPlay()
        this.fz.current = 0
        this.sc.list = []
        this.deleteStatus = false
        this.getGroupingList()
      },
      // 分组改变
      timeChange(item,index){
        this.stopPlay()
        this.fz.current = index
        this.sc.list = []
        this.deleteStatus = false
        this.getGroupingMaterial()
      },
      // 添加分组
      addGrouping(){
        this.$refs.PopupCard.open()
      },
      // 添加
      handleAdd(){
        if(!this.popup.addGroup.groupName.trim()){
          this.$message.error(this.$t("请输入分组名"))
          return
        }
        this.popup.addGroup.loading = true
        let data = {
          name: this.popup.addGroup.groupName.trim(),
          type: this.navBar.list[this.navBar.current].key
        }
        this.$apis.sc.addGrouping(data).then(res=>{
          setTimeout(()=>{
            this.popup.addGroup.loading = false
            this.$message.success(this.$t('添加成功'))
            this.popup.addGroup.groupName = ""
            this.$refs.PopupCard.close()
            this.getGroupingList()
          },500)
        }).catch(err=>{
          this.popup.addGroup.loading = false
        })
      },
      // 管理分组
      editGrouping(){
        this.popup.editGroup.tp = 0
        this.$refs.editGroup.open()
      },
      // 删除分组
      handleEdit(item){
        this.popup.editGroup.editItem = item
        this.popup.editGroup.tp = 1
      },
      // 删除分组
      deleteGroup(){
        if(this.popup.editGroup.qCurrent == 1){
          this.popup.editGroup.tp = 2
        }else{
          this.handleDelete()
        }
      },
      // 批量删除
      deleteAll(){
        this.$confirm(this.$t('确定删除选中') + this.navBar.list[this.navBar.current].name + this.$t('吗?'), this.$t('提示'), {
          confirmButtonText: this.$t('确定'),
          cancelButtonText: this.$t('取消'),
          type: 'warning'
        }).then(() => {
          let allItem = this.sc.list.filter(item=>{
            return item.check
          }).map(item=>item.id).join(',')
          this.deleteLoading = true
          this.$apis.sc.batchRemove({ids:allItem}).then(res=>{
            setTimeout(()=>{
              this.deleteLoading = false
              this.$message.success(this.$t("删除成功"))
              this.deleteStatus = false
              this.sc.page = 1
              this.getGroupingMaterial()
            },300)
          })
        })
        
        
      },
      // 确定删除
      handleDelete(){
        let data = {
          id:this.popup.editGroup.editItem.id,
          type:this.popup.editGroup.qCurrent?2:1
        }
        this.popup.editGroup.loading = true
        this.$apis.sc.removeGrouping(data).then(res=>{
          setTimeout(()=>{
            this.popup.editGroup.loading = false
            this.$message.success(this.$t('删除成功'))
            this.popup.editGroup.tp = 0
            this.popup.editGroup.qCurrent = 0
            this.handleClose()
          },500)
        }).catch(err=>{
          this.popup.editGroup.loading = false
        })
      },
      // 修改分组名
      handleEditName(item){
        this.popup.editGroup.editItem = JSON.parse(JSON.stringify(item))
        this.popup.editGroup.tp = 3
      },
      // 确认修改分组名
      editGroupName(){
        if(!this.popup.editGroup.editItem.name.trim()){
          this.$message.error("请输入分组名")
          return
        }
        this.popup.editGroup.loading = true
        let data = {
          name: this.popup.editGroup.editItem.name.trim(),
          type: this.navBar.list[this.navBar.current].key,
          id:this.popup.editGroup.editItem.id
        }
        this.$apis.sc.updateMaterialGrouping(data).then(res=>{
          setTimeout(()=>{
            this.popup.editGroup.loading = false
            this.$message.success(this.$t('修改成功'))
            this.popup.editGroup.tp = 0
            this.handleClose()
          },500)
        }).catch(err=>{
          this.popup.editGroup.loading = false
        })
      },
      // 关闭pupop
      handleClose(){
        this.$refs.PopupCard.close()
        this.$refs.editGroup.close()
        this.getGroupingList()
      },
      // 上传素材
      updata(){
        this.$refs.updataFile.open()
      },
      // 上传成功
      upLoadSuccess(ev){
        this.getGroupingList()
      },
      // 查看大图
      openPic(item,type,index){
        this.pic = item
        this.picType = type
        if(type == 'audio'){
          // 暂停所有
          this.stopPlay()
          // 播放当前
          var player = new Audio(item);
          this.sc.list[index].audioObj = player
          this.sc.list[index].paused = false
          player.play(); //播放 mp3这个音频对象
          
        }else{
          this.$refs.openPic.open()
        }
      },
      // 停止播放
      stopPlay(item){
        this.sc.list.map(item=>{
          item.paused = true
          let player = item.audioObj
          //暂停
          player && player.pause();
          player && player.load();
        })
      },
      // 查看大图 关闭
      openPicClose(){
        this.$refs.openPic.close()
      },
      // 菜单点击
      menuHandle(type,item){
        console.log(item);
        if(type == 'setGroup'){
          this.popup.editGroup.tp = 4
          this.scGroupId = 0
          this.scGroupItem = item
          this.$refs.editGroup.open()
        }
        
        if(type == 'editname'){
          let index1=item.name.lastIndexOf(".");
          let index2=item.name.length;
          let FileType=item.name.substring(index1,index2);
          this.$prompt(this.$t('请输入新名称'),this.$t('提示'), {
            confirmButtonText: this.$t('确定'),
            cancelButtonText: this.$t('取消'),
          }).then(({ value }) => {
            let data = {
              id:item.id,
              name:value + FileType
            }
            this.$apis.sc.updateMaterial(data).then(res=>{
              this.$message.success(this.$t('修改成功'))
              this.sc.page = 1
              this.getGroupingMaterial()
            })
          }).catch(() => {
            
          });
        }
        if(type == 'delete'){
          this.$confirm('确定删除该素材?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let data = {
              id:item.id,
            }
            this.$apis.sc.deleteMaterial(data).then(res=>{
              this.$message.success(this.$t('删除成功'))
              this.sc.page = 1
              this.getGroupingMaterial()
            })
          }).catch(() => {
          });
        }
      },
      // 素材修改分组
      csGroupChange(){
        if(this.scGroupId){
          let data = {
            id:this.scGroupItem.id,
            groupingId:this.scGroupId
          }
          this.$apis.sc.moveGrouping(data).then(res=>{
            this.$refs.editGroup.close()
            this.$message.success(this.$t('修改成功'))
            this.sc.page = 1
            this.getGroupingMaterial()
            this.getGroupingList()
          })
        }
      },
      editGroupClose(){
        this.$refs.editGroup.close()
      },
      scrollBottom(){
        if(this.sc.page < this.sc.total){
          this.sc.page ++ 
          this.getGroupingMaterial()
        }
      }
    }
  }
</script>

<style lang="scss">
  .myMaterialLibrary{
    .btns{
      padding-right: 27px;
      .button{
        width: 81px;
        height: 32px;
        border-radius: 5px;
        margin-left: 15px;
      }
      .image-tip{
        font-size: 10px;
        color: #696B76;
        line-height: 15px;
        text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
      }
    }
    .Card{
      .times{
        padding: 15px 0 9px 20px;
        .time-name{
          font-size: 12px;
          line-height: 17px;
        }
        .time-item{
          // width: 81px;
          height: 32px;
          margin-right: 36px;
          cursor: pointer;
          font-size: 12px;
          line-height: 17px;
          &.button-ho{
            width: 81px;
            border-color: #FFAE2E;
            color: #FFAE2E;
          }
        }
        .btns{
          .btn-item{
            font-size: 12px;
            color: #696B76;
            line-height: 17px;
            text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
            margin-left: 20px;
            img{
              width: 25px;
              height: 25px;
            }
          }
        }
      }
      
      .list{
        flex-wrap: wrap;
        padding-left: 25px;
        margin-top: 12px;
        .item{
          margin-bottom: 16px;
          .more{
            width: 15px;
            height: 11px;
            position: absolute;
            bottom: 8px;
            right: 8px;
            display: none;
            padding: 4px 0;
          }
          .menu{
            padding: 5px 0;
            background: rgba(0, 0, 0, 0.6);
            position: absolute;
            right: 0;
            bottom: 20px;
            .menu-item{
              margin: 5px 9px;
              font-size: 10px;
              color: #FFFFFF;
              line-height: 15px;
            }
          }
          .select{
            width: 20px;
            height: 20px;
            border: 1px solid #FFAE2E;
            background-color: #FFFFFF;
            position: absolute;
            top: 8px;
            right: 8px;
            border-radius: 100%;
            &.active{
              &::after{
                content: "";
                display: inline-block;
                width: 14px;
                height: 14px;
                background-color: #FFAE2E;
                border-radius: 100%;
              }
            }
          }
          &.image{
            .image-b{
              width: 137px;
              height: 102px;
              border: 1px solid #EBEBEB;
              margin-right: 21px;
              &:hover{
                border: 1px solid #FFAE2E;
                .more{
                  display: inline-block;
                }
              }
            }
            .title{
              width: 137px;
              color: #3B3F56;
              margin-top: 16px;
              text-align: center;
            }
          }
          &.audio{
            width: 137px;
            height: 97px;
            box-shadow: 0px 2px 3px 0px rgba(220,231,239,0.5);
            border-radius: 5px;
            border: 1px solid #E8E3E3;
            margin-right: 13px;
            padding: 10px 0 0 7px;
            &:hover{
              border: 1px solid #FFAE2E;
              .more{
                display: inline-block;
              }
            }
            .title{
              font-size: 10px;
              color: #696B76;
              height: 30px;
              line-height: 15px;
              text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5), 0px 2px 12px rgba(248, 221, 177, 0.61);
            }
            .length{
              margin-top: 1px;
              font-size: 10px;
              color: #696B76;
              line-height: 15px;
              text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5), 0px 2px 12px rgba(248, 221, 177, 0.61);
            }
            .time{
              margin-top: 16px;
              font-size: 9px;
              font-weight: 400;
              color: #696B76;
              line-height: 14px;
              text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5), 0px 2px 12px rgba(248, 221, 177, 0.61);
            }
            .play-icon{
              width: 15px;
              height: 15px;
              position: absolute;
              bottom: 9px;
              right: 9px;
              &.playing_{
                padding: 2px;
                border-radius: 100%;
                background-color: rgba(0,0,0,0.5);
                display: flex;
                align-items: center;
                justify-content: space-around;
              }
              .playing{
                animation: playing 1.3s ease infinite alternate;
                width: 2px;
                background-color: #FFFFFF;
                &.bar2{
                  animation-delay: -2.4s;
                }
                &.bar3{
                  animation-delay: -3.7s;
                }
              }
            }
          }
          &.video{
            width: 167px;
            height: 122px;
            border: 1px solid #EBEBEB;
            margin-right: 21px;
            &:hover{
              border: 1px solid #FFAE2E;
              .more{
                display: inline-block;
              }
            }
            .image{
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
            }
            .title{
              width: 167px;
              height: 72px;
              padding: 6px 9px 0;
              background: linear-gradient(180deg, rgba(0, 0, 0, 0.55) 0%, rgba(255, 255, 255, 0) 100%);
              // box-shadow: 0px 2px 3px 0px rgba(220,231,239,0.5);
              position: absolute;
              left: 0;
              top: 0;
              .text{
                text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
                font-size: 10px;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 15px;
                height: 30px;
                width: 102px;
              }
              .time{
                font-size: 9px;
                color: #FFFFFF;
                line-height: 14px;
                text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
              }
            }
            .length{
              background: linear-gradient(0, rgba(0, 0, 0, 0.55) 0%, rgba(255, 255, 255, 0) 100%);
              box-shadow: 0px 2px 3px 0px rgba(220,231,239,0.5);
              position: absolute;
              bottom: 0;
              left: 0;
              width: 167px;
              height: 50px;
              padding: 15px 9px 0;
              .text{
                font-size: 12px;
                color: #FFFFFF;
                line-height: 17px;
                text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
              }
            }
            .play-icon{
              width: 37px;
              height: 37px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%,-50%);
            }
          }
          
        }
      }
      
    }
    
    .PopupCard{
      width: 393px;
      padding: 24px 0 22px;
      padding: 28px 28px 18px;
      
      .close-icon{
        width: 14px;
        height: 14px;
        position: absolute;
        top: 18px;
        right: 15px;
      }
      
      .radio-group{
        .radio{
          margin-top: 21px;
          &.active{
            .radio-icon:after{
              content: '';
              display: inline-block;
              width: 12px;
              height: 12px;
              background-color: #FFAE2E;
              border-radius: 100%;
            }
          }
          .radio-icon{
            width: 18px;
            height: 18px;
            border: 1px solid #FFAE2E;
            border-radius: 100%;
            margin-right: 17px;
          }
        }
      }
      
      .group-list{
        margin-top: 15px;
        width: 100%;
        .group-item{
          padding: 10px 0;
          .group-name{
            font-size: 14px;
            color: #3B3F56;
            line-height: 19px;
          }
          .delete{
            width: 13px;
            height: 14px;
          }
          .edit{
            width: 12px;
            height: 14px;
            margin-left: 15px;
          }
        }
      }
      
      .title{
        font-size: 15px;
        color: #3B3F56;
        line-height: 21px;
      }
      
      .title-left{
        font-size: 15px;
        color: #3B3F56;
        line-height: 21px;
      }
      
      .edit-group{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .edit-group-item{
          width: 85px;;
          height: 34px;
          background: rgba(255, 174, 46, 0.6);
          border-radius: 3px;
          margin:0 12px;
          margin-top: 11px;
          color: #FFFFFF;
          &.active{
            background: rgba(255, 174, 46, 1);
          }
        }
      }
      
      .searc-input{
        width: 232px;
        height: 34px;
        border-radius: 3px;
        border: 1px solid #DBDBE7;
        margin-top: 22px;
        .input-in{
          padding-left: 12px;
        }
        .string-length{
          font-size: 12px;
          color: #C8CAD6;
          line-height: 17px;
          margin-right: 15px;
        }
      }
      
      .popup-btns{
        width: 232px;
        margin-top: 22px;
        .button{
          width: 90px;
          height: 32px;
          border-radius: 5px;
        }
        &.btns-all{
          width: 100%;
          .button{
            margin: 0;
          }
        }
      }
    }
    
    
    .pic-close{
      width: 17px;
      height: 17px;
      position: absolute;
      top: 47px;
      right: 71px;
    }
    .Card-pic{
      margin: 0 !important;
      img{
        max-width: 582px;
        max-height: 415px;
        border-radius: 7px;
        overflow: hidden;
      }
      video{
        max-width: 582px;
        max-height: 415px;
        border-radius: 7px;
        overflow: hidden;
      }
    }
  }
</style>
