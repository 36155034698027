<template>
  <div class="Switch pos-rel pointer" :class="{open:status,disable:disable}" :style="{...size,backgroundColor,'border-radius':size.height,'--active-background-color':activeBackgroundColor}" @click="switchChange">
    <div class="switch-active row js-center" :style="{width:'calc('+size.height+' - 4px)',height:'calc('+size.height+' - 4px)','--switch-active-width':'calc('+size.height+' - 4px)'}">
      <i class="el-icon-loading" v-if="loadingSwitch"></i>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Switch',
    props:{
      status:{
        type:Boolean,
        default:false
      },
      loading:{
        type:Function,
        default:null
      },
      size:{
        type:Object,
        default:()=>{
          return {
            width:'44px',
            height:'26px'
          }
        }
      },
      backgroundColor:{
        type:String,
        default:"rgb(220, 223, 230)"
      },
      activeBackgroundColor:{
        type:String,
        default:"#FB9C1E"
      },
      id:{
        type:[String,Number,Object],
        default:0
      },
      disable:{
        type:Boolean,
        default:false
      }
    },
    data(){
      return {
        loadingSwitch:false
      }
    },
    created() {
      
    },
    mounted() {
      
    },
    computed:{
      
    },
    methods:{
      switchChange(){
        if(this.disable) return
        if(this.loading){
          this.loadingSwitch = true
          this.loading((status)=>{
            // status true 则确定更新，false 不更新
            if(status){
              this.$emit('update:status',!this.status)
              this.$emit('change',!this.status)
            }
            this.loadingSwitch = false
          },this.id)
        }else{
          this.$emit('update:status',!this.status)
          this.$emit('change',!this.status)
        }
        
      }
    }
  }
</script>

<style lang="scss" scoped>
  .Switch{
    border-radius: 26px;
    padding: 2px;
    box-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);;
    transition: all 0.5s;
    &.disable{
      filter: grayscale(1);
      cursor: not-allowed;
    }
    &.open{
      background-color: var(--active-background-color) !important;
      .switch-active{
        transform: translateX(calc(var(--switch-active-width) - 4px));
      }
    }
    .switch-active{
      background-color: #FFFFFF;
      border-radius: 100%;
      transition: all 0.5s;
    }
  }
</style>
