<template>
  <div class="success">
    <div class="title">{{ this.title }}</div>
    <div class="info row col">
      <div class="image"><img src="assets/image/success@2x.png" ></div>
      <div class="info-text">{{info}}</div>
      <Button class="button" v-if="btnType == 'outLogin'" @click="outLogin">{{$t('返回登录')}}</Button>
      <Button class="button" v-else @click="back">{{$t('返回')}}</Button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'success',
    data(){
      return {
        title:"", // 右上角爱标题
        info:"", // 图标下标题
        btnType:"",// back 返回 outLogin 退出登录
      }
    },
    created() {
      this.btnType = this.$route.query.btnType
      if(this.btnType == 'back'){
        this.title = this.$t("修改手机")
        this.info = this.$t("修改成功")
      }
      if(this.btnType == 'outLogin'){
        this.title = this.$t("修改密码")
        this.info = this.$t("修改成功")
      }
      
    },
    mounted() {
      
    },
    computed:{
      
    },
    methods:{
      back(){
        this.$router.replace('/userSet')
      },
      outLogin(){
        this.$clearData(['loglevel:webpack-dev-server','lang'])
        this.$store.commit('isLoginSet',false)
        this.$router.replace('/login')
      }
    }
  }
</script>

<style lang="scss">
  .success{
    padding: 19px 40px;
    display: flex;
    flex-direction: column;
    height: 100%;
    .title{
      color: #3B3F56;
      font-size: 18px;
      line-height: 25px;
      font-weight: 700;
    }
    .info{
      .image{
        width: 85px;
        height: 85px;
        border-radius: 100%;
        margin-top: 61px;
        
      }
      .info-text{
        margin-top: 19px;
        color: #3B3F56;
        font-size: 15px;
        line-height: 21px;
      }
      .button{
        width: 125px;
        height: 32px;
        border-radius: 5px;
        margin-top: 44px;
      }
    }
  }
</style>
