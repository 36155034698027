<template>
  <div class="navBar">
    <div class="nav-bar row">
      <div class="tab-bar-item row" v-for="(item,index) in list" :key="index" :class="{current:current == index,tag:item.tag}" @click="navBarChange(item,index)">
        {{item.name}}
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'navBar',
    data(){
      return {
        tabBarCurrent:0
      }
    },
    props:{
      list:{
        type:Array,
        default(){return []}
      },
      current:{
        type:Number,
        default:0
      }
    },
    created() {
      
    },
    mounted() {
      
    },
    computed:{
      
    },
    methods:{
     navBarChange(item,index){
       this.$emit('update:current',index)
       this.$emit('change',item)
     }
    }
  }
</script>

<style lang="scss" scoped>
  .nav-bar{
    height: 51px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 3px 0px rgba(220, 231, 239, 0.5);
    border-radius: 6px;
    font-size: 12px;
    line-height: 17px;
    color: #696B76;
    margin-top: 17px;
    position: relative;
    .tab-bar-item{
      padding: 0 20px;
      height: 100%;
      cursor: pointer;
      &.current{
        color: #FFAE2E;
        position: relative;
        &::after{
          content: '';
          display: inline-block;
          height: 2px;
          width: 34px;
          background-color: #FFAE2E;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      
      &.tag{
        position: relative;
        &::before{
          content: '';
          display: inline-block;
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 100%;
          background-color: #FE5555;
          right: 15px;
          margin-bottom: 1.1em;
        }
      }
    }
  }
</style>
