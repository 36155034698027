<template>
  <div class="phone">
    <div class="title row">
      <div class="flex-1 title-text">{{ $t('修改手机') }}</div>
      <div class="link pointer" @click="sm">{{ $t('管理员扫码确认')}}</div>
    </div>
    <card class="Card">
      <!-- 表单 -->
      <div class="form">
        <div class="row user-phone">
          <span>手机：{{phone | VForm}}</span>
          <Button class="button h-100" :loading="loading.sendMsg" :class="{disabled:disabled.sendMsg}" @click="sendCode">{{loading.sendMsg?'':buttonText}}</Button>
        </div>
        <div class="input row hidden">
          <input type="text" @blur="inspectKey()" class="input-in flex-1" :placeholder="$t('')" v-model="code">
        </div>
        <div class="row">
          <div class="input-name"></div>
          <div class="tips row" :style="{opacity:isTips?1:0}">
            <img src class="tips-img" src="assets/image/notice@2x.png" >
            <span class="tips-text">{{tips}}</span>
          </div>
        </div>
      </div>
      <!-- next -->
      <Button class="button button-submit" :loading="loading.submit" @click="submit">{{$t('确定')}}</Button>
    </card>
  </div>
</template>

<script>
  export default {
    name: 'phone',
    data(){
      return {
        loading:{
          submit:false,
          sendMsg:false
        },
        disabled:{
          sendMsg:false
        },
        buttonText:this.$t("发送验证码"),
        code:'',
        isTips:false,
        tips:this.$t("输入验证码"),
        phone:''
      }
    },
    created() {
      this.init()
    },
    mounted() {
      
    },
    computed:{
      
    },
    methods:{
      init(){
        this.$apis.user.getOfficialAccountInfo().then(res=>{
          this.phone = res.data.accountInfoItem.phone
        })
      },
      sm(){
        this.$router.push(`/page/scanCodeConfirm`)
      },
      sendCode(){
        this.loading.sendMsg = true
        this.$apis.login.sendSms({account:this.phone}).then(res=>{
          setTimeout(()=>{
            this.loading.sendMsg  = false
            this.$message.success(this.$t('验证码已发送，请注意查收'));
            this.disabled.sendMsg = true
            let timeNum = 60
            this.buttonText = timeNum + this.$t("秒后重试")
            let timer = setInterval(()=>{
              this.buttonText = timeNum + this.$t("秒后重试")
              timeNum -- 
              if(timeNum <= 0){
                clearInterval(timer)
                this.buttonText = this.$t("发送验证码")
                this.disabled.sendMsg = false
              }
            },1000)
          },500)
          
        }).catch(err=>{
          this.loading.sendMsg = false
        })
      },
      inspectKey(){
        if(this.code.trim()){
          this.isTips = false
          return true
        }
        this.isTips = true
        return false
      },
      submit(){
        if(this.inspectKey()){
          this.loading.submit = true
          let data = {
            account:this.phone,
            smsCode:this.code
          }
          this.$apis.login.validateSms(data).then(res=>{
            setTimeout(()=>{
              this.loading.submit = false
              
              this.$apis.login.replacePhone()
              this.$router.push(`/page/setPhone`)
            },500)
          }).catch(err=>{
            this.loading.submit = false
          })
        }
      }
    },
    filters:{
      VForm(val){
        let strArr = val.split('')
        if(strArr.length <= 5){
          if(strArr.length > 1){
            for(let i = 1;i<strArr.length;i++){
              strArr[i] = '*'
            }
          }
          
          return strArr.join('')
        }
        if(strArr.length == 11){
          strArr[3] = '*'
          strArr[4] = '*'
          strArr[5] = '*'
          strArr[6] = '*'
          return strArr.join('')
        }
        if(strArr.length > 11){
          for(let i = 7;i<=13;i++){
            strArr[i] = '*'
          }
          return strArr.join('')
        }
        
      }
    }
  }
</script>

<style lang="scss">
  .phone{
    padding: 19px 40px;
    display: flex;
    flex-direction: column;
    height: 100%;
    .title{
      .title-text{
        color: #3B3F56;
        font-size: 18px;
        line-height: 25px;
        font-weight: 700;
      }
      .link{
        font-size: 14px;
        color: #1564B9;
        line-height: 19px;
      }
    }
    .Card{
      padding: 0 0 34px 19px;
      .form{
        .user-phone{
          padding: 16px 0 20px;
          border-bottom: 1px solid rgba(151, 151, 151, 0.13);
          .button{
            width: 90px;
            height: 32px;
            border-radius: 5px;
            margin-left: 25px;
            &.disabled{
              background-color: #DCDCDC;
            }
          }
        }
        
        .input{
          width: 251px;
          height: 34px;
          border-radius: 3px;
          border: 1px solid #DBDBE7;
          margin-top: 19px;
          padding-left: 12px;
        }
      }
      .button-submit{
        width: 125px;
        height: 32px;
        border-radius: 5px;
        margin-left: 7px;
        margin-top: 10px;
      }
    }
  }
</style>
