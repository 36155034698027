<template>
  <div class="loadMore row js-center">
    <div class="loading-more" v-if="status == 'loading'">加载中<i class="el-icon-loading"></i></div>
    <div class="loading-more pointer" @click="more" v-if="status == 'more'">点击加载更多</div>
    <div class="loading-more " v-if="status == 'no-more'">没有更多数据了</div>
    <div class="loading-more " v-if="status == 'no-data'">暂无数据</div>
  </div>
</template>

<script>
  export default {
    name: 'loadMore',
    props:{
      status:{
        type:String,
        default:'more',// more 加载更多 no-more 没有更多了 loading 加载中 no-data 没有数据
      }
    },
    methods:{
      more(){
        this.$emit('more')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .loadMore{
    height: 50px;
    color: #989898;
  }
</style>
