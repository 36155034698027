<template>
  <div class="reg">
    <div class="title">{{ $t('注册') }}</div>
    <div class="card-list">
    <!--  <navLink class="card row col" path="">
        <div class="card-title_">{{ $t('小程序') }}</div>
        <img class="card-img" src="assets/image/login/pic@2x.png" >
      </navLink> -->
      <navLink class="card  row col" path="reg/user">
        <div class="card-title_">{{$t('公众号')}}</div>
        <img class="card-img" src="assets/image/login/public@2x.png" >
      </navLink>
    </div>
    <About></About>
  </div>
</template>

<script>
  export default {
    name: 'reg',
    data(){
      return {
        
      }
    },
    created() {
      this.$apis.user.getOfficialAccountInfo().then(res=>{
        let info = res.data.accountInfoItem
        if(info.attestation == 1){
          this.$router.push('/reg/infoRegister')
        }
      })
    },
    mounted() {
      
    },
    computed:{
      
    },
    methods:{
     
    }
  }
</script>

<style lang="scss" scoped>
  .reg{
    padding: 19px 61px;
    display: flex;
    flex-direction: column;
    height: 100%;
    .title{
      color: #3B3F56;
      font-size: 18px;
      line-height: 25px;
      font-weight: 700;
    }
    
    .card-list{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 60px;
      .card{
        width: 390px;
        height: 285px;
        background-color: #FFFFFF;
        box-shadow: 0px 2px 3px 0px rgba(209,209,209,0.5);
        border-radius: 7px;
        margin: 0 17.5px;
        cursor: pointer;
        .card-title_{
          font-size: 15px;
          color: #3B3F56;
          line-height: 21px;
          font-weight: 700;
          text-align: center;
          margin-top: 15px;
          text-shadow: 0px 2px 3px rgba(209, 209, 209, 0.5);
        }
        .card-img{
          width: 200px;
          height: 200px;
          margin-top: 30px;
        }
      }
    }
    
  }
</style>

