<template>
  <button class="button" @click="ckick" :disabled="disabled || loading"><slot></slot><i v-if="loading" class="el-icon-loading"></i></button>
</template>

<script>
  export default {
    name: '',
    props:{
      disabled:{
        type:Boolean,
        default:false
      },
      loading:{
        type:Boolean,
        default:false
      }
    },
    data(){
      return {
        handle:false
      }
    },
    created() {
      
    },
    mounted() {
      
    },
    computed:{
      
    },
    methods:{
      ckick(){
        if(this.handle) return
        if(!this.disabled && !this.loading){
          this.handle = true
          this.$emit('click')
          setTimeout(()=>{
            this.handle = false
          },500)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  
</style>
