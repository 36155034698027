import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Page from '../views/Page.vue'
import preview from '../views/pages/release/preview.vue'
import Html from '../views/pages/release/Html.vue'

import index from '../views/pages/index.vue'

import myCreation from '../views/pages/myCreation.vue'
import draftBox from '../views/pages/draftBox.vue'
import myMaterialLibrary from '../views/pages/myMaterialLibrary.vue'
import myFans from '../views/pages/myFans.vue'


import customizeMenu from '../views/pages/ConfigureAndInteraction/customizeMenu.vue'
import leaveComments from '../views/pages/ConfigureAndInteraction/leaveComments.vue'
import autoRes from '../views/pages/ConfigureAndInteraction/autoRes.vue'
import voteData from '../views/pages/ConfigureAndInteraction/voteData.vue'
import voteDetail from '../views/pages/ConfigureAndInteraction/voteDetail.vue'

import userInfo from '../views/pages/userSafe/userInfo.vue'
import userSet from '../views/pages/userSafe/userSet.vue'
import userSafe from '../views/pages/userSafe/userSafe.vue'
import setUp from '../views/pages/userSafe/setUp.vue'

import login from '../views/pages/login/login.vue'
import retPassword from '../views/pages/login/retPassword.vue'


import reg from '../views/pages/reg/reg.vue'
import regForm from '../views/pages/reg/reg_form.vue'
import regSetType from '../views/pages/reg/reg_set_type.vue'
import regInfo from '../views/pages/reg/reg_info.vue'
import infoRegister from '../views/pages/reg/info_register.vue'

import phone from '../views/pages/userSafe/phone/index.vue'
import scanCodeConfirm from '../views/pages/userSafe/phone/scanCodeConfirm.vue'
import setPhone from '../views/pages/userSafe/phone/setPhone.vue'
import success from '../views/pages/userSafe/success.vue'
import Violation from '../views/pages/userSafe/Violation.vue'
import password from '../views/pages/userSafe/password/index.vue'
import scanCodeConfirmPwd from '../views/pages/userSafe/password/scanCodeConfirm.vue'
import setPassword from '../views/pages/userSafe/password/setPassword.vue'
import release from '../views/pages/release/index.vue'


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children:[
      {
        path: 'index',
        name: 'index',
        component: index
      },
      {
        path: 'myCreation',
        name: '我的创作',
        component: myCreation
      },
      {
        path: 'draftBox',
        name: '草稿箱',
        component: draftBox
      },
      {
        path: 'myMaterialLibrary',
        name: '我的素材库',
        component: myMaterialLibrary
      },
      {
        path: 'myFans',
        name: '我的粉丝',
        component: myFans
      },
      {
        path: 'customizeMenu',
        name: '我的粉丝',
        component: customizeMenu
      },
      {
        path: 'leaveComments',
        name: '留言互动',
        component: leaveComments
      },
      {
        path: 'autoRes',
        name: '自动回复',
        component: autoRes
      },
      {
        path: 'voteData',
        name: '投票数据',
        component: voteData
      },
      {
        path: 'voteDetail',
        name: '投票数据详情',
        component: voteDetail
      },
      {
        path: 'userInfo',
        name: '账号资料',
        component: userInfo
      },
      {
        path: 'userSet',
        name: '账号管理',
        component: userSet
      },
      {
        path: 'userSafe',
        name: '账号安全',
        component: userSafe
      },
      {
        path: 'setUp',
        name: '功能设置',
        component: setUp
      },
      {
        path:'login',
        name:'登录',
        component:login
      },
      {
        path:'retPassword',
        name:'找回密码',
        component:retPassword
      },
      {
        path:'reg',
        name:'注册',
        component:reg
      },
      {
        path:'reg/user',
        name:'登录信息',
        component:regForm
      },
      {
        path:'reg/setType',
        name:'选择类别',
        component:regSetType
      },
      {
        path:'reg/info',
        name:'认证信息',
        component:regInfo
      },
      {
        path:'reg/infoRegister',
        name:'公众号信息登记',
        component:infoRegister
      }
    ]
  },
  {
    path:'/page',
    name:'Page',
    component: Page,
    children:[
      {
        path: 'phone',
        name: '修改手机',
        component: phone
      },
      {
        path: 'scanCodeConfirm',
        name: '扫码确认',
        component: scanCodeConfirm
      },
      {
        path: 'setPhone',
        name: '设置手机',
        component: setPhone
      },
      {
        path: 'Violation',
        name: '违规',
        component: Violation
      },
      {
        path: 'success',
        name: '成功',
        component: success
      },
      {
        path: 'password',
        name: '修改密码',
        component: password
      },
      {
        path: 'pwdScanCodeConfirm',
        name: '修改密码-扫码验证',
        component: scanCodeConfirmPwd
      },
      {
        path: 'setPassword',
        name: '修改密码',
        component: setPassword
      },
      {
        path: 'release',
        name: '文章发布',
        component: release
      },
    ]
  },
  {
    path:'/preview',
    name:'Page',
    component: preview,
  },
  {
    path:'/Html',
    name:'Page',
    component: Html,
  }
  
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(function(to, from, next) {
  console.log(to);
  // 不需要验证的页面
  let paths = ['/Html','/login','/reg',"/reg/user","/retPassword",'/page/success','/reg/setType','/reg/info','/reg/infoRegister']
  let thit = Vue.prototype
  if(paths.indexOf(to.path) == -1){
    
    let userInfo = thit.$getData('userInfo')
    if(userInfo && userInfo.accountState == 2){
      next('/login')
      thit.$message.error(thit.$t('账号已封停'));
      thit.$store.commit('isLoginSet',false)
      return
    }
    // 未认证，跳转认证
    if(userInfo && userInfo.attestation == 2){
      thit.$store.commit('isLoginSet',false)
      next('/reg/setType')
      return
    }
    
    if(userInfo){
      thit.$store.commit('isLoginSet',true)
      thit.$store.commit('userInfoSet',userInfo)
      if(to.path == '/'){
        next('/index')
      }else{
        next()
      }
      return
    }
    thit.$store.commit('isLoginSet',false)
    next('/login')
  }else{
    next()
  }
})


export default router
