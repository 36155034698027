<template>
  <div class="reg_info">
    <div class="card row col">
      <Steps :list="stepsList" current="2"></Steps>
      <!-- 表单 -->
      <div class="form row col">
        <div class="item">
          <div class="form-item row">
            <div class="input-name row">{{$t('认证类型')}}：</div>
            <div class="input un-input row" >{{userType == 1?$t('个人'):$t('企业')}}</div>
            
          </div>
        </div>
        <div class="item" v-for="(item,key) in forms[userType]" :key="key">
          <div class="form-item row ai-top">
            <div class="input-name row" v-html="item.name">{{  }}</div>
            <!-- 输入框 -->
            <div class="input row hidden" v-if="item.type == 'input'">
              <input type="text" class="flex-1" @blur="inspectKey(key,userType)"  :placeholder="item.placeholder" v-model="item.value">
              <Button class="button h-100" v-if="item.isButton" :loading="item.loading" :class="{disabled:item.disabled}" @click="item.buttonFun">{{item.loading?'':item.buttonText}}</Button>
            </div>
            <!-- 下拉选 -->
            <el-dropdown size="medium" trigger="click" @command="item.current = $event" v-if="item.type == 'dropdown'">
              <div class="input row hidden">
                <div class="flex-1">{{item.list[item.current].name}}</div>
                <img src="../../../assets/image/icon_next@2x.png" class="icon">
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(it,idx) in item.list" :command="idx" :key="idx">{{it.name}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <!-- 图片 -->
            <div class="images row" v-if="item.type == 'updata-image'">
              <img class="image-uploader" v-if="item.shili" :src="item.shili" >
              <el-upload
                class="image-uploader"
                name="upload"
                :action="$picHost"
                :show-file-list="false"
                :on-change="item.Success"
                :before-upload="beforeAvatarUpload">
                <img src="assets/image/update-image.png">
              </el-upload>
              <!-- v-if="item.maxLength > item.picList.length || !item.maxLength" -->
              <div class="image-uploader"  v-for="(it,idx) in item.picList"  :key="idx">
                <img src="assets/image/login/icon_close@2x%20(2).png" class="delete pointer" @click="item.deleteImg(idx)">
                <img :src="it" >
              </div>
            </div>
            
            
          </div>
          <div class="row">
            <div class="input-tips"></div>
            <div class="tips" :style="{opacity:item.isTips?1:0}">
              <img src="assets/image/notice@2x.png" class="tips-img" >
              <span class="tips-text">{{item.tips}}</span>
            </div>
          </div>
        </div>
      </div>
      
      <!-- 提交 -->
      <Button class="button submit" :loading="$loading.next" @click="submit">{{ $t('下一步')}}</Button>
      
    </div>
    
    <About></About>
    
  </div>
</template>

<script>
  import About from '@/components/About.vue'
  import Steps from '@/components/Steps.vue'
  export default {
    name: 'reg_info',
    components:{
      Steps,
      About
    },
    data(){
      return {
        stepsList:[this.$t('基本信息'),this.$t('选择类型'),this.$t('信息登记'),this.$t('公众号信息填写')],
        $loading:{
          next:false
        },
        userType:1,// 1个人 2 企业
        forms:{
          1:{
            cardType:{
              name:this.$t("证件类型:"),
              value:"",
              type:'dropdown',
              list:[
                {
                  name:'医保卡',
                  key:1
                },
                {
                  name:'社保卡',
                  key:2
                }
              ],
              current:0
            },
            username:{
              type:'input',
              name:this.$t('名字:'),
              placeholder:"",
              value:"",
              isTips:false,
              tips:'请输入名字',
              isButton:false
            },
            cardId:{
              type:'input',
              name:this.$t('证件号码:'),
              placeholder:'',
              value:"",
              isTips:false,
              tips:this.$t('输入证件号码'),
              isButton:false,
            },
            cardPic:{
              type:'updata-image',
              name:this.$t('证件上传:'),
              placeholder:'',
              value:"",
              isTips:false,
              tips:this.$t('上传身份证'),
              isButton:false,
              picList:[],
              maxLength:1,
              shili:'assets/image/login/card@2x.png',
              Success:(ev)=>{
                this.$apis.sc.upload(ev.raw,(progress,p)=>{
                }).then(res=>{
                  this.forms[1].cardPic.picList = [res]
                })
              },
              deleteImg:(i)=>{
                this.forms[1].cardPic.picList = []
              }
            },
            holdCertificateImg:{
              type:'updata-image',
              name:this.$t('本人手持<br>证件上传:'),
              placeholder:'',
              value:"",
              isTips:false,
              tips:this.$t('上传手持身份证'),
              isButton:false,
              picList:[],
              maxLength:1,
              shili:'assets/image/login/Bitmap@2x.png',
              Success:(ev)=>{
                this.$apis.sc.upload(ev.raw,(progress,p)=>{
                }).then(res=>{
                  this.forms[1].holdCertificateImg.picList = [res]
                })
              },
              deleteImg:(i)=>{
                this.forms[1].holdCertificateImg.picList = []
              }
            },
            phone:{
              type:'input',
              name:this.$t('手机绑定:'),
              placeholder:this.$t('绑定公众号负责人员的手机号码'),
              value:"",
              isTips:true,
              tips:this.$t('输入公众号负责人员的手机号码'),
              isButton:true,
              disabled:false,
              loading:false,
              buttonText:this.$t("发送验证码"),
              buttonFun:this.sendCode
            },
            code:{
              type:'input',
              name:this.$t('验证码:'),
              placeholder:this.$t('6位验证码'),
              value:"",
              isTips:false,
              tips:this.$t('6位验证码'),
              isButton:false,
            },
          },
          2:{
            username:{
              type:'input',
              name:this.$t('企业名字:'),
              placeholder:"",
              value:"",
              isTips:false,
              tips:'输入企业名字',
              isButton:false
            },
            cardId:{
              type:'input',
              name:this.$t('企业注册<br>营业编号:'),
              placeholder:'',
              value:"",
              isTips:false,
              tips:this.$t('输入证件号码'),
              isButton:false,
            },
            cardPic:{
              type:'updata-image',
              name:this.$t('证件上传:'),
              placeholder:'',
              value:"",
              isTips:false,
              tips:this.$t('上传证件'),
              isButton:false,
              picList:[],
              maxLength:1,
              shili:'assets/image/login/Bitmap_qy-card@2x.png',
              Success:(ev)=>{
                this.$apis.sc.upload(ev.raw,(progress,p)=>{
                }).then(res=>{
                  this.forms[2].cardPic.picList = [res]
                })
              },
              deleteImg:(i)=>{
                this.forms[2].cardPic.picList = []
              }
            },
            holdCertificateImg:{
              type:'updata-image',
              name:this.$t('法人手持<br>证件上传:'),
              placeholder:'',
              value:"",
              isTips:false,
              tips:this.$t('上传法人手持证件上传'),
              isButton:false,
              picList:[],
              maxLength:1,
              shili:'assets/image/login/Bitmap_qy@2x.png',
              Success:(ev)=>{
                this.$apis.sc.upload(ev.raw,(progress,p)=>{
                }).then(res=>{
                  this.forms[2].holdCertificateImg.picList = [res]
                })
              },
              deleteImg:(i)=>{
                this.forms[2].holdCertificateImg.picList = []
              }
            },
            manageName:{
              type:'input',
              name:this.$t('公众号<br>负责人:'),
              placeholder:this.$t('名字'),
              value:"",
              isTips:false,
              tips:this.$t('输入负责人名字'),
              isButton:false,
            },
            phone:{
              type:'input',
              name:this.$t('负责人<br>手机绑定:'),
              placeholder:this.$t('绑定公众号负责人员的手机号码'),
              value:"",
              isTips:true,
              tips:this.$t('输入负责人的手机号码'),
              isButton:true,
              loading:false,
              disabled:false,
              buttonText:this.$t("发送验证码"),
              buttonFun:this.sendCode
            },
            code:{
              type:'input',
              name:this.$t('验证码:'),
              placeholder:this.$t('6位验证码'),
              value:"",
              isTips:false,
              tips:this.$t('6位验证码'),
              isButton:false,
            },
          }
        }
      }
    },
    created(o) {
      this.userType = this.$route.query.type
      this.$apis.user.getOfficialAccountInfo().then(res=>{
        let info = res.data.accountInfoItem
        if(info.attestation == 1){
          this.$router.push('/reg/infoRegister')
        }
      })
    },
    mounted() {
      
    },
    computed:{
    },
    methods:{
      sendCode(){
        let type = this.userType
        if(!this.forms[type].phone.value.trim()){
          this.forms[type].phone.isTips = true
          return
        }
       // let reg = /^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/;
	   let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
        if(!reg.test(this.forms[type].phone.value)){
          this.$message.error(this.$t('请输入正确的手机号'));
          return
        }
        if(!this.forms[type].phone.disabled){
          this.forms[type].phone.loading = true
          this.$apis.login.sendSms({account:this.forms[type].phone.value}).then(res=>{
            setTimeout(()=>{
              this.forms[type].phone.loading = false
              this.$message.success(this.$t('验证码已发送，请注意查收'));
              this.forms[type].phone.disabled = true
              let timeNum = 60
              this.forms[type].phone.buttonText = timeNum + this.$t("秒后重试")
              let timer = setInterval(()=>{
                this.forms[type].phone.buttonText = timeNum + this.$t("秒后重试")
                timeNum -- 
                if(timeNum <= 0){
                  clearInterval(timer)
                  this.forms[type].phone.buttonText = this.$t("发送验证码")
                  this.forms[type].phone.disabled = false
                }
              },1000)
            },500)
            
          }).catch(err=>{
            this.forms[type].phone.loading = false
          })
        }
      },
      // 验证所有字段是否有输入
      inspectKey(key,i){
        if( this.forms[i][key].type == 'updata-image'){
          if(this.forms[i][key].picList.length < this.forms[i][key].maxLength){
            this.forms[i][key].isTips = true
            return true
          }else{
            this.forms[i][key].isTips = false
            return false
          }
        }else if(this.forms[i][key].type == "dropdown"){
          return false
        }else{
          if(!this.forms[i][key].value.trim()){
            this.forms[i][key].isTips = true
            return true
          }else{
            this.forms[i][key].isTips = false
            return false
          }
        }
      },
      submit(){
        let type = this.userType
        this.isInspect = false
        for (let key in this.forms[type]) {
          if(this.inspectKey(key,type)){
            this.isInspect = true
          }
        }
        if(!this.isInspect){
          // 验证验证码的正确
          this.$loading.next = true
          let data = {
            account:this.forms[type].phone.value,
            smsCode:this.forms[type].code.value
          }
          this.$apis.login.validateSms(data).then(res=>{
            setTimeout(()=>{
              this.$loading.next = false
              
              
              let userData = {
                "certificateImg": JSON.stringify(this.forms[type].cardPic.picList),
                "certificateNum": this.forms[type].cardId.value,
                "headImg": "",
                "holdCertificateImg": JSON.stringify(this.forms[type].holdCertificateImg.picList),
                "id": 0,
                "introduce": "",
                "name": this.forms[type].username.value,
                "nickname": "",
                "phone": this.forms[type].phone.value,
                "type": type
              }
              if(type == 2){
                userData.manageName = this.forms[2].manageName.value
              }
              if(type == 1){
                userData.certificateType = this.forms[1].cardType.list[this.forms[1].cardType.current].key
              }
              /*
                certificateImg (string, optional): 证件url集合（JSON字符串数组） ,
                certificateNum (string, optional): 证件号码/企业营业编号 ,
                certificateType (integer, optional): 证件类型 1医保卡 2社保卡 （个人） ,
                headImg (string, optional): 公众号头像url ,
                holdCertificateImg (string, optional): 手持证件url集合（JSON字符串数组） ,
                id (integer, optional): 公众号id ,
                introduce (string, optional): 公众号简介 ,
                manageName (string, optional): 管理员姓名（企业） ,
                name (string, optional): 姓名（个人）/企业/机构/组织名称 ,
                nickname (string, optional): 公众号昵称 ,
                phone (string, optional): 手机号 ,
                type (integer, optional): 认证类型——1个人 2企业
              */
                       
              this.$setData('accountInfo',userData)
              this.$router.push('/reg/infoRegister')
            },500)
          }).catch(err=>{
            this.$loading.next = false
          })
        }
        
        // this.$router.push('/reg/infoRegister')
      },
      beforeAvatarUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 5MB!');
        }
        
        if(isLt2M){
          let loading = this.loading(this.$t("上传中"))
          setTimeout(()=>{
            loading.close()
          },1000)
        }
        return isLt2M;
      },
    }
  }
</script>

<style lang="scss">
  .reg_info{
    padding: 15px 27px 0 44px;
    display: flex;
    flex-direction: column;
    height: 100%;
    .card{
      background-color: #FFFFFF;
      border-radius: 7px;
      padding-bottom: 25px;
      .form{
        .item{
          margin-top: 26px;
        }
        .input-name{
          width: 6em;
          height: 34px;
        }
        .input-tips{
          width: 6em;
        }
        .form-item{
          
          .images{
            width: 816px;
            .image-uploader{
              width: 162px;
              height: 114px;
              margin-right: 18px;
              position: relative;
              .delete{
                width: 17px;
                height: 17px;
                position: absolute;
                top: -8px;
                right: -8px;
              }
            }
          }
          
          .input{
            width: 816px;
            height: 34px;
            border-radius: 3px;
            border: 1px solid #DBDBE7;
            padding-left: 11px;
            .button{
              width: 84px;
			  background-color: #427CDF;
              &.disabled{
                background-color: #DCDCDC;
              }
            }
            .icon{
              width: 9px;
              height: 9px;
              margin-right: 12px;
            }
            &.un-input{
              border: none;
              padding-left: 0;
              font-size: 15px;
              line-height: 21px;
              font-weight: 700;
              position: relative;
              &::after{
                content: '';
                display: block;
                position: absolute;
                bottom: -8px;
                margin-top: 16px;
                width: 816px;
                height: 1px;
                background-color: rgba(151,151,151,0.13);
              }
            }
          }
          
        }
      }
      
      .submit{
        width: 350px;
        height: 35px;
        border-radius: 30px;
        margin-top: 29px;
		background-color: #427CDF;
      }
      
    }
  }
</style>

