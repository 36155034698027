<template>
  <div class="userInfo">
    <navBar :list="navBar.list" :current.sync="navBar.current" @change="navBarChange"></navBar>
    <!-- 基本资料 -->
    <card class="Card" v-if="navBar.current == 0">
      <div class="item ai-top row" v-for="(item,index) of info.basicInfo" :key="index">
        <div class="row Regular item-name" v-html="item.name"></div>
        <div class="avatar-box w-100 row js-center" v-if="item.type == 'avatar'">
          <el-upload
            ref="uploadImage"
            class="image-uploader"
            name="upload"
            accept="image/*"
            :show-file-list="false"
            :on-change="onImageFileChange"
            :auto-upload="false"
            action=""
            >
            <div class="avatar pos-rel pointer">
              <img class="image-avatar" :src="item.info" >
              <div class="icon"></div>
            </div>
          </el-upload>
        </div>
        <div class="row flex-1 item-info" v-if="item.type == 'text'">
          <div class="info flex-1">{{item.info}}</div>
          <div class="link pointer" v-if="item.link" @click="item.link(index)">{{item.linkText}}</div>
        </div>
        <div class="row flex-1 item-info" v-if="item.type == 'image'">
          <div class="info images flex-1">
            <img class="image" :src="item.info" >
          </div>
          <div class="link pointer" v-if="item.link" @click="item.link(index)">{{item.linkText}}</div>
        </div>
      </div>
      
      <div class="btns row js-center" v-if="info.infoIsEdit || info.dataState == 1">
        <Button class="button" :loading="info.submitLoading" @click="submitInfo">{{info.dataState == 0 ? '提交'.$t():''}}{{info.dataState == 1 ? '审核中'.$t():''}}</Button>
      </div>
      
    </card>
    <!-- 认证 -->
    <card class="Card" v-if="navBar.current == 1">
      <div class="item ai-top row" v-for="(item,index) of info.authInfo[0]" :key="'g'+index" v-if="type == 1">
        <div class="row Regular item-name" v-html="item.name"></div>
        <div class="row flex-1 item-info" v-if="item.type == 'text'">
          <div class="info">{{item.info}}</div>
          <div class="link"></div>
        </div>
        <div class="row flex-1 item-info" v-if="item.type == 'image'">
          <div class="info images">
            <img class="image" :src="item.info">
          </div>
        </div>
      </div>
      <div class="item ai-top row" v-for="(item,index) of info.authInfo[1]" :key="'q'+index" v-if="type == 2">
        <div class="row Regular item-name" v-html="item.name"></div>
        <div class="row flex-1 item-info" v-if="item.type == 'text'">
          <div class="info">{{item.info}}</div>
          <div class="link"></div>
        </div>
        <div class="row flex-1 item-info" v-if="item.type == 'image'">
          <div class="info images">
            <img class="image" :src="item.info">
          </div>
        </div>
      </div>
    </card>
    
    
    <!-- 裁剪 -->
    <popup style="z-index: 9998;" ref="cropperPopup">
      <card class="Card-cropper">
        <div class="cropper-title">修改头像</div>
        <div class="cropper-disc Regular">上下拖动选择框，选中封面展示区域</div>
        <div class="cropper-box_">
          <vueCropper
            ref="cropper"
            :img="option.img"
            :outputSize="option.size"
            :outputType="option.outputType"
            :info="true"
            :full="option.full"
            :canMove="option.canMove"
            :canMoveBox="option.canMoveBox"
            :original="option.original"
            :autoCrop="option.autoCrop"
            :fixed="option.fixed"
            :fixedNumber="option.fixedNumber"
            :centerBox="option.centerBox"
            :infoTrue="option.infoTrue"
            :fixedBox="option.fixedBox"
          ></vueCropper>
        </div>
        <div class="btns row js-center">
          <Button class="button button-ho" @click="cropperPopupClose">取消</Button>
          <Button class="button" :loading="option.loading" @click="cropperPopupSuccess">完成</Button>
        </div>
      </card>
    </popup>
    
  </div>
</template>

<script>
  export default {
    name: 'userInfo',
    data(){
      return {
        option:{
          img: '', // 裁剪图片的地址
          info: true, // 裁剪框的大小信息
          outputSize: 0.8, // 裁剪生成图片的质量
          outputType: 'jpeg', // 裁剪生成图片的格式
          canScale: false, // 图片是否允许滚轮缩放
          autoCrop: true, // 是否默认生成截图框
          autoCropWidth: 230, // 默认生成截图框宽度
          autoCropHeight: 230, // 默认生成截图框高度
          fixed: true, // 是否开启截图框宽高固定比例
          fixedNumber: [1, 1], // 截图框的宽高比例
          fixedBox: false, // 固定截图框大小 不允许改变
          full: true, // 是否输出原图比例的截图
          canMoveBox: true, // 截图框能否拖动
          original: false, // 上传图片按照原始比例渲染
          centerBox: false, // 截图框是否被限制在图片里面
          infoTrue: false ,// true 为展示真实输出图片宽高 false 展示看到的截图框宽高
          maxImagSize:500,
          name:"",
          loading:false
        },
        navBar:{
          list:[
            {
              name:this.$t('基本资料'),
              key:0,
            },
            {
              name:this.$t('认证信息'),
              key:1,
            }
          ],
          current:0
        },
        type:1,
        info:{
          infoIsEdit:false,
          dataState:0,
          submitLoading:false,
          basicInfo:[
            {
              type:'avatar',
              name:'',
              info:''
            },
            {
              type:'text',
              name:'账号：',
              info:''
            },
            {
              type:'text',
              name:'公众号昵称：',
              info:'',
              link:this.navLink,
              linkText:'修改昵称'
            },
            {
              type:'text',
              name:'公众号ID：',
              info:''
            },
            {
              type:'text',
              name:'二维码：',
              info:'下载二维码，分享给联系人，可通过“妈妈创业营”扫码识别 公众号',
              qrCode:'',
              link:this.navLink,
              linkText:'下载二维码'
            },
            {
              type:'text',
              name:'简介：',
              info:'',
              link:this.navLink,
              linkText:'修改简介'
            },
            {
              type:'text',
              name:'注册时间：',
              info:''
            },
          ],
          authInfo:[
            [
              {
                type:'text',
                name:'认证类型：',
                info:''
              },
              {
                type:'text',
                name:'名字：',
                info:'',
              },
              {
                type:'text',
                name:'证件号码：',
                info:''
              },
              {
                type:'image',
                name:'证件上传：',
                info:'',
              },
              {
                type:'image',
                name:'本人手持<br>证件上传：',
                info:'',
              },
              {
                type:'text',
                name:'联系电话：',
                info:''
              },
            ],
            [
              {
                type:'text',
                name:'认证类型：',
                info:''
              },
              {
                type:'text',
                name:'企业名字：',
                info:'',
              },
              {
                type:'text',
                name:'营业编号：',
                info:''
              },
              {
                type:'image',
                name:'证件上传：',
                info:'',
              },
              {
                type:'image',
                name:'法人手持<br>证件上传：',
                info:'',
              },
              {
                type:'text',
                name:'公众号负<br>责人：',
                info:''
              },
              {
                type:'text',
                name:'联系电话：',
                info:''
              },
            ]
          ]
        }
      }
    },
    created() {
      this.init()
    },
    mounted() {
      
    },
    computed:{
      userInfo(){
        return this.$store.getters.userInfo
      }
    },
    methods:{
      init(){
        this.$apis.user.getOfficialAccountInfo().then(res=>{
          try{
            this.info.basicInfo[0].info = JSON.parse(res.data.accountInfoItem.headImg)
          }catch(e){
            this.info.basicInfo[0].info = res.data.accountInfoItem.headImg
          }
          
          this.info.infoIsEdit = false
          this.info.dataState = res.data.accountInfoItem.dataState || 0 
          
          this.info.basicInfo[1].info = res.data.accountInfoItem.email 
          this.info.basicInfo[2].info = res.data.accountInfoItem.nickname
          this.info.basicInfo[3].info = res.data.accountInfoItem.code
          this.info.basicInfo[4].qrCode = res.data.accountInfoItem.qRCode
          this.info.basicInfo[5].info = res.data.accountInfoItem.introduce 
          this.info.basicInfo[6].info = this.$getDate(res.data.accountInfoItem.createdAt)
          
          this.type = res.data.accountInfoItem.type
          
          if(res.data.accountInfoItem.type == 1){
            this.info.authInfo[0][0].info = res.data.accountInfoItem.type == 1 ?'个人':"企业"
            this.info.authInfo[0][1].info = this.VForm(res.data.accountInfoItem.name)
            this.info.authInfo[0][2].info = this.VForm(res.data.accountInfoItem.certificateNum)
            try{
              this.info.authInfo[0][3].info = JSON.parse(res.data.accountInfoItem.certificateImg)[0]
              this.info.authInfo[0][4].info = JSON.parse(res.data.accountInfoItem.holdCertificateImg)[0]
            }catch(e){
              //TODO handle the exception
              this.info.authInfo[0][3].info = res.data.accountInfoItem.certificateImg
              this.info.authInfo[0][4].info = res.data.accountInfoItem.holdCertificateImg
            }
            this.info.authInfo[0][5].info = this.VForm(res.data.accountInfoItem.phone)
          }
          if(res.data.accountInfoItem.type == 2){
            this.info.authInfo[1][0].info = res.data.accountInfoItem.type == 1 ?'个人':"企业"
            this.info.authInfo[1][1].info = this.VForm(res.data.accountInfoItem.name)
            this.info.authInfo[1][2].info = this.VForm(res.data.accountInfoItem.certificateNum)
            try{
              this.info.authInfo[1][3].info = JSON.parse(res.data.accountInfoItem.certificateImg)[0]
              this.info.authInfo[1][4].info = JSON.parse(res.data.accountInfoItem.holdCertificateImg)[0]
            }catch(e){
              this.info.authInfo[1][3].info = res.data.accountInfoItem.certificateImg
              this.info.authInfo[1][4].info = res.data.accountInfoItem.holdCertificateImg
            }
            this.info.authInfo[1][5].info = res.data.accountInfoItem.manageName
            this.info.authInfo[1][6].info = this.VForm(res.data.accountInfoItem.phone)
          }
          
          
          let storeUserinfo = this.userInfo
          storeUserinfo.headImg = this.info.basicInfo[0].info
          storeUserinfo.nickname = this.info.basicInfo[2].info
          storeUserinfo.introduce = this.info.basicInfo[5].info
          this.$store.commit('userInfoSet',storeUserinfo)
        })
      },
      navBarChange(){},
      navLink(ev){
        if(ev == 2){
          this.$prompt('请输入昵称', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }).then(({ value }) => {
            this.info.basicInfo[2].info = value
            this.info.infoIsEdit = true
          })
        }
        if(ev == 4){
          if(this.info.basicInfo[4].qrCode){
            this.$message.info("下载中")
            function base64ToBlob(code) {
              let parts = code.split(';base64,');
              let contentType = parts[0].split(':')[1];
              let raw = window.atob(parts[1]);
              let rawLength = raw.length;
              let uInt8Array = new Uint8Array(rawLength);
              for(let i = 0; i < rawLength; ++i) {
                  uInt8Array[i] = raw.charCodeAt(i);
              }
              return new Blob([uInt8Array], {
                  type: contentType
              });
            }
            let evt = document.createEvent("HTMLEvents");
            evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
            let imageFile = URL.createObjectURL(base64ToBlob(this.info.basicInfo[4].qrCode));
            let alink = document.createElement("a");
            alink.download = '公众号二维码.png'
            alink.href = imageFile;
            alink.click();
          }
        }
        if(ev == 5){
          this.$prompt('请输入简介', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }).then(({ value }) => {
            this.info.basicInfo[5].info = value
            this.info.infoIsEdit = true
          }).catch(() => {
          });
        }
      },
      onImageFileChange(ev){
        
        
        if(this.beforeUpload(ev.raw)){
          this.option.img = window.URL.createObjectURL(new Blob([ev.raw]))
          this.$refs.cropperPopup.open()
          this.option.name = ev.name
        }
      },
      cropperPopupClose(){
        this.$refs.cropperPopup.close()
      },
      cropperPopupSuccess(){
        // let loading = this.loading('上传中……')
        this.option.loading = true
        this.$refs.cropper.getCropBlob((data) => {
          let fileData = new window.File([data],this.option.name)
          this.$apis.sc.upload(fileData,(e)=>{
            console.log(e);
          }).then(res=>{
            this.option.loading = false
            this.$refs.cropperPopup.close()
            this.info.basicInfo[0].info = res
            this.info.infoIsEdit = true
          }).catch(err=>{
            this.$message.error('上传异常')
            // loading.close()
            this.option.loading = false
          })
          
          
          
        })
      },
      
      // 提交资料审核
      submitInfo(){
        if(this.info.dataState == 1){
          this.$message.info('请等待审核结束后修改')
          return
        }
        console.log(this.info.basicInfo);
        let data = {
          introduce:this.info.basicInfo[5].info,
          nickname:this.info.basicInfo[2].info,
          headImg:this.info.basicInfo[0].info.toString()
        }
        this.info.submitLoading = true
        this.$apis.user.renewAccountInfo(data).then(res=>{
          this.$message.success('修改资料已提交，请等待审核'.$t())
          this.info.submitLoading = false
          this.init()
        })
      },
      
      beforeUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 5;
      
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 5MB!');
        }
        return isLt2M;
      },
      VForm(val){
        let strArr = val.split('')
        if(strArr.length <= 5){
          if(strArr.length > 1){
            for(let i = 1;i<strArr.length;i++){
              strArr[i] = '*'
            }
          }
          
          return strArr.join('')
        }
        if(strArr.length == 11){
          strArr[3] = '*'
          strArr[4] = '*'
          strArr[5] = '*'
          strArr[6] = '*'
          return strArr.join('')
        }
        if(strArr.length > 11){
          for(let i = 7;i<=13;i++){
            strArr[i] = '*'
          }
          return strArr.join('')
        }else{
          for(let i = 3;i<=6;i++){
            strArr[i] = '*'
          }
          return strArr.join('')
        }
        
      }
    }
  }
</script>

<style lang="scss">
.userInfo{
  .Card{
    padding-left: 18px;
    padding-bottom: 24px;
    .item{
      .item-name{
        color: #666A82;
        padding: 30px 0 16px;
        width: 6em;
      }
      .avatar-box{
        .avatar{
          width: 85px;
          height: 85px;
          border-radius: 100%;
          overflow: hidden;
          .image-avatar{
            
          }
          .icon{
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.24);
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            &::after{
              content: '';
              display: inline-block;
              background-image: url(../../../assets/image/user/camera@2x.png);
              background-repeat: no-repeat;
              background-size: 100% 100%;
              width: 15px;
              height: 13px;
            }
          }
        }
      }
      .item-info{
        margin-left: 18px;
        border-bottom: 1px solid rgba(151,151,151,0.13);
        padding: 30px 0 16px;
        .link{
          color: #1564B9;
          margin-right: 24px;
        }
        .info{
          color: #3B3F56;
          font-size: 15px;
          line-height: 21px;
          &.images{
            .image{
              width: 161px;
              height: 114px;
            }
          }
        }
      }
    }
    .btns{
      margin-top: 24px;
      .button{
        width: 120px;
        height: 34px;
        border-radius: 5px;
      }
    }
  }
  
  .Card-cropper{
    width: 588px;
    height: 512px;
    padding: 28px;
    .cropper-title{
      font-size: 15px;
      color: #3B3F56;
      line-height: 21px;
    }
    .cropper-disc{
      font-size: 14px;
      color: #3B3F56;
      line-height: 19px;
      margin-top: 4px;
    }
    .cropper-box_{
      width: 446px;
      height: 321px;
      margin-top: 36.7px;
      margin-left: 29px;
    }
    
    .btns{
      margin-top: 31px;
      .button{
        width: 125px;
        height: 32px;
        border-radius: 5px;
        margin: 0 15px;
        &.button-ho{
          border-color: #FFAE2E;
          color: #FFAE2E;
        }
      }
    }
  }
}  
</style>
