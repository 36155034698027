<template>
  <div class="myFans">
    <navBar :list="navBar.list" :current.sync="navBar.current"></navBar>
    <!-- 粉丝 -->
    <card class="Card" v-if="navBar.current == 0">
      <div class="list">
        <div class="list-item row" v-for="(item,index) of list" :key="index">
          <div class="avatar"><img :src="item.headImg" ></div>
          <div class="user-name flex-1">{{item.userName}}{{item.remarkName?'('+item.remarkName+')':''}}</div>
          <Button class="button-ho" :loading="item.loading" @click="setFansState(item,index,1)">{{$t("拉黑")}}</Button>
          <Button class="button-ho" :ref="'updateName_'+index" @click="setNickName($event,index)">{{$t("修改备注")}}</Button>
        </div>
      </div>
      <loadMore :status="moreStatus" @more="scrollBottom"></loadMore>
    </card>
    <!-- 黑名单 -->
    <card class="Card" v-if="navBar.current == 1">
      <div class="list">
        <div class="list-item row" v-for="(item,index) of list" :key="index">
          <div class="avatar"><img :src="item.headImg" ></div>
          <div class="user-name flex-1">{{item.userName}}{{item.remarkName?'('+item.remarkName+')':''}}</div>
          <Button class="button-ho" :loading="item.loading" @click="setFansState(item,index,2)">{{$t("取消拉黑")}}</Button>
        </div>
      </div>
      <loadMore :status="moreStatus" @more="scrollBottom"></loadMore>
    </card>
    
    
    <!-- 修改备注弹窗 -->
    <div class="popover" v-if="visible">
      <div class="popup-box" :style="popupStyle">
        <div class="title Regular">备注名</div>
        <div class="input row">
          <input type="text" class="input-in flex-1" maxlength="32" v-model="nickName"/>
          <span class="string-length">{{nickName.length}}/32</span>
        </div>
        <div class="popup-btns row js-center">
          <Button class="button button-ho" @click="visible = false">取消</Button>
          <Button class="button" :loading="loading.remarkName" @click="setRemarkName">确定</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import navBar from "@/components/navBar.vue"
  import card from "@/components/card.vue"
  export default {
    name: 'myFans',
    components:{
      navBar,
      card
    },
    data(){
      return {
        navBar:{
          list:[
            {
              name:"",
              key:1
            },
            {
              name:'',
              key:2
            }
          ],
          current:0
        },
        visible:false,
        popupStyle:{
          right:0,
          top:0
        },
        nickName:"",
        loading:{
          remarkName:false
        },
        fansTotal:0,
        blacklistTotal:0,
        page:1,
        total:0,
        count:0,
        list:[],
        moreStatus:'more',
        remarkNameData:{},
        search:''
      }
    },
    created() {
      this.$store.commit('scrollBottomSet',this.scrollBottom)
      this.$store.commit('searchSet',this.Search)
      this.init()
    },
    watch:{
      'navBar.current'(){
        this.page = 1
        this.init()
      }
    },
    mounted() {
      
    },
    computed:{
      
    },
    methods:{
      init(){
        let data = {
          pageNum:this.page,
          pageSize:20,
          type:this.navBar.list[this.navBar.current].key,
          userName:this.search
        }
        this.moreStatus = 'loading'
        this.$apis.fs.getFansList(data).then(res=>{
          this.navBar.list[0].name = this.$t('我的粉丝') + `(${res.data.fansTotal})`
          this.navBar.list[1].name = this.$t('黑名单') + `(${res.data.blacklistTotal})`
          this.count = res.data.total
          this.total = parseInt(this.count / 20) +1
          res.data.userFollows = res.data.userFollows.map(item=>{
            item.loading = false
            return item
          })
          if(this.page == 1){
            this.list = res.data.userFollows
          }else{
            this.list = [...this.list,...res.data.userFollows]
          }
          
          if(this.page >= this.total){
            this.moreStatus = 'no-more'
          }else{
            this.moreStatus = 'more'
          }
        })
      },
      Search(val){
        this.search = val
        this.page = 1
        this.init()
      },
      setFansState(item,index,type){
        let title
        if(type == 1){
          title = this.$t('确定拉黑该粉丝吗?')
        }else{
          title = this.$t('确定取消拉黑该粉丝吗?')
        }
        this.$confirm(title, this.$t('提示'), {
           confirmButtonText: this.$t('确定'),
           cancelButtonText: this.$t('取消'),
           type: 'warning'
        }).then(() => {
           let data = {
             id:item.id,
             type:type
           }
           this.list[index].loading = true
           this.$apis.fs.setFansState(data).then(res=>{
             this.list[index].loading = false
             this.page = 1
             this.init()
           }).catch(err=>{
             this.list[index].loading = false
           })
        })
        
      },
      // 修改备注
      setRemarkName(){
        if(!this.nickName.trim()){
          this.$message.error(this.$t("请输入备注名"))
          return
        }
        this.loading.remarkName = true
        let data = {
          id:this.remarkNameData.id,
          remarkName:this.nickName.trim()
        }
        this.$apis.fs.setRemarkName(data).then(res=>{
          this.loading.remarkName = false
          this.list[this.remarkNameData.index].remarkName = this.nickName
          this.$message.success(this.$t("修改成功"))
          this.visible = false
        }).catch(err=>{
          this.loading.remarkName = false
        })
      },
      setNickName(ev,index){
        let btnRect = this.$refs['updateName_'+index][0].$el.getBoundingClientRect()
        this.list[index].index = index
        this.remarkNameData = this.list[index]
        this.nickName = ''
        
        this.popupStyle.right = (window.innerWidth - btnRect.right) +'px'
        
        if(window.innerHeight - btnRect.bottom - 10 < 137){
          this.popupStyle.top = btnRect.top - 137 - 10 + 'px'
        }else{
          this.popupStyle.top = btnRect.bottom + 10 + 'px'
        }
        
        // this.popupStyle.top = btnRect.bottom + 10 + 'px'
        this.visible = true
      },
      scrollBottom(){
        if(this.page < this.total){
          this.page ++
          this.init()
        }
      }
    }
  }
</script>

<style lang="scss">
  .myFans{
    .Card{
      min-height: 400px;
    }
    .list{
      padding: 5px 20px 20px;
      .list-item{
        padding: 17px 0 20px 0;
        border-bottom: 1px solid rgba(219,219,231,0.26);
        .avatar{
          width: 51px;
          height: 51px;
          border-radius: 100%;
          overflow: hidden;
          background-color: #fafafa;
        }
        .user-name{
          padding: 0 14px;
        }
        .button-ho{
          width: 81px;
          height: 32px;
          margin-left: 20px;
        }
      }
    }
    
    .popover{
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999;
      // background-color: rgba(0,0,0,0.01);
      .popup-box{
        width: 290px;
        height: 137px;
        background-color: #FFFFFF;
        position: absolute;
        box-shadow: 0px 2px 9px 0px rgba(59, 63, 86, 0.1);
        padding: 12px 15px 0;
        border-radius: 5px;
        .title{
          font-size: 12px;
          color: #3B3F56;
          line-height: 17px;
        }
        .input{
          height: 34px;
          border-radius: 3px;
          border: 1px solid #DBDBE7;
          margin-top: 14px;
          .input-in{
            padding-left: 12px;
          }
          .string-length{
            font-size: 12px;
            color: #C8CAD6;
            line-height: 17px;
            margin-right: 15px;
          }
        }
        .popup-btns{
          margin-top: 14px;
          .button{
            width: 90px;
            height: 32px;
            border-radius: 5px;
            margin: 0 13px;
          }
        }
      }
    }
    
  }
</style>
