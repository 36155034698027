<template>
  <div class="index">
    
    <div class="tag-card row">
      <navLink class="tag-card-item row js-around flex-1" path="/leaveComments">
        <div class="info-text ">
          <div class="info-number">{{ Total.message + $store.state.radeMsgNumber }}</div>
          <div class="info-name">{{$t("新消息")}}</div>
        </div>
        <div class="icon"><img src="assets/image/home/news@2x.png" ></div>
      </navLink>
      <navLink class="tag-card-item row js-around flex-1" path="/myFans">
        <div class="info-text ">
          <div class="info-number">{{ Total.fansNumber  }}</div>
          <div class="info-name">{{$t("粉丝数")}}</div>
        </div>
        <div class="icon"><img src="assets/image/home/people@2x.png" ></div>
      </navLink>
      <navLink class="tag-card-item row js-around flex-1" path="/myFans">
        <div class="info-text ">
          <div class="info-number">{{ Total.todayFansNumber }}</div>
          <div class="info-name">{{$t("今日新增粉丝")}}</div>
        </div>
        <div class="icon"><img src="assets/image/home/fans@2x.png" ></div>
      </navLink>
      <navLink class="tag-card-item row js-around flex-1" path="/myCreation">
        <div class="info-text ">
          <div class="info-number">{{ Total.articleNumber }}</div>
          <div class="info-name">{{$t("文章数")}}</div>
        </div>
        <div class="icon"><img src="assets/image/home/article@2x.png" ></div>
      </navLink>
    </div>
    
    <navBar :list="navBar.list" :current.sync="navBar.current" @change="navBarChange">
      <div class="times row js-right flex-1">
        <div class="Previous row js-center pointer" :class="{disabled:times.current == 0}" @click="timeChange('-')"></div>
        <div class="item-time" v-for="(item,index) of times.list" :key="index" v-if="times.current == index">{{item.name}}</div>
        <div class="next row js-center pointer"  :class="{disabled:times.current == times.list.length - 1}" @click="timeChange('+')"></div>
      </div>
    </navBar>
    
    <div class="charts-card">
      <div ref="echarts" class="echarts" v-if="echartsView"></div>
    </div>
    
  </div>
</template>

<script>
  import navBar from "@/components/navBar.vue"
  export default {
    name: 'Header',
    components:{
      navBar
    },
    data(){
      return {
        navBar:{
          list:[
            {
              name:this.$t('公众号访问量(次数)'),
              key:1,
              data:{}
            },
            {
              name:this.$t('粉丝增长(人)'),
              key:2,
              data:{}
            },
            {
              name:this.$t('访问人数(人)'),
              key:3,
              data:{}
            },
          ],
          current:0
        },
        times:{
          list:[
            {
              name:"7天",
              key:1
            },
            {
              name:"15天",
              key:2
            },
            {
              name:"30天",
              key:3
            }
          ],
          current:0
        },
        Total:{},
        echartsView:true,
        Chart:null
      }
    },
    created() {
      this.init()
    },
    mounted() {
      this.Chart = this.echarts.init(this.$refs.echarts)
      window.addEventListener('resize', ()=> {
        // 对每个拖拽圆点重新计算位置，并用 setOption 更新。
        this.Chart.resize()
        // this.getChart()
      });
    },
    computed:{
      
    },
    methods:{
      init(){
        this.$apis.home.getHomeTotal().then(res=>{
          this.Total = res.data.homeTotalItem
        })
        this.getHomeFigureData()
        
        this.IM.$connectReady(()=>{
          this.IM.getConversationList(0).then(res=>{
            if(res.code == 0){
              let radeMsgNumber = 0
              res.data.map(item=>{
                radeMsgNumber += item.unreadMessageCount
              })
              this.$store.commit('radeMsgNumberSet',radeMsgNumber)
            }
          })
        })
        
      },
      getHomeFigureData(){
        // type (integer, optional): 类型 1近7天 2近15天 3近30天
        this.$apis.home.getHomeFigureData({type:this.times.list[this.times.current].key}).then(res=>{
          res.data.figureDataModel.map((item,index)=>{
            this.navBar.list[index].data = item
          })
          this.getChart()
        })
      },
      timeChange(t){
        if(t == '-'){
          if(this.times.current){
            this.times.current --
            this.getHomeFigureData()
          }
        }
        if(t == '+'){
          if(this.times.current < this.times.list.length - 1){
            this.times.current ++
            this.getHomeFigureData()
          }
        }
      },
      navBarChange(ev){
        this.getChart()
      },
      getChart(){
        let option = {
          xAxis: {
            type: 'category',
            data: this.navBar.list[this.navBar.current].data.strDayList
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              data: this.navBar.list[this.navBar.current].data.strNumber,
              type: 'line',
              smooth: true,
              color:"#FFAE2E",
              itemStyle : { normal: {label : {show: true}}}
            }
          ],
        };
        this.Chart.setOption(option,true);
      }
    }
  }
</script>

<style lang="scss">
  .index{
    
    .tag-card{
      margin-top: 17px;
      .tag-card-item{
        background-color: #FFFFFF;
        height: 85px;
        box-shadow: 0px 2px 3px 0px rgba(220,231,239,0.5);
        border-radius: 5px;
        & + .tag-card-item{
          margin-left: 14px;
        }
        .info-text{
          .info-number{
            font-size: 31px;
            color: #3B3F56;
            line-height: 37px;
            font-weight: bold;
            text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
            text-align: center;
          }
          .info-name{
            margin-top: 4px;
            font-size: 14px;
            color: #3B3F56;
            line-height: 19px;
            text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
            text-align: center;
          }
        }
        .icon{
          width: 50px;
          height: 50px;
          border-radius: 100%;
        }
      }
    }
    
    .times{
      padding-right: 15px;
      .Previous{
        width: 26px;
        height: 26px;
        box-shadow: 0 2px 3px 0 #DCE7EF;
        border-radius: 5px;
        &::after{
          content: '';
          display: inline-block;
          border: 5px solid rgba(0,0,0,0);
          border-right-color: #989BB0;
          transform: translateX(-2.5px);
        }
        &.disabled{
          background-color: #F6F6F7;
          cursor: not-allowed;
          &::after{
            border-right-color: #DBDBE7;
          }
        }
      }
      .item-time{
        width: 61px;
        font-size: 12px;
        color: #3B3F56;
        line-height: 17px;
        text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
        text-align: center;
      }
      .next{
        width: 26px;
        height: 26px;
        box-shadow: 0 2px 3px 0 #DCE7EF;
        border-radius: 5px;
        &::after{
          content: '';
          display: inline-block;
          border: 5px solid rgba(0,0,0,0);
          border-left-color: #989BB0;
          transform: translateX(2.5px);
        }
        &.disabled{
          background-color: #F6F6F7;
          cursor: not-allowed;
          &::after{
            border-left-color: #DBDBE7;
          }
        }
      }
    }
    
    
    .charts-card{
      padding: 12px;
      background-color: #FFFFFF;
      margin-top: 17px;
      box-shadow: 0px 2px 3px 0px rgba(220,231,239,0.5);
      border-radius: 5px;
      height: 378px;
      min-height: 500px;
      .echarts{
        width: 100%;
        height: 354px;
      }
    }
    
  }
  
  
</style>
