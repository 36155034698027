<template>
  <div class="check row pointer" :style="{'--size':size+'px','--min-size':minSize + 'px'}" @click="change">
    <div class="checked row js-center" :class="{active:checked}"></div>
    <div class="label" v-if="label">{{label}}</div>
  </div>
</template>

<script>
  export default {
    name: 'check',
    props:{
      label:{
        type:String,
        default:''
      },
      checked:{
        type:Boolean,
        default:false
      },
      size:{
        type:Number,
        default:18
      }
    },
    data(){
      return {
        
      }
    },
    created() {
      
    },
    mounted() {
      
    },
    computed:{
      minSize(){
        return this.size - 6
      }
    },
    methods:{
      change(){
        this.$emit('update:checked',!this.checkeds)
        this.$emit('change',!this.checked)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .check{
    .label{
      margin-left: 9px;
    }
    .checked{
      width: var(--size);
      height: var(--size);
      border: 1px solid #D2D2D2;
      border-radius: 100%;
      background-color: #FFFFFF;
      &.active{
        border-color: #FFAE2E;
      }
      &.active::after{
        content: '';
        display: inline-block;
        width: var(--min-size);
        height: var(--min-size);
        background-color: #FFAE2E;
        border-radius: 100%;
      }
    }
  }
</style>
