<template>
  <div class="tab-bar">
    <el-menu
      :default-active="activeMenu"
      class="el-menu-vertical"
      background-color="#ffffff"
      text-color="#696B76"
      active-text-color="#FFFFFF"
      router
      unique-opened
      @select="selectMenu"
    >
      <el-menu-item index="/index">
        <div :class="{activeMenu:activeMenu == '/index'}">
          <img class="tab-bar-icon w-14 h-12" src="assets/image/home/icon_home_selected@2x.png" v-if="activeMenu == '/index'">
          <img class="tab-bar-icon w-14 h-12" src="assets/image/home/icon_home@2x.png"  v-else>
          <span slot="title">{{ $t('首页') }}</span>
        </div>
      </el-menu-item>
      <el-menu-item index="/myCreation">
        <div :class="{activeMenu:activeMenu == '/myCreation'}">
          <img class="tab-bar-icon w-10 h-12" src="assets/image/home/icon_create_selecte@2x.png" v-if="activeMenu == '/myCreation'">
          <img class="tab-bar-icon w-10 h-12" src="assets/image/home/icon_create@2x.png"  v-else>
          <span slot="title">{{ $t('我的创作') }}</span>
        </div>
      </el-menu-item>
      <el-menu-item index="/draftBox">
        <div :class="{activeMenu:activeMenu == '/draftBox'}">
          <img class="tab-bar-icon w-12 h-12" src="assets/image/home/icon_box_selected@2x.png" v-if="activeMenu == '/draftBox'">
          <img class="tab-bar-icon w-12 h-12" src="assets/image/home/icon_box@2x.png"  v-else>
          <span slot="title">{{ $t('草稿箱') }}</span>
        </div>
      </el-menu-item>
      <el-menu-item index="/myMaterialLibrary">
        <div :class="{activeMenu:activeMenu == '/myMaterialLibrary'}">
          <img class="tab-bar-icon w-12 h-10" src="assets/image/home/icon_materia_selected@2x.png" v-if="activeMenu == '/myMaterialLibrary'">
          <img class="tab-bar-icon w-12 h-10" src="assets/image/home/icon_material@2x.png"  v-else>
          <span slot="title">{{ $t('我的素材库') }}</span>
        </div>
      </el-menu-item>
      <el-menu-item index="/myFans">
        <div :class="{activeMenu:activeMenu == '/myFans'}">
          <img class="tab-bar-icon w-12 h-10" src="assets/image/home/icon_fans_selected@2x.png" v-if="activeMenu == '/myFans'">
          <img class="tab-bar-icon w-12 h-10" src="assets/image/home/icon_fans@2x.png"  v-else>
          <span slot="title">{{ $t('我的粉丝') }}</span>
        </div>
      </el-menu-item>

      <el-submenu index="6">
        <template slot="title">
          <img class="tab-bar-icon w-14 h-14" src="assets/image/home/icon_workorder@2x.png">
          <span>{{$t('菜单配置与互动')}}</span>
        </template>
        <el-menu-item index="/customizeMenu">
          <div :class="{activeMenu:activeMenu == '/customizeMenu'}">{{$t('自定义菜单')}}</div>
        </el-menu-item>
        <el-menu-item index="/leaveComments">
          <div :class="{activeMenu:activeMenu == '/leaveComments'}">{{$t('留言互动')}}</div>
        </el-menu-item>
        <el-menu-item index="/autoRes">
          <div :class="{activeMenu:activeMenu == '/autoRes'}">{{$t('自动回复')}}</div>
        </el-menu-item>
        <el-menu-item index="/voteData">
          <div :class="{activeMenu:activeMenu == '/voteData'}">{{$t('投票数据')}}</div>
        </el-menu-item>
      </el-submenu>

      <el-submenu index="7">
        <template slot="title">
          <img class="tab-bar-icon w-10 h-12" src="assets/image/home/icon_account@2x.png">
          <span>{{$t('账号安全与设置')}}</span>
        </template>
        <el-menu-item index="/userInfo">
          <div :class="{activeMenu:activeMenu == '/userInfo'}">{{$t('账号资料')}}</div>
        </el-menu-item>
        <el-menu-item index="/userSet">
          <div :class="{activeMenu:activeMenu == '/userSet'}">{{$t('账号管理')}}</div>
        </el-menu-item>
        <el-menu-item index="/userSafe">
          <div :class="{activeMenu:activeMenu == '/userSafe'}">{{$t('账号安全')}}</div>
        </el-menu-item>
        <el-menu-item index="/setUp">
          <div :class="{activeMenu:activeMenu == '/setUp'}">{{$t('功能设置')}}</div>
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: 'TabBar',
  data() {
    return {
      activeMenu:'/index'
    };
  },
  created() {
    this.activeMenu = this.$route.path
  },
  mounted() {},
  computed: {},
  watch:{
    $route(to,from){
      this.activeMenu = this.$route.path
    }
  },
  methods: {
    selectMenu(ev){
      this.activeMenu = ev
    }
  }
};
</script>

<style lang="scss" scoped>
.tab-bar {
  // width: 164px;
  .el-menu-vertical {
    height: 100%;
    .activeMenu{
      background-color: #FFAE2E;
      height: 38px;
      width: 147px;
      border-radius: 9px;
    }
  }
  
  .w-10{
    width: 10px;
  }
  .h-10{
    height: 10px;
  }
  .w-12{
    width: 12px;
  }
  .h-12{
    height: 12px;
  }
  .w-14{
    width: 14px;
  }
  .h-14{
    height: 14px;
  }
  
  .tab-bar-icon{
    margin-right: 9px;
  }
  
  
  /deep/.el-menu-item{
    display: flex;
    align-items: center;
    background-color: transparent !important;
    div{
      display: flex;
      align-items: center;
      padding-left: 10px;
      width: 147px;
      height: 38px;
    }
  }
}
</style>
