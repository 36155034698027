<template>
  <div class="setPassword">
    <div class="title">{{ $t('修改密码') }}</div>
    <card class="Card">
      <!-- 表单 -->
      <div class="form">
        <div class="input-box row pos-rel line-buttom">
          <div class="input-name Regular">{{ $t('公众号')}}:</div>
          <div class="info">{{name}}</div>
        </div>
        <div class="input-box row pos-rel line-buttom">
          <div class="input-name Regular">{{ $t('账号')}}:</div>
          <div class="info">{{user}}</div>
        </div>
        <div class="input-box row">
          <div class="input-name Regular">{{ $t('新密码')}}:</div>
          <div class="input"><input type="password" class="input-in flex-1" :placeholder="$t('不低于6位')" v-model="password"></div>
        </div>
        <div class="input-box row ">
          <div class="input-name Regular">{{ $t('确认密码')}}:</div>
          <div class="input"><input type="password" class="input-in flex-1" :placeholder="$t('不低于6位')" v-model="password_"></div>
        </div>
      </div>
      <!-- next -->
      <Button class="button button-submit" :loading="loading.submit" @click="submit">{{$t('确认修改')}}</Button>
    </card>
  </div>
</template>

<script>
  export default {
    name: 'setPassword',
    data(){
      return {
        loading:{
          submit:false
        },
        name:"",
        user:'',
        password:'',
        password_:'',
      }
    },
    created() {
      this.init()
    },
    mounted() {
      
    },
    computed:{
      
    },
    methods:{
      init(){
        this.$apis.user.getOfficialAccountInfo().then(res=>{
          console.log(res);
          this.name = res.data.accountInfoItem.nickname
          this.user = res.data.accountInfoItem.code
        })
      },
      submit(){
        if(!this.password){
          this.$message.error('输入密码'.$t())
          return
        }
        if(this.password.length < 6){
          this.$message.error('密码不能低于6位'.$t())
          return
        }
        if(!this.password_){
          this.$message.error('输入确认密码'.$t())
          return
        }
        if(this.password != this.password_){
          this.$message.error('两次密码不一致'.$t())
          return
        }
        let data = {
          password:this.password
        }
        this.$apis.user.renewPassword(data).then(res=>{
          this.$message.success('修改成功')
          this.$clearData(['loglevel:webpack-dev-server','lang'])
          this.IM.disconnect().then(res=>{
            console.log('IM断开');
          })
          this.$store.commit('isLoginSet',false)
          this.$router.push(`/page/success?btnType=outLogin`)
          // this.$router.replace('/login')
        })
      }
    }
  }
</script>

<style lang="scss">
  .setPassword{
    padding: 19px 40px;
    display: flex;
    flex-direction: column;
    height: 100%;
    .title{
      color: #3B3F56;
      font-size: 18px;
      line-height: 25px;
      font-weight: 700;
    }
    .Card{
      padding: 0 0 34px 19px;
      .form{
        .input-box{
          margin-top: 20px;
          .input-name{
            height: 34px;
            line-height: 34px;
            width: 5em;
            margin-right: 4px;
            font-size: 14px;
            color: #666A82;
          }
          .input{
            width: 238px;
            height: 34px;
            border-radius: 3px;
            border: 1px solid #DBDBE7;
            padding-left: 12px;
          }
          &.line-buttom::after{
            content: '';
            display: block;
            height: 1px;
            background-color: #979797;
            opacity: 0.13;
            position: absolute;
            bottom: 0px;
            width: 100%;
          }
          &.line-buttom{
            padding-bottom: 10px;
          }
        }
        
      }
      .button-submit{
        width: 125px;
        height: 32px;
        border-radius: 5px;
        margin-top:36px;
      }
    }
  }
</style>
