<template>
  <div class="scanCodeConfirm">
    <div class="title row">
      <div class="flex-1 title-text">{{ $t('修改手机') }}</div>
      <div class="link pointer" @click="link">{{ $t('验证码确认')}}</div>
    </div>
    <card class="Card row col">
      <div class="Card-title">{{$t('管理员扫码确认')}}</div>
      <div class="qrcode pos-rel row js-center">
        <img class="qrCode-img w-100 h-100"  v-if="qrCode.status != 0" :src="qrCode.img" >
        <i class="el-icon-loading" v-if="qrCode.status == 0"></i>
        <div class="login-info row col" v-if="qrCode.status == 2">
          <img class="login-info-icon" src="assets/image/login/success@2x.png" >
          <div class="login-info-text">确认成功</div>
        </div>
        <div class="login-info row col pointer" v-if="qrCode.status == 3" @click="getQrCode">
          <img class="login-info-icon loain-info-icon-top" src="assets/image/login/notice@2x.png" >
          <div class="login-info-text">二维码失效</div>
          <div class="login-info-ref row">
            <img src="assets/image/login/update@2x.png" >
            <span>刷新二维码</span>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
  export default {
    name: 'scanCodeConfirm',
    data(){
      return {
        timer:0,
        qrCode:{
          img:"",
          uuid:"",
          status:0,
        },
      }
    },
    created() {
      this.getQrCode()
    },
    mounted() {
      
    },
    computed:{
      
    },
    methods:{
      link(){
        this.$router.push(`/page/phone`)
      },
      getQrCode(){
        this.qrCode.status = 0
        if(this.timer){
          clearInterval(this.timer)
        }
        this. $apis.login.getQrCode({}).then(res=>{
          this.qrCode.img = res.data.qrCode
          this.qrCode.uuid = res.data.uuId
          this.getScanResult()
          this.timer = setInterval(this.getScanResult,4000)
        })
      },
      getScanResult(){
        if(this.$route.path != '/page/scanCodeConfirm'){
          clearInterval(this.timer)
          return
        }
        this.$apis.login.getConfirmScan({uuId:this.qrCode.uuid}).then(res=>{
          this.qrCode.status = res.data.state
          if(res.data.state == 2){
            // 确认成功
            clearInterval(this.timer)
            let data = res.data.officialAccount
            setTimeout(()=>{
              this.$router.push(`/page/setPhone`)
            },1800)
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  .scanCodeConfirm{
    padding: 19px 40px;
    display: flex;
    flex-direction: column;
    height: 100%;
    .title{
      .title-text{
        color: #3B3F56;
        font-size: 18px;
        line-height: 25px;
        font-weight: 700;
      }
      .link{
        font-size: 14px;
        color: #1564B9;
        line-height: 19px;
      }
    }
    .Card{
      padding: 0 0 34px 19px;
      .Card-title{
        margin-top: 27px;
        margin-bottom: 17px;
      }
      .qrcode{
        width: 227px;
        height: 227px;
        .el-icon-loading{
          font-size: 56px;
          color: #afafaf;
        }
        
        .qrCode-img{
          position: absolute;
          top: 0;
          left: 0;
        }
        
        .login-info{
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,0.58);
          .login-info-icon{
            width: 43px;
            height: 43px;
            margin-top: 62px;
            &.loain-info-icon-top{
              margin-top: 37px;
            }
          }
          .login-info-text{
            font-size: 26px;
            color: #FFFFFF;
            line-height: 36px;
            margin-top: 25px;
            width: 205px;
            text-align: center;
          }
          .login-info-ref{
            margin-top: 14px;
            img{
              width: 26px;
              height: 26px;
              margin-right: 13px;
            }
            span{
              font-size: 20px;
              color: #FFFFFF;
              line-height: 28px;
            }
          }
        }
      }
    }
  }
</style>
