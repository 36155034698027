<template>
  <div class="userSet">
    <card class="Card min-height">
      <div class="card-title">账号负责人</div>
      <div class="item ai-top row" v-for="(item,index) of info.principalInfo" :key="index">
        <div class="row Regular item-name" v-if="!item.nameAvatar" v-html="item.name"></div>
        <div class="row item-name" v-if="item.nameAvatar"><img :src="item.name" ></div>
        <div class="row flex-1 item-info" v-if="item.type == 'text'">
          <div class="info flex-1">{{item.info}}</div>
          <div class="link pointer" v-if="item.link" @click="item.link('principalInfo',index)">{{item.linkText}}</div>
        </div>
        <div class="row flex-1 item-info" v-if="item.type == 'image'">
          <div class="info images flex-1">
            <img class="image" src="" >
          </div>
          <div class="link pointer" v-if="item.link" @click="item.link('principalInfo',index)">{{item.linkText}}</div>
        </div>
      </div>
      <div class="card-title mt">账号绑定</div>
      
      <div class="un-bind" v-if="!bind">
        <div class="un-bind-title">{{$t("暂无")}}</div>
        <div class="row">
          <span class="un-bind-info Regular">{{$t('绑定账号，可通过扫码登录公众号')}}</span>
          <span class="un-bind-link pointer"  @click="navLink('OOuserInfo',0,2)">{{$t('立即绑定')}}</span>
        </div>
      </div>
      
      <div class="item ai-top row" v-for="(item,index) of info.OOuserInfo" :key="'oo'+index" v-if="bind">
        <div class="row Regular item-name" v-if="!item.nameAvatar" v-html="item.name"></div>
        <div class="row item-name" v-if="item.nameAvatar"><img class="name-avatar" :src="item.name" ></div>
        <div class="row flex-1 item-info" v-if="item.type == 'text'">
          <div class="info flex-1">{{item.info}}</div>
          <div class="link pointer" v-if="item.link" @click="item.link('OOuserInfo',index,1)">{{item.linkText}}</div>
        </div>
        <div class="row flex-1 item-info" v-if="item.type == 'image'">
          <div class="info images flex-1">
            <img class="image" src="" >
          </div>
          <div class="link pointer" v-if="item.link" @click="item.link('OOuserInfo',index)">{{item.linkText}}</div>
        </div>
      </div>
      
    </card>
    
    <div class="card-heder row">
      <div class="flex-1 name">{{$t('运营人员')}}</div>
      <div class="name">{{userList.length}}/12</div>
      <Button class="button-ho" @click="addAdmin">{{$t('添加管理')}}</Button>
    </div>
    <card class="Card">
      <div class="table-header row">
        <div class="tale-col Regular col-1">头像</div>
        <div class="tale-col Regular col-2">昵称</div>
        <div class="tale-col Regular col-3">账号</div>
        <div class="tale-col Regular col-3">ID</div>
        <div class="tale-col Regular col-3">添加时间</div>
        <div class="tale-col Regular col-4 flex-1">操作</div>
      </div>
      <div class="table">
        <div class="table-item row" v-for="(item,index) of userList" :key="index">
          <div class="tale-col col-1"><img class="avatar" :src="item.headImg" ></div>
          <div class="tale-col w-8em col-2 line-1">{{item.userName}}</div>
          <div class="tale-col col-3">
            <div class="user-code">
              <div v-if="item.account" style="text-align: left;">{{'邮箱:'.$t()}}{{item.account}}</div>
              <div v-if="item.phone" style="text-align: left;">{{'手机:'.$t()}}{{item.phone}}</div>
            </div>
          </div>
          <div class="tale-col col-3">{{item.userId}}</div>
          <div class="tale-col col-3">{{item.createdAt | getDate}}</div>
          <div class="tale-col col-4 flex-1 pointer" @click="relieve(item)">解除管理</div>
        </div>
        <loadMore :status="loading.more"></loadMore>
      </div>
    </card>
    
    <Popup ref="PopupBind">
      <card class="PopupBind row col pos-rel" v-if="popup.type == 1">
        <div class="close-icon pointer"  @click="handleClose"><img src="assets/image/icon_close@2x.png" ></div>
        <div class="title">{{$t('管理员扫码确认')}}</div>
        <div class="qrcode pos-rel row js-center">
          <img class="qrCode-img w-100 h-100"  v-if="qrCode.status != 0" :src="qrCode.img" >
          <i class="el-icon-loading" v-if="qrCode.status == 0"></i>
          <div class="login-info row col" v-if="qrCode.status == 2">
            <img class="login-info-icon" src="assets/image/login/success@2x.png" >
            <div class="login-info-text">确认成功</div>
          </div>
          <div class="login-info row col pointer" v-if="qrCode.status == 3" @click="getQrCode">
            <img class="login-info-icon loain-info-icon-top" src="assets/image/login/notice@2x.png" >
            <div class="login-info-text">二维码失效</div>
            <div class="login-info-ref row">
              <img src="assets/image/login/update@2x.png" >
              <span>刷新二维码</span>
            </div>
          </div>
        </div>
      </card>
      <card class="PopupBind row col" v-if="popup.type == 2">
        <div class="title">{{$t('仅可以绑定一个管理员')}}</div>
        <div class="searc-input row">
          <input type="text" class="input-in flex-1" v-model="userOoId" :placeholder="$t('账号')">
          <Button class="button" :loading="loading.search" @click="searchUser">{{loading.search?'':$t("搜索")}}</Button>
        </div>
        <div class="user-info" v-if="user">
          <div class="avatar"><img :src="searchUserData.headImg" ></div>
          <div class="user-name line-1">{{searchUserData.nickname}}</div>
        </div>
        <div class="btns row js-between">
          <Button class="button-ho" @click="handleClose">{{$t('取消')}}</Button>
          <Button class="button" @click="handleBind" :loading="loading.addButton">{{$t('添加')}}</Button>
        </div>
      </card>
      <card class="PopupBind row col"  v-if="popup.type == 3">
        <div class="title">{{$t('已绑定'.$t()+userList.length+'个账号，还可以绑定'.$t()+(12 - userList.length)+'个运营人员').$t()}}</div>
        <div class="searc-input row">
          <input type="text" class="input-in flex-1" v-model="userOoId" :placeholder="$t('账号')">
          <Button class="button" :loading="loading.search" @click="searchUser">{{loading.search?'':$t("搜索")}}</Button>
        </div>
        <div class="user-info" v-if="user">
          <div class="avatar"><img :src="searchUserData.headImg" ></div>
          <div class="user-name line-1">{{searchUserData.nickname}}</div>
        </div>
        <div class="btns row js-between">
          <Button class="button-ho" @click="handleClose">{{$t('取消')}}</Button>
          <Button class="button" @click="handleBind" :loading="loading.addButton">{{$t('添加')}}</Button>
        </div>
      </card>
    </Popup>
  </div>
</template>

<script>
  export default {
    name: 'userSet',
    data(){
      return {
        loading:{
          addButton:false,
          more:'more'
        },
        info:{
          principalInfo:[
            {
              type:'text',
              name:'负责人：',
              info:''
            },
            {
              type:'text',
              name:'手机：',
              info:'',
              link:this.navLink,
              linkText:'修改手机'
            }
          ],
          OOuserInfo:[
            {
              type:'text',
              name:'',
              nameAvatar:true,
              info:'',
              link:this.navLink,
              linkText:'重新绑定'
            },
            {
              type:'text',
              name:'账号：',
              info:'',
            },
            {
              type:'text',
              name:'ID号：',
              info:'',
            }
          ],
        },
        bind:true,
        loading:{
          search:false
        },
        user:false,
        searchUserData:{},
        userOoId:'',
        qrCode:{
          img:"",
          uuid:"",
          status:0,
          type:2,// 2 绑定管理员 3 添加管理人员 4 解除绑定
        },
        popup:{
          type:1 ,// 1 扫码确认 2 绑定管理员 3 添加运营人员
        },
        timer:0,
        userList:[],
        jcData:{}
      }
    },
    created() {
      this.init()
    },
    mounted() {
      
    },
    computed:{
      
    },
    methods:{
      init(){
        // type = 1 管理人员
        this.$apis.user.queryOperateMgt({type:1}).then(res=>{
          if(res.data.operateMgts && res.data.operateMgts.length){
            this.bind = true
            let userData = res.data.operateMgts[0]
            this.info.OOuserInfo[0].name = userData.headImg
            this.info.OOuserInfo[0].info = userData.userName 
            this.info.OOuserInfo[1].info = userData.account 
            this.info.OOuserInfo[2].info = userData.userId 
          }else{
            this.bind = false
          }
          
        })
        // type = 1 运营人员
        this.loading.more = 'loading'
        this.$apis.user.queryOperateMgt({type:2}).then(res=>{
          this.userList = res.data.operateMgts || []
          if(res.data.operateMgts){
            this.loading.more = 'no-more'
          }else{
            this.loading.more = 'no-data'
          }
        })
        
        this.$apis.user.getOfficialAccountInfo().then(res=>{
          this.info.principalInfo[0].info = res.data.accountInfoItem.name
          this.info.principalInfo[1].info = this.vForm_(res.data.accountInfoItem.phone)
        })
      },
      navLink(type,i,bind){
        if(type == 'principalInfo' && i == 1){
          this.$router.push('/page/phone')
        }
        if(type == 'OOuserInfo' && i == 0){
          this.qrCode.type = 2
          this.popup.type = bind
          this.searchUserData = {}
          this.user = false
          this.userOoId = ''
          this.$refs.PopupBind.open()
          if(this.popup.type == 1){
            this.getQrCode()
          }
        }
      },
      searchUser(){
        if(!this.userOoId.trim()){
          this.$message.error(this.$t("请输入妈妈创业营账号"))
          return
        }
        this.loading.search = true
        let data = {
          account:this.userOoId,
          userId:0
        }
        this.$apis.user.getFriendsListSearch(data).then(res=>{
          if(res.data.friendsList.length){
            this.searchUserData = res.data.friendsList[0]
            this.user = true
          }
          this.loading.search = false
        }).catch(err=>{
          this.loading.search = false
        })
        
      },
      handleClose(){
        this.$refs.PopupBind.close()
      },
      handleBind(){
        // 2 绑定管理员 3 添加管理人员
        if(this.popup.type == 2){
          this.loading.addButton = true
          let data = {
            "account": this.searchUserData.mailbox,
            "headImg": this.searchUserData.headImg ,
            "userId": this.searchUserData.id,
            "userName": this.searchUserData.nickname
          }
          this.$apis.user.addManagement(data).then(res=>{
            this.loading.addButton = false
            this.$refs.PopupBind.close()
            this.init()
            this.$message.success('绑定成功'.$t())
          }).catch(err=>{
            this.loading.addButton = false
          })
        }
        if(this.popup.type == 3){
          this.popup.type = 1
        }
      },
      bindUser(){
        let account = []
        let data = {
          "account": this.searchUserData.mailbox,
          "headImg": this.searchUserData.headImg ,
          "phone":this.searchUserData.phone,
          "userId": this.searchUserData.id,
          "userName": this.searchUserData.nickname
        }
        this.$apis.user.addOperators(data).then(res=>{
          this.loading.addButton = false
          this.$refs.PopupBind.close()
          this.init()
          this.$message.success('添加成功'.$t())
        }).catch(err=>{
          this.popup.type = 3
          this.loading.addButton = false
        })
      },
      addAdmin(){
        if(this.userList.length>=12){
          this.$message.error('运营人员已满，不可添加'.$t())
          return
        }
        if(!this.bind){
          this.$message.error('请先绑定妈妈创业营号')
          return
        }
        this.qrCode.type = 3
        this.userOoId = ''
        this.popup.type = 3
        this.searchUserData = {}
        this.user = false
        this.$refs.PopupBind.open()
        this.getQrCode()
      },
      // 解除账号
      relieve(item){
        this.$confirm(this.$t('确定解除该账号吗?'), this.$t('提示'), {
          confirmButtonText: this.$t('确定'),
          cancelButtonText: this.$t('取消'),
          type: 'warning'
        }).then(res=>{
          this.jcData = item
          this.qrCode.type = 4
          this.popup.type = 1
          this.$refs.PopupBind.open()
          this.getQrCode()
        })
      },
      jcBind(){
        this.$apis.user.deleteOperateMgt({id:this.jcData.id}).then(res=>{
          this.$message.success('解除成功'.$t())
          this.init()
          this.$refs.PopupBind.close()
        })
      },
      getQrCode(){
        this.qrCode.status = 0
        if(this.timer){
          clearInterval(this.timer)
        }
        this. $apis.login.getQrCode({}).then(res=>{
          this.qrCode.img = res.data.qrCode
          this.qrCode.uuid = res.data.uuId
          this.getScanResult()
          this.timer = setInterval(this.getScanResult,4000)
        })
      },
      getScanResult(){
        if(this.$route.path != '/userSet'){
          clearInterval(this.timer)
          return
        }
        this.$apis.login.getConfirmScan({uuId:this.qrCode.uuid}).then(res=>{
          this.qrCode.status = res.data.state
          if(res.data.state == 2){
            // 确认成功
            clearInterval(this.timer)
            let data = res.data.officialAccount
            setTimeout(()=>{
              if(this.qrCode.type == 2){
                this.userOoId = ''
                this.popup.type = this.qrCode.type
              }else if(this.qrCode.type == 4){
                this.jcBind()
              }else{
                this.bindUser()
              }
              
            },1800)
          }
        })
      },
      vForm_(val){
        let strArr = val.split('')
        if(strArr.length <= 5){
          if(strArr.length > 1){
            for(let i = 1;i<strArr.length;i++){
              strArr[i] = '*'
            }
          }
          
          return strArr.join('')
        }
        if(strArr.length == 11){
          strArr[3] = '*'
          strArr[4] = '*'
          strArr[5] = '*'
          strArr[6] = '*'
          return strArr.join('')
        }
        if(strArr.length > 11){
          for(let i = 7;i<=13;i++){
            strArr[i] = '*'
          }
          return strArr.join('')
        }
      }
    },
    filters: {
      VForm(val){
        return this.vForm_(val)
      }
    }
  }
</script>

<style lang="scss">
  .userSet{
    .Card{
      padding: 12px 0;
      padding-left: 18px;
      &.min-height{
        min-height: 386px;
      }
      .card-title{
        &.mt{
          margin-top: 21px;
        }
      }
      
      .un-bind{
        margin-top: 14px;
        .un-bind-title{
          font-size: 15px;
          line-height: 21px;
          color: #3B3F56;
          margin-bottom: 9px;
        }
        .un-bind-info{
          font-size: 12px;
          color: #989BB0;
          line-height: 17px;
          text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
        }
        .un-bind-link{
          color: #1564B9;
          text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
          margin-left: 18px;
        }
      }
      
      .item{
        .item-name{
          color: #666A82;
          padding: 22px 0 16px;
          width: 5em;
          .name-avatar{
            width: 43px;
            height: 43px;
            border-radius: 100%;
            overflow: hidden;
          }
        }
        .item-info{
          margin-left: 18px;
          border-bottom: 1px solid rgba(151,151,151,0.13);
          padding: 22px 0 16px;
          .link{
            color: #1564B9;
            margin-right: 24px;
          }
          .info{
            color: #3B3F56;
            font-size: 15px;
            line-height: 21px;
            &.images{
              .image{
                width: 161px;
                height: 114px;
              }
            }
          }
        }
      }
      
      .table-header{
        height: 44px;
        padding-left: 51px;
        border-bottom: 1px solid rgba(219, 219, 231, 0.26);
        .tale-col{
          text-align: center;
          font-size: 12px;
          color: #989BB0;
          line-height: 17px;
          text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5), 0px 2px 3px rgba(220, 231, 239, 0.5);
          &.w-8em{
            width:8em;
          }
        }
        .col-1{
          min-width: 100px;
          text-align: left;
          flex: 1;
        }
        .col-2,.col-3{
          min-width: 125px;
        }
        .co-2{
          flex: 3;
        }
        .col-3{
          flex: 4;
        }
        .col-4{
          flex: 3;
        }
      }
      .table{
        padding-left: 51px;
        min-height: 188px;
        .table-item{
          border-bottom: 1px solid rgba(219, 219, 231, 0.26);
        }
        .tale-col{
          padding: 22px 0;
          text-align: center;
          color: #696B76;
          text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5), 0px 2px 3px rgba(220, 231, 239, 0.5);
          .user-code{
            display: inline-grid;
            div{
              display: inline-block;
            }
          }
          .avatar{
            width: 38px;
            height: 38px;
            border-radius: 100%;
            overflow: hidden;
          }
        }
        .col-1{
          min-width: 100px;
          padding: 0;
          text-align: left;
          flex: 1;
        }
        .col-2,.col-3{
          min-width: 125px;
        }
        .co-2{
          flex: 3;
        }
        .col-3{
          flex: 4;
        }
        .col-4{
          color: #1564B9;
          flex: 3;
        }
      }
      
    }
    
    .card-heder{
      margin-top: 26px;
      padding-right: 27px;
      .name{
        font-size: 15px;
        color: #3B3F56;
        line-height: 21px;
        margin-right: 15px;
      }
      .button-ho{
        width: 77px;
        height: 32px;
        border-color: #FFAE2E;
        color: #FFAE2E;
        background-color: rgba(0,0,0,0);
      }
    }
    
    .PopupBind{
      width: 393px;
      padding: 24px 0 22px;
      
      .close-icon{
        width: 14px;
        height: 14px;
        position: absolute;
        top: 18px;
        right: 15px;
      }
      
      .title{
        font-size: 15px;
        color: #3B3F56;
        line-height: 21px;
      }
      
      .qrcode{
        width: 227px;
        height: 227px;
        margin-top: 17px;
        margin-bottom: 22px;
        
        
        .el-icon-loading{
          font-size: 56px;
          color: #afafaf;
        }
        
        .qrCode-img{
          position: absolute;
          top: 0;
          left: 0;
        }
        
        .login-info{
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,0.58);
          .login-info-icon{
            width: 43px;
            height: 43px;
            margin-top: 62px;
            &.loain-info-icon-top{
              margin-top: 37px;
            }
          }
          .login-info-text{
            font-size: 26px;
            color: #FFFFFF;
            line-height: 36px;
            margin-top: 25px;
            width: 205px;
            text-align: center;
          }
          .login-info-ref{
            margin-top: 14px;
            img{
              width: 26px;
              height: 26px;
              margin-right: 13px;
            }
            span{
              font-size: 20px;
              color: #FFFFFF;
              line-height: 28px;
            }
          }
        }
        
      }
      
      .searc-input{
        width: 232px;
        height: 34px;
        border-radius: 3px;
        border: 1px solid #DBDBE7;
        margin-top: 22px;
        .input-in{
          padding-left: 12px;
        }
        .button{
          width: 47px;
          height: 32px;
        }
      }
      
      .user-info{
        margin-top: 22px;
        .avatar{
          width: 106px;
          height: 106px;
          border-radius: 100%;
          overflow: hidden;
        }
        .user-name{
          margin-top: 16px;
          text-align: center;
          font-size: 15px;
          line-height: 21px;
          width: 8em;
        }
      }
      
      .btns{
        width: 232px;
        margin-top: 22px;
        .button{
          width: 90px;
          height: 32px;
          border-radius: 5px;
        }
      }
    }
    
    
  }
</style>
