"use strict";

import Vue from 'vue';
import axios from "axios";
import apis from '../config/apis.js'
import store from '../store'
import host from '../config/host.js'
import {Message} from 'element-ui'
let $Vue = null
let userInfo = null



// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
axios.defaults.headers.post['Content-Type'] = 'application/json';

let config = {
  baseURL: host.host,
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);
// 添加请求拦截器
_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    $Vue = store.state.Vue
    let data = localStorage.getItem('userInfo')
    try{
      data = JSON.parse(data)
      userInfo = data.data
    }catch(e){
      //TODO handle the exception
    }
    if(userInfo){
      if(!config.data){
        config.data = {}
      }
      config.data.accountId = userInfo.id
    }
    console.log();
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// 添加响应拦截器
_axios.interceptors.response.use(
  function(response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    if(response.status == 200 && response.data.code == 200){
      if(response.data.data.returnCode == 'FAIL'){
        Message.error(response.data.data.returnMsg);
        return Promise.reject(response.data);
      }else{
        return response.data;
      }
    }else{
      Message.error($Vue.$t('网络错误'));
      return Promise.reject(response.data);
    }
    
  },
  function(error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    
    
    return Promise.reject(error);
  }
);

// 注册axios
Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Vue.use(apis,_axios)
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};


Vue.use(Plugin)

export default Plugin;
