<template>
  <div class="updataFile">
    <Popup ref="popup" zIndex="999">
      <!-- 图片 -->
      <card class="Card image" :margin="false" style="width: 589px;" v-if="type == 1">
        <div class="item row ai-top">
          <div class="item-name row">上传图片</div>
          <el-upload
            ref="uploadImage"
            class="image-uploader"
            name="upload"
            accept="image/*"
            :show-file-list="false"
            :on-change="onImageFileChange"
            :auto-upload="false"
            action=""
            >
            <div class="item-updata-image pos-rel pointer" v-if="!image.unImageUrl">
              <div class="image-tip">上传图片</div>
            </div>
            <div class="item-updata-image pos-rel pointer" v-else>
              <img :src="image.unImageUrl" >
              <div class="progress-box row js-center" v-if="image.progress">
                <div class="progress"><div class="progress-b" :style="{width:image.progress+'%'}"></div></div>
              </div>
            </div>
          </el-upload>
          
        </div>
        <div class="item row">
          <div class="item-name row">图片名称</div>
          <div class="item-input row"><input type="text" maxlength="16"  v-model="image.name"/><span class="item-input-number Regular">{{image.name.length}}/16</span></div>
        </div>
        <!-- 下拉选 -->
        <el-dropdown size="medium" trigger="click" @command="fz.current = $event" v-if="fz.list.length">
          <div class="item row pointer">
            <div class="item-name row">分组</div>
            <div class="item-input row dropdown pos-rel">{{fz.list.length ?fz.list[fz.current].name:''}}<i v-if="!fz.list.length" class="el-icon-loading"></i>  <div class="dropdown"></div></div>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(it,idx) in fz.list" :command="idx" :key="idx">{{it.name}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="new-group row">
          <div class="item-name row" style="color: #606266;"><span  v-if="fz.list.length == 0">分组</span></div>
          <div class="pointer" @click="addGroup">新建分组</div>
        </div>
        
        <div class="btns row js-center">
          <Button class="button button-ho" @click="close">取消</Button>
          <Button class="button" :loading="image.loading" @click="updataImage">上传</Button>
        </div>
      </card>
      
      <!-- 音频 -->
      <card class="Card image" :margin="false" style="width: 589px;" v-if="type == 2">
        <div class="Card-title_">添加音乐</div>
        <div class="item row">
          <div class="item-name row">音乐名字</div>
          <div class="item-input row"><input type="text" placeholder="" v-model="audio.name" maxlength="16"/><span class="item-input-number Regular">{{audio.name.length}}/16</span></div>
        </div>
        <div class="item row ai-top item-file">
          <div class="item-name row" v-if="!audio.unAudioUrl"></div>
          <div class="item-name row" v-if="audio.unAudioUrl">音乐名字</div>
          <div class="audio-name line-1 Regular row" v-if="audio.unAudioUrl"><span v-if="audio.progress">(<i class="el-icon-loading"></i>{{audio.progress}}%)</span>{{audio.name}}</div>
          <div class="row" :class="{'upload-file-text':!audio.unAudioUrl}">
            <el-upload
              ref="uploadAudio"
              class="audio-uploader"
              name="upload"
              :show-file-list="false"
              accept="audio/*"
              action=""
              :on-change="onAudioFileChange"
              :auto-upload="false"
              >
              <div class="image-tip" v-if="!audio.unAudioUrl">上传音乐</div>
              <Button class="audio-button" v-else>重新上传</Button>
            </el-upload>
            <div class="flex-1"></div>
            <div class="tip-icon row" v-if="!audio.unAudioUrl"><img src="assets/image/notice@2x.png" ></div>
            <div class="tip Regular" v-if="!audio.unAudioUrl">音乐小于50M,支持MP3等常见格式</div>
          </div>
          
        </div>
        <!-- 下拉选 分组 -->
        <el-dropdown size="medium" trigger="click" @command="fz.current = $event" v-if="fz.list.length">
          <div class="item row pointer">
            <div class="item-name row">分组</div>
            <div class="item-input row dropdown pos-rel">{{fz.list.length ?fz.list[fz.current].name:''}}<i v-if="!fz.list.length" class="el-icon-loading"></i>  <div class="dropdown"></div></div>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(it,idx) in fz.list" :command="idx" :key="idx">{{it.name}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="new-group row">
          <div class="item-name row" style="color: #606266;"><span  v-if="fz.list.length == 0">分组</span></div>
          <div class="pointer" @click="addGroup">新建分组</div>
        </div>
        
        <div class="xieyi item row pointer" >
          <div class="item-name row"></div>
          <div class="xieyi-check row js-center"  @click="audio.checked = !audio.checked" :class="{checked:audio.checked}"></div>
          <div class="xieyi-text Regular">
            <span>我已阅读</span>
            <span class="link">《妈妈创业营公众号相关服务协议》</span>
          </div>
        </div>
        
        <div class="btns row js-center">
          <Button class="button button-ho" @click="close">取消</Button>
          <Button class="button" :loading="audio.loading" @click="updataAudio">确定</Button>
        </div>
      </card>
      
      <!-- 视频 -->
      <card class="Card image" :margin="false" style="width: 589px;" v-if="type == 3">
        <div class="Card-title_">添加视频</div>
        <div class="item row">
          <div class="item-name row">视频名字</div>
          <div class="item-input row"><input type="text" placeholder="" v-model="video.name" maxlength="16"/><span class="item-input-number Regular">{{video.name.length}}/16</span></div>
        </div>
        <div class="item row ai-top item-file">
          <div class="item-name row" v-if="!video.unVideoUrl"></div>
          <div class="item-name row" v-if="video.unVideoUrl">视频</div>
          <video id="video1" controls class="item-updata-image image-fm" v-if="video.unVideoUrl">
            <source :src="video.unVideoUrl" type='video/mp4'>
            <source :src="video.unVideoUrl" type='video/ogg'>
            <source :src="video.unVideoUrl" type='video/webm'>
          </video>
          <div class="audio-name line-1 Regular row" v-if="video.unVideoUrl"><span v-if="video.progress">(<i class="el-icon-loading"></i>{{video.progress}}%)</span></div>
          <div class="row" :class="{'upload-file-text':!video.unVideoUrl}">
            <el-upload
              ref="upload-video"
              class="audio-uploader"
              name="upload"
              accept="video/*"
              :show-file-list="false"
              :on-change="onVideoFileChange"
              :auto-upload="false"
              action=""
              >
              <div class="image-tip" v-if="!video.unVideoUrl">上传视频</div>
              <Button class="audio-button" style="margin-top: 35px;" v-else>重新上传</Button>
            </el-upload>
            <div class="flex-1"></div>
            <div class="tip-icon row" v-if="!video.unVideoUrl"><img src="assets/image/notice@2x.png" ></div>
            <div class="tip Regular" v-if="!video.unVideoUrl">视频小于50M,支持MP4等常见格式</div>
          </div>
          
        </div>
        <div class="item row ai-top">
          <div class="item-name row">封面</div>
          <div class="item-updata-image image-fm pos-rel pointer" v-if="video.unImageUrl">
            <img :src="video.unImageUrl" >
            <div class="progress-box row js-center" v-if="video.progressImg">
              <div class="progress"><div class="progress-b" :style="{width:video.progressImg+'%'}"></div></div>
            </div>
          </div>
          <el-upload
            ref="upload"
            class="image-uploader"
            name="upload"
            accept="image/*"
            :show-file-list="false"
            :on-change="onVideoCoverFileChange"
            :auto-upload="false"
            action=""
            >
            <!-- <div class="item-updata-image image-fm pos-rel pointer" v-if="!video.unImageUrl">
              <Button class="audio-button" style="margin-top: 35px;">上传封面</Button>
              <div class="image-tip">上传封面</div>
            </div> -->
            <Button class="audio-button" v-if="!video.unImageUrl">上传封面</Button>
            <Button class="audio-button" style="margin-top: 35px;margin-left: 14px;" v-if="video.unImageUrl">重新上传</Button>
          </el-upload>
          
        </div>
        
        <!-- 下拉选 - 分类 -->
        <el-dropdown size="medium" trigger="click" @command="fz.current = $event" v-if="fz.list.length">
          <div class="item row pointer">
            <div class="item-name row">分组</div>
            <div class="item-input row dropdown pos-rel">{{fz.list.length ?fz.list[fz.current].name:''}}<i v-if="!fz.list.length" class="el-icon-loading"></i>  <div class="dropdown"></div></div>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(it,idx) in fz.list" :command="idx" :key="idx">{{it.name}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="new-group row">
          <div class="item-name row" style="color: #606266;"><span  v-if="fz.list.length == 0">分组</span></div>
          <div class="pointer" @click="addGroup">新建分组</div>
        </div>
        <div class="xieyi item row pointer" >
          <div class="item-name row"></div>
          <div class="xieyi-check row js-center"  @click="video.checked = !video.checked" :class="{checked:video.checked}"></div>
          <div class="xieyi-text Regular">
            <span>我已阅读</span>
            <span class="link">《妈妈创业营公众号相关服务协议》</span>
          </div>
        </div>
        
        <div class="btns row js-center">
          <Button class="button button-ho" @click="close">取消</Button>
          <Button class="button" :loading="image.loading" @click="updataVideo">上传</Button>
        </div>
      </card>
      
    </Popup>
    
    <!-- 新建分组 -->
    <Popup ref="PopupCard" zIndex="1000">
      <card class="PopupCard row col">
        <div class="title">{{$t('分组名称')}}</div>
        <div class="searc-input row">
          <input type="text" maxlength="18" class="input-in flex-1" v-model="popup.addGroup.groupName" >
          <span class="string-length Regular">{{popup.addGroup.groupName.length}}/18</span>
        </div>
        <div class="popup-btns row js-between">
          <Button class="button-ho" @click="handleClose">{{$t('取消')}}</Button>
          <Button class="button" :loading="popup.addGroup.loading" @click="handleAdd">{{$t('确定')}}</Button>
        </div>
      </card>
    </Popup>
  </div>
</template>

<script>
  export default {
    name: 'updataFile',
    data(){
      return {
        fz:{
          list:[],
          current:0
        },
        image:{
          unImageUrl:"",
          File:{},
          name:'',
          loading:false,
          progress:0,
          hz:''
        },
        video:{
          unImageUrl:"",
          unVideoUrl:"",
          name:'',
          loading:"",
          progress:0,
          progressImg:0,
          duration:0,
          hz:'',
          checked:false,
          size:0
        },
        audio:{
          unAudioUrl:"",
          name:'',
          loading:false,
          progress:0,
          duration:0,
          hz:'',
          checked:false
        },
        popup:{
          addGroup:{
            groupName:"",
            loading:false
          }
        }
      }
    },
    props:{
      type:{
        type:Number,
        default:0,// 1 图片 2 音频 3 视频
      },
      
      
    },
    created() {
      
    },
    mounted() {
      
    },
    computed:{
      
    },
    methods:{
      async init(){
        let data = {
          type:this.type
        }
        await this.$apis.sc.queryGroupingList(data).then(res=>{
          this.fz.list = res.data.groupingItemList
        })
      },
      open(){
        this.image.unImageUrl = ''
        this.image.name = ''
        this.fz.current = 0
        // 初始化内容
        this.video = {
          unImageUrl:"",
          unVideoUrl:"",
          name:'',
          loading:"",
          progress:0,
          progressImg:0,
          duration:0,
          hz:'',
          checked:false
        }
        // 初始化内容
        this.audio = {
          unAudioUrl:"",
          name:'',
          loading:false,
          progress:0,
          duration:0,
          hz:'',
          checked:false
        }
        setTimeout(()=>{
          // 加载分组列表
          this.init()
        },60)
        this.$refs.popup.open()
      },
      // 图片选择文件
      onImageFileChange(ev){
        if(this.beforeUpload(ev.raw)){
          // 获取资源的URL地址
          this.image.unImageUrl = window.URL.createObjectURL(new Blob([ev.raw]))
          this.image.File = ev.raw
          this.image.name = ev.name
          this.image.hz = ev.name.substr(ev.name.indexOf("."))
        }
      },
      // 图片开始上传
      updataImage(){
        if(!this.image.unImageUrl){
          this.$message.error(this.$t('请选择图片文件'))
          return
        }
        if(!this.image.name){
          this.$message.error(this.$t('请输入名字'))
          return
        }
        if(!this.fz.list.length){
          this.$message.error(this.$t('请新建分组'))
          return
        }
        if(this.image.unImageUrl){
          if(this.image.name.length > 16){
            this.$message.error(this.$t("名称长度最大不超过16位"))
            return
          }
          this.image.loading = true
          console.log(this.image.File);
          this.$apis.sc.upload(this.image.File,(progress,p)=>{
            this.image.progress = progress*100
          }).then(res=>{
            this.image.progress = 0
            this.image.unAudioUrl = res
            let data = {
              coverImg:'',
              duration:'',
              groupingId:this.fz.list[this.fz.current].id,
              name:this.image.name,
              resource:res,
              type:this.type
            }
            if(data.name.toLocaleLowerCase().indexOf(this.image.hz) == -1){
              data.name = data.name + this.image.hz
            }
            // 图片
            this.$apis.sc.addMaterial(data).then(res=>{
              this.image.loading = false
              this.$message.success(this.$t("上传成功"))
              this.$emit('success',data)
              this.close()
            })
            
            
          })
          
        }
      },
      
      
      // 视频开始上传
      updataVideo(){
        if(!this.video.unVideoUrl){
          this.$message.error(this.$t('请上传视频文件'))
          return
        }
        if(!this.video.unImageUrl){
          this.$message.error(this.$t('请上传封面'))
          return
        }
        if(!this.video.name){
          this.$message.error(this.$t('请输入名字'))
          return
        }
        if(this.video.name.length > 16){
          this.$message.error(this.$t("名称长度最大不超过16位"))
          return
        }
        if(!this.fz.list.length){
          this.$message.error(this.$t('请新建分组'))
          return
        }
        if(!this.video.checked){
          this.$message.error(this.$t('请查看并勾选协议'))
          return
        }
        if(this.video.unVideoUrl){
          this.video.loading = true
          this.video.progress = 0
          let data = {
            coverImg:this.video.unImageUrl,
            duration:this.video.duration,
            groupingId:this.fz.list[this.fz.current].id,
            name:this.video.name,
            resource:this.video.unVideoUrl,
            size:this.video.size,
            type:this.type
          }
          if(data.name.toLocaleLowerCase().indexOf(this.video.hz) == -1){
            data.name = data.name + this.video.hz
          }
          this.$apis.sc.addMaterial(data).then(res=>{
            this.video.loading = false
            this.$emit('success',data)
            this.close()
          })
        }
      },
      // 视频选择文件
      onVideoFileChange(ev){
        if(this.beforeUpload(ev.raw)){
          this.video.size = ev.raw.size
          this.video.unVideoUrl = ''
          setTimeout(()=>{
            this.video.unVideoUrl = window.URL.createObjectURL(new Blob([ev.raw]))
            
            this.video.name = ev.name
            this.video.hz = ev.name.substr(ev.name.indexOf("."))
            // 获取视频时长
            let audioElement = new Audio(this.video.unVideoUrl);
            audioElement.addEventListener("loadedmetadata", (_event)=>{
                let duration = audioElement.duration;
                this.video.duration = duration
                // 视频上传
                this.$apis.sc.upload(ev.raw,(progress,p)=>{
                  this.video.progress = parseInt(progress*100)
                }).then(res=>{
                  this.video.progress = 0
                  this.video.unVideoUrl = res
                })
            });
            
          },60)
          
        }
        
        
        
      },
      // 封面
      onVideoCoverFileChange(ev){
        if(this.beforeUpload(ev.raw)){
          this.video.unImageUrl = window.URL.createObjectURL(new Blob([ev.raw]))
          this.$apis.sc.upload(ev.raw,(progress,p)=>{
            this.video.progressImg = parseInt(progress*100)
          }).then(res=>{
            this.video.progressImg = 0
            this.video.unImageUrl = res
          })
        }
        
      },
      
      // 音频选择文件
      onAudioFileChange(ev){
        if(this.beforeUpload(ev.raw)){
          this.audio.unAudioUrl = window.URL.createObjectURL(new Blob([ev.raw]))
          this.audio.hz = ev.name.substr(ev.name.indexOf("."))
          // 获取时长
          let audioElement = new Audio(this.audio.unAudioUrl);
          audioElement.addEventListener("loadedmetadata", (_event)=>{
              let duration = audioElement.duration;
              this.audio.name = ev.name
              this.audio.duration = duration
              this.$apis.sc.upload(ev.raw,(progress,p)=>{
                this.audio.progress = parseInt(progress*100)
              }).then(res=>{
                this.audio.progress = 0
                this.audio.unAudioUrl = res
              })
          });
        }
        
      },
      // 音频确定
      updataAudio(){
        if(!this.audio.unAudioUrl){
          this.$message.error(this.$t('请上传音频文件'))
          return
        }
        if(!this.audio.name){
          this.$message.error(this.$t('请输入名字'))
          return
        }
        if(this.audio.name.length > 16){
          this.$message.error(this.$t("名称长度最大不超过16位"))
          return
        }
        if(!this.fz.list.length){
          this.$message.error(this.$t('请新建分组'))
          return
        }
        if(!this.audio.checked){
          this.$message.error(this.$t('请查看并勾选协议'))
          return
        }
        if(this.audio.unAudioUrl){
          this.audio.loading = true
          this.audio.progress = 0
          let data = {
            coverImg:'',
            duration:this.audio.duration,
            groupingId:this.fz.list[this.fz.current].id,
            name:this.audio.name,
            resource:this.audio.unAudioUrl,
            type:this.type
          }
          if(data.name.toLocaleLowerCase().indexOf(this.audio.hz) == -1){
            data.name = data.name + this.audio.hz
          }
          
          this.$apis.sc.addMaterial(data).then(res=>{
            this.audio.loading = false
            this.$emit('success',data)
            this.close()
          })
        }
        
      },
      // 上传前钩子
      beforeUpload(file) {
        let isLt2M = ''
        if(this.type == 1){
          isLt2M = file.size / 1024 / 1024 < 5;
          if (!isLt2M) {
            this.$message.error('上传图片大小不能超过 5MB!');
            this.image.loading = false
          }
        }else{
          isLt2M = file.size / 1024 / 1024 < 50;
          if (!isLt2M) {
            this.$message.error('上传文件大小不能超过 50MB!');
            this.audio.loading = false
          }
        }
        return isLt2M;
      },
      // 关闭
      close(){
        if(this.image.loading){
          this.$message.info(this.$t('请等待上传结束'))
          return
        }
        if(this.video.loading){
          this.$message.info(this.$t('请等待上传结束'))
          return
        }
        if(this.audio.loading){
          this.$message.info(this.$t('请等待上传结束'))
          return
        }
        this.$refs.popup.close()
      },
      // 新建分组
      addGroup(){
        this.$refs.PopupCard.open()
      },
      // 新建分组添加
      handleAdd(){
        if(!this.popup.addGroup.groupName.trim()){
          this.$message.error(this.$t("请输入分组名"))
          return
        }
        this.popup.addGroup.loading = true
        let data = {
          name: this.popup.addGroup.groupName.trim(),
          type: this.type
        }
        this.$apis.sc.addGrouping(data).then(res=>{
          setTimeout(()=>{
            this.popup.addGroup.loading = false
            this.$message.success(this.$t('添加成功'))
            this.popup.addGroup.groupName = ""
            this.$refs.PopupCard.close()
            this.init()
            this.$emit('addGroup')
          },500)
        }).catch(err=>{
          this.popup.addGroup.loading = false
        })
      },
      // 关闭pupop
      handleClose(){
        this.$refs.PopupCard.close()
        this.init()
      },
    }
  }
</script>

<style lang="scss" scoped>
  .updataFile{
    .Card{
      padding: 10px 0 26px 28px;
      &.image{
        .item{
          margin-top: 27px;
          &.item-file{
            margin-top: 15px;
          }
          .item-name{
            width: 5.5em;
            height: 34px;
          }
          .audio-name{
            font-size: 14px;
            color: #696B76;
            line-height: 19px;
            height: 34px;
            margin-right: 14px;
            max-width: 302px;
          }
          .audio-button{
            width: 111px;
            height: 32px;
            border-radius: 5px;
            &::before{
              content: '';
              display: flex;
              width: 16px;
              height: 16px;
              background-image: url(../../public/assets/image/sc/add_2@2x.png);
              background-repeat: no-repeat;
              background-size: 100% 100%;
              margin-right: 9px;
            }
          }
          .upload-file-text{
            width: 433px;
            .image-tip{
              font-size: 14px;
              color: #2C7BE5;
              line-height: 19px;
            }
            .tip-icon{
              width: 12px;
              height: 12px;
              margin-right: 2px;
            }
            .tip{
              font-size: 12px;
              color: #696B76;
              line-height: 17px;
            }
          }
          .item-updata-image{
            width: 171px;
            height: 171px;
            border-radius: 3px;
            border: 1px solid #E3E3E3;
            &.image-fm{
              width: 137px;
              height: 102px;
            }
            .image-tip{
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%);
            }
            .progress-box{
              width: 100%;
              height: 100%;
              background-color: rgba(0,0,0,0.4);
              position: absolute;
              top: 0;
              left: 0;
              .progress{
                width: 130px;
                height: 5px;
                background-color: #FFFFFF;
                border-radius: 3px;
                overflow: hidden;
                .progress-b{
                  height: 5px;
                  border-radius: 3px;
                  background-color: #FFAE2E ;
                }
              }
            }
          }
          
          .item-input{
            width: 433px;
            height: 34px;
            border-radius: 3px;
            border: 1px solid #E3E3E3;
            padding: 0 11px;
            input{
              width: 100%;
            }
            .item-input-number{
              font-size: 14px;
              color: #696B76;
              line-height: 19px;
            }
            .dropdown{
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 5px solid #989BB0;
              position: absolute;
              right: 15px;
            }
          }
          
          
        }
        
        .xieyi{
          margin-top: 17px;
          .xieyi-check{
            width: 12px;
            height: 12px;
            border: 1px solid #FFAE2E;
            border-radius: 100%;
            margin-right: 7px;
            &.checked::after{
              content: "";
              width: 8px;
              height: 8px;
              border-radius: 100%;
              background-color: #FFAE2E;
            }
          }
          .xieyi-text{
            font-size: 14px;
            color: #A4A5AC;
            line-height: 19px;
            .link{
              color: #2C7BE5;
            }
          }
        }
        
        .new-group{
          margin-top: 15px;
          color: #2C7BE5;
          .item-name{
            width: 5.5em;
          }
        }
        
        .btns{
          margin-top: 29px;
          .button{
            margin: 0 25px;
            width: 81px;
            height: 32px;
            border-radius: 5px;
          }
        }
        
      }
    }
    
    .PopupCard{
      width: 393px;
      padding: 24px 0 22px;
      padding: 28px 28px 18px;
      .title{
        font-size: 15px;
        color: #3B3F56;
        line-height: 21px;
      }
      
      .searc-input{
        width: 232px;
        height: 34px;
        border-radius: 3px;
        border: 1px solid #DBDBE7;
        margin-top: 22px;
        .input-in{
          padding-left: 12px;
        }
        .string-length{
          font-size: 12px;
          color: #C8CAD6;
          line-height: 17px;
          margin-right: 15px;
        }
      }
      
      .popup-btns{
        width: 232px;
        margin-top: 22px;
        .button{
          width: 90px;
          height: 32px;
          border-radius: 5px;
        }
        &.btns-all{
          width: 100%;
          .button{
            margin: 0;
          }
        }
      }
    }
  }
</style>
