<template>
  <div class="Violation">
    <card class="Card">
      <div class="card-title">{{$t('违规记录')}}</div>
      <div class="table-header row">
        <div class="tale-col Regular col-1">{{$t('处罚类型')}}</div>
        <div class="tale-col Regular col-2">{{$t('处罚时间')}}</div>
        <div class="tale-col Regular col-3">{{$t('解除时间')}}</div>
        <div class="tale-col Regular col-4 flex-1">{{$t('处罚理由')}}</div>
      </div>
      <div class="table">
        <div class="table-item row" v-for="(item,index) of list" :key="index">
          <div class="tale-col col-1">{{['',$t('冻结账号'),$t('文章屏蔽')][item.auditType]}}</div>
          <div class="tale-col col-2"><span v-if="item.startTime">{{item.startTime | getTime}}</span></div>
          <div class="tale-col col-3"><span>{{item.endPunishment}}</span></span></div>
          <div class="tale-col col-4 flex-1">{{item.reason}}</div>
        </div>
      </div>
      <loadMore :status="moreStatus" @more="scrollBottom"></loadMore>
    </card>
  </div>
</template>

<script>
  export default {
    name: 'Violation',
    data(){
      return {
        list:[],
        page:1,
        total:1,
        moreStatus:'more'
      }
    },
    created() {
      this.$store.commit('scrollBottomSet',this.scrollBottom)
      this.init()
    },
    mounted() {
      
    },
    computed:{
      
    },
    methods:{
      init(){
        let data = {
          pageNum:this.page,
          pageSize:40
        }
        this.moreStatus = 'loading'
        this.$apis.user.getPunishRecord(data).then(res=>{
          this.total = Math.ceil(res.data.total / 40)
          
          res.data.punishRecord = res.data.punishRecord || []
          if(this.page == 1){
            this.list = res.data.punishRecord 
          }else{
            this.list = [...this.system.dataList,...res.data.punishRecord]
          }
          if(this.page >= this.total){
            this.moreStatus = 'no-more'
          }else{
            this.moreStatus = 'more'
          }
        })
      },
      scrollBottom(){
        if(this.page < this.total){
          this.page ++ 
          this.init()
        }
      }
    }
  }
</script>

<style lang="scss">
  .Violation{
    .Card{
      padding: 11px 0 11px;
      .card-title{
        margin-bottom: 17px;
        margin-left: 17px;
      }
      .table-header{
        height: 44px;
        padding-left: 51px;
        border-bottom: 1px solid rgba(219, 219, 231, 0.26);
        .tale-col{
          text-align: center;
          font-size: 12px;
          color: #989BB0;
          line-height: 17px;
          text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5), 0px 2px 3px rgba(220, 231, 239, 0.5);
        }
        .col-1{
          width: 100px;
          text-align: left;
        }
        .col-2,.col-3{
          width: 176px;
        }
      }
      .table{
        padding-left: 51px;
        .tale-col{
          padding: 22px 0;
          text-align: center;
          color: #696B76;
          text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5), 0px 2px 3px rgba(220, 231, 239, 0.5);
          border-bottom: 1px solid rgba(219, 219, 231, 0.26);
        }
        .col-1{
          width: 100px;
          text-align: left;
        }
        .col-2,.col-3{
          width: 176px;
        }
      }
    }
  }
</style>
