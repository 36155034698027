import host from './host.js'
console.log(host)

const config = {
  // 格式化写localStorage，配合getData使用
  setData(key,data){
    if(typeof data == 'object'){
      data = JSON.stringify({
        type:'object',
        data:data
      })
    }else{
      data = JSON.stringify({
        type:typeof data,
        data:data
      })
    }
    return localStorage.setItem(key,data)
  },
  // 格式化读localStorage 配合setData使用
  getData(key){
    let data = localStorage.getItem(key)
    try{
      data = JSON.parse(data)
      return data.data
    }catch(e){
      //TODO handle the exception
      return data
    }
  },
  /**
   * 清空localStorage
   * @param {Array} keys
   * 要保留的keys
   */
  clearData(keys){
    let keysText = ""
    if(Array.isArray(keys) || typeof keys == 'string'){
      keysText = keys
    }
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i)
      if(keysText.indexOf(key) == -1){
        localStorage.removeItem(key)
      }
    }
  },
  // 删除localStorage
  removeData(keys){
    if(typeof keys == 'string'){
      localStorage.removeItem(keys)
    }
    if(Array.isArray(keys)){
      keys.map(key=>{
        localStorage.removeItem(key)
      })
    }
  },
  // 插入富文本内容，封装
  insert(ev,editor){
    try{
      let Html = ''
      // 插入链接
      if(ev.type == 'link'){
        if(ev.name){
          Html = `<a href="${ev.url}">${ev.name}</a>`
        }
      }else if(ev.type == 'image'){ // 插入图片
        Html = `<img class="wangE"  src="${ev.url}" >`
      }else if(ev.type == 'video'){//插入视频
        
         Html = `<video class="wangE" controls style="width: 137px;height:102px" src="${ev.url}"/ >`
        // let host = location.origin + `/#/Html?type=video&url=`+ ev.url
        // Html = `<iframe src="${host}" style="width:137px;height:102px;" frameborder="0"></iframe>`
      }else if(ev.type == 'audio'){// 插入音频
        //  Html = `<audio class="wangE" controls style="width: 137px;height:102px" src="${ev.url}"/ >`
        // let host = location.origin + `/#/Html?type=audio&name=${ev.item.name}&duration=${ev.item.duration}&url=`+ev.url
       // Html = `<iframe src="${host}" style="width:137px;height:67px;" frameborder="0"></iframe>`
      }else if(ev.type == 'emoji'){// 插入表情
        Html = `<span style="font-size: 24px;">${ev.info}</span>`
      }

      editor && editor.cmd.do('insertHTML',Html)
      console.log(Html);
      return Html
    }catch(err){
      console.log(err)
    }
  }  
}

export default {
  install(Vue,opt = {}){
    Vue.prototype.$setData = config.setData
    Vue.prototype.$getData = config.getData
    Vue.prototype.$clearData = config.clearData
    Vue.prototype.$removeData = config.removeData
    Vue.prototype.$insert = config.insert
    Vue.prototype.$host = host.host
    Vue.prototype.$picHost = host.picHost
  }
}
