<template>
  <div class="Popup row col js-center" v-if="status" @click.stop :class="{open:show}" :style="{zIndex:zIndex}">
    <div @click.stop>
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Popup',
    data(){
      return {
        status:false,
        show:false
      }
    },
    props:{
      maskClick:{
        type:Boolean,
        default:true
      },
      zIndex:{
        type:[String,Number],
        default:""
      }
    },
    created() {
      console.log(this.zIndex);
    },
    mounted() {
      
    },
    computed:{
      
    },
    methods:{
      open(){
        this.status = true
        setTimeout(()=>{
          this.show = true
        },20)
      },
      close(){
        this.show = false
        setTimeout(()=>{
          this.status = false
        },400)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .Popup{
    transition: all 0.5s;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    &.open{
      opacity: 1;
    }
  }
</style>
