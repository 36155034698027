<template>
  <div class="leaveComments">
    <navBar :list="navBar.list" :current.sync="navBar.current" @change="navBarChange"></navBar>
    <card class="Card" v-if="navBar.current == 0">
      <div class="times row">
        <div class="time-name Regular">最近消息时间</div>
        <div class="time-item Regular row" v-for="(item,index) of times.list" :key="index" :class="{'button-ho':index == times.current}" @click="times.current = index">{{item.name}}</div>
      </div>
      <div class="list">
        <div class="list-title pos-rel row">
          <div class="user-info Regular row">用户</div>
          <div class="message Regular row flex-1">消息</div>
          <div class="operate Regular row js-center">操作</div>
        </div>
        <!-- <loadMore status="loading" v-if="Object.keys(conversationList).length == 0"></loadMore> -->
        <div class="list-item pos-rel row" v-for="(item,key) in conversationList" :key="item.targetId">
          <div class="user-info row">
			  
            <div class="avatar"><img :src=" host+usersInfo[item.targetId].headImg" ></div>
            <div class="user-info-text">
              <div class="user-name row">
                <div class="name line-1">{{usersInfo[item.targetId].nickname}}</div>
                <div class="stutus row js-center" v-if="item.latestMessage.senderUserId == $store.state.rongYunId">{{'已回复'.$t()}}</div>
              </div>
              <div class="time Regular">{{item.latestMessage.sentTime | getMsgTime}}</div>
            </div>
          </div>
          <div class="message flex-1" v-if="item.latestMessage.messageType == 'RC:TxtMsg'" v-html="item.latestMessage.content.content"></div>
          <div class="message flex-1" v-if="item.latestMessage.messageType == 'RC:ImgMsg'"><img :src="item.latestMessage.content.imageUri" ></div>
          <div class="message flex-1" v-if="item.latestMessage.messageType == 'RC:HQVCMsg'">
            <!-- <iframe :src="getHost(item.latestMessage,'audio')" style="width:137px;height:67px;" frameborder="0"></iframe> -->
            <div class="audio">
              <div class="name">{{item.latestMessage.content.name}}</div>
              <div class="duration" style=''>{{item.latestMessage.content.duration | getDuration}}</div>
              <img  @click="plays(item.latestMessage,item.latestMessage.content.remoteUrl)" class="plays pointer" src="assets/image/sc/play_an@2x.png" v-if="!item.latestMessage.playStatue" >
              <div class="play-icon playing_ pointer" v-if="item.latestMessage.playStatue" @click.stop="plays(item.latestMessage,item.latestMessage.content.remoteUrl)">
                <div class="playing bar1"></div>
                <div class="playing bar2"></div>
                <div class="playing bar3"></div>
              </div>
            </div>
          </div>
          <div class="message flex-1" v-if="item.latestMessage.messageType == 'RC:FileMsg' && item.latestMessage.content.type == 'mp4'">
            <!-- <iframe :src="getHost(item.latestMessage,'video')" style="width:138px;height:102px;" frameborder="0"></iframe> -->
            <video width="138" height="102" controls>
              <source :src="item.latestMessage.content.fileUrl" type="video/mp4"></source>
            </video>
          </div>
          
          <div class="message-text" ></div>
          <div class="operate row">
            <div class="operate-button" @click="sendMessage(item)">回复</div>
            <div>｜</div>
            <div class="operate-button pointer" @click="deleteConversation(item)">删除对话</div>
          </div>
        </div>
      </div>
    </card>
    <card class="Card" v-if="navBar.current == 1">
      <div class="times row">
        <div class="time-item Regular row" v-for="(item,index) of system.list" :key="index" :class="{'button-ho':index == system.current}" @click="system.current = index">{{item.name}}{{item.count?'('+item.count+')':''}}</div>
      </div>
      <div class="list">
        <div class="list-item pos-rel row" v-for="(item,index) of system.dataList" :key="index">
          <div class="message-time row flex-1">
            <div class="system-msg-icon" :class="{read:item.read == 1}"><img src="assets/image/menu/notice@2x.png" ></div>
            <div class="user-info-text">
              <div class="user-name row">
                {{item.title}}
              </div>
              <div class="time Regular">{{item.createdAt | getTime}}</div>
            </div>
          </div>
          <div class="operate row"  v-if="item.type != 5"  @click="systemDetail(item)">
            <div class="operate-title Regular">{{$t('查看详情')}}</div>
            <div class="operate-icon"><img src="assets/image/menu/arrow@2x.png" ></div>
          </div>
          <div class="operate row"  v-if="item.type == 5"  @click="systemDetail(item)">
            <div class="operate-title Regular">{{$t('已阅')}}</div>
          </div>
        </div>
        <loadMore :status="system.moreStatus" @more="scrollBottom"></loadMore>
      </div>
    </card>
    
    <!-- 系统消息-详情 -->
    <Popup ref="systemDeiail">
      <card class="systemDeiail">
        <div class="scroll-view">
          <div class="systemDeiail-title">{{system.Detail.title}}</div>
          <div class="systemDeiail-content" v-html="system.Detail.content"></div>
        </div>
        <Button class="button" @click="systemDeiailClose">确定</Button>
      </card>
    </Popup>
    
    <!-- 回复消息 -->
    <Popup ref="sendMessage">
      <card class="sendMessage">
        <div class="msgList scroll-view" id="msgList">
          <!-- <div class="row js-center" v-if="!messageList.length"><i class="el-icon-loading"></i></div> -->
          <div class="messageList row ai-top" v-for="(item,index) of messageList" :key="index">
            <div class="avatar" :class="{show:item.senderUserId != $store.state.rongYunId}"><img v-if="usersInfo[item.senderUserId]" :src="usersInfo[item.senderUserId].headImg" ></div>
            <div class="message-content flex-1" :class="{right:item.senderUserId == $store.state.rongYunId}">
              <div class="message-text" v-html="item.content.content" v-if="item.messageType == 'RC:TxtMsg'"></div>
              <div class="message-text" v-if="item.messageType == 'RC:ImgMsg'"><img :src="item.content.imageUri" ></div>
              <div class="message flex-1" v-if="item.messageType == 'RC:HQVCMsg'">
                <!-- <iframe :src="getHost(item,'audio')" style="width:137px;height:67px;" frameborder="0"></iframe> -->
                <!-- item.content.name}&duration=${item.content.duration}&url=`+encodeURIComponent(item.content.remoteUrl) -->
                <div class="audio">
                  <div class="name">{{item.content.name}}</div>
                  <div class="duration" style=''>{{item.content.duration | getDuration}}</div>
                  <img  @click="plays(item,item.content.remoteUrl)" class="plays pointer" src="assets/image/sc/play_an@2x.png" v-if="!item.playStatue" >
                  <div class="play-icon playing_ pointer" v-if="item.playStatue" @click.stop="plays(item,item.content.remoteUrl)">
                    <div class="playing bar1"></div>
                    <div class="playing bar2"></div>
                    <div class="playing bar3"></div>
                  </div>
                </div>
              </div>
              <div class="message flex-1" v-if="item.messageType == 'RC:FileMsg' && item.content.type == 'mp4'">
                <video width="138" height="102" controls>
                  <source :src="item.content.fileUrl" type="video/mp4"></source>
                </video>
                <!-- <iframe :src="getHost(item,'video')" style="width:138px;height:102px;" frameborder="0"></iframe> -->
              </div>
              <div class="time Regular">{{item.sentTime | getMsgTime}}</div>
            </div>
            <div class="avatar" :class="{show:item.senderUserId == $store.state.rongYunId}"><img :src="userInfo.headImg" ></div>
          </div>
        </div>
        <div class="reply-head row">
          <div class="reply-head-item pointer" @click="insert('emoji')"><img src="assets/image/menu/smile@2x.png" ></div>
          <div class="reply-head-item pointer" @click="insert('image')"><img src="assets/image/menu/pic@2x.png" ></div>
          <div class="reply-head-item pointer video-icon" @click="insert('video')"><img src="assets/image/menu/video@2x.png" ></div>
          <div class="reply-head-item pointer" @click="insert('audio')"><img src="assets/image/menu/music@2x.png" ></div>
          <div class="reply-head-item pointer" @click="insert('link')"><img src="assets/image/menu/link@2x.png" ></div>
        </div>
        <div class="input">
          <textarea v-model="message" class="textarea" placeholder="回复"></textarea>
        </div>
        <div class="btns row js-center">
          <Button class="button button-ho" @click="close">{{"取消".$t()}}</Button>
          <Button class="button" @click="sendMsg">{{"发送".$t()}}</Button>
        </div>
      </card>
    </Popup>
    
    <!-- 插入内容组件 -->
    <insert ref="insert" @change="insertChange"></insert>
    
  </div>
</template>

<script>
  export default {
    name: 'leaveComments',
    data(){
      return {
        navBar:{
          list:[
            {
              name:this.$t('留言消息'),
              key:0,
              tag:false,
            },
            {
              name:this.$t('系统消息'),
              key:1,
              tag:false
            }
          ],
          current:0
        },
        times:{
          list:[
            {
              name:this.$t("近十天"),
              key:0
            },
            {
              name:this.$t("今天"),
              key:1
            },
            // {
            //   name:this.$t("昨天"),
            //   key:2
            // },
            {
              name:this.$t("近三天"),
              key:3
            },
          ],
          current:0
        },
        system:{
          list:[
            {
              name:this.$t("全部"),
              key:1,
              count:0
            },
            {
              name:this.$t("未读"),
              key:2,
              count:0
            }
          ],
          dataList:[],
          current:0,
          page:1,
          total:1,
          count:0,  
          moreStatus:'more',
          Detail:{}
        },
        conversationList:[],
        messageList:[],
        sendObj:{},
        more:false,
        message:'',
        newMessage:{},
        usersInfo:{},
		host:'http://admin.tecood.com/admin'
      }
    },
    created() {
      this.$store.commit('scrollBottomSet',this.scrollBottom)
      this.getSystemMessageList()
      this.IM.$connectReady(()=>{
        this.getConversationList()
      })
      
      this.IM.eventMessage((ev)=>{
        this.newMessage = ev
        setTimeout(this.getConversationList,120)
        if(this.sendObj.targetId == ev.senderUserId){
          this.IM.getMessagesList(this.sendObj.targetId).then(res=>{
            this.messageList = res.data.list
            this.more = res.data.hasMore
          })
          console.log("当前会话的窗口");
        }
      })
    },
    mounted() {
      
    },
    computed:{
      userInfo(){
        return this.$store.getters.userInfo
      }
    },
    watch:{
      'navBar.current'(){
        if(this.navBar.current == 0){
          
        }else{
          this.system.page = 1
          this.system.moreStatus = 'more'
          this.getSystemMessageList()
        }
      },
      'times.current'(){
        // 判断状态
        this.IM.$connectReady(()=>{
          // 获取会话
          this.getConversationList()
        })
      },
      'system.current'(){
        this.system.page == 1
        this.system.moreStatus = 'more'
        this.getSystemMessageList()
      }
    },
    methods:{
      // 获取系统消息
      getSystemMessageList(){
        let data = {
          pageNum:this.system.page,
          pageSize:40,
          type:this.system.list[this.system.current].key
        }
        this.system.moreStatus = 'loaading'
        this.$apis.msg.getMessageList(data).then(res=>{
          this.system.list[0].count = res.data.allTotal
          this.system.list[1].count = res.data.unreadTotal
          this.navBar.list[1].tag = res.data.unreadTotal>0?true : false
          this.system.count = res.data.total
          this.system.total = parseInt(res.data.total / 40) + 1
          res.data.systemMessages = res.data.systemMessages || []
          if(this.system.page == 1){
            this.system.dataList = res.data.systemMessages 
          }else{
            this.system.dataList = [...this.system.dataList,...res.data.systemMessages]
          }
          if(this.system.page >= this.system.total){
            this.system.moreStatus = 'no-more'
          }else{
            this.system.moreStatus = 'more'
          }
        })
      },
      // 获取会话列表
      getConversationList(){
        let type = this.times.list[this.times.current].key
        let startTime = 0
        let newDate = new Date().getTime()
        let nowDayDate = new Date(this.$getDate(newDate) + ' 00:00:00').getTime()
        if(type == 0){
          startTime = newDate - (86400000 * 10)
        }
        if(type == 1){
          startTime = nowDayDate
        }
        // if(type == 2){
        //   startTime = nowDayDate - 86400000
        // }
        if(type == 3){
          startTime = nowDayDate - (86400000 * 2)
        }
        
        this.IM.getConversationList(startTime).then(res=>{
        console.log(res,'返回结果-----');
          if(res.code == 0){
            let conversationLists = {}
			let resData = []
            let readMsg = false
            let radeMsgNumber = 0
            let runName = ''
			       for (let i = 0; i < res.data.length; i++) {
			         if(res.data[i].targetId.length>10 && "53b8874a274641dd91239c2688474471"!=res.data[i].targetId){
						 resData.push(res.data[i])
					 }
			       }
          let rongIds = resData.map(item=>{
			  
              radeMsgNumber += item.unreadMessageCount
              if(item.unreadMessageCount > 0){
                readMsg = true
              }
              item.latestMessage.playStatue = false
              item.latestMessage.player = null
				   conversationLists[item.targetId] = item
				    runName = item.targetId
					 return item.targetId
             
            })
            this.$store.commit('radeMsgNumberSet',radeMsgNumber)
            this.navBar.list[0].tag = readMsg
           // console.log(rongIds,'===参数----')
            if(rongIds.length > 0) {
                this.$apis.menu.getRongInfo(rongIds).then(ress=>{
					console.log(ress,'===介入平----')
					console.log(  ress.data.users,'===用户----')
                  let info = {}
                  ress.data.users.map(item=>{
                    info[item.id] = item
                  })
                  this.usersInfo = info
				  
                  this.conversationList = conversationLists
                })
            }
			console.log(this.usersInfo,"===结果")
          }
          
        })
      },
      // 删除会话
      deleteConversation(item){
        this.$confirm(this.$t('确定删除该会话吗?'), this.$t('提示'), {
          confirmButtonText: this.$t('确定'),
          cancelButtonText: this.$t('取消'),
          type: 'warning'
        }).then(res=>{
          this.IM.removeConversation(item).then(res=>{
            this.$message.success('删除成功'.$t())
            setTimeout(this.getConversationList,120)
          })
        })
      },
      // 发送消息
      sendMessage(item){
        item.read = true
        this.IM.readMsg(item.targetId).then(res=>{
          if(res.code == 0){
            this.getConversationList()
          }
        })
        this.sendObj = item
        this.getMsg()
        this.$refs.sendMessage.open()
        // 刷新头消息
        this.$store.state.headerMsg && this.$store.state.headerMsg()
        setTimeout(()=>{this.scroll(msgList)},200)
      },
      // 发送
      sendMsg(){
        if(!this.message.trim()) return
        this.IM.sendMessage(this.sendObj.targetId,{content:this.message},this.IM.MsgType.text).then(res=>{
          this.message = ''
          setTimeout(()=>{this.getMsg()},80)
          setTimeout(()=>{this.scroll(msgList)},120)
          this.getMsg()
        })
      },
      async insertChange(ev){
        if(ev.type == 'emoji'){
          this.message = this.message + ev.info
          return
        }
        let msgContent;
        if(ev.type == 'image'){
          let base64 = await this.getBase64Img({url:ev.url})
          
          // 压缩图片尺寸，获得缩略图
          let pro = function(){
            return new Promise((r,j)=>{
              let img = document.createElement('img')
              img.src = base64
              img.onload = function(){
                var w = 120;    // 获取Base64图片的原始图片大小
                var h = (img.naturalHeight * 120) / img.naturalWidth; 
                //生成canvas
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');    // 创建属性节点
                var anw = document.createAttribute("width"); 
                anw.nodeValue = w;
                var anh = document.createAttribute("height");
                anh.nodeValue = h;
                canvas.setAttributeNode(anw);
                canvas.setAttributeNode(anh);
                ctx.drawImage(img, 0, 0, w, h);
                // .7值越小，所绘制出的图像越模糊
                r(canvas.toDataURL('image/jpeg',0.7));
              }
            })
          }
          let cov = await pro()
          
          msgContent = {
            imageUri:ev.url,
            content:cov,
            burnDuration: 0,
            isBurnAfterRead: false,
            isFull: false,
          }
          console.log(msgContent);
        }
        if(ev.type == 'video'){
          msgContent = {
            fileUrl: ev.url,
            name: ev.name,
            size: ev.info.size,
            type: "mp4",
          }
        }
        if(ev.type == 'audio'){
          msgContent = {
            burnDuration: 0,
            duration: ev.info.duration,
            isBurnAfterRead: false,
            name: ev.info.name,
            remoteUrl:ev.url,
          }
        }
        if(ev.type == 'link'){
          msgContent = {content:ev.name + `[${ev.url}]`}
        }
        
        this.IM.sendMessage(this.sendObj.targetId,msgContent,this.IM.MsgType[ev.type]).then(res=>{
          this.message = ''
          this.getMsg()
        })
      },
      getMsg(){
        this.IM.getMessagesList(this.sendObj.targetId).then(res=>{
          if(res.code == 0){
            res.data.list = res.data.list.map(item=>{
              item.playStatue = false
              item.player = null
              return item
            })
            this.messageList = res.data.list
            this.more = res.data.hasMore
          }
        })
      },
      insert(type){
        this.$refs.insert.open(type)
      },
      getHost(item,type){
        if(type=='audio'){
          return location.origin + `/Html?type=${type}&name=${item.content.name}&duration=${item.content.duration}&url=`+encodeURIComponent(item.content.remoteUrl)
        }else{
          return location.origin + `/Html?type=video&url=`+encodeURIComponent(item.content.fileUrl)
        }
      },
      plays(item,url){
        item.player = new Audio(url);
        if(!item.playStatue){
          item.player.play();
          item.playStatue=true
        }else{
          item.player.pause();
          item.player.load();
          item.playStatue=false
        }
      },
      close(){
        this.sendObj = {}
        this.getConversationList()
        this.$refs.sendMessage.close()
      },
      // 查看详情
      systemDetail(item){
        this.$apis.msg.readMessage({id:item.id}).then(res=>{
          this.system.page = 1
          this.getSystemMessageList()
          // 刷新头消息
          this.$store.state.headerMsg && this.$store.state.headerMsg()
        })
        if(item.type == 1){
          this.system.Detail = item
          this.$refs.systemDeiail.open()
        }
        if(item.type == 2){
          this.$router.push('/preview?type=wz&id='+item.objectId)
        }
        if(item.type == 3){
          this.$router.push('/userSet')
        }
        if(item.type == 4){
          this.$router.push('/userInfo')
        }
      },
      // queding
      systemDeiailClose(){
        this.$refs.systemDeiail.close()
      },
      navBarChange(){
        
      },
      scrollBottom(){
        if(this.navBar.current == 1){
          if(this.system.page < this.system.total){
            this.system.page ++ 
            this.getSystemMessageList()
          }
        }
      },
      scroll(scrollContent,type){
        setTimeout(()=>{
          if(type == 'top'){
            if(scrollContent.scrollTop > 0){
              scrollContent.scrollTo(0,scrollContent.scrollTop - 10)
              this.scroll(scrollContent,type)
            }else{
              scrollContent.scrollTo(0,0)
            }
          }else{
            if(scrollContent.scrollTop < scrollContent.scrollHeight - scrollContent.clientHeight){
              scrollContent.scrollTo(0,scrollContent.scrollTop + 10)
              this.scroll(scrollContent,type)
            }else{
            }
          }
        },1)
      }
    },
    filters:{
      getMsgTime(val){
        if(new Date(val).getDate() == new Date().getDate()){
          return new Date(val).formatDate('hh:mm')
        }else{
          return new Date(val).formatDate('MM-dd')
        }
      }
    }
  }
</script>

<style lang="scss">
  .leaveComments{
    .Card{
      min-height: 400px;
      .times{
        padding: 15px 0 9px 25px;
        .time-name{
          font-size: 12px;
          line-height: 17px;
        }
        .time-item{
          // width: 81px;
          height: 32px;
          margin-left: 36px;
          cursor: pointer;
          font-size: 12px;
          line-height: 17px;
          &.button-ho{
            width: 81px;
            border-color: #FFAE2E;
            color: #FFAE2E;
          }
        }
      }
      
      .list{
        .list-title{
          &::after{
            content: '';
            background: #DBDBE7;
            box-shadow: 0px 2px 3px 0px rgba(220,231,239,0.5);
            opacity: 0.26;
            height: 1px;
            width: 100%;
            position: absolute;
            bottom: 0;
          }
          div{
            height: 44px;
            color: #989BB0;
            font-size: 12px;
            line-height: 17px;
          }
          .user-info{
            margin-left: 51px;
            width: calc(361px - 51px);
            
          }
          
          .operate{
            width: 96px;
            margin: 0 38px;
          }
        }
        
      }
      .list-item{
        margin-left: 21px;
        padding: 14px 0 20px 0;
        &::after{
          content: '';
          background: #DBDBE7;
          box-shadow: 0px 2px 3px 0px rgba(220,231,239,0.5);
          opacity: 0.26;
          height: 1px;
          width: 100%;
          position: absolute;
          bottom: 0;
        }
        .user-info{
          width: calc(361px - 21px);
        }
        .avatar{
          width: 51px;
          height: 51px;
          border-radius: 100%;
          background-color: #DCE7EF;
          overflow: hidden;
        }
        .system-msg-icon{
          width: 20px;
          height: 20px;
          margin-right: 2px;
          // overflow: hidden;
          position: relative;
          &.read::after{
            content: '';
            display: inline-block;
            position: absolute;
            width: 5px;
            height: 5px;
            border-radius: 100%;
            background-color: #FE5555;
            right: -10px;
            top: -10px;
          }
        }
        .user-info-text{
          padding-left: 12px;
          .user-name{
            .name{
              max-width: calc(288px - 12px - 70px - 21px);
            }
            .stutus{
              width: 55px;
              height: 23px;
              background-color: rgba(255,174,46,0.21);
              color: #FFAE2E;
              font-size: 12px;
              line-height: 17px;
              border-radius: 12px;
              margin-left: 12px;
            }
          }
          .time{
            font-size: 12px;
            color: #696B76;
            line-height: 17px;
            margin-top: 7px;
          }
        }
        .message{
          width: 32em;
          img{
            width: 8em;
          }
        }
        .operate{
          color: #2C7BE5;
          text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
          margin-right: 38px;
          .operate-button{
            cursor: pointer;
          }
          .operate-title{
            font-size: 12px;
            color: #696B76;
            line-height: 17px;
            text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
            cursor: pointer;
          }
          .operate-icon{
            width: 11px;
            height: 11px;
            font-size: 0;
            margin-left: 6px;
          }
        }
      }
      
    }
    
    .systemDeiail{
      width: 633px;
      height: 633px;
      padding: 12px;
      .scroll-view{
        overflow-y: auto;
        height: calc(633px - 24px - 34px - 16px);
      }
      .systemDeiail-title{
        font-size: 20px;
        line-height: 26px;
      }
      .systemDeiail-content{
        margin-top: 16px;
      }
      .button{
        width: 120px;
        height: 34px;
        margin: 0 auto;
        margin-top: 16px;
        border-radius: 8px;
      }
    }
  
    .sendMessage{
      width: 589px;
      height: 549px;
      .msgList{
        height: 310px;
        overflow-y: auto;
        padding: 12px 25px;
        .messageList{
          margin-top: 19px;
          .avatar{
            opacity: 0;
            width: 51px;
            height: 51px;
            border-radius: 100%;
            overflow: hidden;
            &.show{
              opacity: 1;
            }
          }
          .message-content{
            padding: 0 12px;
            &.right{
              text-align: right;
            }
            .message-text{
              font-size: 14px;
              color: #696B76;
              line-height: 19px;
              word-break:break-all;
              img{
                width: 8em;
              }
            }
            .time{
              font-size: 12px;
              color: #696B76;
              line-height: 17px;
              margin-top: 12px;
            }
          }
        }
      }
      .reply-head{
        background-color: #FFFFFF;
        border: none;
        box-shadow: none;
        border-bottom: 1px solid rgba(219, 219, 231, 0.26);
      }
      .input{
        height: 134px;;
        padding: 16px 25px;
        .textarea{
          width: 100%;
          height: 100%;
          resize: none;
        }
      }
      .btns{
        margin-top: 19px;
        .button{
          width: 125px;
          height: 32px;
          border-radius: 5px;
          margin: 0 15px;
        }
        .button-ho{
          border-color: #FFAE2E;
          color: #FFAE2E;
        }
      }
    }
    
    
    .audio{
      width:137px;
      height:67px;
      border-radius: 5px;
      border: 1px solid #E8E3E3;
      background-color: #FFFFFF;
      padding:5px 7px;
      display: inline-block;
      position: relative;
      text-align: left;
      .name{
        font-size:12px;
        color:#696B76;
        line-height:17px;
        width:102px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        word-wrap: break-word;
      }
      .duration{
        margin-top:5px;
        font-size:12px;
        color:#696B76;
        line-height:17px;
      }
      .plays{
        position: absolute;
        width: 15px !important;
        height: 15px;
        right: 7px;
        bottom: 14px;
      }
      
      .play-icon{
        width: 15px;
        height: 15px;
        position: absolute;
        bottom: 14px;
        right: 7px;
        &.playing_{
          padding: 2px;
          border-radius: 100%;
          background-color: rgba(0,0,0,0.5);
          display: flex;
          align-items: center;
          justify-content: space-around;
        }
        .playing{
          animation: playing 1.3s ease infinite alternate;
          width: 2px;
          background-color: #FFFFFF;
          &.bar2{
            animation-delay: -2.4s;
          }
          &.bar3{
            animation-delay: -3.7s;
          }
        }
      }
    }
    
  }
</style>
