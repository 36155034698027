<template>
  <div class="steps">
    <div class="steps-item" v-for="(item,index) of list" :key="index">
      <div class="check-box" :class="{active:current>=index}">
        <div class="select" v-if="current>=index"></div>
      </div>
      <span>{{ item }}</span>
      <div class="line" v-if="index < list.length - 1"></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'steps',
    props:{
      list:{
        type:Array,
        default(){
          return []
        }
      },
      current:{
        type:[Number,String],
        default:0
      }
    },
    data(){
      return {
      }
    },
    created() {
      
    },
    mounted() {
      
    },
    computed:{
      
    },
    methods:{
     
    }
  }
</script>

<style lang="scss">
  .steps{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 23px 0;
    .steps-item{
      display: flex;
      align-items: center;
      .check-box{
        width: 20px;
        height: 20px;
        border-radius: 100%;
        border: 1px solid #D2D2D2;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 9px;
        &.active{
          border-color: #427CDF;
        }
        .select{
          width: 12px;
          height: 12px;
          background-color: #427CDF;
          border-radius: 100%;
        }
      }
      .line{
        width: 55px;
        height: 1px;
        background-color: rgba(151,151,151,0.22);
        margin: 0 15px 0 14px;
      }
      
      
    }
  }
</style>

