import * as RongIMLib from '@rongcloud/imlib-next'
import store from '../store'
let connectReadyFun = null
class Im {
  constructor(fun) {
    let options = {
      appkey:"e0x9wycfepjnq",
      logLevel:4
    }
    this.isConnect = false
    RongIMLib.init(options)
    const Events = RongIMLib.Events
    /**
     * 正在链接的事件状态
     */
    RongIMLib.addEventListener(Events.CONNECTING, () => {
      console.log('正在链接...')
    })
    
    /**
     * 链接到服务器会触发这个事件
     */
    RongIMLib.addEventListener(Events.CONNECTED, () => {
      console.log('连接成功')
      if(connectReadyFun){
        connectReadyFun()
      }
      this.isConnect = true
    })
    
    /**
     * 手动调用 disconnect 方法或者用户被踢下线 会触发这个事件
     */
    RongIMLib.addEventListener(Events.DISCONNECT, () => {
      console.log('连接中断，需要业务层进行重连处理')
    })
    
    /**
     * 链接出问题时，内部进行重新链接，会出发这个事件
     */
    RongIMLib.addEventListener(Events.SUSPEND, () => {
      console.log('链接中断，SDK 会尝试重连，业务层无需关心')
    })
    
    // 异常断开，重连
    RongIMLib.addEventListener(Events.SUSPEND,ev=>{
      
    })
    //收到已读回执-单聊
    RongIMLib.addEventListener(Events.READ_RECEIPT_RECEIVED,ev=>{
      
    })
    // 收到消息监听
    RongIMLib.addEventListener(Events.MESSAGES,ev=>{
      this.getConversationList(0).then(res=>{
        if(res.code == 0){
          let radeMsgNumber = 0
          res.data.map(item=>{
            radeMsgNumber += item.unreadMessageCount
          })
          store.commit('radeMsgNumberSet',radeMsgNumber)
        }
      })
      
      this.eventMessageFun && this.eventMessageFun(ev.messages[0])
    })
  }
  // 消息类型
  MsgType = {
    text:"TextMessage",
    image:"ImageMessage",
    video:"FileMessage",//"SightMessage",
    audio:"HQVoiceMessage",
    link:"TextMessage"
  }
  // 连接
  connect(token){
    this.token = token
    return RongIMLib.connect(token)
  }
  // 链接是否就绪
  $connectReady(fun){
    if(this.isConnect){
      fun(this.isConnect)
    }else{
      connectReadyFun = fun
    }
  }
  // 创建新消息监听
  eventMessage(fun){
    this.eventMessageFun = fun
  }
  
  // 获取会话列表
  getConversationList(startTime){
    return RongIMLib.getConversationList({
      count: 40,
      startTime: startTime,
      order: 1
    })
  }
  // 会话删除
  removeConversation(item){
    // 删除会话
    return RongIMLib.removeConversation({
        conversationType:item.conversationType,
        targetId: item.targetId,
    })
  }
  // 获取消息列表
  getMessagesList(targetId,options = {}){
    const conversation = {
      conversationType: RongIMLib.ConversationType.PRIVATE,
      targetId: targetId,
    }
    return RongIMLib.getHistoryMessages(conversation,options)
  }
  // 发送消息
  sendMessage(targetId,content,msgType,options = {}){
    // 定义消息投送目标会话
    const conversation = { conversationType: RongIMLib.ConversationType.PRIVATE, targetId: targetId }
    // 实例化待发送消息，RongIMLib.TextMessage 为内置文本型消息
    console.log(msgType);
    const message = new RongIMLib[msgType](content)
    // 发送
    return RongIMLib.sendMessage(conversation, message,options)
  }
  
  // 会话已读
  readMsg(targetId){
    const conversationType = RongIMLib.ConversationType.PRIVATE;
    return RongIMLib.clearMessagesUnreadStatus({ conversationType, targetId })
  }
  
  // 断开链接
  disconnect(){
    return RongIMLib.disconnect()
  }
}


export default Im