<template>
  <div class="login row col js-center">
    <div class="card">
      <div class="form row col">
        <div class="form-title w-100 row js-between">
          <div class="title">{{$t('企业助理 公众号')}}</div>
          <!-- <div class="login-type" v-if="loginType == 1" @click="loginType = 2">{{$t("账号登录")}}</div>
          <div class="login-type" v-if="loginType == 2" @click="loginType = 1">{{$t('扫码登录')}}</div> -->
        </div>
      <!--  <div class="login-type-form" v-if="loginType == 1">
          <div class="qrCode pos-rel row js-center">
            <img class="qrCode-img w-100 h-100"  v-if="qrCode.status != 0" :src="qrCode.img" >
            <i class="el-icon-loading" v-if="qrCode.status == 0"></i>
            <div class="login-info row col" v-if="qrCode.status == 2">
              <img class="login-info-icon" src="assets/image/login/success@2x.png" >
              <div class="login-info-text">登录成功</div>
            </div>
            <div class="login-info row col" v-if="qrCode.status == 2">
              <img class="login-info-icon" src="assets/image/login/success@2x.png" >
              <div class="login-info-text">等待确认</div>
            </div>
            <div class="login-info row col pointer" v-if="qrCode.status == 3" @click="getQrCode">
              <img class="login-info-icon loain-info-icon-top" src="assets/image/login/notice@2x.png" >
              <div class="login-info-text">二维码失效</div>
              <div class="login-info-ref row">
                <img src="assets/image/login/update@2x.png" >
                <span>刷新二维码</span>
              </div>
            </div>
            <div class="login-info row col" v-if="qrCode.status == 4">
              <img class="login-info-icon" src="assets/image/login/success@2x.png" >
              <div class="login-info-text">登录失败，账号未注册公众号</div>
            </div>
          </div>
          <div class="bottom-info">
            {{$t('使用账号扫码登录')}}
          </div>
        </div> -->
        <div class="login-type-form" >
          <input type="text" class="input" :placeholder="$t('邮箱')" v-model="email"/>
          <input type="password" class="input"  :placeholder="$t('密码')" v-model="password" />
          <navLink class="ret-password" path="/retPassword">{{ $t('找回密码')}}</navLink>
          <Button class="button login-btn" @click="login" :loading="loading.button">{{$t('登录')}}</Button>
        </div>
      </div>
    </div>
    <About></About>
  </div>
</template>

<script>
  export default {
    name: 'login',
    data(){
      return {
        loginType:1,
        email:"",
        password:"",
        loading:{
          button:false
        },
        qrCode:{
          img:"",
          uuid:'',
          status:3
        },
        timer:null
      }
    },
    created() {
      // this.getQrCode()
    },
    mounted() {
      
    },
    computed:{
      
    },
    
    methods:{
      getQrCode(){
        this.qrCode.status = 0
        if(this.timer){
          clearInterval(this.timer)
        }
        this. $apis.login.getQrCode({}).then(res=>{
          this.qrCode.img = res.data.qrCode
          this.qrCode.uuid = res.data.uuId
          this.getScanResult()
          this.timer = setInterval(this.getScanResult,4000)
        })
      },
      getScanResult(){
        if(this.$route.path != '/login'){
          clearInterval(this.timer)
          return
        }
        this.$apis.login.getScanResult({uuId:this.qrCode.uuid}).then(res=>{
          this.qrCode.status = res.data.state
          if(res.data.state == 2){
            clearInterval(this.timer)
            let data = res.data.officialAccount
            // 账号封停
            if(data.accountState == 2){
              this.$message.error(this.$t('账号已封停'));
              return
            }
            
            this.$message.success(this.$t('登录成功'));
            
            
            // 未认证，跳转认证
            if(data.attestation == 2){
              this.$setData('userInfo',data)
              this.$router.push('/reg/setType')
              return
            }else{
              this.$setData('userInfo',data)
              if(data.auditState == 1){
                this.$router.push('/reg/infoRegister')
                return
              }
              this.$store.commit('userInfoSet',data)
              this.$store.commit('isLoginSet',true)
              this.$router.replace('/index')
            }
          }
        })
      },
      login(){
        if(!this.email.trim()){
          this.$message.error(this.$t('请输入邮箱'));
          return
        }
        if(!this.password.trim()){
          this.$message.error(this.$t('请输入密码'));
          return
        }
        this.loading.button = true
        let data = {
          email:this.email,
          password:this.password
        }
        this.$apis.login.login(data).then(res=>{
          setTimeout(()=>{
            this.loading.button = false
            let data = res.data.officialAccount
            // 账号封停
            if(data.accountState == 2){
              this.$message.error(this.$t('账号已封停'));
              return
            }
            this.$message.success(this.$t('登录成功'));
            // 未认证，跳转认证
            if(data.attestation == 2){
              this.$setData('userInfo',data)
              this.$router.push('/reg/setType')
              return
            }else{
              this.$setData('userInfo',data)
              if(data.auditState == 1){
                this.$router.push('/reg/infoRegister')
                return
              }
              this.$store.commit('userInfoSet',data)
              this.$store.commit('isLoginSet',true)
              this.$router.replace('/index')
            }
          },500)
          
          
        }).catch(err=>{
          // this.$message.error(err.data.returnMsg);
          this.loading.button = false
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .login{
    background-color: #F8F7FA;
    height: 100%;
    .card{
      height: 82vh;
      width: 82vw;
      background-image: url(../../../assets/image/pubbg.jpg);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: relative;
      .form{
        width: 291px;
        position: absolute;
        top: 19vh;
        left: 40vw;
        
        .form-title{
          font-size: 15px;
          line-height: 21px;
          color: #333333;
          .login-type{
            color: #2C7BE5;
            cursor: pointer;
          }
		  .title{
			  font-size: 26px;
			  font-weight: bold;
		  }
        }
        
        .login-type-form{
          .qrCode{
            width: 227px;
            height: 227px;
            margin-top: 39px;
            background-color: #F8F8F2;
            
            .el-icon-loading{
              font-size: 56px;
              color: #afafaf;
            }
            
            .qrCode-img{
              position: absolute;
              top: 0;
              left: 0;
            }
            
            .login-info{
              position: absolute;
              width: 100%;
              height: 100%;
              background-color: rgba(0,0,0,0.58);
              .login-info-icon{
                width: 43px;
                height: 43px;
                margin-top: 62px;
                &.loain-info-icon-top{
                  margin-top: 37px;
                }
              }
              .login-info-text{
                font-size: 26px;
                color: #FFFFFF;
                line-height: 36px;
                margin-top: 25px;
                width: 205px;
                text-align: center;
              }
              .login-info-ref{
                margin-top: 14px;
                img{
                  width: 26px;
                  height: 26px;
                  margin-right: 13px;
                }
                span{
                  font-size: 20px;
                  color: #FFFFFF;
                  line-height: 28px;
                }
              }
            }
            
          }
          
          .bottom-info{
            margin-top: 36px;
            text-align: center;
          }
          
          .input{
            margin-top: 26px;
            width: 350px;
            height: 44px;
            padding: 0 11px;
            border-radius: 3px;
            border: 1px solid #DBDBE7;
            box-sizing: border-box;
            &:first-child{
              margin-top: 64px;
            }
          }
          .ret-password{
            width: 350px;
            text-align: right;
            color: #101010;
            line-height: 17px;
            font-size: 12px;
            margin-top: 13px;
          }
          .login-btn{
            width: 350px;
            height: 32px;
            margin: 0 auto;
            margin-top: 27px;
            border-radius: 30px;
			background-color: #427CDF;
          }
          
        }
        
        
        
        
      }
    }
  }
</style>

