<template>
  <div class="backtop" :style="style">
    <div class="item top pointer" @click="topHandle"><img src="assets/image/release/top@2x.png" ></div>
    <div class="item bottom pointer" @click="bottomHandle"><img src="assets/image/release/bottom@2x.png" ></div>
  </div>
</template>

<script>
  export default {
    name: 'backtop',
    props:{
      'visibility-height':{
        type:[String,Number],
        default:200
      },
      target:{
        type:String,
        default:""
      },
      right:{
        type:[String,Number],
        default:40
      },
      bottom:{
        type:[String,Number],
        default:40
      }
    },
    data(){
      return {
        
      }
    },
    created() {
      
    },
    mounted() {
      if(this.target){
        let scrollContent = document.querySelector(this.target)
      }
    },
    computed:{
      style(){
        let right
        if(typeof this.right == 'string'){
          if(this.right.indexOf('px') != -1){
            right = this.right
          }else{
            right = Number(this.right)
            if(right.toString() == 'NaN'){
              right = 40
            }
          }
        }else{
          right = this.right
        }
        let bottom
        if(typeof this.bottom == 'string'){
          if(this.bottom.indexOf('px') != -1){
            bottom = this.bottom
          }else{
            bottom = Number(this.bottom)
            if(bottom.toString() == 'NaN'){
              bottom = 40
            }
          }
        }else{
          bottom = this.bottom
        }
        return {
          right:right + 'px',
          bottom:bottom + 'px'
        }
      }
    },
    methods:{
      topHandle(){
        let scrollContent = document.querySelector(this.target)
        this.scroll(scrollContent,'top')
      },
      bottomHandle(){
        let scrollContent = document.querySelector(this.target)
        this.scroll(scrollContent,'bottom')
      },
      scroll(scrollContent,type){
        setTimeout(()=>{
          if(type == 'top'){
            if(scrollContent.scrollTop > 0){
              scrollContent.scrollTo(0,scrollContent.scrollTop - 10)
              this.scroll(scrollContent,type)
            }else{
              scrollContent.scrollTo(0,0)
            }
          }else{
            if(scrollContent.scrollTop < scrollContent.scrollHeight - scrollContent.clientHeight){
              scrollContent.scrollTo(0,scrollContent.scrollTop + 10)
              this.scroll(scrollContent,type)
            }else{
            }
          }
        },1)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .backtop{
    position: fixed;
    z-index: 9999;
    .item{
      width: 26px;
      height: 26px;
      &.bottom{
        margin-top: 12px;
      }
    }
  }
</style>
