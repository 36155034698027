<template>
  <div class="autoRes">
    <navBar :list="navBar.list" :current.sync="navBar.current" @change="navTabChange"></navBar>
    <!-- 关键字回复 -->
    <card class="Card" v-if="navBar.current == 0">
      <div class="row">
        <div class="switch-name Regular">功能开关</div>
        <m-switch :status.sync="KeywordSwitch[0]" :loading="renewAutoReply"></m-switch>
      </div>
      <div class="switch-desc Regular row">
        <div class="text flex-1">设置关键词和对应的内容，当用户通过公众号发送关键词时，会自动给用户回复对应的答复内容</div>
        <Button class="button" @click="addTagText">添加关键字</Button>
      </div>
      <div class="table-header row">
        <div class="tale-col Regular col-1">关键词</div>
        <div class="tale-col Regular col-2 flex-1">回复内容</div>
        <div class="tale-col Regular col-3">添加时间</div>
        <div class="tale-col Regular col-4">开启</div>
        <div class="tale-col Regular col-5">操作</div>
      </div>
      <div class="table">
        <div class="table-item row" v-for="(item,index) of KeywordData.list" :key="index">
          <div class="tale-col col-1">{{item.keyword}}</div>
          <div class="tale-col col-2 flex-1 line-1 cont" :class="{pointer:item.messageType == 1}" v-html="item.content"  @click="openPic(item)"></div>
          <div class="tale-col col-3">{{item.createdAt | getDate}}</div>
          <div class="tale-col col-4"><m-switch class="switch" :loading="SwitchLoading" :disable="!KeywordSwitch[0]" :id="{item,index}" :status.sync="item.isOpen == 1"></m-switch></div>
          <div class="tale-col col-5 row js-center">
            <img class="icon pointer" @click="deleteKeyWord(item)" src="assets/image/menu/delete@2x.png" >
            <img class="icon pointer" @click="editKeyWord(item)"  src="assets/image/menu/edit@2x.png" >
          </div>
        </div>
        <loadMore status="loading" v-if="KeywordData.loading"></loadMore>
      </div>
    </card>
    <!-- 收到消息回复 -->
    <card class="Card" v-if="navBar.current == 1">
      <div class="row">
        <div class="switch-name Regular">功能开关</div>
        <m-switch :status.sync="KeywordSwitch[1]"></m-switch>
      </div>
      <div class="switch-desc Regular row">
        <div class="text flex-1">收到用户发送的留言消息时，自动给用户回复</div>
      </div>
      <div class="res-content row col">
        <div class="reply-head row">
          <!-- <div class="reply-head-item pointer" @click="insert('emoji')"><img src="assets/image/menu/smile@2x.png" ></div> -->
          <div class="reply-head-item pointer" @click="setType('text')">
            <img v-if="navBar.list[1].sendType == 'text'" src="assets/image/menu/word_selected@2x.png" >
            <img v-else src="assets/image/menu/word@2x.png" >
          </div>
          <div class="reply-head-item pointer" @click="setType('image')">
            <img v-if="navBar.list[1].sendType == 'image'" src="assets/image/menu/pic_selected@2x.png" >
            <img v-else src="assets/image/menu/pic_2@2x.png" >
          </div>
          <div class="reply-head-item pointer" @click="setType('video')">
            <img v-if="navBar.list[1].sendType == 'video'" src="assets/image/menu/video_selected@2x.png" >
            <img v-else src="assets/image/menu/video_2@2x.png" >
          </div>
          <div class="reply-head-item pointer" @click="setType('audio')">
            <img v-if="navBar.list[1].sendType == 'audio'" src="assets/image/menu/music_selected@2x.png" >
            <img v-else src="assets/image/menu/music_2@2x.png" >
          </div>
          <div class="reply-head-item pointer" @click="setType('link')">
            <img v-if="navBar.list[1].sendType == 'link'" src="assets/image/menu/link@2x.png" >
            <img v-else src="assets/image/menu/link@2x.png" >
          </div>
        </div>
        <div class="reply-content row js-center col flex-1">
          <textarea v-if="navBar.list[1].sendType == 'text'" v-model="navBar.list[1].content"></textarea>
          <template v-if="navBar.list[1].sendType != 'text'">
            <div class="select-file row col js-center pointer" v-if="!navBar.list[1].referenceId"  @click="insert(navBar.list[1].sendType)">
              <div class="sc-icon"><img src="assets/image/menu/inventory@2x.png" ></div>
              <div class="sc-text Regular" v-if="navBar.list[1].sendType != 'link'">从素材库选择</div>
              <div class="sc-text Regular" v-else>添加链接</div>
            </div>
            <template v-else>
              <div v-html="navBar.list[1].html"></div>
            </template>
          </template>
        </div>
      </div>
      <div class="btns row js-center">
        <Button class="button button-ho" @click="deleteHtml">删除内容</Button>
        <Button class="button" @click="saveRes">保存</Button>
      </div>
    </card>
    <!-- 被关注回复 -->
    <card class="Card" v-if="navBar.current == 2">
      <div class="row">
        <div class="switch-name Regular">功能开关</div>
        <m-switch :status.sync="KeywordSwitch[2]"></m-switch>
      </div>
      <div class="switch-desc Regular row">
        <div class="text flex-1">用户关注你时会自动回复一条消息</div>
      </div>
      <div class="res-content row col">
        <div class="reply-head row">
          <!-- <div class="reply-head-item pointer" @click="insert('emoji')"><img src="assets/image/menu/smile@2x.png" ></div> -->
          <div class="reply-head-item pointer" @click="setType('text')">
            <img v-if="navBar.list[2].sendType == 'text'" src="assets/image/menu/word_selected@2x.png" >
            <img v-else src="assets/image/menu/word@2x.png" >
          </div>
          <div class="reply-head-item pointer" @click="setType('image')">
            <img v-if="navBar.list[2].sendType == 'image'" src="assets/image/menu/pic_selected@2x.png" >
            <img v-else src="assets/image/menu/pic_2@2x.png" >
          </div>
          <div class="reply-head-item pointer" @click="setType('video')">
            <img v-if="navBar.list[2].sendType == 'video'" src="assets/image/menu/video_selected@2x.png" >
            <img v-else src="assets/image/menu/video_2@2x.png" >
          </div>
          <div class="reply-head-item pointer" @click="setType('audio')">
            <img v-if="navBar.list[2].sendType == 'audio'" src="assets/image/menu/music_selected@2x.png" >
            <img v-else src="assets/image/menu/music_2@2x.png" >
          </div>
          <div class="reply-head-item pointer" @click="setType('link')">
            <img v-if="navBar.list[2].sendType == 'link'" src="assets/image/menu/link@2x.png" >
            <img v-else src="assets/image/menu/link@2x.png" >
          </div>
        </div>
        <div class="reply-content row js-center col flex-1">
          <textarea v-if="navBar.list[2].sendType == 'text'" v-model="navBar.list[2].content"></textarea>
          <template v-if="navBar.list[2].sendType != 'text'">
            <div class="select-file row col js-center pointer" v-if="!navBar.list[2].referenceId"  @click="insert(navBar.list[2].sendType)">
              <div class="sc-icon"><img src="assets/image/menu/inventory@2x.png" ></div>
              <div class="sc-text Regular" v-if="navBar.list[2].sendType != 'link'">从素材库选择</div>
              <div class="sc-text Regular" v-else>添加链接</div>
            </div>
            <template v-else>
              <div v-html="navBar.list[2].html"></div>
            </template>
          </template>
        </div>
      </div>
      
      
      
      
      
      
      
      
      
      <div class="btns row js-center">
        <Button class="button button-ho" @click="deleteHtml">删除内容</Button>
        <Button class="button" @click="saveRes">保存</Button>
      </div>
    </card>
    
    <!-- 添加关键字 -->
    <Popup ref="keyword">
      <card class="insert-Card vote keyword">
        <div class="info">
          <div class="insert-Card-title row col">
            关键词回复
          </div>
          <div class="vote-info">
            <div class="vote-row row ai-top">
              <div class="vote-row-name row">关键词：</div>
              <div class="vote-row-info pos-rel row">
                <input class="input" type="text" maxlength="32" v-model="KeywordData.keyWord" />
                <div class="text-length">{{KeywordData.keyWord.length}}/32</div>
              </div>
            </div>
            <div class="vote-row row ai-top">
              <div class="vote-row-name row">回复内容：</div>
              <div class="keyword-content">
                
                <div class="reply-head row">
                  <!-- <div class="reply-head-item pointer" @click="insert('emoji')"><img src="assets/image/menu/smile@2x.png" ></div> -->
                  <div class="reply-head-item pointer" @click="setType('text')">
                    <img v-if="navBar.list[0].sendType == 'text'" src="assets/image/menu/word_selected@2x.png" >
                    <img v-else src="assets/image/menu/word@2x.png" >
                  </div>
                  <div class="reply-head-item pointer" @click="setType('image')">
                    <img v-if="navBar.list[0].sendType == 'image'" src="assets/image/menu/pic_selected@2x.png" >
                    <img v-else src="assets/image/menu/pic_2@2x.png" >
                  </div>
                  <div class="reply-head-item pointer" @click="setType('video')">
                    <img v-if="navBar.list[0].sendType == 'video'" src="assets/image/menu/video_selected@2x.png" >
                    <img v-else src="assets/image/menu/video_2@2x.png" >
                  </div>
                  <div class="reply-head-item pointer" @click="setType('audio')">
                    <img v-if="navBar.list[0].sendType == 'audio'" src="assets/image/menu/music_selected@2x.png" >
                    <img v-else src="assets/image/menu/music_2@2x.png" >
                  </div>
                  <div class="reply-head-item pointer" @click="setType('link')">
                    <img v-if="navBar.list[0].sendType == 'link'" src="assets/image/menu/link@2x.png" >
                    <img v-else src="assets/image/menu/link@2x.png" >
                  </div>
                </div>
                <div class="reply-content row js-center col flex-1">
                  <textarea v-if="navBar.list[0].sendType == 'text'" v-model="navBar.list[0].content"></textarea>
                  <template v-if="navBar.list[0].sendType != 'text'">
                    <div class="select-file row col js-center pointer" v-if="!navBar.list[0].referenceId"  @click="insert(navBar.list[0].sendType)">
                      <div class="sc-icon"><img src="assets/image/menu/inventory@2x.png" ></div>
                      <div class="sc-text Regular" v-if="navBar.list[0].sendType != 'link'">从素材库选择</div>
                      <div class="sc-text Regular" v-else>添加链接</div>
                    </div>
                    <template v-else>
                      <div v-html="navBar.list[0].html"></div>
                    </template>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btns">
          <div class="btn row js-center">
            <Button class="button button-ho" @click="close">取消</Button>
            <Button class="button" @click="addKeyWord" :loading="loading.addKeyWord">确定</Button>
          </div>
        </div>
      </card>
    </Popup>
    <div id="toolbar0" ref="toolbar0" class="toolbar row" v-show="false"></div>
    <div id="toolbar1" ref="toolbar1" class="toolbar row" v-show="false"></div>
    <div id="toolbar2" ref="toolbar2" class="toolbar row" v-show="false"></div>
    <!-- 插入内容组件 -->
    <insert ref="insert" @change="insertChange"></insert>
    
    <!-- 查看大图 -->
    <popup ref="openPic">
      <div class="pic-close pointer" @click="openPicClose"><img src="assets/image/icon_close@2x%20(1).png" ></div>
      <div class="Card-pic">
        <img :src="pic">
      </div>
    </popup>
    
  </div>
</template>

<script>
  import E from 'wangeditor'
  export default {
    name: 'autoRes',
    data(){
      return {
        loading:{
          addKeyWord:false
        },
        navBar:{
          list:[
            {
              name:"关键字回复",
              key:0,
              sendType:'text',
              content:'',
              referenceId:0,
              info:{},
              html:''
            },
            {
              name:"收到消息回复",
              key:1,
              content:'',
              sendType:'text',
              referenceId:0,
              info:{},
              html:''
            },
            {
              name:"被关注回复",
              key:2,
              content:'',
              sendType:'text',
              referenceId:0,
              info:{},
              html:''
            }
          ],
          current:0
        },
        Keyword:false,
        editor:{0:null,1:null,2:null},
        editorData:{0:null,1:null,2:null},
        KeywordSwitch:{0:false,1:false,2:false},
        KeywordData:{
          loading:false,
          keyWord:"",
          content:"",
          contentTxt:"",
          id:0,
          referenceId:0,
          html:'',
          list:[]
        },
        pic:''
      }
    },
    created() {
      this.init()
    },
    mounted() {
      
    },
    computed:{
      
    },
    methods:{
      init(){
        let data = {
          
        }
        this.KeywordData.loading = true
        this.$apis.menu.queryAutoReplyKeyword(data).then(res=>{
          this.KeywordData.loading = false
          this.KeywordData.list = res.data.responseList 
        })
        this.queryAutoReply()
      },
      // 查询自动回复
      queryAutoReply(){
        let messageType = {
          1:'image',
          2:'video',
          3:'audio',
          4:'text',
          5:'link'
        }
        let data = {
          type:[1,2,3][this.navBar.current]
        }
        this.$apis.menu.queryAutoReply(data).then(res=>{
          if(res.data.autoReplys){
            this.KeywordSwitch[this.navBar.current] = res.data.autoReplys[0].isOpen == 1
            if(this.navBar.current != 0){
              this.navBar.list[this.navBar.current].html = res.data.autoReplys[0].content
              if(res.data.autoReplys[0].messageType == 4){
                this.navBar.list[this.navBar.current].content = res.data.autoReplys[0].content
              }
              this.navBar.list[this.navBar.current].sendType = messageType[res.data.autoReplys[0].messageType]
              this.navBar.list[this.navBar.current].referenceId = res.data.autoReplys[0].referenceId
            }
          }
        })
      },
      // 改变类型
      setType(ev){
        this.navBar.list[this.navBar.current].referenceId = 0
        this.navBar.list[this.navBar.current].html = ''
        this.navBar.list[this.navBar.current].content = ''
        this.navBar.list[this.navBar.current].sendType = ev
      },
      // 编辑自动回复
      renewAutoReply(fun){
        
        let data = {
          type:[1,2,3][this.navBar.current],
          content:'',
          isOpen:this.KeywordSwitch[this.navBar.current]?2:1
        }
        this.$apis.menu.renewAutoReply(data).then(res=>{
          setTimeout(()=>{
            fun()
            this.queryAutoReply()
          },400)
        })
      },
      //保存自动回复
      saveRes(){
        // content (string, optional): 消息内容 ,
        // isOpen (integer, optional): 是否开启 1启动 2关闭 ,
        // messageType (integer, optional): 消息类型——1图片 2视频 3音乐 4文本 5链接 ,
        // referenceId (integer, optional): 文章/图片/视频/音乐Id ,
        // type (integer, optional): 类型—1关键字回复 2收到消息回复 3关注回复
        let messageType = {
          image:1,
          video:2,
          audio:3,
          text:4,
          link:5
        }
        let refID = this.navBar.list[this.navBar.current].referenceId
        if(this.navBar.list[this.navBar.current].referenceId == -1){
          refID = ''
        }
        let data = {
          type:[1,2,3][this.navBar.current],
          content:this.navBar.list[this.navBar.current].content,
          isOpen:this.KeywordSwitch[this.navBar.current]?1:2,
          messageType:messageType[this.navBar.list[this.navBar.current].sendType],
          referenceId:refID
        }
        this.$apis.menu.renewAutoReply(data).then(res=>{
          this.$message.success('保存成功'.$t())
        })
      },
      // 删除内容
      deleteHtml(){
        this.navBar.list[this.navBar.current].content = ''
        this.navBar.list[this.navBar.current].referenceId = 0
        this.navBar.list[this.navBar.current].html = ''
      },
      // 导航栏改变
      navTabChange(ev){
        this.queryAutoReply()
      },
      // 开关改变
      SwitchLoading(fun,obj){
        let {item,index} = obj
        let data = {
          id:item.id,
          type:item.isOpen == 1?2:1
        }
        this.$apis.menu.renewKeywordSwitch(data).then(res=>{
          setTimeout(()=>{
            fun()
            this.init()
          },400)
        })
      },
      // 删除关键字
      deleteKeyWord(item){
        this.$confirm(this.$t('确定删除关键词吗?'), this.$t('提示'), {
          confirmButtonText: this.$t('确定'),
          cancelButtonText: this.$t('取消'),
          type: 'warning'
        }).then(() => {
          let loading = this.mLoading("删除中".$t())
          let data = {
            id:item.id
          }
          this.$apis.menu.deleteAutoReplyKeyword(data).then(res=>{
            loading.close()
            this.$message.success('删除成功'.$t())
            this.init()
          })
        })
      },
      // 编辑关键字
      editKeyWord(item){
        let messageType = {
          1:'image',
          2:'video',
          3:'audio',
          4:'text',
          5:'link'
        }
        this.KeywordData.keyWord = item.keyword
        this.navBar.list[this.navBar.current].content = item.content
        this.navBar.list[this.navBar.current].html = item.content
        this.navBar.list[this.navBar.current].referenceId = item.referenceId
        this.navBar.list[this.navBar.current].sendType = messageType[item.messageType]
        
        this.KeywordData.id = item.id
        this.$refs.keyword.open()
      },
      // 添加关键字
      addTagText(){
        this.KeywordData.keyWord = ''
        this.navBar.list[this.navBar.current].content = ''
        this.navBar.list[this.navBar.current].html = ''
        this.navBar.list[this.navBar.current].referenceId = 0
        this.$refs.keyword.open()
        this.KeywordData.id = 0
      },
      // 创建wedit/ 遗弃
      // createWEdit(){
      //   let editor = new E(this.$refs['toolbar'+this.navBar.current],this.$refs['wangE'+this.navBar.current])
      //   this.editor[this.navBar.current] = editor
      //   editor.config.menuTooltipPosition = 'down'
      //   editor.config.showFullScreen = false
      //   editor.config.zIndex = 100
      //   editor.config.placeholder = ''
      //   // 监听内容改变
      //   editor.config.onchange = (html)=>{
      //     if(this.navBar.current == 0){
      //       this.KeywordData.content = html
      //       this.KeywordData.contentTxt = editor.txt.text().Trim()
      //     }else{
      //       this.navBar.list[this.navBar.current].content = html
      //     }
          
      //   }
        
        
      //   editor.create()
      //   // editor.disable()
      // },
      // 插入内容打开
      insert(type){
        this.$refs.insert.open(type)
      },
      // 插入内容 完成
      insertChange(ev){
        if(ev.type == 'link'){
          console.log(ev);
          this.navBar.list[this.navBar.current].referenceId = 1
          this.navBar.list[this.navBar.current].info = ev.info
          this.navBar.list[this.navBar.current].html = this.$insert(ev)
          this.navBar.list[this.navBar.current].content = `${ev.name}[${ev.url}]`
          if(ev.info){
            this.navBar.list[this.navBar.current].referenceId = ev.info.id
          }else{
            this.navBar.list[this.navBar.current].referenceId = -1
          }
        }else{
          this.navBar.list[this.navBar.current].referenceId = ev.info.id
          this.navBar.list[this.navBar.current].info = ev.info
          this.navBar.list[this.navBar.current].html = this.$insert(ev)
          this.navBar.list[this.navBar.current].content = this.$insert(ev)
        }
      },
      close(){
        this.$refs.keyword.close()
      },
      openPic(item){
        if(item.messageType == 1){
          this.pic = item.resource
          this.$refs.openPic.open()
        }
        
      },
      // 查看大图 关闭
      openPicClose(){
        this.$refs.openPic.close()
      },
      // 添加关键字
      addKeyWord(){
        if(!this.KeywordData.keyWord.trim()){
          this.$message.error(this.$t('请输入关键词'))
          return
        }
        if(!this.navBar.list[this.navBar.current].content.trim()){
          this.$message.error(this.$t('请输入回复内容'))
          return
        }
        let messageType = {
          image:1,
          video:2,
          audio:3,
          text:4,
          link:5
        }
        this.loading.addKeyWord = true
        // content (string, optional): 消息内容 ,
        // isOpen (integer, optional): 是否开启 1启动 2关闭 ,
        // messageType (integer, optional): 消息类型——1图片 2视频 3音乐 4文本 5链接 ,
        // referenceId (integer, optional): 文章/图片/视频/音乐Id ,
        // type (integer, optional): 类型—1关键字回复 2收到消息回复 3关注回复
        let data = {
          content:this.navBar.list[this.navBar.current].content,
          isOpen:1,
          keyword:this.KeywordData.keyWord,
          messageType:messageType[this.navBar.list[this.navBar.current].sendType],
          referenceId:this.navBar.list[this.navBar.current].referenceId,
          id:this.KeywordData.id
        }
        this.$apis.menu.addReplyKeyword(data).then(res=>{
          this.loading.addKeyWord = false
          this.$message.success(this.$t('添加成功'))
          this.$refs.keyword.close()
          this.init()
        }).catch(err=>{
          this.loading.addKeyWord = false
        })
      }
    }
  }
</script>

<style lang="scss">
  .autoRes{
    .Card{
      padding: 18px 0 18px 25px;
      .switch-name{
        font-size: 12px;
        line-height: 17px;
        color: #696B76;
        text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
        margin-right: 13px;
      }
      .switch-desc{
        .text{
          font-size: 12px;
          color: #696B76;
          line-height: 17px;
          text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5);
          margin-top: 12px;
        }
        .button{
          width: 96px;
          height: 34px;
          border-radius: 5px;
          margin-right: 12px;
        }
      }
      .res-content{
        // width: 765px;
        margin-top: 20px;
        margin-right: 24px;
        height: 388px;
      }
      .table-header{
        height: 44px;
        padding-left: 26px;
        border-bottom: 1px solid rgba(219, 219, 231, 0.26);
        .tale-col{
          text-align: center;
          font-size: 12px;
          color: #989BB0;
          line-height: 17px;
          text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5), 0px 2px 3px rgba(220, 231, 239, 0.5);
        }
        .col-1{
          width: 5em;
          text-align: left;
        }
        .col-2{
          // width: 176px;
        }
        .col-3{
          width: 112px;
        }
        .col-4{
          width: 118px;
        }
        .col-5{
          width: 118px;
        }
      }
      .table{
        padding-left: 26px;
        .table-item{
          border-bottom: 1px solid rgba(219, 219, 231, 0.26);
        }
        .tale-col{
          padding: 22px 0;
          text-align: center;
          color: #696B76;
          text-shadow: 0px 2px 3px rgba(220, 231, 239, 0.5), 0px 2px 3px rgba(220, 231, 239, 0.5);
          &.cont{
            p{
              display: inline-block;
              width: 17em;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .col-1{
          width: 12em;
          text-align: left;
        }
        .col-2{
          // width: 176px;
        }
        .col-3{
          width: 112px;
        }
        .col-4{
          width: 118px;
        }
        .col-5{
          width: 118px;
          .icon{
            height: 14px;
            width: 13px;
            margin: 0 8px;
          }
        }
        .switch{
          margin: 0 auto;
        }
      }
      
      .btns{
        margin-top: 22px;
        .button{
          width: 125px;
          height: 32px;
          border-radius: 5px;
          margin: 0 15px;
        }
      }
      
    }
    
    .insert-Card{
      width: 589px;
      
      &.vote{
        padding-bottom: 20px;
        .vote-info{
          padding: 0 17px 0 28px;
          .vote-row{
            margin-top: 14px;
            .vote-row-name{
              width: 5.5em;
              height: 34px;
            }
            .vote-row-info{
              &.height{
                height: 34px;
              }
              &.m-t-12{
                margin-top: 12px;
              }
              .dete-on{
                padding: 0 11px;
              }
              .input{
                width: 433px;
                height: 34px;
                border-radius: 3px;
                border: 1px solid #E3E3E3;
                padding-left: 12px;
                box-sizing: border-box;
                
                padding-right: 54px;
              }
              .text-length{
                position: absolute;
                right: 12px;
                top: 50%;
                transform: translateY(-50%);
              }
              .sx-input{
                width: 399px;
              }
            }
          }
        }
      }
      
      .info{
        .insert-Card-title{
          padding: 28px ;
          display: inline-block;
          font-size: 15px;
          color: #3B3F56;
          line-height: 21px;
        }
      }
      
      &.keyword{
        .keyword-content{
          width: 433px;
        }
      }
      
      
      .btns{
        .btn{
          margin-top: 8px;
          .button{
            width: 125px;
            height: 32px;
            border-radius: 5px;
            margin: 0 14px;
          }
        }
      }
      
    }
    
    
    .reply-content{
      .select-file{
        
      }
      .sc-icon{
        width: 18px;
        height: 19px;
      }
      .sc-text{
        font-size: 12px;
        color: #696B76;
        line-height: 17px;
        margin-top: 4px;
      }
    }
    
    .Card-pic{
      margin: 0 !important;
      img{
        max-width: 582px;
        max-height: 415px;
        border-radius: 7px;
        overflow: hidden;
      }
    }
    
    .pic-close{
      width: 17px;
      height: 17px;
      position: absolute;
      top: 47px;
      right: 71px;
    }
  }
</style>
