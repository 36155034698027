<template>
  <div class="header">
    <img class="logo pointer" src="../../src/assets/image/Lg.png" @click="home">
    <div class="title pointer" @click="home">{{ $t('公众号后台') }}</div>
    
    <!-- 登录后显示 -->
    <div class="box-status" v-show="isLogin">
      
      <div class="search">
        <div class="icon"></div>
        <input class="search-input" type="text" v-model="searchKeyword" @keyup.enter="search" />
        <div class="button" @click="search">{{ $t('搜索') }}</div>
      </div>
      
      <div style="flex: 1;"></div>
      
      <!-- 头像菜单 -->
      <el-dropdown @command="handleUserInfo" class="avatar-box" trigger="click" v-if="isLogin">
        <div class="avatar row js-center" v-if="isLogin">
          <img :src="userInfo.headImg" v-if="userInfo.headImg">
          <!-- <div v-else>{{userInfo.nickname && userInfo.nickname[0]}}</div> -->
        </div>
        
        <!-- 弹出 -->
        <el-dropdown-menu slot="dropdown" class="avatar-dropdown-menu" v-if="isLogin">
          <el-dropdown-item disabled class="user-info">
            <div class="user-name">{{ userInfo.nickname}}</div>
            <div class="user-type">{{userInfo.type == 1?$t("个人"):$t("企业")}}</div>
          </el-dropdown-item>
          <el-dropdown-item command="info" divided>{{$t("资料详情")}}</el-dropdown-item>
          <el-dropdown-item command="outLogin">{{$t("退出登录")}}</el-dropdown-item>
        </el-dropdown-menu>
        
      </el-dropdown>
      
      <!-- 分割 -->
      <div class="division pointer"></div>
      <!-- 设置 -->
      <navLink class="set-up pointer" path="/setUp"><img src="assets/image/home/set@2x.png" ></navLink>
      <!-- 提示 -->
      <navLink class="message pointer" path="/leaveComments" :class="{'h-msg':UnreadMessage || $store.state.radeMsgNumber > 0}"><img src="assets/image/home/icon_message@2x.png" ></navLink>
    </div>
    
    <!-- 未登录显示 -->
    <div class="box-status" v-show="!isLogin && lang">
      <div style="flex: 1;"></div>
      <span class="el-dropdown-link" @click="handleReg">{{ $t('立即注册') }}</span>
      <!-- <span class="el-dropdown-link division-m">|</span> -->
     <!-- <el-dropdown @command="handleLang">
        <span class="el-dropdown-link row">
          {{ $t('简体中文') }}<img class="icon-link" src="../assets/image/icon_arrow_open@2x.png" >
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="cn">中文</el-dropdown-item>
          <el-dropdown-item command="en">English</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
    </div>
    
    
    
    
    
  </div>
</template>

<script>
  export default {
    name: 'Header',
    data(){
      return {
        lang:false,
        UnreadMessage:false,
        searchKeyword:''
      }
    },
    created() {
      this.$store.commit('headerMsgSet',this.init)
      if(this.$route.path == '/login'){
        this.lang = true
      }
    },
    mounted() {
      // this.init()
    },
    computed:{
      isLogin(){
        if(this.$store.getters.isLogin){
          this.init()
        }
        return this.$store.getters.isLogin
      },
      userInfo(){
        return this.$store.getters.userInfo
      }
    },
    watch:{
      $route(to,from){
        if(to.path == '/login'){
          this.lang = true
        }else{
          this.lang = false
        }
      }
    },
    methods:{
      init(){
        this.$apis.home.getUnreadMessage().then(res=>{
          this.UnreadMessage = res.data.unreadMessage
        })
        
        this.IM.$connectReady(()=>{
          this.IM.getConversationList(0).then(res=>{
            if(res.code == 0){
              let radeMsgNumber = 0
              res.data.map(item=>{
                radeMsgNumber += item.unreadMessageCount
              })
              this.$store.commit('radeMsgNumberSet',radeMsgNumber)
            }
          })
        })
      },
      handleUserInfo(ev){
        if(ev == 'outLogin'){
          this.$clearData(['loglevel:webpack-dev-server','lang'])
          this.IM.disconnect().then(res=>{
            console.log('IM断开');
          })
          this.$store.commit('isLoginSet',false)
          this.$router.replace('/login')
        }else{
          this.$router.push('/userInfo')
        }
      },
      handleLang(ev){
        this.$i18n.locale = ev
      },
      home(){
        if(this.isLogin){
          this.$router.replace('/index')
        }else{
          this.$router.replace('/login')
        }
      },
      handleReg(){
        this.$router.push('/reg/user')
      },
      search(){
        this.$store.state.search && this.$store.state.search(this.searchKeyword)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header{
    height: 77px;
    display: flex;
    padding: 0 20px 0 26px;
    align-items: center;
    background-color: #FFFFFF;
    
    .box-status{
      flex: 1;
      display: flex;
      align-items: center;
    }
    
    .logo{
      width: 41px;
      height: 38px;
    }
    .title{
      margin-left: 10px;
    }
    .search{
      display: flex;
      align-items: center;
      margin-left: 40px;
      width: 205px;
      height: 31px;
      background-color: #F9FBFD;
      
      .search-input{
        flex: 1;
        width: 100px;
        height: 100%;
        line-height: 31px;
        padding-left: 12px;
      }
      
      .button{
        width: 51px;
        height: 100%;
        border-radius: 0px 5px 5px 0px;
      }
    }
    
    .avatar-box{
      cursor: pointer;
      position: relative;
      margin-right: 20px;
      &::after{
        content: '';
        display: inline-block;
        border: 4px solid rgba(0,0,0,0);
        width: 0;
        height: 0;
        border-top-color: #989BB0;
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateX(16px)
      }
      .avatar{
        width: 32px;
        height: 32px;
        border-radius: 100%;
        overflow: hidden;
        background-color: rgba(0,0,0,0.06);
      }
      
    }
    
    .division{
      width: 1px;
      height: 34px;
      background-color: #DBDBE7;
      margin: 0 23px 0 20px;
    }
    
    .set-up{
      width: 17px;
      height: 16px;
    }
    
    .message{
      width: 19px;
      height: 19px;
      margin-left: 15px;
      position: relative;
      &.h-msg::after{
        content: '';
        display: inline-block;
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 100%;
        top: -5px;
        right: 0;
        background-color: #DE3838;
      }
    }
    
    .el-dropdown-link{
      color: #2C7BE5;
      font-size: 14px;
      line-height: 19px;
      cursor: pointer;
      &.division-m{
        padding: 0 4px;
      }
      .icon-link{
        width: 9px;
        height: 9px;
        margin-left: 7px;
      }
    }
    
    
  }
</style>
