<template>
  <div class="card" :class="{margin:margin}">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: '',
    data(){
      return {
        
      }
    },
    props:{
      margin:{
        type:Boolean,
        default:true
      }
    },
    created() {
      
    },
    mounted() {
      
    },
    computed:{
      
    },
    methods:{
     
    }
  }
</script>

<style lang="scss" scoped>
  .card{
    background: #FFFFFF;
    box-shadow: 0px 2px 3px 0px rgba(220,231,239,0.5);
    border-radius: 5px;
    position: relative;
    &.margin{
      margin-top: 17px;
    }
  }
</style>
